<template>
  <div>
    <div class="editor">
      <div class="form_docpicker">
        <input
          id="input1"
          ref="input1"
          v-model="inputTime"
          step="2"
          type="time"
          class="my-simple-editor form-control form-control-sm"
          tabindex="1"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, nextTick } from 'vue';

export default {
  name: 'WorkingTimeCellEditor',
  setup(props) {
    const value = ref('value' in props.params ? JSON.parse(JSON.stringify(props.params.value)) : undefined);
    const input1 = ref(null);

    const inputTime = ref(null);
    if (value.value !== undefined) {
      inputTime.value = convertTo24HourFormat(value.value);
    }

    const isPopup = () => {
      return true;
    };

    function convertTo24HourFormat(timeString) {
      // Split the time string into parts: "12:59:58 PM" -> ["12:59:58", "PM"]
      const [time, period] = timeString.split(' ');

      // Extract hours, minutes, and seconds from the time part
      let [hours, minutes, seconds] = time.split(':').map(Number);

      // If the period is PM and the hour is not 12, add 12 to the hour
      if (period === 'PM' && hours !== 12) {
        hours += 12;
      }

      // If the period is AM and the hour is 12, set the hour to 0 (midnight)
      if (period === 'AM' && hours === 12) {
        hours = 0;
      }

      // Format the hours, minutes, and seconds as two-digit numbers and return the result
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    }


    const getValue = () => {
      if (inputTime.value !== value.value) {
        return inputTime.value;
      }
      return value.value;
    }

    const isCancelBeforeStart = () => {
      return false;
    };

    const isCancelAfterEnd = () => {
      return false;
    };

    onMounted(async() => {
      nextTick(() => {
        if (input1.value) {
          input1.value.focus();
        }
      })
    });


    return {
      input1,
      inputTime,

      isPopup,
      getValue,
      isCancelAfterEnd,
      isCancelBeforeStart,

    };
  }
}
</script>

      <style>
      .editor {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: white;
      margin: 0;
      padding: 0;
    }

    .form_docpicker {
      width: 100% !important;
    }
      </style>
