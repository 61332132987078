<template>
  <div class="switch-div d-flex me-2 mb-2">
    <!-- <label
      class="switch"
      for="checkbox"
    >
      <input
        id="checkbox"
        v-model="isChecked"
        type="checkbox"
        @change="switchTheme"
      />
      <div
        class="slider round theme-image"
        :style="{ 'background-image': `url(${themeImage})` }"
      />
    </label> -->
    <v-icon
      name="gi-ceiling-light"
      scale="2"
      :fill="isChecked?'#ffe286':'grey'"
      @click="switchTheme"
    />

    <v-tooltip
      class="mt-2"
      activator="parent"
      location="left"
    >
      {{ !isChecked ? "Dark Mode" : "Light Mode" }}
    </v-tooltip>
  </div>
</template>

<script>
import day from '../../../assets/images/day2.png'
import night from '../../../assets/images/night.png'

import { ref } from 'vue'
export default {
  name: 'WfmThemeSwitch',
  setup(props, context) {
    //refs
    const isChecked = ref(false)
    const themeImage = ref(night)
    //methods
    function switchTheme() {
      isChecked.value = !isChecked.value
      localStorage.setItem('themeDark', isChecked.value)
      document.body.classList.toggle('dark-theme', isChecked.value);
      if (themeImage.value === day) {
        themeImage.value = night
      }
      else {
        themeImage.value = day
      }
    }
    return {
      isChecked,
      switchTheme,
      themeImage
    };
  },
};
</script>

<style>
.switch {
  display: inline-block;
  position: relative;
  width: 55px;

}
.light{
    box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);

}
.theme-image {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 13px;

}

.theme-switch {
  border: 1px solid white;
}

.switch input {
  display: none;
}

.slider {
  bottom: 0;
  padding: 10px;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .10s;
  box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);

}

.slider:before {
  background-color: #fff;
  bottom: 2px;
  content: "";
  height: 16px;
  right: 1px;
  position: absolute;
  transition: .10s;
  width: 16px;
}

input:checked+.slider {
  background-color: black;
}

input:checked+.slider:before {
  transform: translateX(-36px);
}

.slider.round {
  border-radius: 10px;
}

.slider.round:before {
  border-radius: 50%;
}

.slider-image {
  width: 100%;
  height: 100%;
}
</style>
