let reportsData = localStorage.getItem('reportData')

const reportsUtility = () => {

  function getReportsData() {
    return reportsData
  }
  function setReportsData(value) {
    reportsData = value
    localStorage.setItem('reportData', reportsData)
  }

  return { getReportsData, setReportsData};
}

export default reportsUtility;
