<template>
  <div class="employee-header-container">
    <div>
      <span>Employees</span>
    </div>
    <div class="employee-filter-container">
      <span
        class="custom-menu-label"
        @click="menuClickHandler"
      >
        <v-icon
          name="fa-filter"
          fill="#292929"
          scale="0.7"
        />
      </span>
      <span
        class="custom-menu-label"
        @click="clearFilter"
      >
        <v-icon
          v-if="filterApplied"
          name="fa-times"
          fill="#292929"
          scale="0.8"
        />
      </span>
      <span
        class="custom-sort-label"
        @click="sortingClickHandler"
      >
        <v-icon
          v-if="sortState == null"
          name="fa-sort"
          fill="#292929"
          scale="0.8"
        />
        <v-icon
          v-if="sortState === 'asc'"
          name="fa-sort-alpha-down"
          fill="#292929"
          scale="0.8"
        />
        <v-icon
          v-if="sortState === 'desc'"
          name="fa-sort-alpha-up"
          fill="#292929"
          scale="0.8"
        />
      </span>
    </div>
  </div>
</template>

<script>
import { nextTick, ref } from 'vue';
export default {
  setup(props) {
    const sortState = ref(props.params.column.sort);
    function menuClickHandler(event) {
      props.params.showColumnMenu(event.currentTarget);
      nextTick(() => {
        const gridComp = document.getElementsByClassName('ag-grid-gridFilter-input');
        if (gridComp.length > 0) {
          gridComp[0].focus();
        } else {
          console.error("No element found with class 'ag-filter'");
        }
      });
    }
    const filterApplied = ref(Object.keys(props.params.api.getFilterModel()).length > 0);

    function clearFilter() {
      props.params.api.setFilterModel(null);
    }

    function sortingClickHandler() {
      const sortingType = props.params.column.sort;
      if (sortingType === null) {
        props.params.setSort('asc');
        sortState.value = 'asc';
      } else if (sortingType === 'asc') {
        props.params.setSort('desc');
        sortState.value = 'desc';
      } else {
        props.params.setSort('asc');
        sortState.value = 'asc';
      }
    }

    return {
      filterApplied,
      clearFilter,
      sortState,
      sortingClickHandler,
      menuClickHandler
    };
  }
}
</script>

<style>

.employee-header-container {
  display: flex;
  justify-content: space-between;
}

.employee-filter-container span:first-child {
  margin: 10px;
}

.ag-header-cell {
  color: #403f3f;
  font-size: 15px;
}

.ag-theme-quartz .ag-header-cell:not(.ag-header-cell-auto-height) .ag-header-cell-comp-wrapper {
  justify-content: center;
}

.employee-filter-container {
  font-size: 14px;
  width: 45px;
  height: 20px;
  cursor: pointer;
  /* background-color: #ffffff; */
  color: black;
  border-radius: 5px;
}

</style>
