import getConverters from './getConverters';
import getFormInfo from './getFormInfo';


// at this time, we are doing lighweight name prefixes to partitiion calendar utilities by folder
// the prefix "emp" referes to the employees folder
// and the prefix "empLeave" refers to usage with employees data for the purpose of working with the leaves folder
// the prefix "site" may, for example, refer to the sites folder in future, if we choose to display some policy (e.g. site specific holidays) on the calendar
//
// Note that we are using convenient short forms , such as empLeave instead of the more rigoruos employeesLeaves as prefix
//     this is for convenience mostly, but also because the prefix based partitioning is not meant to last too long
//     eventually we'll need to partition these utilities by specific folder(s) which may use the calendar
//
const calendarUtils = () => {

  function formatDate(selectedDate) {
    const dateString = selectedDate;
    // Convert string to Date object
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}T00:00:00.000`;
    return formattedDate;
  }
  // this function is meant to allow selecting cells of employee attendance calendar for requesting leaves
  // as such, the function expects to be called with the employee's attendance data and will return a function which takes
  // a closure of the attendance data.
  // the returned function can then be used as the selectAllow callback of FullCalendar component

  function empLeaveSelectAllow(rowdata) {
    const { getUtcToIstString } = getConverters();

    const attendanceInfo = rowdata.value
    // a callback compatible with selectAllow callback of FullCalendar
    return function(selectionInfo) {
      // selectionInfo start and end give the requested selection range.
      // Check against the closure'd attendanceInfo and return true or false
      // basically disallow selection of Holidays and already Present marked days from leave selection
      let result = true
      for (const key in attendanceInfo) {
        if (key !== 'employees') {
          const thisDate = getUtcToIstString(attendanceInfo[key].attendance_date);
          const startDate = formatDate(selectionInfo.start)
          const endDate = formatDate(selectionInfo.end)
          const thisTag = attendanceInfo[key]['att_type'][0]['code'];
          if (thisDate >= startDate && thisDate < endDate) {
            if (thisTag == 'H' || thisTag == 'P') {
              result = false;
              break;
            }
          }
        }
      }
      return result
    }
  }

  //attendance calendar utility
  function eventClassNames(arg) {
    if (arg.event.title === 'Absent') {
      return ['event_color_absent']
    } else if (arg.event.title === 'Present/OT') {
      return ['event_color_present']
    } else if (arg.event.title === 'Holiday') {
      return ['event_color_holiday']
    }
    return 'event_color'
  }

  function refBy(currEmpAttData, formFolder) {
    const ret = {
      label: 'Apply for leaves',
      component: 'FolderForm',
      name:'Apply leaves',
      params : propsheetBindings(currEmpAttData, formFolder)
    }
    return [ret];
  }

  function propsheetBindings(currEmpAttData, formFolder) {
    const { getCurrentTxn } = getFormInfo()
    const displayFields = formFolder.forms.leaves_create_form;
    const txnToUse =  getCurrentTxn('create', formFolder)

    const ret = {
      rowData : null,
      filledData:{
        employees_id : JSON.stringify(currEmpAttData.value?.employees?.[0]?.code)
      },
      fieldsToDisplay : displayFields,
      formObjectFound : true,
      folder : formFolder,
      label : 'Leave Application',
      refBy : [],
      dateFieldKey: 'leave_period',
      makeParams: true,
      readOnly :false,
      currentTask:'create',
      txnToUse:txnToUse,
      customClass:'h-auto'
    }
    return ret
  }

  function events(currEmpAttData) {
    const { getUtcToIstString } = getConverters();
    const ret = []
    for (const key in currEmpAttData) {
      if (key !== 'employees') {
        const istDateString = getUtcToIstString(currEmpAttData[key].attendance_date);
        const currTitle = currEmpAttData[key]['att_type'][0]['name']['eng'];
        const inputDate = istDateString;
        const date = new Date(inputDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so we add 1
        const day = String(date.getDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;

        ret.push({
          title: currTitle,
          start: formattedDate,
          end: formattedDate,
          display: 'background',
          extendedProps: {
            currAttData: currEmpAttData[key]
          }
        })
      }
    }
    return ret;
  }

  function eventContent(args) {
    const { convertMinutesToHours } = getConverters();
    const att_status = {};
    const currAttData = args.event.extendedProps.currAttData;
    att_status['attendance_type'] = 'att_type' in currAttData ? currAttData['att_type']?.[0]['name']['eng'] : null;
    att_status['shift'] = 'shifts_id' in currAttData ? currAttData['shifts_id']?.[0]['name'] : null;
    att_status['overtime_hrs'] = convertMinutesToHours(currAttData['overtime_minutes']);
    att_status['working_hrs'] = convertMinutesToHours(currAttData['working_minutes']);
    att_status['designation'] = 'designation' in currAttData ? currAttData['designation']?.[0].name : null;
    const spanEl = document.createElement('div');
    spanEl.innerHTML += `<div class="ag-container">
        <div class="ag-status">
          <span class="shift">${att_status['shift']}</span>
        </div>
        <div class="att-status">
          <span class="attendance">${att_status['attendance_type']}</span>
        </div>
        <div class="ag-status">
          <span class="hours">W Hr: ${att_status['working_hrs']} | OT Hr: ${att_status['overtime_hrs']}</span>
        </div>
        <div
        class="ag-status">
        <span>${att_status['designation']}</span>
      </div>
      </div>
    </div>`
    const arrayOfDomNodes = [ spanEl ]
    return { domNodes: arrayOfDomNodes }
  }


  function dataGenerator(rowData) {
    const retVal = {}
    rowData.forEach((each) => {
      const field = getFormattedDateField(each.attendance_date);
      retVal[field] = {
        id: each.id,
        attendance_date: each.attendance_date,
        sites_id: each.sites_id,
        shifts_id: each.shifts_id,
        att_type: each.att_type,
        designation: each.designation,
        working_minutes: each.working_minutes,
        overtime_minutes: each.overtime_minutes,
        exception: each.exception,
        allocations_id: each.allocations_id,
        attendance_status: each.attendance_status,
        in_time: each.observed_in_tmstmp,
        out_time: each.observed_out_tmstmp,
        last_processed_by: each.last_processed_by
      }
    })

    return retVal
  }

  function getFormattedDateField(date) {
    const formattedDate = new Date(date);
    const day = formattedDate.getDate();
    const month = formattedDate.toLocaleString('default', { month: 'short' });
    const year = formattedDate.getFullYear();
    return `${day}${month}${year}`;
  }

  return {
    empLeaveSelectAllow,
    dataGenerator,
    eventClassNames,
    refBy,
    events,
    eventContent,
  }
}

export default calendarUtils;
