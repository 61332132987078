<template>
  <WfmToolbar
    custom-class="action-list p-4 ps-2 pe-2"
    :data-test-id="'list_' + folder?.name"
    :header-show="false"
  >
    <template #left-utility>
      <div class="d-flex">
        <WfmActionsList
          :actions="actionsToPerform.length>0?actionsToPerform:null"
          :data-test-id="'list_' + folder?.name"
          @action-click="performAction"
        />
        <div
          name="left"
          class="navigate-div d-flex"
        >
          <WfmDateSwitcher
            :is-week-picker="false"
            data-test-id="leaves-date-switcher"
            :default-date="defaultDate"
            custom-dp-class="custom-dp-class"
            @update-month-year="updateMonthYear"
          />
        </div>
      </div>
    </template>
    <template #center-utility>
      <div
        name="center"
        class="dropdown-style mb-5 d-flex"
      >
        <div
          v-for="(item, index) in fieldsToShow"
          :key="index"
        >
          <WfmControl
            :key="headerKey"
            :path="item.path"
            :data="paramsToSend"
            :current-folder="folder"
            :field-info="item"
          />
        </div>
      </div>
    </template>
    <template #right-utility>
      <div class="next-prev-div d-flex .d-none .d-md-block .d-lg-none">
        <!-- <div class="d-none d-md-block me-1 my-auto navigate-div">
          <WfmDropDown
            :items="items"
            :drop-down-name="dropdownSelection.label"
            @select-item="selectionHandle"
          />
        </div> -->
        <div
          class="nav-btn"
          @click="monthView"
        >
          Month
        </div>
        <div
          class="nav-btn ms-1"
          @click="yearView"
        >
          Year
        </div>
      </div>
    </template>
  </WfmToolbar>
</template>

<script>
import { ref, onMounted, computed, watch, onActivated } from 'vue';
import WfmControl from '../../../common/form-controls/wfm-control.vue';
import WfmToolbar from '../../../common/wfm-toolbar.vue'
import makeFilter from '../../../composables/makeFilter'
import WfmActionsList from '../../../common/wfm-actions-list.vue'
import actionsListInFolder from '../../../composables/actionsListInFolder'
import useIcons from '../../../composables/getIcons'
import getConverters from '../../../composables/getConverters'
import WfmDateSwitcher from '../../../common/wfm-dateSwitcher.vue'
import { useStore } from 'vuex';
export default {
  name: 'CustomHeaderCalendar',
  components: {
    WfmControl,
    WfmToolbar,
    WfmDateSwitcher,
    WfmActionsList
  },
  props: {
    calendarRef: {
      type: Object,
      default: null,
    },
    folder: {
      type: Object,
      default: null,
    },
    data:{
      type:Object,
      default:null
    },
    readonlyMode:{
      type:Boolean,
      default:false
    }
  },
  emits:['get-filters', 'bulk-txn', 'get-leaves-quota'],
  setup(props, context) {
    const title = ref();
    const dropdownSelection = ref({'label':'Status Filter'})
    const { SQLTsRangeToArray} = getConverters();
    const store = useStore()
    const headerKey = ref(0)
    const bSettings = store.getters['bSettings'];
    const defaultDate = ref()
    const initialDateToShow = computed(() => {
      if (props.data?.leave_days.length > 0) {
        const leavePeriod = props.data.leave_days?.[0]?.leave_period
        const selectedDateRangeArray = SQLTsRangeToArray(leavePeriod, 'UTC', true);
        return selectedDateRangeArray[0]
      }
      else {
        return new Date()
      }
    })

    const paramsToSend = computed(() => {
      const retVal = {}
      retVal.employees_id = props.data?.employees_id?.[0]
      return retVal
    })
    const hasAdminRights = computed(() => {
      return bSettings?.env?.isAO || bSettings?.env?.isRO || bSettings?.env?.code == 'admin'
    })

    const fieldsToShow = computed(() => {
      return [
        {
          'type': 'field',
          'path': 'leaves.employees_id',
          'name': 'employees_id',
          'placeHolder': 'Please Select Employee...',
          'fieldOnlyView' :true,
          'default_value':!hasAdminRights.value ? 'env.code' : null,
          'normal_control': true,
          'readonly_expr':props.readonlyMode
        },

      ];
    });
    const filterFields = computed(() => {
      const ret = [
        {
          'type': 'field',
          'path': 'leaves.leave_days.leave_status',
          'readonly_expr': "env.code!=='admin'",
          'default_value': 'created',
          'normal_control':true,
          'name':'leave_status',
          'fieldOnlyView' :true,
          'is_detail':true
        },
      ];
      return ret;
    })
    const {getIcon} = useIcons()

    const actionsToShow = computed(() => {
      const listOfTxn = props?.folder?.txns;
      const {actionsToShowInList} = actionsListInFolder(listOfTxn)
      const retVal = props.readOnly ? [] : actionsToShowInList()
      return retVal
    })
    const actions = computed(() => {
      const retVal = {name:'Actions', actions:[]}
      for (var i = 0; i < actionsToShow.value.length; i++) {
        const index = i;
        const iconsName = actionsToShow?.value?.[i]?.label?.eng.split(' ')[0];
        const obj = {
          name:actionsToShow?.value?.[i]?.label?.eng,
          icon:getIcon(iconsName)?.name,
          fill:getIcon(iconsName)?.fill,
          action:() => {
            runTxn(actionsToShow.value[index])
          }
        }
        retVal.actions.push(obj)
      }
      return retVal
    })
    const actionsToPerform = computed(() => {
      const retVal = []
      if (actions.value.actions.length > 0) {
        retVal.push(actions.value)

      }
      return retVal


    })
    const items = [
      {'label':'All', 'value':'All'},
      {'label':'Created', 'value':'created'},
      {'label':'Approved', 'value':'final_approved'},
      {'label':'Rejected', 'value':'rejected'},
      {'label':'Cancelled', 'value':'cancelled'},
      {'label':'Processing', 'value':'intermediate_approved'},
    ]

    function performAction(action) {
      console.log('handle action in the list')
      action()
    }
    function updateMonthYear(data) {
      const month = data.month > 12 ? 1 : data.month
      // const day = data.day
      const year = data.year
      const dateToGo = new Date(year, month - 1, 15)
      props.calendarRef.gotoDate(dateToGo)
      const previousMonth = checkPreviousMonth(dateToGo)
      if (previousMonth) {
        const today = new Date()
        const dateToPass = new Date(today.getFullYear(), today.getMonth(), 15)
        context.emit('get-leaves-quota', dateToPass.toISOString())

      }
      else {
        context.emit('get-leaves-quota', dateToGo.toISOString())

      }
    }
    function checkPreviousMonth(datePassed) {
      const currentViewDate = new Date(datePassed)
      const today = new Date()
      const firstDateOfThisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      const firstDateOfCurrentView = new Date(currentViewDate.getFullYear(), currentViewDate.getMonth(), 1)
      if (firstDateOfCurrentView < firstDateOfThisMonth) {
        return true
      }
      else {
        return false
      }
    }
    function selectionHandle(selection) {
      dropdownSelection.value = selection
      let filterParams = null
      if (selection.value !== 'All') {
        const { generateFilterParams } = makeFilter(
          selection.value,
          props.folder,
          null,
          'leaves.leave_days.leave_status.code'
        )
        filterParams = generateFilterParams()
      }
      context.emit('get-filters', filterParams)
    }
    // function todayView() {
    //   // props.calendarRef.today();
    //   const today = new Date()
    //   const thisMonth = today.getMonth();
    //   const thisYear = today.getFullYear();
    //   defaultDate.value = { month: thisMonth, year: thisYear };
    //   const dateToGo = new Date(thisYear, thisMonth, 1);

    //   props.calendarRef.gotoDate(dateToGo);


    // }
    function weekView() {
      props.calendarRef.changeView('resourceTimeGridWeek');
    }

    function monthView() {
      props.calendarRef.changeView('dayGridMonth');
    }

    function yearView() {
      props.calendarRef.changeView('dayGridYear');
    }
    async function runTxn(txn) {
      const txnNameArray = txn.label.eng.split(' ')
      const bulkTxnName = txnNameArray[0]
      context.emit('bulk-txn', txn, bulkTxnName)
    }

    onMounted(() => {
      if (props.data?.leave_days?.length > 0) {
        const thisMonth = initialDateToShow.value.getMonth();
        const thisYear = initialDateToShow.value.getFullYear();
        const dateToGo = new Date(thisYear, thisMonth, 1);
        defaultDate.value = { month: thisMonth, year: thisYear };
        props.calendarRef.gotoDate(dateToGo);
      }
    });

    function touchHeader() {
      headerKey.value = Math.floor(Math.random() * 1000000)

    }
    onActivated(() => {
      // when custom header is activated again
      touchHeader()
    })

    watch(
      () => props.data,
      (newData) => {
        if (newData?.leave_days?.length > 0) {
          const leavePeriod = newData.leave_days[0].leave_period;
          const selectedDateRangeArray = SQLTsRangeToArray(leavePeriod, 'UTC', true);
          const dateChanged = selectedDateRangeArray[0];
          const thisMonth = dateChanged.getMonth();
          const thisYear = dateChanged.getFullYear();
          defaultDate.value = { month: thisMonth, year: thisYear };
          props.calendarRef.updateSize()
        }
      }
    );

    return {
      title,
      fieldsToShow,
      filterFields,
      monthView,
      weekView,
      yearView,
      items,
      selectionHandle,
      actionsToPerform,
      performAction,
      dropdownSelection,
      paramsToSend,
      defaultDate,
      updateMonthYear,
      headerKey
    };
  },
};
</script>

<style>
.custom-dp-class .dp__input{
  height: 32px !important;
  background: #2f688d !important;
  border:none;
  color:white !important;
  width: 162px !important;
}
.dropdown-style .vs--disabled .vs__dropdown-toggle{
    cursor: var(--vs-disabled-cursor);
    background-color: #2f688d !important;
}
.dropdown-style .vs--disabled .vs__search{
    cursor: var(--vs-disabled-cursor);
    background-color: #2f688d !important;
}
.dropdown-style .vs--disabled .vs__open-indicator{
    cursor: var(--vs-disabled-cursor);
    background-color: #2f688d !important;
}
.dropdown-style .vs--disabled .vs__clear{
    cursor: var(--vs-disabled-cursor);
    background-color: #2f688d !important;
}
.filter-fields .vs__dropdown-toggle{
  width:200px;
  margin-top:2px;
  height: 27px !important;
}

.bg-secondary{
  background: #85929e !important;
}
.halfday-container{
      background: linear-gradient(90deg, #83acc8 50%, #e3eef6 50%);
      padding:5px;
}
.fullday-container{
      background: rgb(144,215,245);
            padding:5px;

}

.dropdown-style .vs__dropdown-toggle{
  background-color: #2f688d;
  border: none;
  color: white;
  width: 220px;
  height: 20px;
  margin-top: 20px;
  background-color: none;

}
.dropdown-style .v-select{
  border:none;
  border-bottom: 1px solid white;
  color: white;
}
.dropdown-style .vs__selected{
  color:white;
}
.dropdown-style .vs__open-indicator{
  color:white;
}
.dropdown-style .close-class{
  color:white;
  fill:white;
}
.center-div {
  /* color: #6faa5dff; */
  border: 1px solid white;
  height: fit-content;
  padding: 5px;
  font-size: 18px;
  border-radius: 8px;
}
.navigate-div {
  /* color: #6faa5dff; */
  height: fit-content;
  font-weight: 600;
  font-size: 18px;
  border-radius: 8px;
}


/* .nav-btn:hover {
    color: white;
    border: 1px solid white;
    background-color: var(--co-main-color);
} */

.nav-btn {
  border: 2px solid #2e688dff;
  background-color: white;
  color: #2e688dff;
  border-radius: 6px;
  font-size: 18px;
  padding-left: 8px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  padding-right: 8px;
}
.nav-btn:hover {
  background-color: #2e688dff;
  color: white;
}
.leaves-types-label{
  font-size: 20px;
}
.center-div {
  /* color: #6faa5dff; */
  border: 1px solid white;
  height: fit-content;
  padding: 5px;
  font-size: 18px;
  border-radius: 8px;
}


/* .nav-btn:hover {
    color: white;
    border: 1px solid white;
    background-color: var(--co-main-color);
} */


</style>
