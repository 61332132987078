<template>
  <div v-if="showField">
    <div class="d-flex justify-content-between mb-3 mt-2">
      <div>
        {{ params.fieldOptions.label[currentLanguage] }}
      </div>
      <div class="d-flex">
        <WfmGlobalSearch
          v-if="showSearchBar"
          search-input-class="detail-search-input"
          search-div-class="detail-search-div"
          placeholder="Search"
          :hide-icon="true"
          :data-test-id="detailSearchTestId"
          @handle-search="handleSearchInDetail"
        />
        <div
          class="ms-2"
          tabindex="0"
          :data-test-id="detailAddTestId"
          @keydown.enter="handleEnterKey(addNewRow)"
          @click="addNewRow"
        >
          <v-icon
            name="md-addcircleoutline"
            scale="1.2"
          />
        </div>
        <div
          class="ms-2"
          tabindex="0"
        >
          <v-icon
            :name="showSearchBar?'md-searchoff-sharp':'md-search-sharp'"
            scale="1.2"
            @keydown.enter="handleEnterKey(toggleSearchBar)"
            @click="toggleSearchBar()"
          />
        </div>
      </div>
    </div>
    <div class="detail-table mb-2">
      <slot
        :row-data="rowData"
        :column-def="columnDef"
        :unique-key="uniqueKey"
        :detail-name="detailName"
        row-model-type="clientSide"
        dom-layout="normal"
        pagination="false"
        suppress-pagination-panel="false"
        header-height="27"
        :detail-grid-api="currentGridApi"
        :render="render"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed, inject} from 'vue'
import {keys} from '../../provideKeys.js'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import gridConfig from '../../composables/getGridData'
import WfmGlobalSearch from '../wfm-global-search.vue'
export default {
  name: 'WfmDetail',
  components: { WfmGlobalSearch},
  props: {
    params: {
      type: Object,
      default: null
    }
  },
  emits: ['input-change', 'adjust-height'],
  setup(props, context) {
    //variables
    const store = useStore()
    const i8vue = useI18n()
    const showSearchBar = ref(false)
    const formData = inject(keys.formData)
    const currentLanguage = i8vue.locale.value
    const columnDef = ref([])
    const detailName = ref(props.params.fieldOptions.name)
    const fieldPath = props.params.fieldOptions?.path
    const pathArray = fieldPath?.split('.')
    const detailParams = ref()
    const render = ref(false)
    const uniqueKey = ref(props.params.fieldOptions.name)
    const basePathOfDetail = fieldPath || props.params.currentFolder.name

    const detailPrimaryKeyId = props.params.data?.[pathArray?.[0]]?.[0]?.id || props.params.data?.id

    if (!props.params.data?.[detailName.value] && pathArray) {
      const parentField = pathArray[0]
      detailParams.value = props.params.data?.[parentField]?.[0]?.[detailName.value]

    } else {
      //if its found thn continue
      detailParams.value = props.params.data?.[detailName.value]
    }
    const fullList = ref(detailParams.value ? detailParams.value : [])

    //computed
    const isEditNotAllowed = inject(keys.editNotAllowed, false)
    const detailAddTestId = computed(() => {
      return `${uniqueKey.value}/add-new`
    })
    const  detailSearchTestId = computed(() => {
      return `${uniqueKey.value}/detail/search-bar`

    })

    const fieldsToShow = computed(() => {
      const uniqueFields = [...new Set(props.params.fieldOptions.toShow)];
      uniqueFields.push('id')
      uniqueFields.push('deleted')
      return uniqueFields
    })
    const fields = computed(() => {
      const retVal = {}
      const uniqueFields = [...new Set(props.params.fieldOptions.toShow)];
      if (uniqueFields.length === 0) {
        return props.fieldOptions.fields
      } else {
        //adding the deleted field in everything
        if (!isEditNotAllowed.value) {
          uniqueFields.push('deleted')
        }
        // const { processVisibleExpr } = processExpr()
        uniqueFields.map((each) => {
          if (each in props.params.fieldOptions.fields) {
            Object.assign(retVal, { [each]: props.params.fieldOptions.fields[each] })
            Object.assign(retVal[each], { path: fieldPath + `.${each}` })
          }
        })
        return retVal
      }
    })
    const mandatoryStatus = inject(keys.mandatoryFilter, false)

    const showField = computed(() => {
      if (!mandatoryStatus?.value) {
        return true
      }
      else {
        return false
      }
    })

    const rowData = computed(() => {
      const filteredData = fullList.value
        .map((obj) => {
          const newObj = Object.keys(obj)
            .filter((key) => fieldsToShow.value.includes(key))
            .reduce((acc, key) => {
              acc[key] = obj[key]
              return acc
            }, {})
          return newObj
        })
        .filter((obj) => obj.deleted !== true)

      return filteredData
    })
    const currentGridApi = computed(() => {
      const detailGridApis = store.getters['agGridModule/detailGridApi']
      const gridApi = detailGridApis.find((api) => api.tableName === uniqueKey.value);
      return gridApi
    })
    //methods
    function addNewRow() {
      //add a new row
      var node = currentGridApi.value.gridApi.applyTransaction({
        add: [{}],
        // addIndex: currentGridApi.value.gridApi.getDisplayedRowCount() + 1
        addIndex: 0
      })
      node.add[0].data.id = uuidv4()
      node.add[0].data.newRowAdded = true
      const firstColKey = currentGridApi.value.gridApi.columnModel.columnDefs[1].field;

      currentGridApi.value.gridApi.ensureColumnVisible(firstColKey);
      currentGridApi.value.gridApi.setFocusedCell(0, firstColKey);
      currentGridApi.value.gridApi.startEditingCell({rowIndex: 0, colKey: firstColKey});

      const activeElement = document.activeElement;
      const firstInput = activeElement.querySelector('input');
      firstInput.focus()

      if (formData.value[detailName.value]) {
      } else {
        formData.value[detailName.value] = []
      }
      render.value = true
      //formData.value[detailName.value].unshift(node?.add[0].data)
    }
    function removeNewRow() {
      //remove a new row
      const selectedRow = currentGridApi.value.gridApi.getSelectedRows()
      //TODO:-exception cases check and fix when occurs
      if (selectedRow.length > 0 && selectedRow[0].deleted) {
        currentGridApi.value.gridApi.applyTransaction({ remove: selectedRow })
      }
    }
    function toggleSearchBar() {
      showSearchBar.value = !showSearchBar.value
      const selector = `input[data-test-id="${detailSearchTestId.value}"]`;
      const inputElement = document.querySelector(selector);
      // Check if the element was found
      if (inputElement != null) {
        inputElement.focus()
      } else {
        console.log('Input element not found.');
      }


    }
    const { detailColumnDef } = gridConfig()

    columnDef.value = detailColumnDef(fields.value, uniqueKey.value, props.params.currentFolder, onCellValueChanged, addNewRow)
    function onCellValueChanged(value, rowNode) {
      //if deleted is set true in detail with new row
      //input-change is not emitted but row is removed
      // currentGridApi.value.gridApi.setColumnDefs(columnDef.value);
      if (value?.newRowAdded && value?.deleted) {
        //if the value if deleted true then select the row and remove it
        currentGridApi.value.gridApi.deselectAll();
        currentGridApi.value.gridApi.getRowNode(rowNode.id).setSelected(true);
        removeNewRow();
        formData.value[detailName.value] = formData.value[detailName.value].filter((each) => each.id !== (value ? value.id : undefined));
      }
      //else emit change
      else {
        if (value?.deleted) {
          //if the value if deleted true then select the row and remove it
          currentGridApi.value.gridApi.deselectAll();
          currentGridApi.value.gridApi.getRowNode(rowNode.id).setSelected(true);
          //make sure to remove the row in ui
          removeNewRow()
        }
        context.emit('input-change', value, basePathOfDetail)
      }
      formData.value[detailName.value].pathofDetail = basePathOfDetail
      formData.value[detailName.value].primaryKey = detailPrimaryKeyId
    }
    function handleSearchInDetail(searchTerm) {
      // search in detail table making a local AgGrid quick search
      currentGridApi.value.gridApi.setQuickFilter(searchTerm)
    }
    function handleEnterKey(actionName) {
      actionName()
    }
    // function isCellEmpty(rowId, colId) {
    //   const rowNode = this.gridApi.getRowNode(rowId)
    //   if (rowNode) {
    //     const cellValue = rowNode.data[colId]
    //     return cellValue === null || cellValue === undefined || cellValue === ""
    //   }
    //   return true
    // }

    //hook
    return {
      detailName,
      rowData,
      columnDef,
      addNewRow,
      removeNewRow,
      uniqueKey,
      currentLanguage,
      handleSearchInDetail,
      currentGridApi,
      showField,
      isEditNotAllowed,
      detailAddTestId,
      detailSearchTestId,
      render, //to force a rerender of the field
      toggleSearchBar,
      showSearchBar,
      handleEnterKey
    }
  }
}
</script>


<style>
.detail-head {
  /* background-color: var(--toolbar-color); */
  color: white;
  padding: 0px;
}

.detail-table {
  background-color: var(--toolbar-color);
  cursor: pointer;
  margin-top: 5px;
  /* border:1px solid var(--detail-grid-border); */
  border-top: none;
  border-radius: 4px;
}

.detail-search-input {
  width: 200px;
  height: 25px !important;
  color: var(--main-color) !important;
  border-bottom: 1px solid var(--main-color) !important;
  font-size:10px;
  border-radius: 0px !important;

  box-shadow: none !important;
}

.detail-search-div{

  box-shadow: none !important;
}

.detail-header-text{
  font-size: 0.8rem;
  font-weight: 700;
}
</style>
