<template>
  <div :ref="dropDownToggleRef">
    <div
      class="dropdown-container"
      :class="customContainerClass"
      @click="toggleDropdown"
    >
      <div
        class="dropdown-container d-flex"
      >
        <div>
          <v-icon
            :name="arrowName"
            scale="1"
          />
        </div>
        <div>
          {{ dropDownName }}
        </div>
      </div>
    </div>
    <div
      v-if="isOpen"
      :ref="dropDownMenuRef"
      class="dd-menu mt-3"
      :class="customMenuClass"
    >
      <li
        v-for="(item, index) in items"
        :key="index"
        @click="selectItem(item)"
      >
        <div class="d-flex">
          <div>
            <v-icon
              :name="listIcon"
              scale="0.7"
            />
          </div>

          <div class="ms-2">
            {{ item.label }}
          </div>
        </div>
      </li>
    </div>
  </div>
</template>

<script>
import {ref, computed} from 'vue'
import getUtility from '../composables/getUtility'

export default {
  name: 'WfmDropDown',
  props: {
    items: {
      type: Array,
      required: true,
    },
    dropDownName:{
      type:String,
      default:null
    },
    customContainerClass:{
      type:String,
      default:null
    },
    customMenuClass:{
      type:String,
      default:null
    },
    listIcon:{
      type:String,
      default:null
    }
  },
  emits:['select-item'],
  setup(props, context) {
    const dropDownMenuRef = ref(props.dropDownName + 'dropdown')
    const dropDownToggleRef = ref(props.dropDownName + 'toggle')
    //TODO=add the outside click listener later
    const { registerClickOutside } = getUtility()
    registerClickOutside(dropDownToggleRef, () => { isOpen.value = false })

    const isOpen = ref(false)
    const arrowName = computed(() => {
      return isOpen.value ? 'md-arrowdropup' : 'md-arrowdropdown'
    })

    function toggleDropdown() {
      isOpen.value = !isOpen.value;
    }
    function selectItem(item) {
      isOpen.value = false;
      context.emit('select-item', item);
    }

    return {
      isOpen,
      selectItem,
      toggleDropdown,
      arrowName,
      dropDownMenuRef,
      dropDownToggleRef

    };
  },
};
</script>

<style scoped>
.dropdown-container {
  position: relative;
  display: inline-block;
}


.dd-menu{
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  list-style-type: none;
  border-radius: 8px;
  padding: 0;
  margin: 0;
  width: fit-content;
  z-index:100;
  border:2px solid var(--co-main-color)
}
.dd-menu:after {
  content: "";
   display: block;
   position: absolute;
   left: 3rem;
   top: -10px;
   border-bottom: 10px solid var(--co-main-color);
   border-left: 10px solid transparent;
   border-right: 10px solid transparent;
}
.dd-menu li {
  padding: 10px;
  font-weight: 400;
  cursor: pointer;
  color:var(--main-color)
}

.dd-menu li:hover {
  background-color: var(--co-main-color);
  color:white;
}

</style>
