<template>
  <!-- TODO:- need to make the submit call this form submits -->
  <!-- formData should be handled from here -->
  <form
    :data-test-id="dataTestId"
    class="form-container"
    @submit.prevent="handleSubmit"
  >
    <slot name="form-header" />
    <slot name="form-body" />
    <slot name="form-footer" />
  </form>
</template>

<script>
export default {
  name: 'WfmForm',
  components: {
  },
  props: {
    fields: {
      type: Object,
      default: null
    },
    dataTestId: {
      type: String,
      default: ''
    }
  },

  emits: ['submit-form'],
  setup(props, { emit }) {
    //store
    function handleSubmit() {
      emit('submit-form')
    }

    return {
      handleSubmit
    };
  },
};
</script>

<style scoped>
.input-group-text {
  background-color: antiquewhite;
}
.form-container{
  background-color: var(--main-background-color);
}
/* For Webkit-based browsers like Chrome and Safari */
.form-container:-webkit-scrollbar {
  display: none;
}

/* For Firefox */
.form-container {
  scrollbar-width: none; /* Firefox 64 and later */
}


</style>
