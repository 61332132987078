<template>
  <!-- Open Modal with required form -->
  <WfmModal @close="closeModal">
    <!-- header section modal -->
    <template #header>
      <div class="d-none d-sm-block my-auto mx-auto">
        <h6 class="text-capitalize modal-header-name">
          {{ currentTask }}
        </h6>
      </div>
    </template>
    <!-- body section modal -->
    <template #body>
      <div
        class="mb-3 upload-form"
        style="padding: 20px"
      >
        <label
          for="formFile"
          class="form-label"
        >Browse File To Upload</label>
        <div class="input-group">
          <input
            id="bulk-upload"
            name="bulk-upload"
            class="form-control"
            type="file"
            accept=".csv,.xlsx,image/*,.pdf"
            aria-label="Upload"
            @change="handleFileInputChange"
          >
        </div>
        <div
          v-if="uploading"
          class="upload-status {{ uploading }}"
        >
          <span>{{ progress }}</span>
        </div>
      </div>
    </template>
    <!-- footer section modal -->
    <template #footer>
      <div class="footer-content d-flex justify-content-between ">
        <div>
          <WfmButton
            button-class="ms-1 save-btn me-2"
            @button-click="saveHandle"
          >
            Save
          </WfmButton>
        </div>
      </div>
    </template>
  </WfmModal>
</template>

<script>
import { ref } from 'vue';
import { sendMessage } from '../../services/websocket';
import { toast } from 'vue3-toastify';
import WfmModal from '../../common/wfm-modal.vue';
import WfmButton from '../../common/wfm-button.vue';
import bulkUpload from '../../composables/bulkUpload';
import getFatRow from '../../composables/fatRowGenerator';

export default {
  name: 'UploadForm',
  components: {
    WfmModal,
    WfmButton,
  },
  props: {
    currentTask: {
      type: String,
      default: null,
    },
    txnToUse:{
      type:Object,
      default:null
    },
    agGridApi:{
      type:Object,
      default:null
    }
  },
  emits:['close-upload'],
  setup(props, context) {
    // Variables
    const fileUploadResponse = ref(null);
    const selectedFile = ref(null);
    const uploading = ref('');
    const progress = ref('Upload file');
    const notificationId = ref();
    const listIcon = ref('bi-list');
    const listExpanded = ref(false);
    const formSaveClicked = ref(false);

    // Methods
    function closeModal() {
      try {
        formSaveClicked.value = false;
        listIcon.value = 'bi-list';
        //emit to close
        context.emit('close-upload')
      } catch (error) {
        console.log(error);
      }
    }

    async function handleFileInputChange(event) {
      const file = event.target.files;
      selectedFile.value = file;
      await uploadFile();
    }

    async function uploadFile() {
      try {
        if (selectedFile.value !== null) {
          progress.value = 'Uploading';
          // console.log(fileFormData);
          const { runTxn } = bulkUpload();
          const response = await runTxn(selectedFile.value, props.txnToUse);
          if (response !== 'error') {
            progress.value = 'File Uploaded Successfully';
            uploading.value = 'uploaded';
            fileUploadResponse.value = response;
          } else {
            uploading.value = 'error';
            progress.value = 'Upload Failed';
          }
        }
      } catch (err) {
        toast.error(err, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }

    async function saveHandle() {
      try {
        formSaveClicked.value = true;
        notificationId.value = toast.loading('Working on it...', {
          position: toast.POSITION.TOP_CENTER,
          className: 'toast-body',
        });
        if (fileUploadResponse.value !== 'error') {
          const uploadTxn = await sendMessage(fileUploadResponse.value);
          if (uploadTxn.output.type === 'success') {
            //emit to close
            context.emit('close-upload')
            toast.update(notificationId.value, {
              render: 'Done',
              autoClose: 1000,
              closeButton: true,
              type: 'success',
              isLoading: false,
            });
            if (uploadTxn.output.type === 'success') {
              const records = [];
              for (let i = 0; i < uploadTxn.output.data.records.length; i++) {
                records.push(JSON.parse(uploadTxn.output.data.records[i]))
              }
              uploadTxn.output.data.records = records;
            }
            const { fatRowGenerator } = getFatRow();
            const listData = fatRowGenerator(uploadTxn);
            props.agGridApi.applyTransaction({
              add: [listData[0]],
              addIndex: 0,
            });
          } else if (uploadTxn.output.type === 'error') {
            toast.update(notificationId.value, {
              render: uploadTxn.output.message,
              type: 'error',
              isLoading: false,
              closeButton: true,
              position: toast.POSITION.TOP_CENTER,
            });
            context.emit('close-upload')
          }
        } else if (fileUploadResponse.value[0].output.type === 'error') {
          console.log(fileUploadResponse.value[0].output.message);
        }
      } catch (err) {
        throw err;
      }
    }

    return {
      uploadFile,
      handleFileInputChange,
      uploading,
      progress,
      closeModal,
      saveHandle,
      listIcon,
      listExpanded,
    };
  },
};
</script>

<style>
.upload-status {
  font-size: 1rem;

  /* &.uploading {
    color: #2e688dff;
  }

  &.uploaded {
    color: rgb(127, 169, 113);
  }

  &.error {
    color: rgb(161, 77, 77);
  }

  &.show-progress {
    color: #292929;
  } */
}

.footer-content {
  width: 100%;
}

.modal-header-name {
  color: #2e688dff;
  font-weight: 600;
}

.upload-form{
  width: fit-content !important;
}

.save-btn {
  border: 2px solid #2e688dff;
  background-color: white;
  color: #2e688dff;
  border-radius: 6px;
  font-size: 18px;
  padding-left: 8px;
  font-weight: 600;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  padding-right: 8px;

  &:hover {
    background-color: #2e688dff;
    color: white;
  }
}
</style>
