<template>
  <v-icon
    name="md-close-round"
    scale="0.8"
    class="close-class"
  />
</template>

<script>
import { ref } from 'vue';
export default {
  name: 'DropdownClose',

  setup() {
    const value = ref(null);

    return {
      value,
    };
  },
};
</script>

<style scoped>
.close-class{
  color:var(--controls-font-color)
}
</style>
