<template>
  <v-icon
    name="md-arrowdropdown-sharp"
    scale="1.3"
    :fill="`var(--controls-font-color)`"
  />
</template>

<script>
import { ref } from 'vue';
export default {
  name: 'DropdownToggle',

  setup() {
    const value = ref(null);

    return {
      value,
    };
  },
};
</script>

<style scoped>
.toggle-class{
  color:var(--controls-font-color) !important;
}
</style>
