const controlUtility = (invalidFields, mandatoryFields) => {
  //checks invalid fields and stores
  function invalidCheck(data) {
    if (data === 'reset') {
      invalidFields.value = []
    }
    else {
      //if the control entry is invalid push the property name to the array
      if (data.value) {
        if (!invalidFields?.value?.includes(data.propertyName)) {
          invalidFields?.value?.push(data.propertyName)
        }
      }
      else {
        for (let i = invalidFields?.value?.length - 1; i >= 0; i--) {
          if (invalidFields?.value?.[i] === data.propertyName) {
            invalidFields?.value.splice(i, 1);
          }
        }
      }
    }
    return invalidFields.value
  }

  //checks mandatoryFields and stores
  function mandatoryCheck(data) {
    if (data === 'reset') {
      mandatoryFields.value = []
    }
    else {
      if (data.value) {
        if (!mandatoryFields?.value?.includes(data.propertyName)) {
          mandatoryFields?.value?.push(data.propertyName)
        }
      }
      else {
        // state.mandatoryFields = state.mandatoryFields.filter((n) => n !== value.propertyName);
        for (let i = mandatoryFields?.value?.length - 1; i >= 0; i--) {
          if (mandatoryFields?.value[i] === data.propertyName) {
            mandatoryFields?.value.splice(i, 1);
          }
        }

      }
    }
    return mandatoryFields.value
  }

  function defaultValuesAssign(rowData, fieldOptions) {
    //in cases of leaves applying from calender check proper
    for (const key in rowData) {
      if (key == fieldOptions?.name) {
        fieldOptions.default_value = rowData[key]
      }
    }
    return fieldOptions
  }


  return { invalidCheck, mandatoryCheck, defaultValuesAssign}
}

export default controlUtility
