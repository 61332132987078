<template>
  <div class="editorContainer form-group">
    <div class="selectInput">
      <label for="inputText1">{{ name[0] }}</label>
      <v-select
        ref="inputText0"
        v-model="selected_allocationType"
        label="name"
        :placeholder="allocation_type_placeHolder"
        :options="valueToUpdate.allocation_type"
        clear-search-on-select
        :map-keydown="createKeydownHandler(0)"
        @selecting="preventHover"
        @mouseover.prevent
      >
        <template #option="option">
          <span
            :class="{'selected_option':selected_allocationType && (selected_allocationType === option.name)}"
            @mouseleave="enableHover"
          >
            {{ option.name }}
          </span>
        </template>
      </v-select>
    </div>
    <div class="selectInput">
      <label for="inputText1">{{ name[1] }}</label>
      <v-select
        ref="inputText1"
        v-model="selected_site"
        label="name"
        :placeholder="site_placeHolder"
        :options="valueToUpdate.sites_id"
        clear-search-on-select
        :map-keydown="createKeydownHandler(1)"
      >
        <template #option="option">
          <span :class="{'selected_option':selected_site && (selected_site === option.name)}">
            {{ option.name }}
          </span>
        </template>
      </v-select>
    </div>
    <div class="selectInput">
      <label for="inputText2">{{ name[2] }}</label>
      <v-select
        ref="inputText2"
        v-model="selected_shift"
        label="name"
        :placeholder="shift_placeHolder"
        :options="valueToUpdate.shifts_id"
        clear-search-on-select
        :map-keydown="createKeydownHandler(2)"
      >
        <template #option="option">
          <span :class="{'selected_option':selected_shift && (selected_shift === option.name)}">
            {{ option.name }}
          </span>
        </template>
      </v-select>
    </div>
    <div class="selectInput">
      <label for="inputText3">{{ name[3] }}</label>
      <v-select
        ref="inputText3"
        v-model="selected_department"
        label="name"
        :placeholder="department_placeHolder"
        :options="valueToUpdate.departments"
        clear-search-on-select
        :map-keydown="createKeydownHandler(3)"
      >
        <template #option="option">
          <span :class="{'selected_option':selected_department && (selected_department === option.name)}">
            {{ option.name }}
          </span>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed, nextTick } from 'vue';
export default {
  name: 'CellEditor',
  setup(props) {
    const inputText0 = ref(null);
    const inputText1 = ref(null);
    const inputText2 = ref(null);
    const inputText3 = ref(null);
    const value = ref('value' in props.params ? JSON.parse(JSON.stringify(props.params.value)) : undefined);
    const selectedContract = props.params?.cellEditorDataSource?.['contracts_id'][0];
    const name = ref([]);
    const dataSource = props.params?.cellEditorDataSource;
    const valueToUpdate = ref({});
    const selected_allocationType = ref();
    const default_allocationType = computed(() => {
      if (value.value !== undefined && value.value.hasOwnProperty('allocation_type')) {
        return value.value['allocation_type'][0]['name']['eng'];
      } else {
        return null;
      }
    })
    selected_allocationType.value = default_allocationType.value;
    const allocation_type_placeHolder = computed(() => {
      if (default_allocationType.value !== null) {
        return default_allocationType.value;
      } else {
        return 'Select allocation type'
      }
    })
    const selected_site = ref();
    const default_site = computed(() => {
      if (value.value !== undefined && value.value.hasOwnProperty('sites_id')) {
        return value.value['sites_id'][0]['name']['eng'];
      } else {
        return null;
      }
    })
    selected_site.value = default_site.value;
    const site_placeHolder = computed(() => {
      if (default_site.value !== null) {
        return default_site.value;
      } else {
        return 'Select site'
      }
    })
    const selected_shift = ref();
    const default_shift = computed(() => {
      if (value.value !== undefined && value.value.hasOwnProperty('shifts_id')) {
        return value.value['shifts_id'][0]['name'];
      } else {
        return null;
      }
    })
    selected_shift.value = default_shift.value;
    const shift_placeHolder = computed(() => {
      if (default_shift.value !== null) {
        return default_shift.value;
      } else {
        return 'Select shift'
      }
    })
    const selected_department = ref();
    const default_department = computed(() => {
      if (value.value !== undefined && value.value.hasOwnProperty('departments_id') && value.value['departments_id'] !== null) {
        return value.value['departments_id']?.[0]?.['name'];
      } else {
        if (props.params.data?.employees?.[0]?.departments !== null) {
          return props.params.data?.employees?.[0]?.departments?.[0]?.name
        }
        return null;
      }
    })
    selected_department.value = default_department.value;
    const department_placeHolder = computed(() => {
      if (default_department.value !== null) {
        return default_department.value;
      } else {
        return 'Select department'
      }
    })

    const getValue = () => {
      // const targetElement = props.params.eGridCell;
      // if (targetElement.value === undefined) {
      //   targetElement.style.removeProperty('background-color');
      // }
      // targetElement.style.removeProperty('opacity');
      if (value.value === undefined) {
        value.value = {};
      }
      const allocation_edit = {};
      if (Object.keys(value.value).length === 0) {
        if (selected_allocationType.value !== undefined) {
          for (let i = 0; i < dataSource['allocation_type'].length; i++) {
            if (selected_allocationType.value?.id === dataSource['allocation_type'][i].id) {
              value.value['allocation_type'] = [dataSource['allocation_type'][i]];
              allocation_edit['allocation_type'] = [dataSource['allocation_type'][i]];
              break;
            }
          }
        }
        if (selected_site.value !== undefined) {
          for (let i = 0; i < dataSource['sites_id'].length; i++) {
            if (selected_site.value?.id === dataSource['sites_id'][i].id) {
              value.value['sites_id'] = [dataSource['sites_id'][i]];
              allocation_edit['sites_id'] = [dataSource['sites_id'][i]];
              break;
            }
          }
        }
        if (selected_shift.value !== undefined) {
          for (let i = 0; i < dataSource['shifts_id'].length; i++) {
            if (selected_shift.value?.id === dataSource['shifts_id'][i].id) {
              value.value['shifts_id'] = [dataSource['shifts_id'][i]];
              allocation_edit['shifts_id'] = [dataSource['shifts_id'][i]];
              break;
            }
          }
        }
        if (selected_department.value !== undefined) {
          for (let i = 0; i < dataSource['departments'].length; i++) {
            if (selected_department.value?.id === dataSource['departments'][i].id) {
              value.value['departments_id'] = [dataSource['departments'][i]];
              allocation_edit['departments_id'] = [dataSource['departments'][i]];
              break;
            }
          }
        }
      } else {
        if (selected_allocationType.value !== undefined) {
          for (let i = 0; i < dataSource['allocation_type'].length; i++) {
            if (selected_allocationType.value?.id === dataSource['allocation_type'][i].id  || selected_site.value === dataSource['allocation_type'][i]?.['name']?.['eng']) {
              value.value['allocation_type'] = [dataSource['allocation_type'][i]];
              allocation_edit['allocation_type'] = [dataSource['allocation_type'][i]];
              break;
            }
          }
        }
        if (selected_site.value !== undefined) {
          for (let i = 0; i < dataSource['sites_id'].length; i++) {
            if (selected_site.value?.id === dataSource['sites_id'][i].id || selected_site.value === dataSource['sites_id'][i]?.['name']?.['eng']) {
              value.value['sites_id'] = [dataSource['sites_id'][i]];
              allocation_edit['sites_id'] = [dataSource['sites_id'][i]];
              break;
            }
          }
        }
        if (selected_shift.value !== undefined) {
          for (let i = 0; i < dataSource['shifts_id'].length; i++) {
            if (selected_shift.value?.id === dataSource['shifts_id'][i].id || selected_shift.value === dataSource['shifts_id'][i]?.['name']) {
              value.value['shifts_id'] = [dataSource['shifts_id'][i]];
              allocation_edit['shifts_id'] = [dataSource['shifts_id'][i]];
              break
            }
          }
        }
        if (selected_department.value !== undefined) {
          for (let i = 0; i < dataSource['departments'].length; i++) {
            if (selected_department.value?.id === dataSource['departments'][i].id || selected_department.value === dataSource['departments'][i]?.name.eng) {
              value.value['departments_id'] = [dataSource['departments'][i]];
              allocation_edit['departments_id'] = [dataSource['departments'][i]];
              break;
            }
          }
        }
      }
      if (Object.keys(allocation_edit).length === 0) {
        return props.params.value;
      }
      value.value['edited'] = true;
      if (allocation_edit.hasOwnProperty('sites_id') && allocation_edit.hasOwnProperty('shifts_id') && allocation_edit.hasOwnProperty('allocation_type')) {
        allocation_edit['contracts_id'] = [selectedContract];
        if (props.params.data?.employees?.[0]?.designation !== null) {
          allocation_edit['products_id'] = props.params.data?.employees?.[0]?.designation
        }
        value.value['contracts_id'] = [selectedContract]
        value.value['products_id'] = props.params.data?.employees?.[0]?.designation
        value.value['allocations_edit'] = allocation_edit;
      } else {
        value.value['edited'] = false;
      }
      return value.value;
    }

    const isPopup = () => {
      return true;
    };

    const isCancelBeforeStart = () => {
      return false;
    };

    const isCancelAfterEnd = () => {
      return false;
    };

    // function updateShiftList(selectedSite) {
    //   for (let i = 0; i < dataSource.shifts_id.length; i++) {
    //     const shifts_id = [];
    //     if (dataSource.shifts_id[i].sites_id[0].id === selectedSite.id) {
    //       shifts_id.push({ name: dataSource.shifts_id[i].name, id: dataSource.shifts_id[i].id })
    //     }
    //     valueToUpdate.value['shifts_id'] = shifts_id;
    //   }
    // }

    // watch(() => selected_site.value,
    //   (newValue, oldValue) => {
    //     updateShiftList(newValue)
    //   }
    // );

    function getInitialValue() {
      name.value.push('Allocation Type');
      valueToUpdate.value['allocation_type'] = dataSource.allocation_type.map(
        (item) => ({ name: item.name.eng, id: item.id}
        ));
      name.value.push('Sites');
      valueToUpdate.value['sites_id'] = dataSource.sites_id.map(
        (item) => ({ name: item.name.eng, id: item.id}
        ));
      name.value.push('Shifts');
      valueToUpdate.value['shifts_id'] = dataSource.shifts_id.map((item) => ({ name: item.name, id: item.id }));
      name.value.push('Department');
      valueToUpdate.value['departments'] = dataSource.departments.map((item) => ({ name: item?.name, id: item.id }));
    }

    const onClose = (index) => {
      focusNext(null, index);
    }

    const focusNext = async(e, currentIndex) => {
      const refList = [inputText0, inputText1, inputText2, inputText3];
      if (e !== null && e.key === 'Enter' && currentIndex === refList.length - 1) {
        props.params.stopEditing();
      }
      const nextIndex = (currentIndex + 1) % refList.length;
      const nextElement = refList[nextIndex].value;
      await nextTick(); // Ensure next DOM update is completed

      if (nextElement && nextElement.$el) {
        const inputEl = nextElement.$el.querySelector('input');
        if (inputEl) {
          inputEl.focus();
        } else {
          nextElement.focus(); // for input elements
        }
      } else if (nextElement) {
        nextElement.focus(); // for input elements
      }
    };

    const createKeydownHandler = (currentIndex) => {
      return (map, vm) => ({
        ...map,
        9: async(e) => { // Tab key
          e.stopPropagation();
          await focusNext(e, currentIndex);
        },
        13: async(e) => { // Enter key
          e.stopPropagation();
          vm.typeAheadSelect(); // Ensure selection
          await nextTick();
          await focusNext(e, currentIndex);
        }
      });
    };
    const handleKeydown = async(currentIndex, e) => {
      if (e.key === 'Tab') {
        e.preventDefault();
        e.stopPropagation();
        await focusNext(e, currentIndex);
      } else if (e.key === 'Enter') {
        e.stopPropagation();
        // vm.typeAheadSelect(); // Ensure selection
        await nextTick();
        await focusNext(e, currentIndex);
      }
    };

    onMounted(() => {
      getInitialValue();
      nextTick(() => {
        if (inputText0.value) {
          inputText0.value.$el.querySelector('input').focus();
        }
      })
    })

    const isHoverDisabled = ref(false);
    function preventHover(event) {
      // Disable hover selection behavior
      isHoverDisabled.value = false;
      // event.target.style.pointerEvents = 'none';
      event.stopPropagation()
    }
    function enableHover(event) {
      // Re-enable hover behavior (when mouse leaves)
      isHoverDisabled.value = false;
      event.target.style.pointerEvents = 'auto';
    }

    return {
      preventHover,
      enableHover,
      inputText0,
      inputText1,
      inputText2,
      inputText3,
      selected_allocationType,
      allocation_type_placeHolder,
      selected_site,
      site_placeHolder,
      selected_shift,
      shift_placeHolder,
      selected_department,
      department_placeHolder,
      name,
      valueToUpdate,
      getValue,
      isPopup,
      isCancelBeforeStart,
      isCancelAfterEnd,
      createKeydownHandler,
      handleKeydown,
      onClose
    };
  }
}
</script>

<style>
  .editorContainer {
    display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
    background-color: rgb(250, 247, 247);
    padding: 5px;
  }

  .selectInput {
    width: 200px;
    height: 100px;
  }

  .selected_option {
    cursor: pointer;
  }
</style>

<!--
<template>
  <div class="editorContainer form-group">
  sLoop through the field configs and generate v-select for each
    <div class="selectInput" v-for="(field, index) in fields" :key="field.name">
      <label :for="'inputText' + index">{{ field.label }}</label>
      <v-select
        :ref="'inputText' + index"
        v-model="selectedValues[field.name]"
        :label="field.optionLabel"
        :placeholder="placeholders[field.name]"
        :options="valueToUpdate[field.name]"
        clear-search-on-select
        :map-keydown="createKeydownHandler(index)"
        @mouseover.prevent
      >
        <template #option="option">
          <span
            :class="{
              selected_option: selectedValues[field.name] && (selectedValues[field.name] === option[field.optionLabel])
            }"
            @mouseover="preventHover"
            @mouseleave="enableHover"
          >
            {{ option[field.optionLabel] }}
          </span>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
import { ref, computed, nextTick, onMounted } from 'vue';

export default {
  name: 'CellEditor',
  props: {
    fieldsConfig: {
      type: Array,
      required: true, // Array of fields configuration passed to the component
    },
    params: Object,
  },
  setup(props) {
    const selectedValues = ref({});
    const placeholders = ref({});
    const valueToUpdate = ref({});
    const name = ref([]);

    const dataSource = props.params?.cellEditorDataSource;

    // Dynamically process each field based on the config passed in props
    const fields = computed(() => {
      return props.fieldsConfig.map((field) => ({
        name: field.name,
        label: field.label,
        optionLabel: field.optionLabel || 'name',
      }));
    });

    const getDefaultValue = (fieldName) => {
      if (props.params?.value?.[fieldName]) {
        return props.params.value[fieldName][0]?.name?.eng || props.params.value[fieldName][0]?.name;
      }
      return null;
    };

    // Initialize values for each field
    const initializeFields = () => {
      fields.value.forEach((field) => {
        const defaultValue = getDefaultValue(field.name);
        selectedValues.value[field.name] = defaultValue;
        placeholders.value[field.name] = defaultValue || `Select ${field.label}`;

        // Map options based on field's data source
        valueToUpdate.value[field.name] = dataSource[field.name]?.map((item) => ({
          name: item?.name?.eng || item.name,
          id: item.id,
        }));
      });
    };

    // Create keydown handler to handle navigation through inputs
    const createKeydownHandler = (currentIndex) => {
      return (map, vm) => ({
        ...map,
        9: async (e) => {
          e.stopPropagation();
          await focusNext(e, currentIndex);
        },
        13: async (e) => {
          e.stopPropagation();
          vm.typeAheadSelect(); // Ensure selection
          await nextTick();
          await focusNext(e, currentIndex);
        },
      });
    };

    // Focus on next input or stop editing on the last input
    const focusNext = async (e, currentIndex) => {
      const refList = fields.value.map((_, i) => `inputText${i}`);
      const nextIndex = (currentIndex + 1) % refList.length;

      if (e?.key === 'Enter' && currentIndex === refList.length - 1) {
        props.params.stopEditing();
      } else {
        const nextElement = refs[refList[nextIndex]]?.$el?.querySelector('input');
        if (nextElement) {
          nextElement.focus();
        }
      }
    };

    // Handle hover prevention
    const preventHover = (event) => {
      event.stopPropagation();
    };

    const enableHover = (event) => {
      event.target.style.pointerEvents = 'auto';
    };

    onMounted(() => {
      initializeFields();
      nextTick(() => {
        if (refs.inputText0) {
          refs.inputText0.$el.querySelector('input').focus();
        }
      });
    });

    return {
      selectedValues,
      placeholders,
      fields,
      valueToUpdate,
      preventHover,
      enableHover,
      createKeydownHandler,
    };
  },
};
</script>

<style scoped>
.editorContainer {
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  background-color: white;
}

.selectInput {
  width: 200px;
  height: 100px;
}

.selected_option {
  cursor: pointer;
}
</style> -->
