const processOtherForm = () => {
  //get the fields data
  let docpList = {}
  let detailList = {}
  let detailKeyFieldName = null
  function getOtherFormDataGroup(formData, currentFolder, txnType) {
    const fieldsData = txnType === 'bulk' ? [] : {}

    for (const key in formData) {
      if ((formData[key]?.value !== null || formData[key] !== null && Object.keys(formData[key]).length !== 0) || typeof formData[key] === 'number') {
        //in the form data entered. checking if the top level fields are of detail or docp type
        //and adding those to a list accordingly
        if (currentFolder.fields[key]?.type === 'detail') {
          for (const eachKey in currentFolder.fields[key].fields) {
            if (currentFolder.fields[key].fields[eachKey].label.eng === 'Key') {
              detailKeyFieldName = currentFolder.fields[key].fields[eachKey].name
            }
          }
          Object.assign(detailList, {
            [key]: formData[key]?.value || formData[key]
          })
        } else if (currentFolder.fields[key]?.type === 'docpicker') {
          Object.assign(docpList, {
            [key]: formData[key]?.value || formData[key]
          })
        } else {
          if (txnType === 'bulk') {
            fieldsData.push(formData[key])
          } else {
            Object.assign(fieldsData, {
              [key]: formData[key]?.value || formData[key]
            })
          }
        }
      }
    }
    console.log('fields', fieldsData)
    console.log('docp', docpList)
    console.log('details', detailList)
    console.log('fields', fieldsData)
    console.log('detailFey', detailKeyFieldName)

    return { fieldsData, docpList, detailList, detailKeyFieldName }
  }
  function emptyOthersContainer() {
    docpList = {},
    detailList = {},
    detailKeyFieldName = null
  }

  return {getOtherFormDataGroup, emptyOthersContainer}
}

export default processOtherForm
