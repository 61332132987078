import processExpr from './processExprs';
const processRowData = () => {
  function getProcessedDataList(folder, rowData, fieldsToShow) {
    if (Array.isArray(fieldsToShow)) {
      const computedFields = fieldsToShow.filter((each) => each.type == 'cfield')
      if (rowData && rowData?.length != 0) {
        rowData.forEach((eachRow) => {
          for (var i = 0; i < computedFields.length; i++) {
            const cExpr = computedFields[i].computed_expr
            const fieldName = computedFields[i].name
            const {computedExpr} = processExpr(folder, eachRow)
            const retval = computedExpr(cExpr)
            eachRow[fieldName] = retval
          }
        });
      }

    }
    return rowData
  }
  return { getProcessedDataList};
};

export default processRowData;
