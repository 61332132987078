<template>
  <div class="datesHeader">
    <span>
      {{ dates[0] }}
    </span>
    <span>
      {{ dates[1] }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'HeaderComponent',
  setup(props) {
    const dates = props.params.displayName.split(' ');
    return {
      dates
    };
  }
}
</script>
  <style>
  .datesHeader {
    display: flex;
    flex-direction: column;
  }
  </style>
