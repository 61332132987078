<template>
  <div
    v-if="readOnly"
    :data-test-id="dataTestId"
    class="list-view-class"
  >
    {{ value ? `${value[0]} -to- ${value[1]}`:'' }}
  </div>
  <div
    v-else-if="showField"
  >
    <span
      v-if="!showInTable && !readOnly && !shiftOptions.fieldOnlyView"
      class="input-group my-2"
    >{{ shiftOptions.label[currentLanguage] }}<span
      v-if="isMandatory"
      class="ms-2"
      style="color: red"
    >*</span>
    </span>
    <div
      v-if="!showInTable"
      :class="{ 'd-block': isMissing || !isValid }"
      class="invalid-feedback p-0 text-xs"
    >
      {{ errorText }}
    </div>
    <VueDatePicker
      v-model="value"
      :uid="dataTestId"
      :class="{ 'mandatory-field': isMandatory && isMissing,
                'invalid-field':!isValid}"
      text-input
      :is-24="true"
      :ui="uiClasses"

      :placeholder="placeHolder"
      :data-test-id="dataTestId"
      time-picker
      :range="{partialRange:true, disableTimeRangeValidation: true}"
      :disabled="isReadOnly || isEditNotAllowed"
      @update:model-value="handleChange"
      @closed="handleClosed"
    />
    <v-tooltip
      v-if="showInTable && value!=null"
      activator="parent"
      open-delay="600"
      location="top"
    >
      {{ value }}
    </v-tooltip>
  </div>
</template>

<script>
import {
  ref, computed, toRef
} from 'vue';
import getConverters from '../../composables/getConverters';
import { useI18n } from 'vue-i18n';
import processExpr from '../../composables/processExprs';
import controlUtility from '../../composables/controlUtility';
export default {
  name: 'WfmShiftRange',
  props: {
    params: {
      type: Object,
      default: null,
    }
  },
  emits: ['input-change', 'invalid-check', 'mandatory-check'],
  setup(props, context) {
    //variables
    const i8vue = useI18n()
    const currentLanguage = i8vue.locale.value
    const showInTable = props.params?.showInTable ? true : false;
    const readOnly = props.params?.readOnly ? true : false
    const shiftParams = toRef(props.params.data)
    const shiftOptions = props.params.fieldOptions
    const propertyName = shiftOptions.name;
    const folderName = shiftOptions?.path?.split('.')[0]
    const idOfTheFolder = shiftParams.value?.[folderName]?.[0]?.id ? shiftParams.value?.[folderName]?.[0]?.id : shiftParams.value?.id
    const fieldPath = shiftOptions?.path
    const existingRange = ref(shiftParams.value?.[propertyName] ? shiftParams.value?.[propertyName] : shiftParams.value?.[folderName]?.[0]?.[propertyName]);
    const { storedRangeToUserFriendly, userFriendlyToStoredRange, storedRangeToDatePickerFormat } = getConverters();
    // convert the value to display on ui
    const value = ref(readOnly ? storedRangeToUserFriendly(existingRange.value) : storedRangeToDatePickerFormat(existingRange.value));
    const mandatoryFields = props.params.mandatoryFields
    const invalidFields = props.params.invalidFields
    const { invalidCheck, mandatoryCheck } = controlUtility(invalidFields, mandatoryFields)
    const isValid = ref(true)
    const valueEntered = ref(false)
    const mandatoryStatus = props.params.mandatoryStatus
    const isEditNotAllowed = props.params.isEditNotAllowed
    const missingFields = props.params.missingFields
    const currentFolder = ref(props.params.currentFolder)
    const { processReadOnly, processVisibleExpr, processMandatoryExpr, processDefaultValue, processValidExpr} = processExpr(currentFolder.value, shiftParams.value, showInTable)

    //computed
    const placeHolder = computed(() => {
      return shiftOptions.placeHolder ? shiftOptions.placeHolder : shiftOptions.label.eng
    })
    const isMissing = computed(() => {
      if (missingFields?.value?.includes(dataTestId.value)) {
        if (valueEntered.value) {
          return false
        }
        else {
          return true
        }
      }
      else {
        return false
      }
    })
    const isVisible = computed(() => {
      const retVal = processVisibleExpr(shiftOptions.visible_expr)
      return retVal
    })
    const isMandatory = computed(() => {
      const retVal = processMandatoryExpr(shiftOptions.mandatory_expr) && isVisible.value
      if (!showInTable) {
        mandatoryCheck({
          propertyName: dataTestId.value,
          value: retVal,
        })
      }
      return retVal
    })
    const isReadOnly = computed(() => {
      const retVal = processReadOnly(shiftOptions.readonly_expr)
      return retVal
    })
    const errorText = computed(() => {
      if (!isValid.value) {
        return 'Invalid Input'
      }
      if (isMissing.value) {
        return 'Mandatory field'
      }
      else {
        return ''
      }
    })
    const defaultValue = computed(() => {
      const retVal = processDefaultValue(shiftOptions.default_value)
      return retVal
    });
    if (defaultValue.value) {
      getDefaultValue()
    }
    const showField = computed(() => {
      if (!mandatoryStatus?.value) {
        return true
      }
      else {
        if (isMandatory.value) {
          return true
        }
        else {
          return false
        }
      }
    })
    const dataTestId = computed(() => {
      if (showInTable || readOnly) {
        const rowIndex = props.params.node.rowIndex
        const tableName = props.params.tableName
        return `${currentFolder.value.name}/${tableName}/${propertyName}/${rowIndex}`
      } else {
        if (currentFolder.value !== null) {
          return `${currentFolder.value.name}/${propertyName}`
        }
        else {
          return `${shiftOptions.type}/${propertyName}`
        }
      }
    })
    const uiClasses = computed(() => {
      return {
        input:  'form-input',
      }

    })

    //methods
    function handleChange() {
      const timeRangeToStore = value.value;
      if (timeRangeToStore) {
        valueEntered.value = true
      }
      if (shiftOptions.valid_expr !== undefined) {
        isValid.value = processValidExpr(shiftOptions.valid_expr, timeRangeToStore)

      }
      if (isValid.value) {
        // context.emit("invalid-check", { propertyName: propertyName, value: false })
        invalidCheck({ propertyName: propertyName, value: false })

        //convert the value to store in db
        const newValue = timeRangeToStore ? userFriendlyToStoredRange(timeRangeToStore) : null;
        //if the data is changed in ag grid
        if (showInTable) {
          shiftParams.value[propertyName] = newValue
          props.params.cellValueChanged(shiftParams.value)
        }
        //else emit event to control
        else {
          context.emit('input-change', { path: fieldPath, value: newValue, folderId: idOfTheFolder, mandatory: shiftOptions.mandatory_expr });
        }
      }
      else {
        // context.emit("invalid-check", { propertyName: propertyName, value: true })
        invalidCheck({ propertyName: propertyName, value: true })

      }

    }
    function getValue() {
      return value.value;
    }
    function getDefaultValue() {
      const defaultSelectedValue = defaultValue.value
      value.value = defaultSelectedValue
      handleChange()
    }
    function handleClosed() {
      //on close giving the focus back to the input field inside the datepicker
      document.getElementById(`dp-input-${dataTestId.value}`).focus()

    }
    return {
      value,
      handleChange,
      showInTable,
      readOnly,
      getValue,
      currentLanguage,
      isMandatory,
      isReadOnly,
      isMissing,
      errorText,
      isValid,
      showField,
      handleClosed,
      isEditNotAllowed,
      placeHolder,
      shiftOptions,
      dataTestId,
      uiClasses
    };
  },
};
</script>
<style>
.form-input {
  min-height: 5px;
  width: 100%;
  background-color: var(--controls-background-color) !important;
  color:var(--controls-font-color) !important;
  border:1px solid var(--controls-border-color);
  cursor: pointer;
  border-radius: 8px;

}
</style>
<style scoped>
.input-group-text {
  background-color: antiquewhite;
}

.mandatory-field {
  border: 1px solid rgb(214, 65, 85) !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  color: black
}
.invalid-field{
    border: 1px solid rgb(214, 65, 85) !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  color: black

}
</style>
