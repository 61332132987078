<template>
  <div v-if="showLoader">
    <WfmLoader />
  </div>
  <div
    :style="{ visibility: !showLoader ? 'visible' : 'hidden' }"
  >
    <HeaderComponent
      class="month-switcher"
      :current-folder="currentFolder"
      :is-duty-chart="isDutyChart"
      :register-view="registerView"
      :selected-cells="selectedCells"
      :on-grid-ready="onGridReady"
      :show-in-out-toggle="showInOutTimeToggle"
      :update-filter-values="updateFilterValues"
      :clear-selection="clearSelection"
      :filters-data="gridDataSource"
      @update-month-year="updateMonthYear"
      @show-register-view="showRegisterView"
    />
    <div
      class="grid-container"
      @keydown.capture="keyboardCommandsHandler"
    >
      <div
        v-if="!registerView"
        id="my-grid"
        class="ag-theme-quartz"
      >
        <ag-grid-vue
          :style="{width: '100%', height: gridHeight}"
          class="ag-grid"
          :row-height="rowHeight"
          :header-height="headerHeight"
          :column-defs="columnDefs"
          :default-col-def="defaultColDef"
          :row-buffer="rowBuffer"
          :row-selection="rowSelection"
          :suppress-row-selection="suppressRowSelection"
          :suppress-row-click-selection="suppressRowClickSelection"
          :stop-editing-when-grid-loses-focus="stopEditingWhenGridLosesFocus"
          :row-model-type="rowModelType"
          :cache-block-size="cacheBlockSize"
          :cache-overflow-size="cacheOverflowSize"
          :max-concurrent-data-source-requests="maxConcurrentDatasourceRequests"
          :infinite-initial-row-count="infiniteInitialRowCount"
          :max-blocks-in-cache="maxBlocksInCache"
          :pinned-bottom-row-data="pinnedBottomRowData"
          :on-cell-key-down="onCellKeyDown"
          :tooltip-mouse-track="true"
          :enable-browser-tooltips="true"
          :loading="true"
          :loading-overlay-component="loadingOverlayComponent"
          @grid-ready="onGridReady"
          @cell-clicked="onCellClicked"
        />
      </div>
      <div
        v-else-if="registerView"
        id="my-grid"
        class="ag-theme-quartz"
      >
        <ag-grid-vue
          :style="{width: '100%', height: '88vh'}"
          class="ag-grid"
          :row-height="25"
          :header-height="30"
          :column-defs="columnDefs"
          :default-col-def="defaultColDef"
          :row-buffer="rowBuffer"
          :row-selection="rowSelection"
          :suppress-row-selection="suppressRowSelection"
          :suppress-row-click-selection="suppressRowClickSelection"
          :stop-editing-when-grid-loses-focus="stopEditingWhenGridLosesFocus"
          :row-model-type="rowModelType"
          :cache-block-size="cacheBlockSize"
          :cache-overflow-size="cacheOverflowSize"
          :max-concurrent-data-source-requests="maxConcurrentDatasourceRequests"
          :infinite-initial-row-count="infiniteInitialRowCount"
          :max-blocks-in-cache="maxBlocksInCache"
          :pinned-bottom-row-data="pinnedBottomRowData"
          :on-cell-key-down="onCellKeyDown"
          :tooltip-mouse-track="true"
          :enable-browser-tooltips="true"
          :loading="true"
          :loading-overlay-component="loadingOverlayComponent"
          :loading-overlay-component-params="loadingOverlayComponentParams"
          @grid-ready="onGridReady"
          @cell-clicked="onCellClicked"
        />
      </div>
    </div>
    <WfmModal
      v-if="formOpen"
      class="calendar-modal"
      @close="closeCalendar"
    >
      <template #header>
        <div class="d-flex" />
      </template>
      <template #body>
        <div class="m-3 mt-0">
          <!-- eslint-disable vue/no-v-html -->
          <WfmTabsHorizontal
            :key="tabKey"
            :tabs-list="tabsList"
            :selected-index="selectedIndex"
            :custom-class="customTabsClass"
            @add-tab="addTab"
            @del-tab="delTab"
            @change-tab="changeTab"
            @update-keep-alive-state="updateKeepAliveState"
          />
        </div>

        <!--eslint-enable-->
      </template>
    </WfmModal>

    <div class="alert-window">
      <div class="alert-content">
        Selected rows for approval has deficit hours
        <span class="alert-note"> <br>
          <b>Approve:</b> This action will result in salary deductions if the approval request has attendance with deficit hours.<br><br>
          <b>Settle:</b> This action will ignore all deficit hours and there will be no deductions against deficit hours.
          <br><br>
          <b>Missed Punch:</b> If there are Missed Punch in the request then they will first be adjusted against the available paid leaves. Remaining Missed punches will be marked as 'LWP' resulting in salary deduction.</span>      <span id="deficit-hours"><!----></span>
        <div
          class="alert-buttons"
        >
          <button
            @click="isApproveAttendance(true, 'approve')"
          >
            approve
          </button>
          <button
            @click="isApproveAttendance(false, 'settle')"
          >
            settle
          </button>
          <button
            @click="isApproveAttendance(false, 'cancel')"
          >
            cancel
          </button>
        </div>
      </div>
    </div>

    <div v-if="!registerView">
      <HelperList
        grid-view="attendance"
        :register-view="registerView"
        :helper-list-data="gridDataSource"
        :show-designation="showDesignations"
        :show-site="showSites"
      />
    </div>
  </div>
</template>

<script>
import 'ag-grid-community/styles/ag-grid.css';
import { onBeforeMount, ref, watch, computed } from 'vue';
import { useStore } from 'vuex';
import { toast } from 'vue3-toastify';
import { AgGridVue } from 'ag-grid-vue3';
import WfmLoader from '../../common/loader/wfm-loader.vue';
import AttendanceCellRenderer from './grid-components/CellRendrer.vue';
import HeaderComponent from '../grid/common-components/HeaderComponent.vue';
import GridHeaderComponent from '../grid/common-components/GridHeaderComponent.vue';
import TotalHoursHeaderComponent from '../grid/common-components/TotalHoursHeaderComponent.vue';
import EmployeeCellEditor from '../grid/common-components/EmployeeCellEditor.vue';
import cellEditor from './grid-components/CellEditor.vue';
import DropdownCellEditor from './grid-components/register-view-components/DropdownCellEditor.vue';
import WorkingTimeEditor from './grid-components/register-view-components/WorkingTimeEditor.vue';
import AttendanceTypeCellRenderer from './grid-components/register-view-components/AttendanceTypeCellRenderer.vue';
import TimeCellEditor from './grid-components/register-view-components/TimeCellEditor.vue';
import EmployeeCellRenderer from '../grid/common-components/EmployeeCellRenderer.vue';
import PinnedRowRenderer from './grid-components/PinnedRowRenderer.vue';
import TotalHoursCellRenderer from '../grid/common-components/TotalHoursCellRenderer.vue';
import HelperList from '../grid/common-components/HelperList.vue';
import getFolders from '../../composables/getFolders';
import WfmModal from '../../common/wfm-modal.vue';
import WfmTabsHorizontal from '../../common/wfm-tabs-horizontal.vue';
import attendanceGridUtils from '../../composables/attendanceGridUtils';
import allocationGridUtils from '../../composables/allocationGridUtils';
import dataProcessor from '../../composables/attendanceGridDataProcessor';
import calendarUtils from '../../composables/calendarUtils';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'AttendanceGrid',
  components: {
    /* eslint-disable vue/no-unused-components */
    WfmLoader,
    HeaderComponent,
    AgGridVue,
    AttendanceCellRenderer,
    cellEditor,
    DropdownCellEditor,
    AttendanceTypeCellRenderer,
    WorkingTimeEditor,
    TimeCellEditor,
    GridHeaderComponent,
    PinnedRowRenderer,
    HelperList,
    WfmModal,
    WfmTabsHorizontal
    /* eslint-enable */
  },
  setup(props) {
    //variables
    // Extract necessary data from the store
    const store = useStore();
    const registerView = ref(true);
    const { getAttendanceDate, txnDataSetter, generateTxn, save, attendancePeriodColumns, getDocpickerList, selectedCellsSetter, attendanceRegisterColumns, approveAttendance } = attendanceGridUtils();
    const { getAllDocpickerList } = allocationGridUtils();
    const { getFoldersFormList, getCurrentFolder } = getFolders();

    const bSettings = store.getters['bSettings'];
    const folderList = getFoldersFormList(bSettings);
    const currentFolder = computed(() => {
      return getCurrentFolder('attendance', folderList)
    })
    const selectedIndex = ref(0)
    const tabKey = ref(0)
    const tabsList = ref([])

    let newRow = false;
    let isEdited = false;
    let deselectAllCalled = false;
    console.log(deselectAllCalled)
    let showInOut = false;
    let toggleCellColor = false;
    let showSitesCellColor = false;
    let showDesignationsCellColor = false;
    const onGridReadyCalled = ref(false);
    const gridDataSource = ref({});
    const filterValues = ref({
      contract: null,
      employee:null,
      site:null,
      shift:null,
      designation: null,
      department:null,
      attendanceType:null,
      reportingManger:null
    });
    const selectedCells = ref([]);
    let selectAll = false;
    const cellSelected = ref([]);
    const customTabsClass = computed(() => {
      //change background and give maximum height
      return 'bg-body h-100 p-2'
    })
    const currentDateTime = new Date();
    const timeRange = ref({
      startWeek: null,
      endWeek:null,
      day: 1,
      year: currentDateTime.getFullYear(),
      month: currentDateTime.getMonth() + 1
    });
    const gridApi = ref();
    const newRows = ref();
    let copyValue = null;
    const currEmpAttData = ref([]);
    let selectedRows = [];
    const defaultColDef = ref({
      flex: 1,
      resizable: true,
      minWidth: 100,
    });
    const gridHeight = ref('76vh');
    const rowHeight = ref('70');
    const headerHeight = ref('60');
    const rowBuffer = ref(null);
    const rowSelection = ref(null);
    const rowModelType = ref(null);
    const cacheBlockSize = ref(null);
    const cacheOverflowSize = ref(null);
    const maxConcurrentDatasourceRequests = ref(null);
    const infiniteInitialRowCount = ref(null);
    const maxBlocksInCache = ref(null);
    const gridData = ref(null);
    const pinnedBottomRowData = ref(null);
    const totalRowCount = ref(0);
    const showLoader = ref();
    const suppressRowSelection = ref();
    const suppressRowClickSelection = ref();
    const stopEditingWhenGridLosesFocus = ref();
    const loadingOverlayComponent = ref();
    const loadingOverlayComponentParams = ref();
    // Define the column definitions for the ag-Grid

    const gridColumnDefs = [
      {
        headerName: 'Employees',
        pinned: 'left',
        minWidth: 170,
        maxWidth: 180,
        suppressSizeToFit: false,
        field: 'employees',
        unSortIcon: true,
        sortable: true,
        suppressMovable: true,
        cellEditor: EmployeeCellEditor,
        // suppressMenu: true,
        editable: (params) => {
          if (params.node.rowPinned) {
            return false;
          }
          // if (Object.keys(params.data).length < 2)
          return true;
        },
        filter:'agTextColumnFilter',
        filterParams: {
          filterOptions: ['equals'],
          maxNumConditions: 1,
          closeOnApply: false,
          debounceMs: 1000,
        },
        enableCellChangeFlash: true,
        cellStyle: {
          color: 'black',
          fontSize: '14px',
          border: '0.1px solid #dde2eb',
          'justify-content': 'center',
        },
        cellEditorParams: {
          'currentFolder':currentFolder.value,
        },
        cellRendererSelector: (params) => {
          if (params.node.rowPinned) {
            return {
              component: PinnedRowRenderer,
            };
          } else {
            return {
              component: EmployeeCellRenderer,
            };
          }
        },
        cellRendererParams : {
          gridView: 'attendance',
          openCalendar : openCalendar,
          updateFilterValues: updateFilterValues,
          showRegisterView: showRegisterView
        }
      },
      ...attendancePeriodColumns(timeRange.value, gridDataSource.value, showSitesCellColor, showDesignationsCellColor, isEditedEnable, selectedCells.value, selectAll, showInOut),
      {
        field: 'total',
        maxWidth: 220,
        minWidth: 200,
        sortable: false,
        suppressMovable: true,
        enableCellChangeFlash: true,
        cellRendererSelector: (params) => {
          if (params.node.rowPinned) {
            return {
              component: PinnedRowRenderer,
            };
          }
          return {
            component: TotalHoursCellRenderer,
          };
        },
        cellRendererParams: {
          'currentFolder': currentFolder.value,
        },
        pinned: 'right',
        headerComponent: TotalHoursHeaderComponent,
        headerComponentParams: {
          addNewRow: addNewRow,
          clearSelection:clearSelection
        },
        cellStyle: {
          color: 'black',
          fontSize: '14px',
          border: '0.1px solid #dde2eb',
        },
      },
    ];
    const registerViewColumnDefs = [ ...attendanceRegisterColumns(gridDataSource.value)];
    const columnDefs = ref(!registerView.value ? gridColumnDefs : registerViewColumnDefs);

    //calendar config
    const formOpen = ref(false)
    const formFolder =  bSettings?.output?.data?.records[0]?.business?.containers?.folders?.folders?.leaves;
    const txnForCalendar = bSettings?.output?.data?.records[0]?.business?.containers?.folders?.folders?.leaves?.txns?.txn_leaves_create;
    const keepAliveState = ref(null);
    const initialDate = computed(() => {
      if (Object.keys(timeRange.value).length !== 0) {
        const formattedDate = `${timeRange.value.year}-${String(timeRange.value.month).padStart(2, '0')}-01`;
        return formattedDate;
      }
      return new Date();
    });
    const calendarHeader = computed(() => {
      const empCode = currEmpAttData.value.employees?.[0]?.code
      const empName = currEmpAttData.value.employees?.[0]?.persons_id?.[0]?.first_name.eng
      return `${empName} (${empCode})`
    })
    const calendarParams = () => {
      const { empLeaveSelectAllow, eventClassNames, refBy, events, eventContent } = calendarUtils();
      return {
        'select-allow' : empLeaveSelectAllow(currEmpAttData),
        'readOnly':true,
        'selectable': true,
        'actionString': 'Apply for leave',
        'events': events(currEmpAttData),
        'eventClassNames': eventClassNames,
        'folder':formFolder,
        'txnToUse':txnForCalendar,
        'eventContent': eventContent,
        'header':calendarHeader.value,
        'refBy': refBy(currEmpAttData, formFolder),
        'initialDate': initialDate.value,
        'current-task':'create'
      }
    }

    function openCalendar(employeeAttData) {
      currEmpAttData.value = employeeAttData;
      formOpen.value = true
      selectedIndex.value = 0
      tabsList.value.push(
        {
          label:'Calender',
          component:'WfmCalendar',
          name:'calender',
          params:calendarParams()
        }
      )
    }

    function closeCalendar() {
      //empty the tabslist and close the modal
      tabsList.value = []
      console.log('AttendanceGrid closing form ...')
      formOpen.value = false;
      keepAliveState.value = null;
    }

    function updateKeepAliveState(selectionInfo) {
      if (selectionInfo !== undefined && selectionInfo !== null) {
        keepAliveState.value = selectionInfo;
      } else {
        keepAliveState.value = null
      }
    }

    function changeTab(index) {
      selectedIndex.value = index
    }
    function addTab(tabSpec) {
      const idx = tabsList.value.findIndex((obj) => obj.name === tabSpec.name)
      if (idx < 0) {
        tabsList.value.push(tabSpec)
        selectedIndex.value = tabsList.value.length - 1
      }
      else {
        selectedIndex.value = idx
      }
    }

    function delTab(index) {
      tabsList.value.splice(index, 1)
    }
    // calendar config end

    //
    function isEditedEnable(isEditing) {
      isEdited = isEditing;
    }

    function showRegisterView(value) {
      registerView.value = value;
      if (registerView.value) {
        gridApi.value = undefined;
        rowBuffer.value = 0;
        rowModelType.value = 'infinite';
        rowSelection.value = 'multiple';
        cacheBlockSize.value = 100;
        cacheOverflowSize.value = 2;
        maxConcurrentDatasourceRequests.value = 1;
        infiniteInitialRowCount.value = 0;
        maxBlocksInCache.value = 50;
        pinnedBottomRowData.value = !registerView.value ? [0] : null;
        showLoader.value = true;
        suppressRowSelection.value = true;
        suppressRowClickSelection.value = true;
        stopEditingWhenGridLosesFocus.value = true;
        headerHeight.value = '30';
      } else {
        gridApi.value = null;
        rowBuffer.value = 0;
        rowModelType.value = 'infinite';
        rowSelection.value = 'multiple';
        cacheBlockSize.value = 100;
        cacheOverflowSize.value = 2;
        maxConcurrentDatasourceRequests.value = 1;
        infiniteInitialRowCount.value = 0;
        maxBlocksInCache.value = 50;
        pinnedBottomRowData.value = !registerView.value ? [0] : null;
        showLoader.value = true;
        suppressRowSelection.value = true;
        suppressRowClickSelection.value = true;
        stopEditingWhenGridLosesFocus.value = true;
        rowHeight.value = '70';
        headerHeight.value = '60';
        selectAll = false;
      }
      setHeaderNames(timeRange.value)
    }

    function showInOutTimeToggle(inOutTime) {
      showInOut = inOutTime;
      const monthGroupColumnsDefs = attendancePeriodColumns(timeRange.value, gridDataSource.value, showSitesCellColor, showDesignationsCellColor, isEditedEnable, selectedCells.value, selectAll, showInOut)
      const columnDefs = gridApi.value.getColumnDefs()
      const columnsArray = [];
      if (columnDefs !== undefined && columnDefs !== null) {
        columnsArray.push(columnDefs[0]);
        columnsArray.push(...monthGroupColumnsDefs);
        columnsArray.push(columnDefs[columnDefs.length - 1]);
        gridApi.value.setColumnDefs(columnsArray)
      }
    }

    function updateFilterValues(filterValue) {
      if (filterValue.type === 'contract') {
        filterValues.value.contract = filterValue.value;
        if (filterValues.value.contract !== null) {
          timeRange.value.day = filterValues.value.contract?.datePreviousMonth;
          let siteList = [];
          let shiftList = [];
          let productList = [];
          for (let i = 0; i < gridDataSource?.value?.contracts_id.length; i++) {
            if (gridDataSource?.value?.contracts_id?.[i]?.id === filterValues.value.contract?.id) {
              const filteredList = updateDataSource(gridDataSource?.value?.contracts_id?.[i], siteList, shiftList, productList);
              siteList = filteredList[0];
              shiftList = filteredList[1];
              productList = filteredList[2];
              break;
            }
          }
          gridDataSource.value['siteList'] = siteList;
          gridDataSource.value['shiftList'] = shiftList;
          gridDataSource.value['productList'] = productList;
        } else {
          timeRange.value.day = 1;
          let siteList = [];
          let shiftList = [];
          let productList = [];
          for (let i = 0; i < gridDataSource?.value?.contracts_id.length; i++) {
            const filteredList = updateDataSource(gridDataSource?.value?.contracts_id?.[i], siteList, shiftList, productList);
            siteList = filteredList[0];
            shiftList = filteredList[1];
            productList = filteredList[2];
          }
          gridDataSource.value['siteList'] = siteList;
          gridDataSource.value['shiftList'] = shiftList;
          gridDataSource.value['productList'] = productList;
        }
        setHeaderNames(JSON.parse(JSON.stringify(timeRange.value)));
      } else  if (filterValue.type === 'site') {
        filterValues.value.site = filterValue.value;
      } else if (filterValue.type === 'shift') {
        filterValues.value.shift = filterValue.value;
      } else if (filterValue.type === 'designation') {
        filterValues.value.designation = filterValue.value;
      } else if (filterValue.type === 'department') {
        filterValues.value.department = filterValue.value;
      } else if (filterValue.type === 'attendanceType') {
        filterValues.value.attendanceType = filterValue.value;
      } else if (filterValue.type === 'reportingManager') {
        filterValues.value.reportingManger = filterValue.value;
      } else if (filterValue.type === 'employee') {
        if (filterValue.openRegisterView !== undefined) {
          if (filterValue.openRegisterView) {
            filterValues.value.employee = filterValue.value.id;
            showRegisterView(true);
            gridDataSource.value['employees'] = filterValue.value;
          }
        } else {
          filterValues.value.employee = filterValue.value;
        }
      }
    }

    function addNewRow() {
      newRow = true;
      gridApi.value.refreshInfiniteCache();
    }

    function clearSelection() {
      deselectAllCalled = true;
      selectedCells.value = [];
      cellSelected.value = [];
      selectAll = false;
      const selected = gridApi.value.getSelectedNodes();
      for (let i = 0; i < selected.length; i++) {
        selected[i].setSelected(false);
      }
      const columnDefs = gridApi.value.getColumnDefs();
      for (let i = 0; i < columnDefs.length; i++) {
        if (columnDefs[i].field !== 'employees' && columnDefs[i].field !== 'total') {
          columnDefs[i].cellRendererParams = {
            'showSitesCellColor':showSitesCellColor,
            'showDesignationsCellColor':showDesignationsCellColor,
            'cellsSelected': selectedCells.value,
            'selectAllCells': selectAll
          }
        }
      }
      gridApi.value.setColumnDefs(columnDefs);
    }

    function showSites() {
      const checkedValue = document.querySelector('.sites').checked;
      showSitesCellColor = checkedValue;
      toggleCellColor = true;
      const columnDefs = gridApi.value.getColumnDefs();
      for (let i = 0; i < columnDefs.length; i++) {
        if (columnDefs[i].field !== 'employees' && columnDefs[i].field !== 'total') {
          columnDefs[i].cellRendererParams = {
            'showSitesCellColor':showSitesCellColor,
            'showDesignationsCellColor':showDesignationsCellColor,
            'cellsSelected': selectedCells.value,
            'selectAllCells': selectAll
          }
        }
      }
      gridApi.value.setColumnDefs(columnDefs);
    }

    function showDesignations() {
      const checkedValue = document.querySelector('.designations').checked;
      showDesignationsCellColor = checkedValue;
      toggleCellColor = true;
      const columnDefs = gridApi.value.getColumnDefs();
      for (let i = 0; i < columnDefs.length; i++) {
        if (columnDefs[i].field !== 'employees' && columnDefs[i].field !== 'total') {
          columnDefs[i].cellRendererParams = {
            'showSitesCellColor':showSitesCellColor,
            'showDesignationsCellColor':showDesignationsCellColor,
            'cellsSelected': selectedCells.value,
            'selectAllCells': selectAll
          }
        }
      }
      gridApi.value.setColumnDefs(columnDefs);
    }

    async function isApproveAttendance(isApprove, action) {
      const element = document.getElementsByClassName('alert-window');
      element[0].style.display = 'none';
      if(action != 'cancel'){
        if (isApprove) {
          const cellsSelected = await approveAttendance(store, gridApi.value, selectedCells.value, 'approve');
          console.log(cellsSelected);
        } else {
          const cellsSelected = await approveAttendance(store, gridApi.value, selectedCells.value, 'settled');
          console.log(cellsSelected);
        }
      }
      clearSelection([]);
    }

    async function keyboardCommandsHandler(event) {
      if (event.altKey === true && event.keyCode === 73) {
        addNewRow(gridApi.value);
      }
      if (event.altKey === true && event.keyCode === 83) {
        await save(store, registerView.value, gridApi.value);
      }
      if (
        event.ctrlKey === true &&
        (event.keyCode === 65 || event.keyCode === 97)
      ) {
        gridApi.value.forEachNode((node) => {
          // Check if the row is already selected
          if (!selectedRows.includes(node.id)) {
            selectedRows.push(node.id);
            node.setSelected(true); // Select the row
            selectedRows = [];
          }
        });
      }
    }

    const onCellKeyDown = async(event) => {
      if (event.event.code === 'Space') {
        const colId = event.column.colId;
        const selectedRowNode = event.node;
        const isSelected = selectedRowNode.isSelected();
        if (colId !== 'employees') {
          selectedRowNode.setSelected(!isSelected);
        }
        const result = await selectedCellsSetter(store, event, selectedCells.value, selectAll, registerView.value);
        selectedCells.value = result.cellsSelected;
        selectAll = result.selectAllCells;
        cellSelected.value = result.cellsSelected;
      } else if (
        event.event.ctrlKey === true &&
        (event.event.keyCode === 65 || event.event.keyCode === 97)
      ) {
        const result = await selectedCellsSetter(store, event, selectedCells.value, selectAll, registerView.value);
        selectedCells.value = result.cellsSelected;
        selectAll = result.selectAllCells;
        cellSelected.value = result.cellsSelected;
        if (!selectAll) {
          clearSelection([]);
        }
      } else if (event.event.ctrlKey === true && event.event.keyCode === 67) {
        if (!registerView.value) {
          if (event.value !== null && (event.value.leave_type === undefined || event.value.leave_type === null || event.value.leave_type.length === 0)) {
            copyValue = event.value
          } else {
            toast.warning('Leaves cannot be copied', {
              position: toast.POSITION.TOP_CENTER
            }
            );
          }
        } else {
          if (event.data !== null) {
            if (!event.data.isMissedPunch) {
              if (event.data['att_type'][0].code === 'L') {
                toast.warning('Leaves cannot be copied', {
                  position: toast.POSITION.TOP_CENTER
                }
                )
              } else {
                copyValue = event.data;
              }
            } else {
              toast.warning('Missed Punch cannot be copied', {
                position: toast.POSITION.TOP_CENTER
              }
              );
            }
          }
        }
      } else if (event.event.ctrlKey === true && event.event.keyCode === 86 && copyValue !== null) {
        copyValue = JSON.parse(JSON.stringify(copyValue));
        if (registerView.value) {
          delete copyValue.attendanceData.attendance_status;
          delete copyValue.attendanceData.last_processed_by;
          delete copyValue.attendanceData.minWorkingHours;
          delete copyValue.attendanceData.exception;
          delete copyValue.attendanceData.allocations_id;
          delete copyValue.attendanceData.leave_type;
          delete copyValue.attendanceData.deficit_minutes;
          delete copyValue.attendanceData.uconst;
          delete copyValue.attendanceData.observed_in_tmstmp;
          delete copyValue.attendanceData.observed_out_tmstmp;
          delete copyValue.day;
          delete copyValue.dates;
          delete copyValue.dates;
          delete copyValue.remarks;
          delete copyValue.deficit_minutes;
          delete copyValue.backgroundColor;
          delete copyValue.attendance_status;
          delete copyValue.attendance_date;
          delete copyValue.observed_in_tmstmp;
          delete copyValue.observed_out_tmstmp;
          if (copyValue.attendance_edit !== undefined) {
            delete copyValue.attendanceEdit;
          }
          for (const key in copyValue) {
            event.data[key] = copyValue[key];
          }
          const newDate = getAttendanceDate(event, event.data.attendance_date);
          copyValue.attendanceData.id = uuidv4();
          copyValue.attendanceData['attendance_date'] = newDate;
          copyValue.attendanceData['sync_timestamp'] = newDate;
          const txnData = txnDataSetter(copyValue.attendanceData);
          await generateTxn(txnData);
          gridApi.value.refreshCells();
          return null;
        }
        const copiedCell = {[`${event.colDef.field}`] :  copyValue}
        const data = event.data;
        event.data = Object.assign(data, copiedCell)
        const newDate = getAttendanceDate(event, null);
        if (copyValue.attendance_edit !== undefined && copyValue.attendance_edit !== null) {
          event.value = copyValue.attendance_edit;
          copyValue = copyValue.attendance_edit
        } else {
          event.value = copyValue;
          copyValue['employees_id'] = event?.data?.employees;
          delete copyValue.attendance_status;
          delete copyValue.last_processed_by;
          delete copyValue.minWorkingHours;
          delete copyValue.exception;
          delete copyValue.allocations_id;
          delete copyValue.leave_type;
          delete copyValue.deficit_minutes;
          if (copyValue.attendance_edit !== undefined) {
            delete copyValue.attendance_edit;
          }
        }
        copyValue.attendance_date = newDate
        copyValue['sync_timestamp'] = newDate
        copyValue.id = uuidv4()
        delete copyValue.in_time;
        delete copyValue.out_time;

        const txnData = txnDataSetter(copyValue);
        await generateTxn(txnData);
        gridApi.value.refreshCells();
      } else if (event.event.shiftKey === true && (event.event.keyCode === 37 || event.event.keyCode === 38 || event.event.keyCode === 39 || event.event.keyCode === 40)) {
        const focusedCell = gridApi.value.getFocusedCell();
        if (!focusedCell) return;
        const column = focusedCell.column;
        const nextColumn = column;
        const colId = nextColumn.colId;
        if (colId === undefined || colId === 'employees' || colId === 'total') {
          return;
        }
        const value = event.data[colId]
        event.value = value;
        event.column = nextColumn;
        const result = await selectedCellsSetter(store, event, selectedCells.value, selectAll, registerView.value);
        selectedCells.value = result.cellsSelected;
        selectAll = result.selectAllCells;
        cellSelected.value = result.cellsSelected;
        event.event.preventDefault();
      }
    };

    async function onCellClicked(event) {
      if (event.event.ctrlKey === true) {
        const colId = event.column.colId;
        const selectedRowNode = event.node;
        const isSelected = selectedRowNode.isSelected();
        if (colId === 'employees' || registerView.value) {
          selectedRowNode.setSelected(!isSelected);
        } else {
          selectedRowNode.setSelected(false);
        }
        const result = await selectedCellsSetter(store, event, selectedCells.value, selectAll, registerView.value);
        if (result !== false) {
          selectedCells.value = result.cellsSelected;
          selectAll = result.selectAllCells;
          cellSelected.value = result.cellsSelected;
        } else {
          selectedRowNode.setSelected(false);
        }
      }
    }

    function addObjectIfUnique(array, newObject) {
      // Check if the object with the same id already exists in the array
      if (newObject !== undefined && newObject !== null) {
        const exists = array.some((obj) => obj.id === newObject?.id);
        // If it doesn't exist, push the new object to the array
        if (!exists) {
          array.push(newObject);
        }
      }
      return array;
    }

    function updateDataSource(contract, siteList, shiftList, productList) {
      if (contract?.attendance_based_items !== null) {
        for (let j = 0; j < contract?.attendance_based_items.length; j++) {
          siteList = addObjectIfUnique(siteList, contract?.attendance_based_items?.[j]?.sites_id?.[0])
          shiftList = addObjectIfUnique(shiftList, contract?.attendance_based_items?.[j]?.shifts_id?.[0])
          productList = addObjectIfUnique(productList, contract?.attendance_based_items?.[j]?.products_id?.[0])
        }
      } else if (contract?.job_based_items !== null) {
        for (let j = 0; j < contract?.job_based_items.length; j++) {
          siteList = addObjectIfUnique(siteList, contract?.job_based_items?.[j]?.sites_id?.[0])
          productList = addObjectIfUnique(productList, contract?.job_based_items?.[j]?.sites_id?.[0])
        }
        if (siteList.length > 0) {
          for (let k = 0; k < siteList.length; k++) {
            for (let l = 0; l < gridDataSource?.value?.shifts_id?.length; l++) {
              if (siteList?.[k]?.id === gridDataSource?.value?.shifts_id?.[l]?.sites_id?.[0]?.id) {
                shiftList = addObjectIfUnique(shiftList, gridDataSource?.value?.shifts_id?.[l])
              }
            }
          }
        }
      }
      return [siteList, shiftList, productList];
    }

    async function filtersData() {
      //using composables to get attendance_edits folder to set as bSettings
      const folderList = getFoldersFormList(bSettings);
      const currentFolder = getCurrentFolder('attendance_edits', folderList);
      const allocationsFolder = getCurrentFolder('allocations', folderList);
      const employeesFolder = getCurrentFolder('employees', folderList);
      const fieldNames = ['att_type', 'shifts_id', 'departments', 'sites_id', 'designation'];
      const docpickerResults = await Promise.all(fieldNames.map((fieldName) =>
        getDocpickerList(fieldName !== 'departments' ? currentFolder?.fields?.[fieldName] : employeesFolder?.fields?.[fieldName])
      ));
      const contractsList = await getAllDocpickerList(allocationsFolder?.fields['contracts_id']);
      for (let i = 0; i < docpickerResults.length; i++) {
        const docpObj = docpickerResults[i];
        gridDataSource.value = Object.assign(gridDataSource.value, docpObj)
      }
      gridDataSource.value['contracts_id'] = contractsList?.['contracts_id'];
      console.log('FILTERSDATA')
      if (newRows.value !== null && newRows.value !== undefined) {
        if (newRows.value.length > 0) {
          if (newRows.value['employees'] !== undefined && newRows.value['employees'] !== null) {
            gridDataSource.value['employees'] = newRows.value['employees'];
          }
        }
      }
      // let siteList = [];
      // let shiftList = [];
      // let productList = [];
      // for (let i = 0; i < gridDataSource?.value?.contracts_id.length; i++) {
      //   const filteredList = updateDataSource(gridDataSource?.value?.contracts_id?.[i], siteList, shiftList, productList);
      //   siteList = filteredList[0];
      //   shiftList = filteredList[1];
      //   productList = filteredList[2];
      // }
      // gridDataSource.value['siteList'] = siteList;
      // gridDataSource.value['shiftList'] = shiftList;
      // gridDataSource.value['productList'] = productList;
      if (gridDataSource?.value?.['contracts_id']?.length === 1) {
        if (gridDataSource.value['contracts_id']?.[0]?.date_of_previous_month !== null) {
          timeRange.value.day = gridDataSource.value['contracts_id']?.[0]?.date_of_previous_month;
        }
      }
      setHeaderNames(timeRange.value)
      showLoader.value = false;
      if (newRows.value !== undefined) {
        if (newRows.value.length === 0) {
          gridApi.value.showNoRowsOverlay();
        }
      }
    }

    async function dataGenerator(params) {
      try {
        console.time('test2');
        console.time('test3');
        let attendanceRows = store.getters['agGridModule/attendanceGridRows'];

        if (newRow) {
          attendanceRows.unshift({});
          totalRowCount.value = attendanceRows.length;
          newRow = false;
          setTimeout(() => {
            gridApi.value.setFocusedCell(0, gridApi.value?.columnModel?.columnDefs[0]?.field);
            gridApi.value.startEditingCell({
              rowIndex: 0,
              colKey: 'employees',
              keyPress: null,
              charPress: ''
            });
          }, 0);
        } else if (toggleCellColor) {
          toggleCellColor = false;
        } else if (isEdited) {
          isEdited = false;
        } else {
          deselectAllCalled = false;
          const { dataCreation, attendanceList } = dataProcessor();
          const attendanceData = await attendanceList(params.filterModel, params, timeRange.value, currentFolder.value, filterValues.value, registerView.value);
          gridData.value = attendanceData.attendanceList;
          const idx = gridData.value.output?.data?.records[gridData.value.output?.data?.records.length - 1];
          if (idx?.dataStart !== idx?.dataEnd) {
            const rowCount = idx?.rowCount;
            totalRowCount.value = rowCount;
          }
          console.timeEnd('test2');
          attendanceRows = await dataCreation(gridData.value, params.sortModel, attendanceData.attendanceSummary, registerView.value, timeRange.value, gridDataSource.value, filterValues.value.employee);
          onGridReadyCalled.value = false;
          console.timeEnd('test3');
        }
        isEdited = false;
        return attendanceRows;
      } catch (err) {
        console.log(err);
      }
    }

    const onGridReady = (params) => {
      if (gridApi.value === undefined || gridApi.value === null) {
        gridApi.value = params?.api;
      }
      store.dispatch('agGridModule/attendanceGridApiSetter', gridApi.value);
      const dataSource = {
        rowCount: 200,
        getRows: async(params) => {
          try {
            console.log(
              'asking for ' + params.startRow + ' to ' + params.endRow
            );
            if (params.startRow === 0) {
              gridApi.value.showLoadingOverlay();
            }
            if (gridApi.value !== undefined && gridApi.value !== null) {
              gridApi.value.sizeColumnsToFit();
            }
            newRows.value = await dataGenerator(params);
            await store.dispatch(
              'agGridModule/attendanceGridRowsSetter',
              newRows.value
            );
            let lastRow = totalRowCount.value;
            if (registerView.value) {
              lastRow = newRows.value?.length;
            }
            if (newRows.value?.length === 0) {
              params.endRow = 0;
              lastRow = 0;
              if (showLoader.value === false) {
                gridApi.value.showNoRowsOverlay();
              }
            } else {
              params.endRow = newRows.value?.length;
              gridApi.value.hideOverlay();
            }
            params.successCallback(newRows.value, lastRow);
            gridApi.value.hideOverlay();
            if (!registerView.value) {
              gridApi.value.setPinnedBottomRowData([{}]);
            }
            if (registerView.value && filterValues.value.employee === null) {
              if (newRows.value !== null && newRows.value !== undefined) {
                if (newRows.value.length > 0) {
                  if (newRows.value['employees'] !== undefined && newRows.value['employees'] !== null) {
                    gridDataSource.value['employees'] = newRows.value['employees'];
                  }
                }
              }
            }
          } catch (err) {
            console.error('Error loading data:', err);
            toast.error(err, {
              position: toast.POSITION.TOP_CENTER
            });
            params.failCallback();
          }
        },
      };
      if (params !== undefined) {
        params?.api?.setDatasource(dataSource);
        filtersData();
      } else {
        gridApi.value?.setDatasource(dataSource);
      }
    };

    function setHeaderNames(monthYear) {
      if (!registerView.value) {
        const monthGroupColumnsDefs = attendancePeriodColumns(monthYear, gridDataSource.value, showSitesCellColor, showDesignationsCellColor, isEditedEnable, selectedCells.value, selectAll, showInOut);
        // const newColumnDefs = gridApi.value.getColumnDefs()
        const newColumnDefs = gridColumnDefs;
        const columnsArray = [];
        columnsArray.push(newColumnDefs[0]);
        columnsArray.push(...monthGroupColumnsDefs);
        columnsArray.push(newColumnDefs[newColumnDefs.length - 1]);
        columnDefs.value = columnsArray;
      } else {
        columnDefs.value = registerViewColumnDefs;
      }
      if (gridApi.value !== undefined && gridApi.value !== null) {
        gridApi.value.refreshInfiniteCache();
      }
    }

    function updateMonthYear(newMonthYear) {
      timeRange.value = newMonthYear;
    }

    //watchers
    watch(() => timeRange.value,
      async(newTimeRange, oldTimeRange) => {
        if (
          newTimeRange.year !== oldTimeRange.year ||
          newTimeRange.month !==
            new Date(`${oldTimeRange.month} 1, 2000`).getMonth() + 1
        ) {
          totalRowCount.value = 0;
          gridApi.value.infiniteRowModel.resetCache();
          setHeaderNames(JSON.parse(JSON.stringify(newTimeRange)));
        }
      }
    );

    //lifecycle hooks
    onBeforeMount(async() => {
      rowBuffer.value = 0;
      rowModelType.value = 'infinite';
      rowSelection.value = 'multiple';
      cacheBlockSize.value = 100;
      cacheOverflowSize.value = 2;
      maxConcurrentDatasourceRequests.value = 1;
      infiniteInitialRowCount.value = 0;
      maxBlocksInCache.value = 50;
      pinnedBottomRowData.value = !registerView.value ? [0] : null;
      showLoader.value = true;
      suppressRowSelection.value = true;
      suppressRowClickSelection.value = true;
      stopEditingWhenGridLosesFocus.value = true;
      loadingOverlayComponent.value = WfmLoader;
      loadingOverlayComponentParams.value = {isRegisterView: registerView.value}
    });

    return {
      currentFolder,
      registerView,
      showRegisterView,
      selectedCells,
      updateMonthYear,
      showInOutTimeToggle,
      cellSelected,
      clearSelection,
      updateFilterValues,
      onCellKeyDown,
      onCellClicked,
      showLoader,
      columnDefs,
      gridApi,
      defaultColDef,
      gridHeight,
      rowHeight,
      headerHeight,
      rowBuffer,
      rowSelection,
      suppressRowSelection,
      suppressRowClickSelection,
      stopEditingWhenGridLosesFocus,
      rowModelType,
      cacheBlockSize,
      cacheOverflowSize,
      maxConcurrentDatasourceRequests,
      loadingOverlayComponent,
      loadingOverlayComponentParams,
      infiniteInitialRowCount,
      maxBlocksInCache,
      keyboardCommandsHandler,
      onGridReady,
      timeRange,
      pinnedBottomRowData,
      calendarParams,
      formFolder,
      txnForCalendar,
      formOpen,
      closeCalendar,
      currEmpAttData,
      updateKeepAliveState,
      gridDataSource,
      showSites,
      showDesignations,
      tabKey,
      tabsList,
      selectedIndex,
      addTab,
      changeTab,
      delTab,
      customTabsClass,
      isApproveAttendance
    };
  },
};
</script>
<style>

.grid-container {
  padding: 2px;
  border: 1px solid #83ACC8;
  display: flex;
  flex-direction: column;
}
.calendar-modal{
  z-index: 1000 !important;
}
#my-grid {
  width: 100%;
  z-index:1
}

.ag-theme-quartz .ag-row-selected {
  border: 1px solid #00b7ff !important;
}

.ag-header-cell-menu-button {
  opacity: 1 !important;
}

.ag-header-group-cell-label, .ag-header-cell-label {
  justify-content: center !important;
}
:deep .ag-root-wrapper {
  overflow: visible;
}

.ag-theme-quartz {
  --ag-foreground-color: black;
  --ag-background-color: #ffffff;
  --ag-header-foreground-color: var(--main-background-color);
  --ag-header-background-color: #83ACC8;
  --ag-font-size: 12px;
  --ag-overlay-background-color: var(--main-background-color) !important;
  --ag-selected-row-background-color: rgba(0, 102, 255, 0.1);
  --ag-font-family: 'Poppins', sans-serif;
  --ag-popup-shadow: black;
  --ag-borders: none;
  --ag-row-border-style: solid;
  --ag-row-border-width: 0.1px;
  --ag-row-border-color: #aebbcc;
  --ag-header-column-resize-handle-display: inline;
  --ag-icon-font-family: "agGridAlpine";
  --ag-icon-size: 16px;
}

.month-switcher {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  flex-shrink: 0;
  z-index: 10;
}

.alert-window {
  color: #292929;
  font-size: 20px;
  display: none;
  position: absolute;
  bottom: 0%;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  backdrop-filter: blur(5px)!important;
}

.alert-content {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  position: relative;
  top: 20%;
  left: 30%;
  width: 611px;
  height: 450px;
  background-color: rgb(236 236 236);
  border-radius: 10px;
}

.alert-note {
  font-size: 15px;
  padding: 15px;
  text-align:left;
}

.alert-buttons {
  width: 200px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.alert-buttons button {
  background-color: #aebbcc;
  padding: 5px;
  border-radius: 5px;
  margin: 2px;
}

.alert-buttons button:hover {
  background-color: #83ACC8;
}
</style>
