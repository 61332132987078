<template>
  <div
    v-if="!showInTable && !readOnly"
  >
    <span class="input-group my-2">{{ dowTypeOptions.label[currentLanguage] }}
      <span
        v-if="isMandatory"
        class="ms-2"
        style="color: red"
      >
        *</span>
    </span>
    <div>
      <input
        v-model="value"
        class="form-select"
        type="text"
        :data-test-id="dataTestId"
        :disabled="isReadOnly || isEditNotAllowed"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      />
      <ul class="dropdown-menu">
        <div v-if="dowList.length === 0">
          <span
            class="spinner-border spinner-border-sm text-info ms-2"
            role="status"
            aria-hidden="true"
          />
        </div>
        <div
          v-for="(item, index) in dowList"
          :key="index"
          class="px-2"
          @click="handleChange(item)"
        >
          <div>
            {{ item.name }}
          </div>
        </div>
      </ul>
    </div>
  </div>
  <div v-else-if="readOnly">
    {{ value }}
  </div>
</template>

<script>
import { ref, watch, computed} from 'vue';
import { useI18n } from 'vue-i18n';
import processExpr from '../../composables/processExprs';
export default {
  name: 'WfmDowType',
  props: {
    params: {
      type: Object,
      default: null,
    }
  },
  emits: ['input-change'],
  setup(props, context) {
    //variables
    const i8vue = useI18n();
    const currentLanguage = i8vue.locale.value;
    const showInTable = props.params?.showInTable ? true : false;
    const readOnly = props.params?.readOnly ? true : false;
    const dowTypeOptions = props.params.fieldOptions
    const dowParams =  props.params.data
    const propertyName = dowTypeOptions.name;
    const currentDow = dowParams?.[propertyName] || 'none';
    const selectedValue = ref(currentDow);
    const value = ref(selectedValue.value?.code || selectedValue.value?.name?.eng || selectedValue.value?.name || 'none');
    const dowList = ref([{ name: 'Monday', day: 0 }, { name: 'Tuesday', day: 1 }, { name: 'Wednesday', day: 2 }, { name: 'Thursday', day: 3 }, { name: 'Friday', day: 4 }, { name: 'Saturday', day: 5 }, { name: 'Sunday', day: 6 }]);
    const currentFolder = ref(props.params.currentFolder)
    const isEditNotAllowed = props.params.isEditNotAllowed
    const missingFields = props.params.missingFields

    const { processReadOnly, processVisibleExpr, processMandatoryExpr} = processExpr(currentFolder.value)
    //watcher
    watch(() => dowList, (newValue) => {
      try {
        dowList.value = newValue;

      } catch (error) {
        console.log('error ', error)
      }
    });

    //computed
    const isMissing = computed(() => {
      if (missingFields?.value?.includes(propertyName)) {
        return true
      }
      else {
        return false
      }
    })
    const isVisible = computed(() => {
      const retVal = processVisibleExpr(dowTypeOptions.visible_expr)
      return retVal
    })
    const isMandatory = computed(() => {
      return processMandatoryExpr(dowTypeOptions.mandatory_expr)
    })
    const isReadOnly = computed(() => {
      return processReadOnly(dowTypeOptions.readonly_expr)
    })
    const dataTestId = computed(() => {
      if (showInTable) {
        const rowIndex = props.params.node.rowIndex
        const tableName = props.params.tableName
        return `${currentFolder.value.name}/${tableName}/${propertyName}/${rowIndex}`
      } else {
        if (currentFolder.value !== null) {
          return `${currentFolder.value.name}/${propertyName}`
        }
        else {
          return `${dowTypeOptions.type}/${propertyName}`
        }
      }
    })

    //method
    function handleChange(item, index) {
      try {
        const newValue = item
        value.value = newValue.name;

        //if the data is changed in ag grid
        if (showInTable) {
          dowParams[propertyName] = item;
          props.params.cellValueChanged(dowParams);
        }
        //else emit event to control
        else {
          context.emit('input-change', newValue.day);
        }
      } catch (error) {
        throw error
      }

    }
    function getValue() {
      return value.value;
    }


    return {
      value,
      dowList,
      selectedValue,
      readOnly,
      dowTypeOptions,
      dowParams,
      showInTable,
      currentLanguage,
      getValue,
      handleChange,
      isMandatory,
      isReadOnly,
      isMissing,
      isVisible,
      isEditNotAllowed,
      dataTestId
    };
  },
};
</script>

<style scoped>
.dropdown-menu {
  cursor: pointer;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  position: absolute;
  height: 100px !important;
  overflow-y: scroll;

  /* Use a higher value to ensure it appears on top */
}

.input-group-text {
  background-color: antiquewhite;
}

.input-group {
  text-align: left;
}
</style>
