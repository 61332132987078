<template>
  <div
    class="calender-container"
  >
    <h4>{{ header }}</h4>
    <div
      v-if="selectable"
      class="d-flex justify-space-between calendar-actions"
    >
      <div class="d-flex">
        <div class="me-3">
          {{ rangeString }}
        </div>
        <button
          class="update-btn ms-3 mb-2"
          :disabled="!selectionActive"
          @click="addTab(actionParams)"
        >
          {{ actionString }}
        </button>
      </div>
    </div>
    <FullCalendar
      ref="calendar"
      :options="calendarOptions"
    />
    <WfmLoader v-if="showLoader" />
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import FullCalendar from '@fullcalendar/vue3';
import WfmLoader from './loader/wfm-loader.vue';
import interactionPlugin from '@fullcalendar/interaction';
import getConverters from '../composables/getConverters';
import dayGridPlugin from '@fullcalendar/daygrid';
const { emptyDayRangeDisplayStr, exclusiveDayRangeDisplayStr } = getConverters();

export default {
  name: 'WfmCalendar',
  components: {
    FullCalendar,
    WfmLoader
  },
  props: {
    initialDate: {
      type: String,
      default : ''
    },
    keepAliveState: {
      type: Object,
      default: () => {}
    },
    actionString: {
      type: String,
      default: () => ''
    },
    selectable: {
      type: Boolean,
      default: () => true
    },
    selectAllow: {
      type: Function,
      default: () => true
    },
    events: {
      type: Function,
      default: () => []
    },
    eventContent: {
      type: Function,
      default: () => []
    },
    eventClassNames: {
      type: Function,
      default: () => ''
    },
    header:{
      type:String,
      default: () => ''
    },
    refBy: {
      type: Array,
      default: () => []
    },
    updateEvents:{
      type:Function,
      default:() => ''
    }
  },
  emits: ['add-tab', 'update-keep-alive-state', 'create-tab-spec', 'fetch-new-events'],

  setup(props, context) {
    const calendar = ref(null)
    const displayDateFormat = 'dd/mm/yyyy'
    const selectionActive = ref(false)
    const actionString = ref(props.actionString);
    const rangeString = ref()
    const actionParams = ref(null)
    const selectable = ref(props.selectable);
    const refBy = props.refBy;
    const { arrayToSQLRange } = getConverters();
    const showLoader = ref(false)
    function handleSelect(selectionInfo) {
      //TODO implement unselect handler!
      selectionActive.value = true
      context.emit('update-keep-alive-state', selectionInfo)
      rangeString.value = exclusiveDayRangeDisplayStr(selectionInfo, displayDateFormat)
      actionParams.value = selectionInfo
    }
    function addTab() {
      if (refBy.length !== 0) {
        if (refBy[0].component === 'FolderForm') {
          const updatedSelectionInfo = { ...actionParams.value };
          let endDate = new Date(updatedSelectionInfo.end);
          endDate = new Date(endDate.setDate(endDate.getDate() - 1))
          const selDtArr = JSON.parse(JSON.stringify([updatedSelectionInfo.start, endDate])).map((dt) => new Date(dt));
          const datePeriod = arrayToSQLRange(selDtArr, true, false, true);
          const filledData = refBy[0].params.filledData;
          const datePeriodKey = refBy[0].params.dateFieldKey;
          filledData[datePeriodKey] = datePeriod;
          refBy[0].params.filled_data = filledData;
        }
        context.emit('add-tab', refBy[0])
      }
    }
    const eventsToShow = ref()
    const calendarOptions = computed(() => {
      const retVal = {
        selectable: props.selectable,
        selectMirror : true,
        unselectAuto: true,
        aspectRatio: 'auto',
        height: 'auto',
        contentHeight: 'auto',
        selectAllow: props.selectAllow,
        plugins: [ dayGridPlugin, interactionPlugin ],
        initialView: 'dayGridMonth',
        initialDate:new Date(props.initialDate),
        headerToolbar: {
          left: 'prev,next',
          center: 'title',
          right: 'dayGridYear,dayGridMonth,dayGridWeek,dayGridDay' // user can switch between the two
        },
        eventClassNames: props.eventClassNames,
        events: eventsToShow.value,
        select: handleSelect,
        eventContent: props.eventContent,
        datesSet: handleDateChange
      }
      return retVal
    });

    async function handleDateChange(info) {
      //on every change of the date, get new events for that range
      showLoader.value = true
      eventsToShow.value = await props.updateEvents(info.start, info.end)
      showLoader.value = false
    }


    onMounted(() => {
      if (props.keepAliveState != null) {
        rangeString.value =  exclusiveDayRangeDisplayStr(props.keepAliveState.value, displayDateFormat);
        const calendarApi = calendar.value.getApi()
        calendarApi.select(props.keepAliveState.value.start, props.keepAliveState.value.end);
      }
      else {
        rangeString.value = emptyDayRangeDisplayStr(displayDateFormat)
      }
    });

    return {
      calendar,
      calendarOptions,
      selectionActive,
      rangeString,
      actionString,
      selectable,
      actionParams,
      addTab,
      showLoader
    }
  }
}
</script>

<style>

.event_color {
    color:black;
    background-color:rgb(199, 175, 131) !important;
    opacity: 0.8 !important;
  }

  .event_color_absent {
    color:black;
    background-color:rgb(229, 142, 142) !important;
    opacity: 0.8 !important;
  }

  .event_color_present {
    color:black;
    background-color:rgb(127, 210, 127) !important;
    opacity: 0.8 !important;
  }

  .event_color_holiday {
    color:black;
    background-color:rgb(166, 224, 242) !important;
    opacity: 0.8 !important;
  }

.calender-container{
  background-color: var(--main-background-color);
  overflow-y: scroll;
  height: 100%;
  padding:100px;
}

.calendar-actions {
    align-items: center;
    color:black;
    flex-direction: row-reverse;
    flex-wrap: wrap;
}
.update-btn {
    color: #6faa5dff;
    border: 1px solid var(--co-main-color);
    height: auto;
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    padding: 6px;
    border-radius: 8px;
    margin-right:10px;
    box-shadow: 1px 1px #2e688dff;
  }
.update-btn:hover {
    color: white;
    border: 1px solid white;
    background-color: var(--co-main-color);
}


.ag-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    border-right: 0.01px solid #aebbcc;
    z-index: 0;
  }

  .ag-status {
    z-index: 2;
    display: flex;
    height: 12px;
    margin-top: 3px;
    font-size: 10px;
    align-items: normal;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
  }

  .att-status {
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15px;
  }

  .attendance {
    margin: 0px;
    padding: 2px;
  }

  .hours {
    font-size: 10px;
    padding: 2px;
  }
</style>
