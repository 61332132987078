<template>
  <div>
    <div class="editor">
      <div class="form_docpicker">
        <input
          id="input1"
          ref="input1"
          v-model="workingHrs"
          :style="error !== '' ? 'border: 1px solid red;': ''"
          type="text"
          placeholder="HH:MM"
          class="my-simple-editor form-control form-control-sm"
          maxlength="5"
          @input="formatTime"
        >
        <p v-if="error">
          {{ error }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, nextTick } from 'vue';

export default {
  name: 'WorkingTimeCellEditor',
  setup(props) {
    const value = ref('value' in props.params ? JSON.parse(JSON.stringify(props.params.value)) : undefined);
    const input1 = ref(null);
    const workingHrs = ref(null);
    const error = ref('');
    if (value.value !== undefined) {
      workingHrs.value = value.value.split(' ')[0];
    }

    const isPopup = () => {
      return true;
    };


    const getValue = () => {
      // console.log('I am here', workingHrs.value, value.value)
      if (workingHrs.value !== value.value) {
        return workingHrs.value;
      }
      return value.value;
    }

    const isCancelBeforeStart = () => {
      return false;
    };

    const isCancelAfterEnd = () => {
      return false;
    };

    onMounted(async() => {
      nextTick(() => {
        if (input1.value) {
          input1.value.focus();
        }
      })
    });

    const formatTime = (event) => {
      let input = event.target.value.replace(/[^0-9]/g, ''); // Only keep numbers
      if (input.length > 2) {
        input = input.slice(0, 2) + ':' + input.slice(2); // Insert ':' after two digits
      }
      workingHrs.value = input; // Update the v-model with the formatted value

      // Validate time format (HH:MM in 24-hour format)
      const timePattern = /^([01]\d|2[0-3]):([0-5]\d)$/;
      if (!timePattern.test(workingHrs.value)) {
        error.value = 'Please enter a valid time (HH:MM).';
      } else {
        error.value = ''; // Clear error if valid
      }
    };

    return {
      input1,
      workingHrs,
      formatTime,
      error,

      isPopup,
      getValue,
      isCancelAfterEnd,
      isCancelBeforeStart,

    };
  }
}
</script>

    <style>
    .editor {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    margin: 0;
    padding: 0;
  }

  .form_docpicker {
    width: 100% !important;
  }
    </style>
