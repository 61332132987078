<template>
  <WfmToolbar
    :actions="actionInModal"
    action-class="action-list"
    @action-click="performAction"
  />
  <div class="content-box mt-2">
    <!-- header part -->
    <div class="d-flex justify-content-between modal-body">
      <div class="d-flex  ms-5">
        <div class="tabs underlined">
          <div class="box-title-profile">
            <v-icon
              name="bi-people-fill"
              scale="1"
            />
            Contract Invoices
          </div>
        </div>
      </div>
      <div class="d-flex">
        <div class="input-group me-2">
          <span class="input-group-text">Contract</span>
          <WfmDocpicker
            :field-options="contractFolder"
            :params="{ generalUse: true }"
          />
        </div>
        <div class="input-group">
          <span class="input-group-text">Invoice Period</span>
          <WfmTsRange
            :field-options="employeeFolder"
            :params="{ generalUse: true }"
            :default="contractDefaultPeriod"
          />
        </div>
        <div>
          <v-icon
            class="my-1 printer-icon"
            name="bi-printer-fill"
            scale="2"
            @click="generate()"
          />
        </div>
      </div>
    </div>
    <hr class="hr mt-0">
    <!-- body part -->
    <div>
      <div v-if="noContactSelected">
        <h3 class="mt-5">
          Please Select Contract and Invoice Period..
        </h3>
      </div>
      <div
        v-else
        class="row template-box"
      >
        <div class="p-5 col">
          <div class="row m-5">
            <div class="col-sm-6">
              <div class="text-start mt-2 mb-1">
                Invoices Category
              </div>
              <WfmDocpicker
                :field-options="invoicesCategory"
                :params="{ generalUse: true }"
              />
            </div>
            <div class="col-sm-6">
              <div class="text-start mt-2 mb-1">
                narration
              </div>
              <wfmText
                :field-options="{ name: 'narration' }"
                :params="{ generalUse: true }"
              />
            </div>
            <div class="col-sm-6">
              <div class="text-start mt-2 mb-1">
                custom invoice numbers
              </div>
              <wfmText
                :field-options="{ name: 'custom_invoice_numbers' }"
                :params="{ generalUse: true }"
              />
            </div>
            <div class="col-sm-6">
              <div class="text-start mt-2 mb-1">
                customer GSTIN
              </div>
              <wfmText
                :field-options="{ name: 'cgst_in' }"
                :params="{ generalUse: true }"
              />
            </div>
            <div class="col">
              <div class="text-start mt-2">
                Print Template
              </div>
              <wfmText
                :field-options="{ name: 'print_preview_template' }"
                :params="{ generalUse: true }"
              />
            </div>
          </div>
        </div>
        <div
          v-if="contractList"
          class="col"
        >
          <h5 class="mt-4">
            Generating Invoices from
          </h5>
          <div class="text-success mb-4">
            <h6>{{ `${pay_period[0]} - to - ${pay_period[1]}` }}</h6>
          </div>
          <div class="d-flex justify-content-between list-box mx-auto">
            <div class="name-tag">
              {{ contractList[0].name.eng }}
            </div>
            <div v-if="payslipData.length === 0 && startedGenerating">
              <v-icon
                :fill="`var(--toolbar-color)`"
                name="bi-arrow-repeat"
                scale="2"
                class="mt-1"
                animation="spin"
              />
            </div>

            <div
              v-if="payslipData.length !== 0 && !startedGenerating"
              class="my-auto"
            >
              <v-icon
                fill="green"
                name="bi-check2-circle"
                scale="1.5"
                class="mt-1"
              />
              <v-icon
                fill="#2e688dff"
                name="bi-receipt"
                scale="1.5"
                class="mt-1"
                @click="openModal(payslipData)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <WfmModal
      v-if="openPayslip"
      @close="closeModal"
    >
      <template #header>
        <div class="mx-auto">
          <h4>{{ payslipData[0].contracts_id[0].name.eng }}</h4>
        </div>
      </template>
      <template #body>
        <!-- eslint-disable vue/no-v-html -->
        <div
          class="template-view"
          v-html="invoicesTemplate"
        />
        <!--eslint-enable-->
      </template>
      <template #footer>
        <div class="d-flex justify-content-between">
          <WfmButton
            button-class="btn-outline-success"
            class="btn circle-div"
            @click="download()"
          >
            <v-icon
              name="bi-download"
              scale="1"
            />
          </WfmButton>

          <WfmButton
            button-class="btn-outline-success"
            class="btn circle-div"
            @click="print()"
          >
            <v-icon
              name="bi-printer"
              scale="1"
            />
          </WfmButton>
        </div>
      </template>
    </WfmModal>
    <!-- head tab -->
  </div>
</template>
<script>
import WfmToolbar from '../../common/wfm-toolbar.vue';
import { reactive, ref, computed } from 'vue'
import WfmTsRange from '../../common/form-controls/wfm-ts-range.vue';
import WfmDocpicker from '../../common/form-controls/wfm-docpicker.vue';
import getFolders from '../../composables/getFolders'
import WfmModal from '../../common/wfm-modal.vue'
import { useStore } from 'vuex';
import { toast } from 'vue3-toastify';
import { sendMessage } from '../../services/websocket';
import getGridConfig from '../../composables/getGridData';
import getConverters from '../../composables/getConverters';
import wfmText from '../../common/form-controls/wfm-text.vue';
import _ from 'lodash'
export default {
  name: 'InvoicesGenerate',
  components: {
    WfmToolbar,
    WfmDocpicker,
    WfmTsRange,
    WfmModal,
    wfmText
  },
  setup() {
    //variables
    const store = useStore();
    const startedGenerating = ref(false)
    const payslipData = ref([])
    const openPayslip = ref(false)
    const showData = ref(false) // Controls the animation
    const list = ref();
    const notificationId = ref(null)
    const hideLoader = ref(false)
    const actionInModal = reactive([
      {
        name: 'Action',
        actions: [],
      },
      {
        name: 'Go To',
        actions: [],
      },
    ]);

    //computed
    const bSettings = computed(() => {
      const bSettingString = store.getters['bSettings'];
      return bSettingString;
    });
    const invoicesTemplate = computed(() => {
      return `<div id="modal-body" tabindex="-1" class="modal-body toolbar-visible"><div style="padding: 3%;"><div><div>
                
                <table width="100%" cellpadding="2" cellspacing="2" border="0" class="font-small">
                    <!-- HEADER PART --> 
                    
                    <tbody><tr>
                        <td align="left" valign="middle" width="40%" style="height: 120px !important; overflow: hidden">
                        
                        </td>
                        <td align="center" valign="middle" width="20%"><img src="https://api.saralweb.com:8488/media/qrcode/https%3A%2F%2Fapp.saralweb.com%3A443%2Fverify%2FE630F23E41C98E69950737D5CB162145" style="width :120px; height:120px;"></td>
                        <td align="right" valign="top" width="40%" class="font-large">
                            <b>OM SECURITY FORCE &amp; LABOUR BUREAU</b>
                            <br>D 1 Pethkar Plaza, , Peth Naka Panchvati,, Nashik, Maharashtra - 422003
                            <br><b>Phone No:</b> 02532515487
                            <br><b>Email:</b> omsecurityforce@gmail.com
                            <br><b>Website:</b> 
                        </td>
                    </tr>
                </tbody></table>
                
                <table width="100%" cellpadding="2" cellspacing="2" border="1" class="font-small" rules="cols">
                    <tbody><tr valign="top" style="background: lightgray; border: 1px solid black;">
                        <td align="center" colspan="19"><b>TAX INVOICE</b></td>
                    </tr>
                    <tr valign="top" style="background: lightgray; border: 1px solid black;">
                        <td align="center" colspan="3"><b>Company Details</b></td>
                        <td align="center" colspan="6"><b>Invoice Details</b></td>
                        <td align="center" colspan="5"><b>Billing Address</b></td>
                        <td align="center" colspan="5"><b>Delivery Address</b></td>
                    </tr>
                    
                    <tr valign="top">
                        <td align="left" valign="top" colspan="3">
                            <b>CIN:</b> <br>
                            <b>GSTIN:</b> 27ACJPT1557C1ZJ <br>
                            <b>PAN No:</b> ACJPT1557C<br>
                            <b>EPF Code No:</b> <br>
                            <b>ESIC Code No:</b> <br>
                        </td>
                        <td align="left" valign="top" colspan="6">
                            <b>Invoice No:</b> 156<br>
                            <b>Invoice Date:</b> 30/10/2023<br>
                            <b>Invoice Month:</b> October - 2023
                             
                        </td>
                        <td align="left" valign="top" colspan="5">
                            <b> Everest Industries Limited </b><br>
                            152, Lakhamapur Dindori,<br>
                            Nashik, Maharashtra
                        </td>
                        <td align="left" valign="top" colspan="5">
                            <b> Everest Industries Limited </b><br>
                            152, Lakhamapur Dindori,<br>
                            Nashik, Maharashtra<br>
                            <b>GSTIN:</b> 27AAACE7550N1ZA<br>
                            <b>State &amp; Code:</b> Maharashtra / 27
                        </td>
                    </tr>
                
                    <!-- HEADER PART END -->
                    <!-- DAILY RATE BASED ITEMS STRAT -->
                    
                
                        
                
                        <tr valign="top" style="background: lightgray; border: 1px solid black;">
                            <td width="5%" align="center"><b>S.NO.</b></td>
                            
                            <td width="10%" align="center"><b>Location Code</b></td>
                            <td width="15%" align="center"><b>Particulars</b></td>
                            
                            <td width="8%" align="center" colspan="2"><b>Qty (days)</b></td>
                            <td width="8%" align="center" colspan="2"><b>Rate (per day)</b></td>
                            <td width="9%" align="center" colspan="2"><b>Net Values</b></td>
                            
                
                            <td width="7%" align="center" colspan="3"><b>Taxable Amount</b></td>
                
                            
                            <td width="9%" align="center" colspan="2"><b>CGST</b></td>
                            <td width="9%" align="center" colspan="2"><b>SGST</b></td>
                            <td width="9%" align="center" colspan="2"><b>IGST</b></td>
                            <td width="7%" align="right"><b>Amount</b></td>
                        </tr>
                        <tr valign="top" style="background: lightgray; border: 1px solid black;">
                            <td width="5%" align="center"></td>
                            
                            <td width="10%" align="center"></td>
                            <td width="15%" align="center"></td>
                            
                            <td width="5%" align="center"><b>Reg.</b></td>
                            <td width="5%" align="center"><b>OT</b></td>
                            <td width="5%" align="center"><b>Reg.</b></td>
                            <td width="5%" align="center"><b>OT</b></td>
                            <td width="3%" align="center"><b>Reg.</b></td>
                            <td width="6%" align="center"><b>OT</b></td>
                
                            
                
                            <td width="10%" align="center" colspan="3"></td>
                
                            
                            <td width="3%" align="center"><b>%</b></td>
                            <td width="6%" align="center"><b>Value</b></td>
                            <td width="3%" align="center"><b>%</b></td>
                            <td width="6%" align="center"><b>Value</b></td>
                            <td width="3%" align="center"><b>%</b></td>
                            <td width="6%" align="center"><b>Value</b></td>
                            <td width="7%" align="center"></td>
                        </tr>
                        
                        <tr valign="top">
                            <td align="center"> 1 </td>
                            
                            <td align="left"> Lakhmapur Works - Nashik </td>
                            
                            <td align="left" colspan="1">
                                HELPER:Unskilled:Contractual
                                
                                
                            </td>
                            <td align="right"> 1103.00 </td>
                            <td align="right"> 549.13 </td>
                            <td align="right"> 424.89 </td>
                            <td align="right"> 358.88 </td>
                            <td align="right"> 468653.67 </td>
                            <td align="right"> 197071.77 </td>
                            
                
                            <td align="right" colspan="3"> 665725.44 </td>
                
                            
                
                            <td align="right"> 9.00 </td>
                            <td align="right"> 59915.29 </td>
                            <td align="right"> 9.00 </td>
                            <td align="right"> 59915.29 </td>
                            <td align="right"> 0.00 </td>
                            <td align="right"> 0.00 </td>
                            <td align="right"> 785556.02 </td>
                        </tr>
                        
                        <tr valign="top">
                            <td align="center"> 2 </td>
                            
                            <td align="left"> Lakhmapur Works - Nashik </td>
                            
                            <td align="left" colspan="1">
                                Supervisor:Semi Skilled:Contractual
                                
                                
                            </td>
                            <td align="right"> 174.51 </td>
                            <td align="right"> 76.88 </td>
                            <td align="right"> 435.39 </td>
                            <td align="right"> 362.73 </td>
                            <td align="right"> 75979.91 </td>
                            <td align="right"> 27886.68 </td>
                            
                
                            <td align="right" colspan="3"> 103866.59 </td>
                
                            
                
                            <td align="right"> 9.00 </td>
                            <td align="right"> 9347.99 </td>
                            <td align="right"> 9.00 </td>
                            <td align="right"> 9347.99 </td>
                            <td align="right"> 0.00 </td>
                            <td align="right"> 0.00 </td>
                            <td align="right"> 122562.57 </td>
                        </tr>
                        
                        <tr valign="top">
                            <td align="center"> 3 </td>
                            
                            <td align="left"> Lakhmapur Works - Nashik </td>
                            
                            <td align="left" colspan="1">
                                Supervisor:Skilled:Contractual
                                
                                
                            </td>
                            <td align="right"> 61.00 </td>
                            <td align="right"> 36.75 </td>
                            <td align="right"> 619.26 </td>
                            <td align="right"> 370.46 </td>
                            <td align="right"> 37774.86 </td>
                            <td align="right"> 13614.40 </td>
                            
                
                            <td align="right" colspan="3"> 51389.26 </td>
                
                            
                
                            <td align="right"> 9.00 </td>
                            <td align="right"> 4625.03 </td>
                            <td align="right"> 9.00 </td>
                            <td align="right"> 4625.03 </td>
                            <td align="right"> 0.00 </td>
                            <td align="right"> 0.00 </td>
                            <td align="right"> 60639.32 </td>
                        </tr>
                        
                        <tr valign="top">
                            <td align="center"> 4 </td>
                            
                            <td align="left"> Lakhmapur Works - Nashik </td>
                            
                            <td align="left" colspan="1">
                                Semi Skilled - CONTRACTOR WORKMEN:Contractual
                                
                                
                            </td>
                            <td align="right"> 148.50 </td>
                            <td align="right"> 35.88 </td>
                            <td align="right"> 460.39 </td>
                            <td align="right"> 362.73 </td>
                            <td align="right"> 68367.91 </td>
                            <td align="right"> 13014.75 </td>
                            
                
                            <td align="right" colspan="3"> 81382.66 </td>
                
                            
                
                            <td align="right"> 9.00 </td>
                            <td align="right"> 7324.44 </td>
                            <td align="right"> 9.00 </td>
                            <td align="right"> 7324.44 </td>
                            <td align="right"> 0.00 </td>
                            <td align="right"> 0.00 </td>
                            <td align="right"> 96031.54 </td>
                        </tr>
                        
                        <tr valign="top" style="background: lightgray; border: 1px solid black; font-weight: bold;">
                            <td align="left" colspan="3"></td>
                            <td align="right">1487.01</td>
                            <td align="right">698.64</td>
                            <td align="right"></td>
                            <td align="right"></td>
                            <td align="right"> 650776.35 </td>
                            <td align="right"> 251587.60 </td>
                            
                
                            <td align="right" colspan="3"> 902363.95 </td>
                
                            
                
                            <td align="right"></td>
                            <td align="right"> 81212.75 </td>
                            <td align="right"></td>
                            <td align="right"> 81212.75 </td>
                            <td align="right"></td>
                            <td align="right"> 0.00 </td>
                            <td align="right"> 1064789.45 </td>
                        </tr>
                        <!-- DAILY RATE BASED ITEMS END -->
                    
                    <tr valign="top" style="font-weight: bold;">
                        <td align="left" colspan="12" rowspan="2">Narration : </td>
                        <td align="left" colspan="6">Net Chargable</td>
                        <td align="right"> 902363.95 </td>
                    </tr>
                
                
                        <tr valign="top">
                       
                        <td align="left" colspan="6"><b>Service Charge </b></td>
                        <td align="right"><b> 0.00 </b></td>
                    </tr>
                
                
                
                    <tr valign="top" style="border-bottom: 1px solid black;">
                        <td align="left" colspan="12">
                                
                        </td>
                        <td align="left" colspan="6"><b>Net Taxable</b></td>
                        <td align="right"><b> 902363.95 </b></td>
                    </tr>
                    
                    <tr valign="top">
                       
                        <td align="left" colspan="3"><b>Bank Name</b></td>
                        <td align="left" colspan="9">  </td>
                        <td align="left" colspan="6">CGST</td>
                        <td align="right"> 81212.75 </td>
                    </tr>
                    <tr valign="top">
                        <td align="left" colspan="3"><b>Bank A/C</b></td>
                        <td align="left" colspan="9">  </td>
                        <td align="left" colspan="6">SGST</td>
                        <td align="right"> 81212.75 </td>
                    </tr>
                    <tr valign="top">
                        <td align="left" colspan="3"><b>Bank IFSC Code</b></td>
                        <td align="left" colspan="9">  </td>
                        <td align="left" colspan="6">IGST</td>
                        <td align="right"> 0.00 </td>
                    </tr>
                    <tr valign="top">
                        <td align="left" colspan="3"></td>
                        <td align="left" colspan="9"></td>
                        <td align="left" colspan="6"><b>GST</b></td>
                        <td align="right"><b> 162425.5 </b></td>
                    </tr>
                    <tr valign="top" style="background: lightgray; border: 1px solid black; font-weight: bold;">
                        <td align="left" colspan="12"> TEN LAKH SIXTY FOUR THOUSAND SEVEN HUNDRED EIGHTY NINE RUPEES  ONLY</td>
                        <td align="left" colspan="6">Net Payable</td>
                        <td align="right"> 1064789.00 </td>
                    </tr>
                    <tr valign="top" style="font-weight: bold; border: 1px solid transparent">
                        <td align="left" colspan="12"></td>
                        <td align="right" colspan="7" style="height: 80px"><br>(Authorized Signatory)</td>
                    </tr>
                </tbody></table>
                
              </div></div></div></div>`
    })
    const formData = computed(() => {
      return store.getters['commonModule/formData'];
    });
    const pay_period = computed(() => {
      const range = store.getters['commonModule/formData'].pay_period;
      const { tsRangeStoredToUserFriendly } = getConverters();
      return tsRangeStoredToUserFriendly(range) || ''
    })
    const contractList = computed(() => {
      return store.getters['commonModule/formData'].contracts_id
    })
    const noContactSelected = computed(() => {
      if (_.isEmpty(contractList.value)) {
        return true
      }
      else {
        return false
      }
    })
    const contractDefaultPeriod = computed(() => {
      return formData.value.contracts_id?.[0].contract_period
    })
    const { getFoldersList, getCurrentFolder } = getFolders();
    const currentFolder = computed(() => {

      const folderList = getFoldersList(bSettings.value);
      const folderName = 'invoices'
      const folder = getCurrentFolder(folderName, folderList);
      return folder
    });
    const employeeFolder = computed(() => {
      const folderList = getFoldersList(bSettings.value)
      let empFolder = {}
      folderList.map((folder) => {
        if (folder.name === 'employees') {
          empFolder = folder
        }
      })
      return empFolder
    });
    const contractFolder = computed(() => {
      const folderList = getFoldersList(bSettings.value)
      let empFolder = {}
      folderList.map((folder) => {
        if (folder.name === 'contracts') {
          empFolder = folder
        }
      })
      return empFolder
    });
    const invoicesCategory = computed(() => {
      const folderList = getFoldersList(bSettings.value)
      let empFolder = {}
      folderList.map((folder) => {
        if (folder.name === 'invoice_categories') {
          empFolder = folder
        }
      })
      return empFolder
    });
    //methods
    function performAction(action) {
      console.log('handle action in the list')
      action()
    }
    function closeModal() {
      openPayslip.value = false
    }
    function openModal(payslipData) {
      openPayslip.value = true
    }

    function print() {
      console.log('print ')
    }
    function download() {
      console.log('print ')
    }
    async function generate() {
      if (formData.value.pay_period && formData.value.contracts_id) {
        startedGenerating.value = true
        try {
          notificationId.value = toast.loading('generating invoices...', {
            position: toast.POSITION.TOP_RIGHT,
          });
          //close the modal
          const txnToRun = currentFolder.value.txns.txn_invoices_create
          const sessionId = store.getters['sessionIdGetter'];
          txnToRun.params = {
            'contracts_id': formData.value.contracts_id[0].id,
            'print_preview_template': formData.value.pay_period,
            'cgst_in': formData.value.cgst_in,
            'narration': formData.value.narration,
            'custom_invoice_numbers': formData.value.custom_invoice_numbers,
            'invoice_categories_id': formData.value.invoice_categories_id[0].id,
            // "invoice_types_id":formData.value.invoice_types_id[0].id,
            'invoice_period': formData.value.pay_period,
            'session_key': sessionId
          }
          console.log('final txn to run', txnToRun)
          list.value = await sendMessage(txnToRun);

          // const txnToRun = generateTxn();
          // //run the txn
          if (list.value.output.type === 'success') {
            const { rowDataToShow } = getGridConfig();
            payslipData.value = await rowDataToShow(list.value, bSettings.value);
            hideLoader.value = true
            startedGenerating.value = false
            showData.value = true

            toast.update(notificationId.value, {
              render: 'done',
              autoClose: 1000,
              closeButton: true,
              type: 'success',
              isLoading: false,
            });

          }
          else {
            hideLoader.value = true
            startedGenerating.value = false
            toast.update(notificationId.value, {
              render: 'Error !',
              closeButton: true,
              type: 'error',
              autoClose: 2000,
              toastClassName: 'toast-body-error',
              isLoading: false,
            });
          }
          //take care of states after closing the form
        } catch (err) {
          throw err;
        }

      }
      else {
        toast.error('please fill the required data')
      }


    }
    return {
      actionInModal,
      startedGenerating,
      payslipData,
      employeeFolder,
      contractFolder,
      invoicesTemplate,
      generate,
      pay_period,
      print,
      download,
      contractDefaultPeriod,
      openPayslip,
      closeModal,
      openModal,
      noContactSelected,
      contractList,
      invoicesCategory,
      performAction
    }
  }
}
</script>

<style scoped>
.mystyle {
  border: 1px solid red;
}

.name-tag {
  text-align: start;
  padding: 8px;
  color: black;
  font-size: 20px;
  font-weight: 700;
}

.card-header {
  width: 100%;
  background-color: white;
  border: 0px;
}

.card-footer {
  color: black;
  width: 100%;
  padding: 0px;
  font-size: 20px;
  font-weight: 900;
  background-color: white;
  border: 0px;

}

.list-box {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 7px;
  border-radius: 20px;
  margin-top: 5px;
  width: 600px;
}

.selected {
  border: 4px solid lightgreen;
}

.person-name {
  font-size: 1rem;
}

.action-btn-download {
  position: absolute;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  bottom: 0;
}

.template-view {
  width: fit-content;
  height: 70vh;
  overflow-y: scroll;
}

.circle-div {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  align-items: center;
  padding: 8px;
  background-color: lightblue;
  font-weight: 900;
  color: black;
}

.circle-div:hover {
  background-color: lightgreen;
  color: black
}

.action-btn-print {
  position: absolute;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  right: 0;
  margin-right: 10px;
}

.content-box {
  height: 85vh;
  margin-left: 20px;
  margin-right: 20px;
}

.employee-box {
  box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
  border: 1px solid grey;
  height: 100px;

}

.employee-row {
  margin-left: 200px;
  height: auto;
  overflow-y: scroll;
}

.printer-icon {
  cursor: pointer;
  border: 2px solid #2e688dff;
  border-radius: 10px;
}

.action-list {
  color: white;
  background-color: var(--toolbar-color);
}

.modal-body {
  padding-left: 10px;
  padding-right: 10px;
}

.toolbar-modal {
  border: 2px solid red;
  background-color: black;
}

.profile-picture {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

}

.profile-picture2 {
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

}

.box-title-reset {
  color: var(--main-font-color);
  font-weight: 700;
  text-transform: capitalize;
  font-size: large;

}

.box-title-profile {
  color: var(--main-font-color);
  font-weight: 700;
  text-transform: capitalize;
  font-size: large;

}

.box-title-reset::after {
  border-bottom: 0px;
  color: var(--main-font-color);
  font-weight: 700;
  text-transform: capitalize;
  font-size: large;

}

.input-group {
  flex-wrap: nowrap;
  height: fit-content;
}

.toolbar-modal {
  background-color: var(--main-background-color) !important;
}


.tabs {
  cursor: pointer;
  padding: 20px;
  padding-bottom: 0px;
  background-color: lightgoldenrodyellow;
  margin-bottom: 0px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.tabs2 {
  cursor: pointer;
  padding: 20px;
  padding-bottom: 0px;
  background-color: lightgoldenrodyellow;
  margin-bottom: 0px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.input-group-text {
  background-color: var(--co-main-color);
  color: white
}

.underlined {
  text-decoration: underline;
  text-decoration-color: var(--main-font-color);
  text-decoration-thickness: 2px !important;
  background-color: lightblue;
  color: var(--toolbar-color) !important;
}

.files-box {
  height: 500px;
  margin-left: 300px;
  margin-right: 20px;
  padding: 30px;
  margin-left: 10px;
  overflow-y: scroll;

}

.files-box::-webkit-scrollbar {
  display: none;
}

.template-box {
  height: fit-content;
}

.template {
  width: 300px;
  height: 300px;

}

.box-title-reset {
  color: var(--main-font-color);
  font-weight: 700;
  text-transform: capitalize;
  font-size: medium;

}

.box-title-profile {
  color: var(--main-font-color);
  font-weight: 700;
  text-transform: capitalize;
  font-size: medium;

}

.box-title-reset::after {
  border-bottom: 0px;
  color: var(--main-font-color);
  font-weight: 700;
  text-transform: capitalize;
  font-size: medium;

}

.glass {
  position: relative;
  display: inline-block;
  padding: 15px 25px;
  background-color: red;
  background-image: linear-gradient(green, lightgreen);
}

.glass:after {
  content: '';
  position: absolute;
  background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.2));
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

.container {
  width: 100%;
  height: 100px;
  overflow: hidden;
}

.animated-div {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
