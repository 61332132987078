const foldersUtility = () => {

  function changeWorkingFolder(docpSource, bSettings) {
    //change the folder given source and bSettings

    const container = docpSource.container || docpSource.containers;
    const foldertype = docpSource.foldertype;
    const folderName = docpSource.folder;
    const newCurrentFolder = bSettings?.[container]?.containers[foldertype]?.folders[folderName];
    return newCurrentFolder;
  }

  return { changeWorkingFolder };
};

export default foldersUtility;
// {
//     "swhandle": ".",
//     "container": "business",
//     "folder": "employee_types",
//     "foldertype": "masters",
//     "schema": "ddnews_masters"
// }
