const leavesForms = () => {
  const fieldsToDisplayCreate = [
    {
      'type': 'field',
      'path': 'leaves.leave_days.leave_types_id',
      'mandatory_expr': 'true',
      'placeholder': 'Please Select...',
      'name':'leave_types_id',
      'normal_control':true,
      'is_detail':true
    },
    { 'type': 'field',
      'path': 'leaves.notes',
      'placeholder': 'Please type...',
      'name':'notes',
      'normal_control':true,
    },
    {
      'type': 'field',
      'path': 'leaves.leave_days.leave_status',
      'readonly_expr': 'true',
      'default_value': 'created',
      'normal_control':true,
      'name':'leave_status',
      'is_detail':true
    },
    {
      'type': 'field',
      'path': 'leaves.leave_days.documents',
      'file_upload': true,
      'showFull':true,
      'name':'documents',
      'normal_control':true,
      'is_detail':true
    }
  ]
  const fieldsToDisplayUpdate =  [
    {
      'type': 'field',
      'path': 'leaves.leave_days.leave_types_id',
      'mandatory_expr': 'true',
      'placeholder': 'Please Select...',
      'name':'leave_types_id',
      'normal_control':true,
      'is_detail':true
    },
    { 'type': 'field',
      'path': 'leaves.notes',
      'placeholder': 'Please type...',
      'name':'notes',
      'normal_control':true,
    },
    {
      'type': 'field',
      'path': 'leaves.leave_days.leave_status',
      'readonly_expr': 'true',
      'normal_control':true,
      'name':'leave_status',
      'is_detail':true
    },
    {
      'type': 'field',
      'path': 'leaves.leave_days.documents',
      'file_upload': true,
      'showFull':true,
      'name':'documents',
      'normal_control':true,
      'is_detail':true
    }
  ]


  function getLeavesForm(formName) {
    if (formName == 'update') {
      return fieldsToDisplayUpdate
    }
    else if (formName == 'create') {
      return fieldsToDisplayCreate
    }

  }


  return { getLeavesForm }
}
export default leavesForms;
