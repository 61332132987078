<template>
  <div class="helper-list-container">
    <div
      v-if="currentView === 'allocations'"
      class="helper-list"
    >
      <div class="list-type">
        <input
          id="contracts"
          type="checkbox"
          name="contracts"
          class="contracts"
          @change="showContracts"
        />
        <span>Contracts</span>
      </div>
      <!-- eslint-disable vue/no-v-html -->
      <div class="helper-item">
        <span
          v-for="contract in contracts"
          :key="contract"
          v-html="contract"
        />
      </div>
      <!--eslint-enable-->
    </div>
    <div class="helper-list">
      <div class="list-type">
        <input
          id="sites"
          type="checkbox"
          name="sites"
          class="sites"
          @change="showSites"
        />
        <span>Sites</span>
      </div>
      <!-- eslint-disable vue/no-v-html -->
      <div class="helper-item">
        <span
          v-for="site in sites"
          :key="site"
          v-html="site"
        />
      </div>
      <!--eslint-enable-->
    </div>
    <div
      v-if="currentView === 'attendance'"
      class="helper-list"
    >
      <div class="list-type">
        <input
          id="designations"
          type="checkbox"
          name="designations"
          class="designations"
          @change="showDesignations"
        />
        <span>Designations</span>
      </div>
      <!-- eslint-disable vue/no-v-html -->
      <div class="helper-item">
        <span
          v-for="designation in designations"
          :key="designation"
          v-html="designation"
        />
      </div>
      <!--eslint-enable-->
    </div>
    <div
      v-if="currentView === 'attendance'"
      class="helper-list"
    >
      <div class="list-type">
        <!-- <input
          id="designations"
          type="checkbox"
          name="designations"
          class="designations"
          checked
          @change="showAttendanceType"
        /> -->
        <span>Attendance Type</span>
      </div>
      <!-- eslint-disable vue/no-v-html -->
      <div class="helper-item">
        <span
          v-for="att_type in att_types"
          :key="att_type"
          v-html="att_type"
        />
      </div>
      <!--eslint-enable-->
    </div>
    <div>
      <v-icon
        :fill="`#292929`"
        name="bi-three-dots-vertical"
        scale="1.5"
        class="filter-icon"
      />
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import attendanceGridUtils from '../../../composables/attendanceGridUtils';

export default {
  name: 'HelperList',
  props: {
    gridView: {
      type: String,
      default: () => ''
    },
    helperListData: {
      type: Object,
      default: () => {}
    },
    showDesignation: {
      type: Function,
      default: () => (false)
    },
    showSite: {
      type: Function,
      default: () => (false)
    },
    showContract: {
      type: Function,
      default: () => (false)
    }
  },
  setup(props) {
    const { getRandomColor } = attendanceGridUtils();
    const currentView = ref(props.gridView);

    const showDesignations = () => {
      props.showDesignation();
    };

    const showSites = () => {
      props.showSite();
    };

    const showContracts = () => {
      props.showContract();
    }

    const contracts = computed(() => {
      if (!props.helperListData.contracts_id) return [];
      return props.helperListData.contracts_id.map((item) => {
        const randomColor = getRandomColor('sites_id', item.name.eng, 0.5);
        return `<span data-original-value="${item.name.eng}" style="background-color: ${randomColor}; padding: 4px; border-radius: 10%; text-wrap: nowrap" onmouseover="this.textContent='${item.name.eng}'" onmouseout="this.textContent='${item.name.eng}'">${item.name.eng}</span>`;
      });
    });

    const sites = computed(() => {
      if (!props.helperListData.sites_id) return [];
      return props.helperListData.sites_id.map((item) => {
        const randomColor = getRandomColor('sites_id', item?.name?.eng, 0.5);
        return `<span data-original-value="${item?.name?.eng}" style="background-color: ${randomColor}; padding: 4px; border-radius: 10%; text-wrap: nowrap" onmouseover="this.textContent='${item?.name?.eng}'" onmouseout="this.textContent='${item?.name?.eng}'">${item?.name?.eng}</span>`;
      });
    });

    const designations = computed(() => {
      if (!props.helperListData.designation) return [];
      return props.helperListData.designation.map((item) => {
        const randomColor = getRandomColor('designation', item.code, 0.5);
        return `<span data-original-value="${item.code}" style="background-color: ${randomColor}; padding: 4px; border-radius: 10%; text-wrap: nowrap" onmouseover="this.textContent='${item.name}'" onmouseout="this.textContent='${item.name}'">${item.name}</span>`;
      });
    });

    const att_types = computed(() => {
      if (!props.helperListData.att_type) return [];
      return props.helperListData.att_type.map((item) => {
        let color = ''
        if (item.code === 'P') {
          color = 'rgb(182 247 182)';
        } else if (item.code === 'A') {
          color = 'rgb(245 210 209)';
        } else if (item.code === 'H') {
          color = 'rgb(199 237 249)'
        } else if (item.code === 'SP') {
          color = ' #FFFFE0'
        } else if (item.code === 'PR') {
          color = '#D3D3D3'
        } else {
          color = 'rgb(255 234 191)'
        }
        return `<span data-original-value="${item?.code}" style="background-color: ${color}; padding: 4px; border-radius: 10%; text-wrap: nowrap" onmouseover="this.textContent='${item?.name?.eng}'" onmouseout="this.textContent='${item?.name?.eng}'">${item?.name?.eng}</span>`;
      })
    })

    return { currentView, contracts, designations, sites, showContracts, showDesignations, showSites, att_types };
  }
}
</script>

<style>
.helper-list-container {
  background-color: rgba(255, 255, 255, 0);
  position: fixed;
  width: 100%;
  z-index: 1;
  color: #292929;
  display: flex;
  justify-content: space-around;
}

.list-type {
  display: flex;
  justify-content:center;
  align-items: center;
  font-size: 12px;
  padding: 0 2px;
  margin-bottom: 2px;
  border: 0.1px solid #00b7ff;
  background-color: rgb(213, 210, 210);
  border-radius: 5px;
}

.helper-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 9vh;
  padding: 2px;
}

.helper-list-type {
  margin-right: 10px;
}

.helper-list span {
  font-size: 12px;
  margin: 5px;
}

.helper-item {
  display: flex;
  scrollbar-width: thin;
  overflow-x: scroll;
  width: 500px;
  background-color: white;
}
</style>
