<template>
  <div
    v-if="readOnly"
    class="list-view-class"
  >
    {{ value }}
  </div>
  <div
    v-else
    class="input-group d-none"
  >
    <span
      v-if="!showInTable && !readOnly"
      class="input-group my-2"
    >{{ uuidOptions.label[currentLanguage] }}
      <span
        v-if="isMandatory"
        class="ms-2"
        style="color: red"
      >
        *</span>
    </span>
    <input
      v-model="value"
      disabled
      type="text"
      class="form-control"
    >
  </div>
</template>

<script>
import { ref, computed, inject} from 'vue';
import {keys} from '../../provideKeys.js'
import { useI18n } from 'vue-i18n';
import { v4 as uuidv4 } from 'uuid';
import processExpr from '../../composables/processExprs';
import controlUtility from '../../composables/controlUtility';
export default {
  name: 'WfmUuid',
  props: {
    params: {
      type: Object,
      default: null,
    }
  },
  emits: ['input-change', 'mandatory-check'],
  setup(props, context) {
    //variables
    const i8vue = useI18n()
    const currentLanguage = i8vue.locale.value
    const value = ref(null);
    const showInTable = props.params?.showInTable ? true : false;
    const readOnly = props.params?.readOnly ? true : false;
    const uuidOptions = props.params.fieldOptions
    const uuidParams = props.params.data
    const folderName = uuidOptions?.path?.split('.')[0]
    const propertyName = uuidOptions.name;
    value.value = uuidParams?.[propertyName] ? uuidParams?.[propertyName] : uuidParams?.[folderName]?.[0][propertyName];
    const idOfTheFolder = uuidParams?.[folderName]?.[0].id ? uuidParams?.[folderName]?.[0].id : uuidParams?.id
    const fieldPath = uuidOptions?.path
    context.emit('input-change', value.value)
    if (!uuidParams?.[propertyName]) {
      value.value = uuidv4()
      context.emit('input-change', { path: fieldPath, value: value.value, folderId: idOfTheFolder, mandatory: uuidOptions.mandatory_expr })
    }
    const currentFolder = ref(props.params.currentFolder)
    const mandatoryFields = props.params.mandatoryFields
    const { mandatoryCheck } = controlUtility(null, mandatoryFields)
    const { processReadOnly, processVisibleExpr, processMandatoryExpr} = processExpr(currentFolder.value)
    //computed
    const missingFields = (readOnly || showInTable) ? props.params.missingFields  : inject(keys.missingFields, [])

    const isMissing = computed(() => {
      if (missingFields?.value?.includes(propertyName)) {
        return true
      }
      else {
        return false
      }
    })
    const isVisible = computed(() => {
      const retVal = processVisibleExpr(uuidOptions.visible_expr)
      return retVal
    })
    const isMandatory = computed(() => {
      const retVal = processMandatoryExpr(uuidOptions.mandatory_expr) && isVisible.value
      if (!showInTable) {
        // context.emit("mandatory-check", {
        //   propertyName: propertyName,
        //   value: retVal,
        // })
        mandatoryCheck({
          propertyName: propertyName,
          value: retVal,
        })
      }
      return retVal
    })
    const isReadOnly = computed(() => {
      const retVal = processReadOnly(uuidOptions.readonly_expr)
      return retVal
    })

    //methods
    function getValue() {
      return value.value;
    }

    return {
      value,
      uuidOptions,
      showInTable,
      currentLanguage,
      readOnly,
      getValue,
      isMandatory,
      isReadOnly,
      isMissing
    };
  },
};
</script>

<style scoped>
.input-group-text {
  background-color: antiquewhite;
}
</style>
