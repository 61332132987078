<template>
  <div
    v-if="dataReady"
    class="mx-3"
  >
    <WfmTabsHorizontal
      :key="tabKey"
      :tabs-list="tabsList"
      :selected-index="selectedIndex"
      :handle-click="handleClick"
      @add-tab="addTab"
      @del-tab="delTab"
      @change-tab="changeTab"
    />
  </div>
</template>
<script>
import WfmTabsHorizontal from '../../common/wfm-tabs-horizontal.vue';
import { ref, computed, onMounted, inject} from 'vue';
import {keys} from '../../provideKeys.js'
import { useStore } from 'vuex';
import makeTxn from '../../composables/makeTxn';
import processFormData from '../../composables/processFormData';
import { sendMessage } from '../../services/websocket';
import { toast } from 'vue3-toastify';
import getFolders from '../../composables/getFolders';
import postData from '../../composables/postData';
import getFatRow from '../../composables/fatRowGenerator';
import getFormInfo from '../../composables/getFormInfo';

export default {
  name: 'ProfileInfo',
  components: {
    WfmTabsHorizontal,
  },
  setup(props) {
    const store = useStore();
    const { listData } = postData();
    const dataReady = ref(false)
    const { getGroupedFormData } = processFormData();
    const { getFormObject, getCurrentTxn } = getFormInfo();
    const tabKey = ref(0);
    const notificationId = ref();
    const sessionId = store.getters['sessionIdGetter'];
    const userData = ref(null)
    const selectedIndex = ref(0)

    // const formData = computed(() => {
    //   return store.getters["commonModule/formData"];
    // });
    const formData = inject(keys.formData)

    const bSettings = computed(() => {
      return store.getters['bSettings'];
    });
    const { getAllFoldersList, getCurrentFolder } = getFolders();
    const folderList = getAllFoldersList(bSettings.value);

    // Employees folder
    const employeesFolder = computed(() => {
      const currentFolder = getCurrentFolder('employees', folderList);
      return currentFolder;
    });
    const currentTxn = computed(() => {
      return getCurrentTxn('update', employeesFolder.value);
    })

    //list of details in the form

    const swHandle = computed(() => {
      return store.getters['userModule/swHandle'];
    });
    const refBy = computed(() => {
      const result = {}
      // result['resetPassword'] = []
      // result['resetPassword'].push({
      //   label: 'Reset PassWord',
      //   component: 'ResetPassword',
      //   name:'resetPassword',
      //   params: {
      //     readOnly:false,
      //   }
      // })
      return result
    })
    const baseParams = computed(() => {
      return {
        rowData: userData.value,
        fieldsToDisplay:formObject.value,
        formObjectFound: true,
        folder:employeesFolder.value,
        txnToUse :currentTxn.value,
        refBy: refBy.value,
        readOnly: false,
        currentTask:'update',
        agGridApi:null
      }
    })
    const formObject = computed(() => {
      const formName = currentTxn.value?.form?.form_name
        ? currentTxn.value?.form?.form_name
        : currentTxn.value?.form;
      return getFormObject(formName, employeesFolder.value);
    });

    const tabsList = computed(() => {
      return  [
        {
          label: 'Update Profile',
          component: 'FolderForm',
          name: 'updateProfile',
          params: baseParams.value,
        }
      ]
    })
    //methods
    function changeTab(index) {
      selectedIndex.value = index
    }
    function touchTabKey() {
      tabKey.value = Math.floor((Math.random() * 10000) + 1);
    }
    //update the profile
    async function updateProfile() {
      const txnType = null;
      const {
        normalFieldList,
        docpList,
        detailList,
        detailKeyFieldName,
      } = getGroupedFormData(formData.value, employeesFolder.value, txnType);
      const params = {
        normalFieldList:normalFieldList,
        docpList:docpList,
        detailList:detailList,
        folderDetails:employeesFolder.value,
        currentTaskName:'update',
        detailKeyName:detailKeyFieldName,
        currentFolderId:userData.value.id,
        txn:employeesFolder.value.txns.txn_employees_update
      }
      const { generateTxn } = makeTxn(params);
      const txnToRun = generateTxn();
      txnToRun.session_key = sessionId;
      notificationId.value = toast.loading('working on it...', {
        position: toast.POSITION.TOP_CENTER,
      });
      const retVal = await sendMessage(txnToRun);
      await handleResult(retVal);
    }

    async function getProfileInfo() {
      const txnParams = {
        refRows: true,
      };
      const filters = {
        joinop: 'or',
        filterInput: [
          {
            operator: 'iLike',
            path: 'employees.code',
            value: bSettings.value.env.code,
          },
        ],
      };
      txnParams.filters = filters;
      const list = await listData(employeesFolder.value, txnParams);
      const { fatRowGenerator } = getFatRow();
      const fatRow = fatRowGenerator(list);
      const retVal = fatRow[0];
      if (retVal?.registration_image?.[0]?.filepath) {
        localStorage.setItem('profilePic', retVal?.registration_image?.[0]?.filepath);
      }
      return retVal
    }

    async function handleResult(data) {
      if (data.output.type === 'success') {
        toast.update(notificationId.value, {
          render: 'Update Done',
          closeButton: true,
          type: 'success',
          autoClose: 3000,
          toastClassName: 'toast-body-error',
          isLoading: false,
        });
        // store.commit("folderModule/userDataUpdatedMutations", true);
        // store.commit("commonModule/formDataMutation", {});
        formData.value = {}
      }
      if (data.output.type === 'error') {
        toast.update(notificationId.value, {
          render: `Error !${data.output.message}`,
          closeButton: true,
          type: 'error',
          autoClose: 3000,
          toastClassName: 'toast-body-error',
          isLoading: false,
        });
      }
    }

    async function resetPassword() {
      if (formData.value && Object.keys(formData.value).length !== 0) {
        if (formData.value.newPassword !== formData.value.confirmPassword) {
          toast.error("passwords don't match");
        } else {
          const resetTxn = employeesFolder.value.txns.txn_employees_set_password;
          const params = {
            code: formData.value.userName,
            old_password: formData.value.password,
            password: formData.value.newPassword,
          };
          resetTxn.swhandle = swHandle.value;
          resetTxn.params = params;
          resetTxn.session_key = sessionId;
          const retval = await sendMessage(resetTxn);
          if (retval.output.type === 'success') {
            toast.success('password changed!');
          } else {
            toast.error('password change failed');
          }
        }
      }
    }

    // Define handleClick function here or import it if it's defined elsewhere
    function handleClick(name) {
      // Call your specific function based on the tab name
      if (name === 'updateProfile') {
        updateProfile()
      } else if (name === 'resetPassword') {
        resetPassword()
      }
    }

    function addTab(tabSpec) {
      const idx = tabsList.value.findIndex((obj) => obj.label === tabSpec.label)
      if (idx < 0) {
        tabsList.value.push(tabSpec)
        selectedIndex.value = tabsList.value.length - 1

      }
      else {
        tabsList.value[idx].component = tabSpec.component
        tabsList.value[idx].params = tabSpec.params
        selectedIndex.value = idx

      }

    }

    function delTab(index) {
      tabsList.value.splice(index, 1)
      touchTabKey()
    }

    onMounted(async() => {
      userData.value = await getProfileInfo();
      dataReady.value = true
    });

    return {
      tabsList,
      addTab,
      delTab,
      tabKey,
      changeTab,
      handleClick,
      dataReady,
      selectedIndex,

    };
  }
};
</script>


<style scoped>

</style>
