<template>
  <div
    v-if="isLoading"
    class="overlay"
  >
    <WfmLoader />
  </div>
</template>

<script>
import WfmLoader from '../src/common/loader/wfm-loader.vue';
export default {
  components: {
    WfmLoader
  },
  computed: {
    isLoading() {
      // Get the loading flag from the store or component state
      return this.$store.state.writes_blocked;
    }
  }
}
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Ensure it stays on top */
}

.spinner {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 0.5s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
