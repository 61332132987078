const mutation = {
  bSettingsMutations: (state, params) => {
    state.bSettings = params;
  },
  folderMutations: (state, params) => {
    state.folderValue = params;
  },
  fieldNameMutations: (state, params) => {
    state.fieldName = params;
  },
  propsMutation: (state, params) => {
    state.props = params;
  },
  sessionIdMutation: (state, params) => {
    state.session_key = params.sessions_id
    localStorage.setItem('sessions_id', params.sessions_id);
  },
  serverPortMutation: (state, params) => {
    localStorage.setItem('server_port', params);
  },
  listTxnArrayMutation: (state, params) => {
    state.listTxnArray = params;
  },
  writeBlockingMutation: (state, value) => {
    state.writes_blocked = value;
  },
  writeSelectedEmps: (state, value) => {
    state.selectedEmps = state.selectedEmps.concat(value);
  },
  writeDutyChartsData: (state, value) => {
    state.dutyCharts = value;
  },
  writeDCPinnedBtmRowsData: (state, value) => {
    state.dutyChartPinnedBottomRows = value;
  },
};

export default mutation;
