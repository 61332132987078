const addUiInteractionFlags = () => {
  const defaultFlags = {
    list_view :true,
    modal_view: false,
    tabs_view :true,
    grid_view:false,
    calender_view:false
  }
  const exceptionFolders = {
    'reports':{
      list_view:true,
      modal_view:false,
      tabs_view:true,
      grid_view:false,
      calender_view:false,
      reports_view:true,
    },
    'payslips':{
      list_view:true,
      modal_view:false,
      tabs_view:true,
      grid_view:false,
      calender_view:false,

    },
    'attendance_edits':{
      list_view:false,
      modal_view:false,
      tabs_view:false,
      grid_view:true,
      calender_view:false
    },
    'attendance':{
      list_view:false,
      modal_view:false,
      tabs_view:false,
      grid_view:true,
      calender_view:false
    },
    'bulk_upload':{
      list_view:true,
      modal_view:true,
      tabs_view:false,
      grid_view:false,
      calender_view:false
    }
  }

  function addFlags(folder) {

    if (exceptionFolders.hasOwnProperty(folder.name)) {
      folder.uiFlags = exceptionFolders[folder.name]
    }
    else {
      folder.uiFlags = defaultFlags
    }
    return folder
  }

  return { addFlags };
};

export default addUiInteractionFlags;
