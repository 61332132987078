<!-- eslint-disable vue/require-toggle-inside-transition -->
<template>
  <div class="reset-container">
    <div
      v-for="(item, index) in textInputs"
      :key="index"
    >
      <div class="mb-3 mx-auto">
        <span class="input-group">{{ item.fieldName }}</span>
        <div class="input-group mb-3">
          <input
            v-model="item.value"
            :type="item.inputType"
            class="form-control input-class"
            placeholder="text here"
            name="text"
            @change="changeValue(item, index)"
          />
          <div
            v-if="item.iconName"
            class="input-group-append input-group-text"
          >
            <v-icon
              :name="item.iconName"
              scale="1.2"
              fill="#2e688dff"
              @click="toggleVisible(index)"
            />
          </div>
        </div>
      </div>
    </div>
    <WfmButton
      button-class="reset-btn"
      @button-click="resetPassword()"
    >
      Reset Password
    </WfmButton>
  </div>
</template>
<script>
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import WfmButton from '../../common/wfm-button.vue';
export default {
  name: 'ResetPassword',
  components:{
    WfmButton
  },
  setup(props) {
    // Refs
    const store = useStore();
    const bSettings = computed(() => {
      return store.getters['bSettings'];
    })
    const formData = ref()
    const userName = ref(bSettings.value.env.code);
    const password = ref();
    const iconName = ref('bi-eye-fill');
    const newPassword = ref();
    const inputType = ref('password');
    const confirmPassword = ref();

    const textInputs = ref();
    textInputs.value = [
      {
        fieldName: 'username',
        value: userName.value,
        inputType: 'text',
        iconName: null,
      },
      {
        fieldName: 'old password',
        value: password.value,
        inputType: inputType.value,
        iconName: 'bi-eye',
      },
      {
        fieldName: 'new password',
        value: newPassword.value,
        inputType: inputType.value,
        iconName: 'bi-eye',
      },
      {
        fieldName: 'confirm password',
        value: confirmPassword.value,
        inputType: inputType.value,
        iconName: 'bi-eye',
      },
    ];


    function toggleVisible(index) {
      const currentTextBox = textInputs.value[index];
      if (currentTextBox.iconName === 'bi-eye-slash-fill') {
        currentTextBox.iconName = 'bi-eye';
        currentTextBox.inputType = 'password';
      } else {
        currentTextBox.iconName = 'bi-eye-slash-fill';
        currentTextBox.inputType = 'text';
      }
    }

    function changeValue(item, index) {
      if (index == 1) {
        password.value = item.value;
      }
      if (index == 2) {
        newPassword.value = item.value;
      }
      if (index == 3) {
        confirmPassword.value = item.value;
      }
      formData.value = {
        userName:userName.value,
        password:password.value,
        newPassword:newPassword.value,
        confirmPassword:confirmPassword.value
      }

      // store.dispatch("commonModule/formDataSetter", formData)
    }
    function resetPassword() {
      console.log('handle reset password', formData.value)
    }
    return {
      userName,
      password,
      confirmPassword,
      newPassword,
      iconName,
      textInputs,
      toggleVisible,
      inputType,
      changeValue,
      resetPassword
    };
  },
};
</script>


<style>
  .input-class {
    min-height: 50px;
  }
  .reset-container{
    background-color: white;
    height: 85vh;
    border-radius: 8px;
    padding: 10% 30%;

  }

.reset-btn {
  border: 2px solid #2e688dff;
  background-color: white;
  color: #2e688dff;
  border-radius: 6px;
  font-size: 18px;
  padding-left: 8px;
  font-weight: 600;
  margin-top:10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  padding-right: 8px;

  &:hover {
    background-color: #2e688dff;
    color: white;
  }
}
  .action-class{
  background-color: var(--toolbar-color);
  padding-top:30px;
  padding-bottom:30px;
  margin-top: 0px;

}
</style>
