<template>
  <div id="app">
    <SpinnerOverlay />
    <WfmNavbar v-if="isLoggedIn" />
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import SpinnerOverlay from './SpinnerOverlay.vue';
import WfmNavbar from '../src/common/navigations/navbar/wfm-navbar.vue';
import { ref } from 'vue';
import { useStore } from 'vuex';
export default {
  components: {
    WfmNavbar,
    SpinnerOverlay
  },

  setup(props, context) {
    const isDarkTheme = ref(false);
    const store = useStore();
    const navBar = ref(null);
    const isLoggedIn = ref(store.getters['userModule/isLoggedIn']);
    function change() {
      isDarkTheme.value = !isDarkTheme.value
      document.body.classList.toggle('dark-theme', isDarkTheme.value);
    }

    return {
      isLoggedIn,
      navBar,
      isDarkTheme,
      change
    };
  },
};
</script>

  <style>
  /*global css import */
  @import '../src/assets/css/app.css';
  body {
    margin: 0;
    height: 100vh;
  }
  #app {
    text-align: center;
    color: var(--main-color);
    height: 100%;
    background-color: var(--main-background-color);
  }
  </style>
    background: rgb(32, 32, 32);
    border: 1px solid bisque;
    border-bottom: none;
    color: bisque;
