import store from '../../store';
import foldersUtility from './foldersUtility';

const processFormData = () => {
  const bSettings = store.getters['bSettings'];
  let docpList = {};
  let detailList = {};
  let detailKeyFieldName = null;
  let normalFieldList = {};
  let folderPath = '';
  let parentDocpSource = null;
  function getGroupedFormData(formData, currentFolder, txnType) {
    //loop through each fields that are in formData
    //check if the field that is changed is visible in the form or not
    //if its not showing in the form.the field will have showing=false.
    for (const key in formData) {
      if (formData[key]?.visible === undefined || formData[key]?.visible === true) {
        //if the value has pathOfDetail
        if (formData[key]?.pathofDetail) {
        //detail table path
          const detailPath = formData[key].pathofDetail;
          const mainfolder = detailPath.split('.')[0];
          if (currentFolder.fields[mainfolder]) {
            parentDocpSource = currentFolder.fields[mainfolder].source;
          }
        }
        if (parentDocpSource) {
          folderPath = `${parentDocpSource.container}.${parentDocpSource.foldertype}.${parentDocpSource.folder}`;
        } else {
          folderPath = `business.${currentFolder.foldertype}.${currentFolder.name}`;
        }
        if (formData[key]?.path) {
          if (currentFolder.fields[key]) {
            if (currentFolder.fields[key]?.type === 'docpicker') {
            //if the field type is docpicker
              const src = formData[key].folderId;
              const dst = formData[key].value.id;
              // formData[key].patchPath = `${folderPath}.${key}`;
              formData[key].src = src;
              formData[key].dst = dst;
              Object.assign(docpList, {
                [key]: formData[key],
              });
              //remove the source
              parentDocpSource = null;
            } else {
              // formData[key].patchPath = folderPath;
              Object.assign(normalFieldList, {
                [key]: formData[key],
              });
              //remove the source
              parentDocpSource = null;
            }
          } else {
          //change the current folder
          //get the docpFolder source to change if the formData has path given
            const parentFolder = formData[key]?.path?.split('.')[0];
            const parentDocp = currentFolder.fields[parentFolder];
            parentDocpSource = parentDocp.source;
            const { changeWorkingFolder } = foldersUtility();
            const newCurrentFolder = changeWorkingFolder(
              parentDocpSource,
              bSettings.output.data.records[0]
            );
            if (formData[key] !== undefined) {
              const currentFormData = {};
              currentFormData[key] = formData[key];
              getGroupedFormData(currentFormData, newCurrentFolder, txnType);
            }
          }
        } else {
          let pathofDetail = '';
          //todo start
          //get the fields path and chnage the current folder
          //to check if the field is detail or not
          //if yes prepare the details list
          //careful of docp inside detail ???
          pathofDetail = formData[key]?.pathofDetail;
          const mainFoler = pathofDetail?.split('.')[0];
          if (currentFolder.name === mainFoler) {
            if (
              currentFolder.fields[key]?.type === 'detail' &&
            key !== 'mainFolderId'
            ) {
            //fix at the end
              for (const eachKey in currentFolder.fields[key].fields) {
                if (
                  currentFolder.fields[key].fields[eachKey].label.eng === 'Key'
                ) {
                  detailKeyFieldName =
                  currentFolder.fields[key].fields[eachKey].name;
                }
              }
              // formData[key].patchPath = folderPath;
              formData[key].primaryKeyField = detailKeyFieldName;
              Object.assign(detailList, {
                [key]: formData[key],
              });
            }
            parentDocpSource = null;
          } else {
            if (currentFolder.fields[mainFoler]) {
              const diffFolderSource = currentFolder.fields[mainFoler].source;
              parentDocpSource = diffFolderSource;

              const { changeWorkingFolder } = foldersUtility();
              const newCurrentFolder = changeWorkingFolder(
                diffFolderSource,
                bSettings.output.data.records[0]
              );
              //call again with new folder and formdata

              if (
                newCurrentFolder.fields[key]?.type === 'detail' &&
              key !== 'mainFolderId'
              ) {
              //fix at the end
                for (const eachKey in newCurrentFolder.fields[key].fields) {
                  if (
                    newCurrentFolder.fields[key].fields[eachKey].label.eng ===
                  'Key'
                  ) {
                    detailKeyFieldName =
                    newCurrentFolder.fields[key].fields[eachKey].name;
                  }
                }
                formData[key].patchPath = folderPath;
                formData[key].primaryKeyField = detailKeyFieldName;
                Object.assign(detailList, {
                  [key]: formData[key],
                });
              }
            }
          }
        }
        if (key === 'deleted' && formData[key] === true) {
          Object.assign(normalFieldList, formData);
          //remove the source
          parentDocpSource = null;
        }
      }

    }
    //get the fields data

    console.log('docp', docpList);
    console.log('details', detailList);
    console.log('normal', normalFieldList);
    console.log('detailFey', detailKeyFieldName);
    return { normalFieldList, docpList, detailList, detailKeyFieldName };
  }
  function emptyTheContainer() {
    docpList = {},
    detailList = {},
    detailKeyFieldName = null,
    normalFieldList = {},
    folderPath = '',
    parentDocpSource = null;
    console.log('empty', docpList, detailList, folderPath)
  }

  return { getGroupedFormData, emptyTheContainer };
};

export default processFormData;
