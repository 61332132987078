<template>
  <div
    v-if="readOnly"
    class="list-view-class"
    :data-test-id="dataTestId"
  >
    {{ value }}
  </div>
  <div v-else-if="showField">
    <span
      v-if="!showInTable && !readOnly"
      class="input-group my-2"
    >{{ loctextOptions.label[currentLanguage] }}
      <span
        v-if="isMandatory"
        class="ms-2"
        style="color: red"
      >
        *</span>
    </span>
    <div
      v-if="!showInTable"
      :class="{ 'd-block': isMissing || !isValid }"
      class="invalid-feedback p-0 text-xs"
    >
      {{ errorText }}
    </div>
    <input
      :id="dataTestId"
      v-model="value"
      :data-test-id="dataTestId"
      :disabled="isReadOnly || isEditNotAllowed"
      :class="{ 'mandatory-field': isMandatory && isMissing,
                'invalid-field':!isValid}"
      class="form-control form-input"
      type="text"
      :placeholder="placeHolder"
      @change="handleChange"
      @keydown="onKeyDown"
    >
    <v-tooltip
      v-if="showInTable && value !=null"
      activator="parent"
      open-delay="600"
      location="top"
    >
      {{ value }}
    </v-tooltip>
  </div>
</template>

<script>
import { ref, computed, toRef} from 'vue';
import { useI18n } from 'vue-i18n';
import processExpr from '../../composables/processExprs';
import controlUtility from '../../composables/controlUtility';
export default {
  name: 'WfmLoctext',
  props: {
    params: {
      type: Object,
      default: null,
    }
  },
  emits: ['input-change', 'invalid-check', 'mandatory-check'],

  setup(props, { emit }) {
    //variables
    const i8vue = useI18n()
    const currentLanguage = i8vue.locale.value
    const value = ref(null);
    const showInTable = props.params?.showInTable ? true : false;
    const readOnly = props.params?.readOnly ? true : false;
    const loctextOptions = props.params.fieldOptions
    const propertyName = loctextOptions?.name;
    const loctextParams =  toRef(props.params.data)
    const fieldPath = loctextOptions?.path
    const folderName = loctextOptions?.path?.split('.')[0]
    value.value = loctextParams.value?.[propertyName]?.[currentLanguage] ? loctextParams.value?.[propertyName]?.[currentLanguage] : loctextParams.value?.[folderName]?.[0]?.[propertyName]?.[currentLanguage];
    const currentLoctext = loctextParams.value?.[propertyName] || { 'ben': null, 'eng': null, 'hin': null }
    const idOfTheFolder = loctextParams.value?.[folderName]?.[0]?.id ? loctextParams.value?.[folderName]?.[0]?.id : loctextParams.value?.id
    const isValid = ref(true)
    const valueEntered = ref(false)

    const mandatoryStatus = props.params.mandatoryStatus
    const isEditNotAllowed = props.params.isEditNotAllowed
    const missingFields = props.params.missingFields
    const currentFolder = ref(props.params.currentFolder)
    // const folderFields = ref(props.folderFields || props.params.fieldParams.folderFields)
    const mandatoryFields = props.params.mandatoryFields
    const invalidFields = props.params.invalidFields
    const { invalidCheck, mandatoryCheck } = controlUtility(invalidFields, mandatoryFields)

    const { processReadOnly, processVisibleExpr, processMandatoryExpr, processDefaultValue, processValidExpr} = processExpr(currentFolder.value, loctextParams.value, showInTable)

    //computed
    const placeHolder = computed(() => {
      return loctextOptions.placeHolder ? loctextOptions.placeHolder : loctextOptions.label.eng
    })
    const showField = computed(() => {
      if (!mandatoryStatus?.value) {
        return true
      }
      else {
        if (isMandatory.value) {
          return true
        }
        else {
          return false
        }
      }
    })
    const isVisible = computed(() => {
      const retVal = processVisibleExpr(loctextOptions.visible_expr)
      return retVal
    })
    const isMissing = computed(() => {
      if (missingFields?.value?.includes(dataTestId.value)) {
        if (valueEntered.value) {
          return false
        }
        else {
          return true

        }
      }
      else {
        return false
      }
    })
    const isReadOnly = computed(() => {
      const retVal = processReadOnly(loctextOptions.readonly_expr)
      return retVal
    })

    const isMandatory = computed(() => {
      const retVal = processMandatoryExpr(loctextOptions.mandatory_expr) && isVisible.value
      if (!showInTable) {
        mandatoryCheck({
          propertyName: dataTestId.value,
          value: retVal,
        })
      }
      return retVal
    })
    const errorText = computed(() => {
      if (!isValid.value) {
        return 'Invalid Input'
      }
      if (isMissing.value) {
        return 'Mandatory field'
      }
      else {
        return ''
      }
    })
    const defaultValue = computed(() => {
      const retVal = processDefaultValue(loctextOptions.default_value)
      return retVal
    });
    const dataTestId = computed(() => {
      if (showInTable || readOnly) {
        const rowIndex = props.params.node.rowIndex
        const tableName = props.params.tableName
        return `${currentFolder.value.name}/${tableName}/${propertyName}/${rowIndex}`
      } else {
        if (currentFolder.value !== null) {
          return `${currentFolder.value.name}/${propertyName}`
        }
        else {
          return `${loctextOptions.type}/${propertyName}`
        }
      }
    })


    //methods
    function onKeyDown(event) {
      event.stopPropagation();
    }
    if (defaultValue.value) {
      getDefaultValue()
    }
    function getDefaultValue() {
      const defaultSelectedValue = defaultValue.value
      value.value = defaultSelectedValue
      handleChange()
    }
    function handleChange() {
      try {
        currentLoctext[currentLanguage] = value.value;
        if (currentLoctext[currentLanguage]) {
          valueEntered.value = true
        }
        if (loctextOptions.valid_expr !== undefined) {
          isValid.value = processValidExpr(loctextOptions.valid_expr, value.value)
        }
        if (isValid.value) {
        // emit("invalid-check", { propertyName: propertyName, value: false })
          invalidCheck({ propertyName: propertyName, value: false })

          //if the data is changed in ag grid
          if (showInTable) {
            // inputParams[propertyName] = currentLoctext
            loctextParams.value[propertyName] = currentLoctext
            props.params.cellValueChanged(loctextParams.value, props.params.rowNode)
          }
          //else emit event to control
          else {
            emit('input-change', { path: fieldPath, value: currentLoctext, folderId: idOfTheFolder, mandatory: loctextOptions.mandatory_expr });
          }
        }
        else {
        // emit("invalid-check", { propertyName: propertyName, value: true })
          invalidCheck({ propertyName: propertyName, value: true })
        }
      } catch (error) {
        throw error
      }

    }
    return {
      value,
      showInTable,
      loctextOptions,
      readOnly,
      currentLanguage,
      handleChange,
      onKeyDown,
      isMandatory,
      isReadOnly,
      isMissing,
      errorText,
      isValid,
      propertyName,
      showField,
      isEditNotAllowed,
      placeHolder,
      dataTestId
    };
  },
};
</script>
<style>
.invalid-field{
    border: 1px solid rgb(214, 65, 85) !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  color: black

}
</style>
<style scoped>
.input-group-text {
  background-color: antiquewhite;
}


.form-input {
  min-height: 5px;
  width: 100%;
  background-color: var(--controls-background-color);
  color:var(--controls-font-color) !important;
  border:1px solid var(--controls-border-color);
  cursor: pointer;

}

.mandatory-field {
  border: 1px solid rgb(214, 65, 85) !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  color: black
}
.invalid-field{
    border: 1px solid rgb(214, 65, 85) !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  color: black

}
</style>
