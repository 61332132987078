<template>
  <div class="form-floating">
    <textarea
      id="floatingTextarea2"
      class="form-control"
      placeholder="Leave a comment here"
      style="height: 100px"
    />
    <label for="floatingTextarea2">Text Area</label>
  </div>
</template>

<script>
import {
  ref
} from 'vue';
export default {
  name: 'WfmTextArea',
  setup() {
    const listItems = ref('');
    return {
      listItems
    };
  },
};
</script>

<style scoped></style>
