<template>
  <div
    v-if="!showInTable && !readOnly"
    class="input-group"
    :data-test-id="dataTestId"
  >
    <span class="input-group my-2">{{ nspOptions.label[currentLanguage] }}
      <span
        v-if="nspOptions.mandatory_expr === 'true'"
        class="ms-2"
        style="color: red"
      >
        *</span>
    </span>
    <select
      v-model="value"
      class="form-select"
      @change="handleChange"
    >
      <option
        v-for="(item, index) in nspDropDown"
        :key="index"
      >
        {{ item }}
      </option>
    </select>
  </div>

  <div
    v-else-if="showInTable"
    class="row"
  >
    <div
      v-for="n in 2"
      :key="n"
      class="col-sm-6"
      :class="n==2?'ps-0':'pe-0'"
      :name="giveTagName(n)"
    >
      <v-select
        ref="testing"
        v-model="value[n - 1]"
        append-to-body
        auto-scroll
        :data-test-id="dataTestId"
        :uid="dataTestId"
        :input-id="dataTestId"
        clearable
        :options="nspList[n - 1]?.list"
        class="form-input style-choose"
        :components="{ Deselect, OpenIndicator }"
        :reduce="(option) => option"
        clear-search-on-select
        @update:model-value="handleChange(value[n-1], n - 1)"
        @open="getNspList()"
      >
        <template #option="option">
          <!-- <span :class="{'selected-option':value && (value === option.label || value?.[0]?.id === option?.id)}"> -->
          <span :class="{'selected-option':markSelectedOption(option)}">
            <div>
              {{ option?.label }}
            </div>
          </span>
        </template>
      </v-select>
      <v-tooltip
        v-if="showInTable && value[n-1]!=null"
        activator="parent"
        open-delay="600"
        location="top"
      >
        {{ value[n-1] }}
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import { ref, computed} from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import getFolders from '../../composables/getFolders';
import postData from '../../composables/postData';
import getFatRow from '../../composables/fatRowGenerator';
import DropdownClose from '../../custom-renderer/DropdownClose.vue';
import DropdownToggle from '../../custom-renderer/DropdownToggle.vue';
export default {
  name: 'WfmNsp',
  props: {
    params: {
      type: Object,
      default: null,
    }
  },
  emits: ['input-change'],
  setup(props, context) {
    //variables
    const store = useStore();
    const i8vue = useI18n();
    const currentLanguage = i8vue.locale.value;
    const nspPath = ref([]);
    const nspName = ref();
    const nspList = ref([]);
    const Deselect = DropdownClose;
    const OpenIndicator = DropdownToggle;
    const showInTable = props.params?.showInTable ? true : false;
    const nspOptions = props.params.fieldOptions
    const nspParams = props.params.data
    const propertyName = nspOptions.name;
    const value = ref(nspParams[propertyName]?.path || []);
    const { listData } = postData();
    //TODO: nsp value and data show properly
    nspPath.value = nspParams[propertyName]?.path;
    nspName.value = nspParams[propertyName]?.nsp_name;
    // const missingFields = props.params.missingFields
    const valueEntered = ref(false);

    //methods
    function handleChange(item, index) {
      try {
        //if item is not undefined emit the changes
        if (item != null) {
          valueEntered.value = true;

          const newValue = item.code;
          value.value[index] = newValue;

          //if the data is changed in ag grid
          if (showInTable) {
            const nspObject = {
              'nsp_name': 'payhead_structure',
              'path': [
                value.value[0],
                value.value[1]
              ]
            }
            nspParams[propertyName] = nspObject;
            props.params.cellValueChanged(nspParams);
          }
          //else emit event to control
          else {
            context.emit('input-change', newValue);
          }
        }
        else {
          console.log('none selected')
        }
      } catch (error) {
        console.log(error)
      }

    }
    const readOnly = props.params?.readOnly ? true : false;
    const currentFolder = ref(props.params.currentFolder)

    // const isMissing = computed(() => {
    //   if (missingFields?.value?.includes(dataTestId.value)) {
    //     if (valueEntered.value) {
    //       return false;
    //     } else {
    //       return true;
    //     }
    //   } else {
    //     return false;
    //   }
    // });
    // const errorText = computed(() => {
    //   if (isMissing.value) {
    //     return "Mandatory field";
    //   } else {
    //     return "";
    //   }
    // });

    const dataTestId = computed(() => {
      if (showInTable || readOnly) {
        const rowIndex = props.params.node.rowIndex
        const tableName = props.params.tableName
        return `${currentFolder.value?.name}/${tableName}/${propertyName}/${rowIndex}`
      } else {
        if (currentFolder.value !== null) {
          return `${currentFolder.value?.name}/${propertyName}`
        }
        else {
          return `${nspOptions?.type}/${propertyName}`
        }
      }
    })
    function getValue() {
      return value.value;
    }
    function giveTagName(n) {
      const rowIndex = props.params.node.rowIndex
      const tableName = props.params.tableName
      const name = `${currentFolder.value?.name}/${tableName}/${propertyName}/${rowIndex}`
      return n == 1 ? name + '/nsp_type' : name + '/nsp_value'
    }
    async function getNspList() {
      const bSettings = store.getters['bSettings'];
      const { getAllFoldersList, getNspFolder } = getFolders();
      const folderList = getAllFoldersList(bSettings);
      const nspFolderList = getNspFolder(nspOptions.type, folderList);
      const txnParams = {
        refRows: true,
      };
      for (let i = 0; i < nspFolderList.length - 1; i++) {
        if (nspFolderList[i]) {
          const list = await listData(nspFolderList[i], txnParams);
          if (list.output.type === 'error') {
            console.log(list.output.message);
          } else {
            const { fatRowGenerator } = getFatRow()
            const fatRow = fatRowGenerator(list)
            //add label to the list
            fatRow.map((each) => each.label = each.label ? each.label : each?.code)
            nspList.value.push({ folderName: nspFolderList[i].name, list: fatRow });
          }
        }
      }
    }

    function markSelectedOption(option) {
      if (value.value[0] === option.code || value.value[1] === option.code) {
        return true
      }
    }


    return {
      handleChange,
      value,
      nspPath,
      nspOptions,
      nspParams,
      showInTable,
      getValue,
      nspName,
      currentLanguage,
      getNspList,
      nspList,
      markSelectedOption,
      Deselect,
      OpenIndicator,
      dataTestId,
      giveTagName
    };
  },
};
</script>
<style scoped>
.vs__dropdown-toggle {
  height: 38px !important;
  overflow: hidden;
  border-radius: 4px !important;
  color:grey;

}
.vs__dropdown-menu {
  font-size: 0.9rem;
  color: #2e688dff;
  max-height: 300px !important;
  width: 100%;
}
.vs__dropdown-menu::-webkit-scrollbar {
  display: none !important;
  /* Safari and Chrome */
     overflow: auto;
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */
}

.selected-option {
  font-weight: 700;
  color:green;
}
.selected-option :hover {
  color:white;
}
.multi-docp-style .vs__dropdown-toggle {
  height: fit-content !important;
  border-radius: 4px !important;
}


.mandatory-field {
  border: 1px solid rgb(214, 65, 85) !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  color: black;
  border-radius: 4px;
}


</style>
<style scoped>
.input-group-text {
  background-color: antiquewhite;
}

.input-group {
  text-align: left;
}

.form-input {
  min-height: 5px;
  width: 100%;
  cursor: pointer;
}

</style>
