const getFormInfo = () => {

  //get the current txn object (adding for now)
  function getCurrentTxn(txnType, currentFolder, txnName) {
    let currentTxn = null
    const txnAvailable = currentFolder?.txns

    if (txnName != null) {
      currentTxn = txnAvailable?.[txnName]
    }
    else {
      currentTxn = txnAvailable?.[`txn_${currentFolder?.name}_${txnType}`]
    }
    return currentTxn
  }
  // using that get the formObject needed for the txn(adding for now)
  function getFormObject(formName, currentFolder) {
    const currentForm = currentFolder?.forms ? currentFolder?.forms[formName] : null;
    return currentForm
  }
  return {getCurrentTxn, getFormObject}
}

export default getFormInfo
