const passwordStrength = () => {

  let upperCaseFound = false
  let lowerCaseFound = false
  let numberFound = false
  let stringLengthOk = false
  let specialCharacterFound = false
  const max = 141 //maxis made by typing in the characters in order which will get the highest point
  const min = 0

  function checkStrength(password) {
    const toAdd1 = numberOfCharacterPoints(password);
    const toAdd2 = upperCaseLetterPoint(password);
    const toAdd3 = lowerCaseLetterPoint(password);
    const toAdd4 = numbersPoint(password);
    const toAdd5 = symbolsPoint(password);
    // const toAdd7 = middleNumbersOrSymbolsPoint(password);
    const toDeduct1 = letterOnlyPoint(password);
    const toDeduct2 = repeatedCharacterPoint(password)
    const toDeduct3 = consecutiveUppercaseLetters(password)
    const toDeduct4 = consecutiveLowercaseLetters(password)
    const toDeduct5 = consecutiveNumbers(password)
    const toDeduct6 = sequentialLetters(password)
    const toDeduct7 = numbersOnlyPoint(password)
    const toDeduct8 = sequentialNumbers(password)
    const toDeduct9 = sequentialSymbols(password)
    const pointsToAdd = toAdd1 + toAdd2 + toAdd3 + toAdd4 + toAdd5
    const pointsToDeduct = toDeduct1 + toDeduct2 + toDeduct3 + toDeduct4 + toDeduct5 + toDeduct6 + toDeduct7 + toDeduct8 + toDeduct9
    const requirementMet = requirementCheck(password) ? true : false
    let finalScore = pointsToAdd + pointsToDeduct
    if (requirementMet) {
      finalScore = finalScore + 40
      const percent = 100 * (finalScore - min) / (max - min)
      const strength = percent.toFixed(2)
      let strengthString = ''
      if (strength <= 50) {
        strengthString = 'Weak'
      }
      else if (strength > 50 && strength < 70) {
        strengthString = 'Poor'
      }
      else if (strength >= 70 && strength < 90) {
        strengthString = 'Good'
      }
      else if (strength >= 90 && strength < 100) {
        strengthString = 'Great'
      }
      else if (strength >= 100) {
        strengthString = 'Strong'
      }

      const retVal = {strength:strength, status:true, msg:strengthString}

      return retVal
    }
    else {
      const percent = 100 * (finalScore - min) / (max - min)
      const strength = percent.toFixed(2)
      const strengthString = 'Weak'
      const retVal = {strength:strength, status:false, msg:strengthString}
      return retVal
    }

  }


  //points to add function
  function requirementCheck(stringEntered) {
    if (stringEntered) {
      const lastLetter = stringEntered.charAt(stringEntered.length - 1)
      console.log('entered', lastLetter);

      upperCaseFound = stringEntered.match(/[A-Z]/g)?.length >= 1
      lowerCaseFound = stringEntered.match(/[a-z]/g)?.length >= 1
      numberFound = stringEntered.match(/[0-9]/g)?.length >= 1
      specialCharacterFound = stringEntered.match(/[^a-zA-Z0-9\s\n\b]/g)?.length >= 1
      stringLengthOk = stringEntered.length >= 8

      return upperCaseFound && lowerCaseFound && numberFound && specialCharacterFound && stringLengthOk
    }

  }
  function numberOfCharacterPoints(stringEntered) {
    //number of characters get +point
    const charCount = stringEntered.length
    return  ((charCount || 0) * 4)

  }
  function upperCaseLetterPoint(stringEntered) {
    //presense of upper case letter gets +point
    const strLength = stringEntered.length
    const charCount = stringEntered.match(/[A-Z]/g)?.length
    return (strLength - (charCount || 0)) * 3


  }
  function lowerCaseLetterPoint(stringEntered) {
    //presense of lowercase letter gets +point
    const strLength = stringEntered.length
    const charCount = stringEntered.match(/[a-z]/g)?.length
    return (strLength - (charCount || 0)) * 2


  }
  function numbersPoint(stringEntered) {
    //presense of numbers gets +point
    const charCount = stringEntered.match(/[0-9]/g)?.length
    return  ((charCount || 0) * 4)

  }
  function symbolsPoint(stringEntered) {
    //presense of symbols gets a +point
    const specialCharRegex = /[^a-zA-Z0-9\s\n\b]/g;
    const matches = stringEntered.match(specialCharRegex);
    const charCount = matches ? matches.length : 0;
    return ((charCount || 0) * 6)

  }
  //   function middleNumbersOrSymbolsPoint(stringEntered) {
  //     //if numbers or character present in the middle gets a +point
  //     const charCount = 4
  //     return (charCount * 2)

  //   }

  //points to deduct functions
  function letterOnlyPoint(stringEntered) {
    //if only letters used gets -point
    const lettersOnly = /[a-zA-Z]/g;
    const strLength = stringEntered.length
    const charCount = stringEntered.match(lettersOnly);
    const lettersLength = charCount ? charCount.length : 0
    if (strLength == lettersLength) {
      return strLength
    }
    else {
      return 0
    }

  }
  function numbersOnlyPoint(stringEntered) {
    //if only numbers used gets -point
    const numbersOnly = /[0-9]/g;
    const strLength = stringEntered.length
    const charCount = stringEntered.match(numbersOnly);
    const numbersLength = charCount ? charCount.length : 0
    if (strLength == numbersLength) {
      return strLength
    } else {
      return 0
    }
  }
  function repeatedCharacterPoint(stringEntered) {
    //if repeated characters is used gets -point
    const charMap = {};
    for (const char of stringEntered) {
      charMap[char] = (charMap[char] || 0) + 1;
    }

    let repeatedCount = 0;
    for (const count of Object.values(charMap)) {
      if (count > 1) {
        repeatedCount += 1;
      }
    }
    //add more condition
    return  ((repeatedCount || 0) * 2)

  }
  function consecutiveUppercaseLetters(stringEntered) {
    //if consecutive use of uppercase letter gets -point
    let charCount = 0
    let currentCharIsUpper = false
    let nextCharIsUpper = false
    for (const index in stringEntered) {
      const currentChar = stringEntered[index]
      const nextChar = stringEntered[parseInt(index) + 1]
      if (currentChar) {
        currentCharIsUpper = (/[A-Z]/g).test(currentChar)
      }
      if (nextChar) {
        nextCharIsUpper = (/[A-Z]/g).test(nextChar)
      }
      if (currentCharIsUpper && nextCharIsUpper) {
        charCount++
      }

    }
    return ((charCount || 0) * 2)

  }
  function consecutiveLowercaseLetters(stringEntered) {
    //if consecutive use of lowercase letter gets -point
    let charCount = 0
    let currentCharIsLower = false
    let nextCharIsLower = false
    for (const index in stringEntered) {
      const currentChar = stringEntered[index]
      const nextChar = stringEntered[parseInt(index) + 1]
      if (currentChar) {
        currentCharIsLower = (/[a-z]/g).test(currentChar)
      }
      if (nextChar) {
        nextCharIsLower = (/[a-z]/g).test(nextChar)
      }
      if (currentCharIsLower && nextCharIsLower) {
        charCount++
      }

    }
    return ((charCount || 0) * 2)

  }
  function consecutiveNumbers(stringEntered) {
    //if consecutivly numbers used gets -point
    let charCount = 0
    let currentCharIsNum = false
    let nextCharIsNum = false
    for (const index in stringEntered) {
      const currentChar = stringEntered[index]
      const nextChar = stringEntered[parseInt(index) + 1]
      if (currentChar) {
        currentCharIsNum = (/[0-9]/g).test(currentChar)
      }
      if (nextChar) {
        nextCharIsNum = (/[0-9]/g).test(nextChar)
      }
      if (currentCharIsNum && nextCharIsNum) {
        charCount++
      }

    }
    return ((charCount || 0) * 2)


  }
  function sequentialLetters(stringEntered) {
    //if sequential letters used gets -point
    let charCount = 0
    const alphabet = 'abcdefghijklmnopqrstuvwxyz';
    for (const index in stringEntered) {
      const currentChar = stringEntered[index]
      const nextChar = stringEntered[parseInt(index) + 1]
      if (currentChar && nextChar) {
        const subString = currentChar + nextChar
        if (alphabet.includes(subString)) {
          charCount++
        }
      }

    }
    return  ((charCount || 0) * 3)

  }
  function sequentialNumbers(stringEntered) {
    //if sequential numbers used gets -point
    let charCount = 0
    const numbers = '0123456789';
    for (const index in stringEntered) {
      const currentChar = stringEntered[index]
      const nextChar = stringEntered[parseInt(index) + 1]
      if (currentChar && nextChar) {
        const subString = currentChar + nextChar
        if (numbers.includes(subString)) {
          charCount++
        }
      }
    }
    return  ((charCount || 0) * 3)

  }
  function sequentialSymbols(stringEntered) {
    //if sequentialsymbols used gets -point

    let charCount = 0
    const specialCharacterSequence = '~!@#$%^&*()_+';
    for (const index in stringEntered) {
      const currentChar = stringEntered[index]
      const nextChar = stringEntered[parseInt(index) + 1]
      if (currentChar && nextChar) {
        const subString = currentChar + nextChar
        if (specialCharacterSequence.includes(subString)) {
          charCount++
        }
      }
    }
    return  ((charCount || 0) * 3)


  }
  return { checkStrength };
};

export default passwordStrength;
