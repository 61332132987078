<template>
  <div
    class="navbar toolbar"
    :class="customClass"
    :data-test-id="dataTestId"
  >
    <div id="left-utility">
      <slot name="left-utility" />
    </div>
    <div
      v-if="headerShow"
      class="folder-name d-none d-sm-block"
    >
      {{ toolbarHeader }}
    </div>
    <div class="center-utility">
      <slot name="center-utility" />
    </div>
    <div id="right-utility">
      <slot name="right-utility" />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'WfmToolbar',
  components: {},
  props: {
    hideSearch: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: '',
    },
    toolbarHeader: {
      type: String,
      default: '',
    },
    dataTestId: {
      type: String,
      default: '',
    },
    headerShow:{
      type:Boolean,
      default:true
    }

  },
  emits: ['handle-search', 'input-changed', 'action-click'],
  setup(props, { emit }) {
    //variables
    const inputValue = ref();
    //methods
    function handleAction(action) {
      emit('action-click', action);
      //TODO: emit the action
    }
    function inputChanged() {
      emit('input-changed');
    }

    function handleSearch() {
      emit('handle-search');
    }
    function makeDataTestId(action) {
      return `${props?.dataTestId}/${action?.name?.split(' ')[0]}`
    }
    return {
      handleAction,
      inputChanged,
      handleSearch,
      inputValue,
      makeDataTestId,
    };
  },
};
</script>

<style>
.toolbar {
  color: var(--main-font-color);
  cursor: pointer;
  display: flex;
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.navbar {
  height: 42px;
  align-content: center;
}

.box {
  border-radius: 12px;
  background-color: red;
}

.folder-name {
  font-size: 18px;
  text-transform: capitalize;
  text-align: center;
  color: white;
}
</style>
