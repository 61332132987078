<template>
  <div
    v-if="readOnly"
    :data-test-id="dataTestId"
    class="list-view-class"
  >
    {{ value }}
  </div>
  <div v-else-if="showField">
    <span
      v-if="!showInTable && !readOnly"
      class="input-group my-2"
    >{{ sourceTypeOptions.label[currentLanguage] }}
      <span
        v-if="isMandatory"
        class="ms-2"
        style="color: red"
      >
        *
      </span>
    </span>
    <div
      v-if="!showInTable"
      :class="{ 'd-block': isMissing || !isValid }"
      class="invalid-feedback p-0 text-xs"
    >
      {{ errorText }}
    </div>
    <input
      v-model="value"
      id:dataTestId
      :data-test-id="dataTestId"
      :class="{ 'mandatory-field': isMandatory && isMissing , 'invalid-field':!isValid}"
      type="text"
      class="form-control"
      :placeholder="placeHolder"
      :disabled="isReadOnly || isEditNotAllowed"
      @change="handleChange"
    />
  </div>
</template>

<script>
import { ref, computed, toRef} from 'vue';
import { useI18n } from 'vue-i18n';
import processExpr from '../../composables/processExprs';
import controlUtility from '../../composables/controlUtility';
export default {
  name: 'WfmSourceType',
  props: {
    params: {
      type: Object,
      default: null,
    }
  },
  emits: ['input-change', 'invalid-check', 'mandatory-check'],
  setup(props, context) {
    //variables
    const i8vue = useI18n();
    const currentLanguage = i8vue.locale.value;
    const value = ref(null);
    const showInTable = props.params?.showInTable ? true : false;
    const readOnly = props.params?.readOnly ? true : false;
    const sourceTypeOptions = props.params.fieldOptions
    const sourceTypeParams = toRef(props.params.data)
    const propertyName = sourceTypeOptions?.name;
    value.value = sourceTypeParams.value[propertyName];
    const folderName = sourceTypeOptions?.path?.split('.')[0]
    const fieldPath = sourceTypeOptions?.path
    const idOfTheFolder = sourceTypeParams.value?.[folderName]?.[0]?.id ? sourceTypeParams.value?.[folderName]?.[0]?.id : sourceTypeParams.value?.id
    const isValid = ref(true)
    const valueEntered = ref(false)
    const mandatoryStatus = props.params.mandatoryStatus
    const isEditNotAllowed = props.params.isEditNotAllowed
    const missingFields = props.params.missingFields

    const currentFolder = ref(props.params.currentFolder)
    const mandatoryFields = props.params.mandatoryFields
    const invalidFields = props.params.invalidFields
    const { invalidCheck, mandatoryCheck } = controlUtility(invalidFields, mandatoryFields)

    const { processReadOnly, processVisibleExpr, processMandatoryExpr, processDefaultValue, processValidExpr} = processExpr(currentFolder.value, sourceTypeParams.value)

    //computed
    const placeHolder = computed(() => {
      return sourceTypeOptions.placeHolder ? sourceTypeOptions.placeHolder : sourceTypeOptions.label.eng
    })
    const isMissing = computed(() => {
      if (missingFields?.value?.includes(dataTestId.value)) {
        if (valueEntered.value) {
          return false
        }
        else {
          return true

        }
      }
      else {
        return false
      }
    })
    const isVisible = computed(() => {
      const retVal = processVisibleExpr(sourceTypeOptions.visible_expr)
      return retVal
    })
    const isMandatory = computed(() => {
      const retVal = processMandatoryExpr(sourceTypeOptions.mandatory_expr) && isVisible.value
      if (!showInTable) {
        mandatoryCheck({
          propertyName: dataTestId.value,
          value: retVal,
        })
      }
      return retVal
    })
    const isReadOnly = computed(() => {
      const retVal = processReadOnly(sourceTypeOptions.readonly_expr)
      return retVal
    })

    const errorText = computed(() => {
      if (!isValid.value) {
        return 'Invalid Input'
      }
      if (isMissing.value) {
        return 'Mandatory field'
      }
      else {
        return ''
      }
    })
    const defaultValue = computed(() => {
      const retVal = processDefaultValue(sourceTypeOptions.default_value)
      return retVal
    });
    if (defaultValue.value) {
      getDefaultValue()
    }
    const showField = computed(() => {
      if (!mandatoryStatus?.value) {
        return true
      }
      else {
        if (isMandatory.value) {
          return true
        }
        else {
          return false
        }
      }
    })

    const dataTestId = computed(() => {
      if (showInTable) {
        const rowIndex = props.params.node.rowIndex
        const tableName = props.params.tableName
        return `${currentFolder.value.name}/${tableName}/${propertyName}/${rowIndex}`
      } else {
        if (currentFolder.value !== null) {
          return `${currentFolder.value.name}/${propertyName}`
        }
        else {
          return `${sourceTypeOptions.type}/${propertyName}`
        }
      }
    })


    //methods
    function handleChange() {
      const newValue = value.value;
      if (newValue) {
        valueEntered.value = true
      }
      if (sourceTypeOptions.valid_expr !== undefined) {
        isValid.value = processValidExpr(sourceTypeOptions.valid_expr, newValue)

      }
      if (isValid.value) {
        // context.emit("invalid-check", { propertyName: propertyName, value: false })
        invalidCheck({ propertyName: propertyName, value: false })

        //if the data is changed in ag grid
        if (showInTable) {
          sourceTypeParams.value[propertyName] = newValue;
        }
        //else emit event to control
        else {
          context.emit('input-change', { path: fieldPath, value: newValue, folderId: idOfTheFolder, mandatory: sourceTypeOptions.mandatory_expr }
          );
        }
      }
      else {
        // context.emit("invalid-check", { propertyName: propertyName, value: true })
        invalidCheck({ propertyName: propertyName, value: true })

      }

    }
    function getDefaultValue() {
      const defaultSelectedValue = defaultValue.value
      value.value = defaultSelectedValue
      handleChange()
    }

    return {
      value,
      handleChange,
      sourceTypeOptions,
      showInTable,
      readOnly,
      currentLanguage,
      isMandatory,
      isReadOnly,
      isMissing,
      errorText,
      isValid,
      showField,
      isEditNotAllowed,
      placeHolder,
      dataTestId
    };
  },
};
</script>

<style scoped>
.input-group-text {
  background-color: antiquewhite;
}

.mandatory-field {
  border: 1px solid rgb(214, 65, 85) !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  color: black
}
.invalid-field{
    border: 1px solid rgb(214, 65, 85) !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  color: black

}
</style>
