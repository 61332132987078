const folderModules = {
  namespaced: true,
  state: {
    foldersDocpList: null,
    employeeDetail:null //used during fatrow generation to maintain docp list in folder
  },
  getters: {
    foldersDocpListGetter:function(state) {
      return state.foldersDocpList
    },
    employeeDetailGetter:function(state) {
      return state.foldersDocpList
    }
  },
  mutations: {
    foldersDocpListMutation:function(state, value) {
      state.foldersDocpList = value;
    }
  },
  actions: {

  }
}

export default folderModules;
