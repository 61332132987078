import { sendMessage } from '../services/websocket';
import store from '../../store/index';
import addUiInteractionFlags from './addUiInteractionFlags';
const getFolders = () => {
  const {addFlags} = addUiInteractionFlags()

  function getFoldersList(bSettings) {
    const bFolders = [];
    const folderList = bSettings?.output.data.records[0].business.containers.folders.folders;
    const mastersList = bSettings?.output.data.records[0].business.containers.masters.folders;
    const mviewsList = bSettings?.output.data.records[0].business.containers.mviews.folders;
    for (const folder in folderList) {
      if (folderList[folder].visibleExpr) {
        if (folderList[folder].visibleExpr === true) {
          bFolders.push(addFlags(folderList[folder]));
        } else {
          const result = evalFun(folderList[folder].visibleExpr, {}, bSettings.env);
          if (result) {
            bFolders.push(addFlags(folderList[folder])
            );
          }
        }
      }
    }
    for (const mview in mviewsList) {
      if (mviewsList[mview].visibleExpr) {
        if (mviewsList[mview].visibleExpr === true) {
          bFolders.push(addFlags(mviewsList[mview]));
        } else {
          const result = evalFun(mviewsList[mview].visibleExpr, {}, bSettings.env);
          if (result) {
            bFolders.push(addFlags(mviewsList[mview]));
          }
        }
      }
    }
    for (const master in mastersList) {
      if (mastersList[master].visibleExpr) {
        if (mastersList[master].visibleExpr === true) {
          bFolders.push(addFlags(mastersList[master]));
        } else {
          const result = evalFun(mastersList[master].visibleExpr, {}, bSettings.env);
          if (result) {
            bFolders.push(addFlags(mastersList[master]));
          }
        }
      }
    }
    return bFolders;
  }

  function evalFun(expr, data, env) {
    let result;
    try {
      const fnBody = 'try { ' + expr + " } catch(err) { throw new Error('error in expr evaluation ' + err.message) }";
      const func = new Function('data', 'env', fnBody);
      result = func(data, env);
    } catch (err) {
      return err;
    }
    return result;
  }

  function getFoldersFormList(bSettings) {
    const bFolders = [];
    const folderList = bSettings?.output.data.records[0]?.business.containers.folders.folders;
    const mastersList = bSettings?.output.data.records[0]?.business.containers.masters.folders;
    const mviewsList = bSettings?.output.data.records[0]?.business.containers.mviews.folders;
    for (let i = 0; i < bSettings?.output.data.records[0]?.business.containers.folders.folderList.length; i++) {
      bFolders.push(folderList[bSettings?.output.data.records[0]?.business.containers.folders.folderList[i]]);
    }
    for (let i = 0; i < bSettings?.output.data.records[0]?.business.containers.masters.folderList.length; i++) {
      bFolders.push(mastersList[bSettings?.output.data.records[0]?.business.containers.masters.folderList[i]]);
    }
    for (let i = 0; i < bSettings?.output.data.records[0]?.business.containers.mviews.folderList.length; i++) {
      bFolders.push(mviewsList[bSettings?.output.data.records[0]?.business.containers.mviews.folderList[i]]);
    }
    return bFolders;
  }

  function getCurrentFolder(folderName, folderList) {
    if (folderName === 'persons_id') {
      folderName = 'persons'
    }
    let currentFolder = null;
    for (let i = 0; i < folderList.length; i++) {
      if (folderList[i].name === folderName) {
        currentFolder = folderList[i];
      }
    }
    return currentFolder;
  }
  function getNspFolder(folderName, folderList) {
    //search for nsp folder in globals
    const nspFolderList = []
    const newfolderName = folderName.substring(4)
    for (let i = 0; i < folderList.length; i++) {
      if (folderList[i].name.includes(newfolderName)) {
        nspFolderList.push(folderList[i])
      }
    }
    return nspFolderList;
  }

  async function leaveQuota(bSettings, sessionId) {
    try {
      let leaveQuota = null;
      //first get the leave quota if employee id is added
      const folderList = getAllFoldersList(bSettings);
      const leavesFolder =  getCurrentFolder('leaves', folderList)
      const txnToGetLeaveQuota = leavesFolder.txns.txn_show_leaves_quota
      const txnParams = {refRows:true, getRowCount:true, employees_id:bSettings?.env?.id}
      txnToGetLeaveQuota.params = txnParams;
      txnToGetLeaveQuota.session_key = sessionId;

      leaveQuota = await sendMessage(txnToGetLeaveQuota);
      leaveQuota.output.data.records[0] = JSON.parse(leaveQuota.output.data.records[0]);
      return leaveQuota;
    } catch (error) {
      throw error
    }

  }

  function getAllFoldersList(bSettings) {
    const bFolders = [];
    const folderList = bSettings?.output.data.records[0].business.containers.folders.folders;
    const mastersList = bSettings?.output.data.records[0].business.containers.masters.folders;
    const mviewsList = bSettings?.output.data.records[0].business.containers.mviews.folders;
    for (const master in mastersList) {
      bFolders.push(mastersList[master]);
    }
    for (const mview in mviewsList) {
      bFolders.push(mviewsList[mview]);
    }
    for (const folder in folderList) {
      bFolders.push(folderList[folder]);
    }
    return bFolders;
  }

  function folderListToUse(bSettings) {
    const bFolders = [];
    const folderList = bSettings?.output.data.records[0].business.containers.folders.folders;
    const mastersList = bSettings?.output.data.records[0].business.containers.masters.folders;
    const mviewsList = bSettings?.output.data.records[0].business.containers.mviews.folders;
    for (const master in mastersList) {
      bFolders.push(addFlags(mastersList[master]));
    }
    for (const mview in mviewsList) {
      bFolders.push(addFlags(mviewsList[mview]));
    }
    for (const folder in folderList) {
      bFolders.push(addFlags(folderList[folder]));
    }
    return bFolders;
  }

  //TODO refactor this code
  function getDocpList() {
    const bSettings = store.getters['bSettings'];
    const folderList = getAllFoldersList(bSettings);
    const docpList = {};
    for (let i = 0; i < folderList.length; i++) {
      docpList[folderList[i].name] = []
      for (const field in folderList[i].fields) {
        if (folderList[i].fields[field].type === 'docpicker') {
          docpList[folderList[i].name].push(folderList[i].fields[field])
        }
        if (folderList[i].fields[field].type === 'detail') {
          docpList[folderList[i].name].push({[field]: []})
          for (const detailField in folderList[i].fields[field].fields) {
            if (folderList[i].fields[field].fields[detailField].type === 'docpicker') {
              docpList[folderList[i].name][docpList[folderList[i].name].length - 1][field].push(folderList[i].fields[field].fields[detailField])
            }
          }
        }
      }
    }
    return docpList;
  }

  // get an arbitrary folder object by  name
  function getFolderByName(fname, bSettings) {
    const folderList = getAllFoldersList(bSettings);
    const result = getCurrentFolder(fname, folderList)
    return result
  }


  return {  getFoldersFormList, getDocpList, leaveQuota, getFoldersList, getCurrentFolder, getNspFolder, getFolderByName, getAllFoldersList, folderListToUse};
}

export default getFolders;
