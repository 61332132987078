<!-- eslint-disable vue/valid-v-slot -->
<template>
  <WfmForm>
    <template #form-body>
      <v-stepper
        next-text="continue"
        height="450"
        selected-class="bg-success"
        min-height="2"
        :items="['Detail', 'Verify Email', 'Verify Phone']"
      >
        <!-- details form -->
        <template #item.1>
          <div class="d-flex">
            <div class="input-field">
              <input
                v-model="newBusinessName"
                type="text"
                autofocus
                placeholder="Business Name"
                @change="updateNewUserName"
              />
            </div>
            <div class="input-field">
              <input
                v-model="newSwHandle"
                type="text"
                placeholder="Swhandle"
                @change="updateNewSwHandle"
              />
            </div>
          </div>
          <div class="input-field">
            <input
              v-model="newPassword"
              type="password"
              placeholder="Password"
              @input="updateNewPassWord"
            />
          </div>
        </template>
        <!-- //email verification -->
        <template #item.2>
          <div class="input-field">
            <div class="text-left mb-2">
              Please enter your email,<br>
              We will send you an OTP on your email for verification
            </div>

            <div class="d-flex">
              <input
                v-model="email"
                type="email"
                placeholder="Enter Email"
              />
              <v-tooltip
                activator="parent"
                location="right"
              >
                Send mail
              </v-tooltip>
              <v-icon
                v-if="!mailSending && !mailSend"
                class="my-auto ms-3 mb-0"
                name="ri-mail-send-fill"
                scale="1.6"
                :fill="mailIconColor"
                animation="ring"
                hover
                @click="sendMail()"
              />
              <v-icon
                v-if="mailSending && !mailSend"
                class="my-auto ms-3 mb-0"
                name="fa-spinner"
                scale="1.6"
                animation="spin"
                fill="green"
              />
              <v-icon
                v-if="mailSend"
                class="my-auto ms-3 mb-0"
                name="fa-check-circle"
                scale="1.6"
                fill="green"
              />
            </div>
            <!-- otp component -->
            <div
              v-if="mailSend"
              class="d-flex mt-4"
            >
              <div class="my-auto">
                Enter the otp
              </div>
              <v-otp-input
                v-model="mailOtpValue"
                focus-all
                :error="mailErrorState && mailOtpValue.length >= 1"
                :length="5"
                @update:model-value="mailHandleChange"
              />
            </div>
          </div>
        </template>
        <!-- phone number verification -->
        <template #item.3>
          <div class="input-field">
            <div class="text-left mb-2">
              Please enter your Phone No,<br>
              We will send you an OTP on your Number for verification
            </div>
            <div class="d-flex">
              <input
                v-model="phonNumber"
                type="number"
                placeholder="Enter Phone Number"
                @input="updatePhoneNumber"
              />
              <v-tooltip
                activator="parent"
                location="right"
              >
                Send Text
              </v-tooltip>
              <v-icon
                v-if="!smsSending && !smsSend"
                class="my-auto ms-3 mb-0"
                name="md-sendtomobile"
                scale="1.6"
                :fill="phoneIconColor"
                animation="ring"
                :loading="loading"
                hover
                @click="sendSms()"
              />
              <v-icon
                v-if="smsSending && !smsSend"
                class="my-auto ms-3 mb-0"
                name="fa-spinner"
                scale="1.6"
                fill="green"
                animation="spin"
              />
              <v-icon
                v-if="smsSend"
                class="my-auto ms-3 mb-0"
                name="fa-check-circle"
                scale="1.6"
                fill="green"
                animation="ring"
                hover
              />
              <!-- otp component -->
            </div>
            <div
              v-if="smsSend"
              class="d-flex mt-4"
            >
              <div class="my-auto">
                Enter the otp
              </div>
              <v-otp-input
                v-model="smsOtpValue"
                :error="smsErrorState && smsOtpValue.length >= 1"
                focus-all
                :length="5"
                @update:model-value="smsHandleChange"
              />
            </div>
          </div>
        </template>
      </v-stepper>
      <!-- error message -->
      <div
        v-if="errorMsg"
        class="link text-danger"
      >
        <span>{{ errorMsg }}</span>
      </div>
    </template>
  </WfmForm>
  <div class="action">
    <WfmButton
      class="btn-outline-success btn"
      :disabled="smsErrorState"
      @button-click="signupHandle"
    >
      SignUp
    </WfmButton>
  </div>
</template>

<script>
import { ref } from 'vue'
import { toast } from 'vue3-toastify'

import postData from '../../composables/postData'
import { useStore } from 'vuex'
import WfmButton from '../../common/wfm-button.vue'
import WfmForm from '../../common/wfm-form.vue'

export default {
  name: 'SignupPage',
  components: {
    WfmButton,
    WfmForm
  },
  setup() {
    //variables
    const store = useStore()
    const swHandle = ref('')
    const newBusinessName = ref('')
    const newSwHandle = ref('')
    const newUserName = ref('admin')
    const newPassword = ref('')
    const empCode = ref('')
    const password = ref('')
    const errorMsg = ref('')
    const doSignup = ref(false)
    const smsSending = ref(false)
    const mailSending = ref(false)
    const otpValue = ref(12345)
    const loading = ref(false)
    const mailSend = ref(false)
    const smsSend = ref(false)
    const smsOtpValue = ref('')
    const mailOtpValue = ref('')
    const otp = ref(null)
    const smsErrorState = ref(true)
    const mailErrorState = ref(true)
    const email = ref(null)
    const phonNumber = ref(null)
    const mailIconColor = ref('#2e688dff')
    const phoneIconColor = ref('#2e688dff')
    const uploadNotification = ref()

    //Composable
    const { signup } = postData()
    function updateNewSwHandle() {
      store.dispatch('userModule/swHandleSetter', swHandle.value)
    }
    function updateNewUserName() {
      store.dispatch('userModule/usernameSetter', empCode.value)
    }

    //methods

    async function signupHandle() {
      try {
        uploadNotification.value = toast.loading('signing up...', {
          position: toast.POSITION.TOP_CENTER,
        });
        const retval = await signup(newSwHandle.value, newUserName.value, newPassword.value)
        if (retval.output.type === 'error') {
          toast.update(uploadNotification.value, {
            render: 'signup failed',
            autoClose: 1000,
            closeButton: true,
            type: 'error',
            isLoading: false,
          });
        }

        //make signup success and then move to show the next step getting number and emial otp

      } catch (error) {

      }
    }
    function smsHandleChange() {
      if (smsOtpValue.value == otp.value) {
        smsErrorState.value = false
      }
      else {
        smsErrorState.value = true
      }
    }
    function mailHandleChange() {
      if (mailOtpValue.value == otp.value) {
        mailErrorState.value = false
      }
      else {
        mailErrorState.value = true
      }
    }

    function sendMail() {
      console.log('sending mail to', email.value)
      mailSending.value = true

      setTimeout(() => {
        mailSending.value = false
        mailSend.value = true
        otp.value = 67890
        console.log('mail send')
      }, 5000);
    }

    function sendSms() {
      console.log('sending sms to', phonNumber.value)
      smsSending.value = true

      setTimeout(() => {
        smsSending.value = false
        smsSend.value = true
        otp.value = 12345
        console.log('text Send')
        //make the sumit active
        loading.value = true
      }, 4000);
    }


    return {
      password,
      errorMsg,
      signupHandle,
      doSignup,
      sendMail,
      sendSms,
      otpValue,
      loading,
      smsSending,
      mailSending,
      mailSend,
      smsSend,
      smsOtpValue,
      mailOtpValue,
      otp,
      smsHandleChange,
      mailHandleChange,
      smsErrorState,
      mailErrorState,
      newUserName,
      newPassword,
      newBusinessName,
      newSwHandle,
      updateNewSwHandle,
      updateNewUserName,
      email,
      phonNumber,
      mailIconColor,
      phoneIconColor,
    }
  }
}
</script>

<style scoped>
input {
  width: 20rem;
  height: 50px;
  margin-top: 20px;
  border-radius: 5px;
  text-align: center;
}

.button {
  width: 10rem;
  margin-left: 10px;
  margin-top: 30px;
}

label {
  color: green;
}


.input-field {
  padding: 12px 5px;
}


.input-field input {
  font-size: 16px;
  display: block;
  /* font-family: 'Rubik', sans-serif; */
  width: 100%;
  padding: 10px 1px;
  border: 0;
  border-bottom: 1px solid #747474;
  outline: none;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.input-field input::-webkit-input-placeholder {
  text-transform: uppercase;
}

.input-field input::-moz-placeholder {
  text-transform: uppercase;
}

.input-field input:-ms-input-placeholder {
  text-transform: uppercase;
}

.input-field input::-ms-input-placeholder {
  text-transform: uppercase;
}

.input-field input::placeholder {
  text-transform: uppercase;
}

.input-field input:focus {
  border-color: #222;
}

.link {
  text-decoration: none;
  color: #747474;
  letter-spacing: 0.2px;
  display: inline-block;
  margin-top: 20px;
  font-style: italic;
  font-size: 15px;
}

.action {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}

.action button {
  width: 100%;
  border: none;
  padding: 18px;
  /* font-family: 'Rubik', sans-serif; */
  cursor: pointer;
  text-transform: uppercase;
  color: #777;
  letter-spacing: 0.2px;
  outline: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #004084;
  color: #fff;
}

.action :nth-child(1) {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}


.action :nth-child(2) {
  background: green;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.action button:hover {
  background: #3c4d6d;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.v-stepper-header {
  align-items: center;
  display: flex;
  position: relative;
  overflow-x: auto;
  justify-content: space-between;
  z-index: 1;
  box-shadow: none !important
}
</style>
