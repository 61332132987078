<template>
  <div
    v-if="useCount"
    class="progress square-edge"
    :data-test-id="`progress-bar/${rowIndex}`"
  >
    <div
      :data-test-id="`progressBar/${rowIndex}`"
      class="progress-bar bg-success text-dark overflow-visible"
      role="progressbar"
      :aria-valuenow="currentCountPercentage"
      aria-valuemin="0"
      aria-valuemax="100"
      :style="{ width: `${currentCountPercentage}%` }"
    >
      <b
        class="justify-content-center d-flex position-absolute w-100"
        name="message"
      > {{ currentCountPercentage }}% processed</b>
    </div>
  </div>
  <div
    v-else
    class="progress square-edge"
    :data-test-id="`progress-bar/${rowIndex}`"
  >
    <div
      :data-test-id="`progressBar/${rowIndex}`"
      class="progress-bar bg-success text-dark overflow-visible"
      role="progressbar"
      :aria-valuenow="currentProgress"
      aria-valuemin="0"
      aria-valuemax="100"
      :style="{ width: `${currentProgress}%` }"
    >
      <b
        v-if="!processed"
        class="justify-content-center d-flex position-absolute w-100"
        name="status"
        :class="{'text-danger':error}"
      > {{ msg }}% processed</b>
      <b
        v-else
        class="justify-content-center d-flex position-absolute w-100"
        name="message"
        :class="{error:'text-danger'}"
      >{{ msg }}</b>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed} from 'vue';
export default {
  name: 'WfmProgressLoader',
  props: {
    totalCount:{
      type:Number,
      default:0
    },
    currentCount:{
      type:Number,
      default:0
    },
    useCount:{
      type:Boolean,
      default:false
    }
  },
  emits:['generated'],
  setup(props, context) {
    //variables
    const stages = ref();
    const progress = ref();
    const msg = ref();
    const currentProcessId = ref(props?.params?.data?.id);
    const rowIndex = props.params?.node?.rowIndex || null
    const error = ref(false)
    const currentProgress = ref(2);
    const processed = ref(props?.params?.data?.processed);
    if (processed.value === true) {
      currentProgress.value = 100;
      msg.value = 'Completed';
    } else {
      msg.value = '0';
    }
    const handleBroadcastMessage = (event) => {
      // Log or process the broadcast message data
      // console.log('Broadcast message received:', event);
      // Handle the broadcast message as needed...
      const folderName = event?.detail?.ctrl?.folder

      if (folderName == 'payslips') {
        const msgType = event.detail.ctrl.output.type
        if (msgType != 'error') {
          progress.value = event.detail.count;
          const [currentStage, totalStages] = event.detail.stage.split('/');
          const [processed, total] = event.detail.count.split('/');
          const stageProgress = (parseInt(currentStage) - 1) / parseInt(totalStages);
          const recordProgress = (parseInt(processed) / parseInt(total)) / parseInt(totalStages);
          currentProgress.value = (stageProgress * 100) + (recordProgress * 100);
          msg.value = Math.round(currentProgress.value * 100) / 100;
        }
        else {
          const mssg = event.detail.ctrl.output.message
          error.value = true
          msg.value = mssg
        }
        context.emit('generated', event.detail)
      }
      else if (currentProcessId.value === event?.detail?.id) {
        progress.value = event.detail.count;
        const [currentStage, totalStages] = event.detail.stage.split('/');
        const [processed, total] = event.detail.count.split('/');
        const stageProgress = (parseInt(currentStage) - 1) / parseInt(totalStages);
        const recordProgress = (parseInt(processed) / parseInt(total)) / parseInt(totalStages);
        currentProgress.value = (stageProgress * 100) + (recordProgress * 100);
        msg.value = Math.round(currentProgress.value * 100) / 100;
      }
    };
    const currentCountPercentage = computed(() => {
      if (props.totalCount && props.currentCount) {
        return ((props.currentCount / props.totalCount) * 100).toFixed(2)

      }
      else {
        return 'Starting process'
      }
    })

    onMounted(() => {
      // Add event listener for "broadcastReceived" event
      window.addEventListener('eventReceived', handleBroadcastMessage);
    });
    return {
      stages,
      msg,
      progress,
      currentProgress,
      processed,
      rowIndex,
      error,
      currentCountPercentage
    };
  },
};
</script>

<style>

.progress {
  --bs-progress-bg: #cdd1ce !important;
}
.text-danger{
  color:red !important;
}
.progressBar  {
  justify-content: center;
  align-items: center;
}

.square-edge {
  border-radius: 0;
  margin: 10px;
}
</style>
