/*
makes sorting path for fields in folder
*/

const makeSortingPath = (fieldInfo, currentFolder) => {
  function getPathToFilter() {
    let retVal = null
    const currentFolderName = currentFolder.name
    if (fieldInfo.type == 'cfield') {
      retVal = fieldInfo.sort_path
    }
    else {
      const fieldName = fieldInfo.name
      const folderName = fieldInfo.foldername
      const fieldType = fieldInfo.type
      const fieldInFolder = folderName == currentFolderName
      const pathAdded = fieldInfo.path || null
      if (fieldInFolder) {
        if (fieldType == 'docpicker') {
          if (!['registration_image', 'registration_device', 'documents'].includes(fieldName)) {
            retVal = `${pathAdded}.code`
          }
        }
        else {
          retVal = `${pathAdded}`
        }
      }
      else {
        //if field is from diff folder
        if (fieldType == 'docpicker') {
          retVal = `${currentFolderName}.${pathAdded}.code`
        }
        else {
          retVal = `${currentFolderName}.${pathAdded}`
        }
      }
    }

    return retVal;
  }
  return { getPathToFilter }
}

export default makeSortingPath;
