<template>
  <button
    :class="buttonClass"
    :disabled="disabled"
    @click.prevent="handleClick"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'WfmButton',
  props: {
    buttonClass: {
      type: String,
      default: '',
    },
    disabled:{
      type:Boolean,
      default:false
    }
  },
  emits: ['button-click'],
  setup(props, { emit }) {
    function handleClick() {
      emit('button-click');
    }
    return {
      handleClick,
    };
  },
};
</script>

<style></style>
