import allocationGridUtils from './allocationGridUtils';
import attendanceGridUtils from './attendanceGridUtils'
import getFatRow from './fatRowGenerator';
import postData from './postData';
import getProjections from './getProjections';
import getFolders from './getFolders';
import store from '../../store/index'

const dataProcessor = () => {

  async function getAllocationRows(monthYear, currentFolder, filterValues, employeeFilter) {
    try {
      const { createTimeRange } = allocationGridUtils();
      const dateRange = createTimeRange(monthYear)
      const { projectionForFolder } = getProjections();
      const projections = projectionForFolder('allocations');

      const txnParams = {
        refRows: true,
        expand:[{
          path: 'allocations.allocations_period.allocations_range',
          interval: '1 day'
        }],
        page: {
          limitBag: {
            path: 'allocations.employees_id.id',
            limit: 100,
            offset:0,
            orderBy: 'code',
          },
          getRowCountBag: {
            path: 'allocations.employees_id.id',
            value: true,
          },
          orderByBag: {
            path: 'allocations.employees_id.code',
            value: 'desc'
          }
        },
        filters: {
          filterInput: [
            {
              path: 'allocations.allocations_period.allocations_range',
              operator: 'between',
              value: [dateRange[0], dateRange[1]],
            },
          ],
          joinop: 'and',
        },
        projections: projections,
      };
      txnParams['refRows'] = true;

      if (filterValues.contract !== null) {
        txnParams.filters.filterInput.push({
          operator: 'in',
          path: 'allocations.contracts_id.id',
          value: [filterValues.contract.id],
        });
      }

      if (filterValues.site !== null) {
        txnParams.filters.filterInput.push({
          operator: 'in',
          path: 'allocations.sites_id.id',
          value: [filterValues.site.id],
        });
      }

      if (filterValues.designation !== null) {
        txnParams.filters.filterInput.push({
          operator: 'in',
          path: 'allocations.products_id.id',
          value: [filterValues.designation.id],
        });
      }

      if (filterValues.department !== null) {
        txnParams.filters.filterInput.push({
          operator: 'in',
          path: 'allocations.employees_id.departments.id',
          value: [filterValues.department.id],
        });
      }

      if (filterValues.shift !== null) {
        txnParams.filters.filterInput.push({
          operator: 'in',
          path: 'allocations.allocations_period.shifts_id.id',
          value: [filterValues.shift.id],
        });
      }
      if (filterValues.reportingManger !== null) {
        txnParams.filters.filterInput.push({
          operator: 'in',
          path: 'allocations.employees_id.reporting_manager.id',
          value: [filterValues.reportingManger.id],
        });
      }

      if (Object.keys(employeeFilter).length !== 0) {
        const filterInput = [];
        filterInput.push({
          operator: 'ilike',
          path: 'allocations.employees_id.persons_id.first_name',
          value: '%' + employeeFilter.employees.filter + '%',
        });
        filterInput.push({
          operator: 'ilike',
          path: 'allocations.employees_id.code',
          value: '%' + employeeFilter.employees.filter + '%',
        });
        txnParams.filters.filterInput.push({filterInput, joinop: 'or'});
      }

      const { listData } = postData();

      const bSettings = store.getters['bSettings'];
      const { getAllFoldersList, getCurrentFolder } = getFolders()
      const folderList = getAllFoldersList(bSettings);
      const leavesFolder = getCurrentFolder('leaves', folderList);
      // const holidaysFolder = getCurrentFolder('holidays', folderList);
      const leavesTxnParams = {
        filters: {
          filterInput: [
            {
              path: 'leaves.leave_days.leave_period',
              operator: 'between',
              value: [dateRange[0], dateRange[1]],
            },
          ],
          joinop: 'and',
        },
        refRows:true,
        filterDetail: true,
        projections: {
          'leaves': ['id', 'deleted', 'employees_id', 'leave_days'],
          'leaves.employees_id':  ['id', 'code', 'deleted', 'persons_id', 'departments', 'designation', 'duty_chart_officer'],
          'leaves.employees_id.designation': ['id', 'code', 'name', 'price', 'deleted', 'creation_date', 'last_modified'],
          'leaves.employees_id.persons_id': ['id', 'deleted', 'last_name', 'birth_date', 'first_name', 'middle_name', 'gender'],
          'leaves.employees_id.departments': ['id', 'code', 'name', 'deleted', 'creation_date', 'last_modified'],
          'leaves.leave_days': ['id', 'leave_period', 'leave_status', 'leave_types_id', 'half_day_duration'],
          'leaves.leave_days.leave_types_id': ['id', 'code', 'name'],
          'leaves.employees_id.duty_chart_officer': ['id'],
        }
      }
      // const holidaysTxnParams = {
      //   filters: {
      //     filterInput: [
      //       {
      //         path: 'holidays.effective_range',
      //         operator: 'between',
      //         value: [dateRange[0], dateRange[1]],
      //       },
      //     ],
      //     joinop: 'and',
      //   },
      //   refRows:true
      // }
      const filterInput = [
        {
          operator: '=',
          path: 'leaves.leave_days.leave_status.code',
          value: 'final_approved',
        },
        {
          operator: '=',
          path: 'leaves.leave_days.leave_status.code',
          value: 'intermediate_approved',
        },
      ]
      leavesTxnParams.filters.filterInput.push({filterInput, joinop: 'or'})
      const leavesList = await listData(leavesFolder, leavesTxnParams);
      // const holidaysList = await listData(holidaysFolder, holidaysTxnParams)

      const list = await listData(currentFolder, txnParams);
      return { allocationsList:list, leavesList: leavesList, holidaysList: []};
    } catch (e) {
      console.log('Error', e);
    }
  }

  function rowDataCreator(data, sortModel) {
    console.time('test1');
    const { fatRowGenerator } = getFatRow();
    const finalData = fatRowGenerator(data.allocationsList);
    const leavesFatRow = fatRowGenerator(data.leavesList);
    // const holidaysFatRow = fatRowGenerator(data.holidaysList);
    // console.log('LEAVES', leavesFatRow);
    // const holidaysData = holidaysFatRow.reduce((holiday, dataForm) => {
    //   const invalidJsonString = dataForm.effective_range;
    //   const validJsonString = invalidJsonString.replace(/\)$/, ']');
    //   const effective_range = JSON.parse(validJsonString)
    //   dataForm.effective_range = effective_range[0];
    //   const field = getFormattedDateField(dataForm.effective_range);
    //   console.log('HOLIDAYS', holiday, field);
    //   const holidays = {};
    //   holidays[field] = {
    //     id: dataForm.id,
    //     name: dataForm.name.eng,
    //     code: dataForm.code
    //   }
    //   return holidays;
    // }, [])
    let alcData = finalData.reduce((alc, dataForm) => {
      if (dataForm.deleted === false && dataForm.allocations_period !== undefined && dataForm.allocations_period !== null) {
        const employeeId = dataForm?.employees_id?.[0]?.id;
        const existingData = alc.find((item) => item?.employees?.[0].id === employeeId);
        if (existingData) {
          for (let i = 0; i < dataForm.allocations_period.length; i++) {
            const field = getFormattedDateField(dataForm.allocations_period[i].allocations_range);
            existingData[field] = dataGenerator(i, dataForm)[field];
          }
        } else {
          let newData = {};
          for (let i = 0; i < dataForm.allocations_period.length; i++) {
            const keys = Object.keys(newData);
            if (keys.length === 0) {
              newData = dataGenerator(i, dataForm);
              newData['employees'] = dataForm.employees_id;
            } else {
              const field = getFormattedDateField(dataForm.allocations_period[i].allocations_range);
              newData[field] = dataGenerator(i, dataForm)[field];
            }
          }
          alc.push(newData);
        }
      }
      return alc;
    }, []);

    const leavesData = leavesFatRow.reduce((leave, dataForm) => {
      if (dataForm.deleted === false && dataForm.leave_days !== undefined) {
        const employeeId = dataForm?.employees_id?.[0]?.id;
        const existingData = leave.find((item) => item?.employees?.[0].id === employeeId);
        if (existingData) {
          for (let i = 0; i < dataForm.leave_days.length; i++) {
            if (dataForm.leave_days[i].leave_status !== undefined && dataForm.leave_days[i].leave_status !== null && (dataForm.leave_days[i].leave_status?.[0]?.code === 'intermediate_approved' || dataForm.leave_days[i].leave_status?.[0]?.code === 'final_approved')) {
              const invalidJsonString = dataForm.leave_days[i].leave_period;
              const validJsonString = invalidJsonString.replace(/\)$/, ']');
              const leave_period = JSON.parse(validJsonString)
              dataForm.leave_days[i].leave_period = leave_period[0];
              const field = getFormattedDateField(dataForm.leave_days[i].leave_period);
              existingData[field] = leaveDataGenerator(i, dataForm)[field];
            }
          }
        } else {
          let newData = {};
          for (let i = 0; i < dataForm.leave_days.length; i++) {
            if (dataForm.leave_days[i].leave_status !== undefined && dataForm.leave_days[i].leave_status !== null && (dataForm.leave_days[i].leave_status?.[0]?.code === 'intermediate_approved' || dataForm.leave_days[i].leave_status?.[0]?.code === 'final_approved')) {
              const invalidJsonString = dataForm.leave_days[i].leave_period;
              const validJsonString = invalidJsonString.replace(/\)$/, ']');
              const leave_period = JSON.parse(validJsonString)
              dataForm.leave_days[i].leave_period = leave_period[0];
              const keys = Object.keys(newData);
              if (keys.length === 0) {
                newData = leaveDataGenerator(i, dataForm, employeeId);
                newData['employees'] = dataForm.employees_id;
              } else {
                const field = getFormattedDateField(dataForm.leave_days[i].leave_period);
                newData[field] = leaveDataGenerator(i, dataForm)[field];
              }
            }
          }
          leave.push(newData);
        }
      }
      return leave;
    }, []);
    for (let i = 0; i < leavesData.length; i++) {
      if (Object.keys(leavesData[i]).length === 0) {
        delete leavesData[i];
      }
    }
    const tempData = leavesData.filter((i) => i != null);
    leavesData.length = 0;
    leavesData.push(...tempData);
    console.log('ALCDATA', alcData, leavesData);
    let sortedRows = alcData;
    if (alcData.length > 0 && leavesData.length > 0) {
      alcData = mergeAllocations(alcData, leavesData);
    } else if (alcData.length === 0 && leavesData.length > 0) {
      alcData = leavesData;
    }
    const { quickSort } = attendanceGridUtils();
    if (alcData.length > 0) {
      sortedRows = quickSort(alcData, 'code');
    }
    console.timeEnd('test1');
    if (sortModel.length !== 0 && sortModel[0].sort !== null) {
      if (sortModel[0].sort === 'desc') {
        sortedRows.reverse();
      }
    }
    return sortedRows;
  }

  function mergeAllocations(alcData, leaveData) {
    for (let i = 0; i < alcData.length; i++) {
      console.log('mergeAllocations', alcData[i])
      for (let j = 0; j < leaveData.length; j++) {
        if (alcData[i]['employees'][0].id === leaveData[j]['employees'][0].id) {
          console.log('mergeAllocations', alcData[i]['employees'][0].id, leaveData[j]['employees'][0].id)
          for (const leave in leaveData[j]) {
            alcData[i][leave] = leaveData[j][leave];
            delete leaveData[j][leave];
          }

          // TODO: Hotfix just to resolve demo error. In the loop above employee record is also deleted when the intention is to remove
          // just the leave dates but the loop on keys of the object results in deletion of employees key resulting in an empty
          // object in the if condition above.
          //
          if (Object.keys(leaveData[j]).length === 0) {
            delete leaveData[j];
          }
          const tempLeaveData = leaveData.filter((d) => d != null)
          leaveData.length = 0;
          leaveData.push(...tempLeaveData);
        }
      }
    }
    for (let i = 0; i < leaveData.length; i++) {
      if (Object.keys(leaveData[i]).length === 0) {
        delete leaveData[i];
      }
    }
    const tempData = leaveData.filter((i) => i != null);
    leaveData.length = 0;
    leaveData.push(...tempData);
    const newAlcData = [...alcData, ...leaveData];
    return newAlcData;
  }

  function leaveDataGenerator(i, dataForm) {
    const field = getFormattedDateField(dataForm.leave_days[i].leave_period);
    return {
      [field]: {
        id: dataForm.id,
        leaveTypeCode: dataForm.leave_days[i].leave_types_id[0].code,
        leaveTypeName: dataForm.leave_days[i].leave_types_id[0].name.eng,
        leaveStatus: dataForm.leave_days[i].leave_status[0].code,
        halfDayDuration: dataForm.leave_days[i].half_day_duration?.[0]?.code,
        isLeave: true
      }
    }
  }

  function dataGenerator(i, dataForm) {
    const field = getFormattedDateField(dataForm.allocations_period[i].allocations_range);
    return {
      [field]: {
        id: dataForm.id,
        allocations_range: dataForm.allocations_period[i].allocations_range,
        sites_id: dataForm.sites_id,
        shifts_id: dataForm.allocations_period[i].shifts_id,
        allocations_id: dataForm.allocations_period[i].shifts_id,
        allocation_type: dataForm.allocations_period[i].allocation_type,
        departments_id: dataForm.allocations_period[i].departments_id,
        contracts_id: dataForm.contracts_id,
        name: dataForm.name,
        products_id: dataForm.products_id,
        isLeave: false
      }
    };
  }

  function getFormattedDateField(date) {
    const formattedDate = new Date(date);
    formattedDate.setHours(formattedDate.getHours() + 5);
    formattedDate.setMinutes(formattedDate.getMinutes() + 30);
    const day = formattedDate.getDate();
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = months[formattedDate.getMonth()];
    const year = formattedDate.getFullYear();
    return `${day}${month}${year}`;
  }

  return { getAllocationRows, rowDataCreator };
};

export default dataProcessor;
