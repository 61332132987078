<template>
  <div>
    <div class="row">
      <div
        v-for="(eachFolder, index) in sortedItems()"
        :key="index"
        class="card col-3"
        style="width: 9.7rem; height: 9.7rem"
        @click="getValue(eachFolder)"
      >
        <div
          class="card-body"
          :name="eachFolder.name"
          :data-test-id="eachFolder.name"
        >
          <v-icon
            class="card-img-top"
            :name="getIcon(eachFolder.name).name"
            scale="1.5"
          />
          <span class="card-text">{{ convertToTitleCase(eachFolder?.label[currentLanguage] || currentLanguage) }}</span>
        </div>
        <div
          v-if="eachFolder.name === 'leaves' && !leavesNotApplicable"
          class="card-footer"
        >
          <div class="balance-leave">
            <div class="text-dark">
              Balance leaves
            </div>
            <div>
              Current Month:{{ currentMonthLeave }}
            </div>
            <div>
              Current Year:{{ currentYearLeave || 0 }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import getFolders from '../composables/getFolders';
import useIcons from '../composables/getIcons'
export default {
  name: 'WfmListIcons',
  props: {
    itemsList: {
      type: Array,
      default: () => [],
    },
    getValue: {
      type: Function,
      default: () => () => []
    },
    searchTerm:{
      type:String,
      default:null
    }
  },
  emits: ['list-item-clicked'],
  setup(props, context) {
    // Variables
    const i18n = useI18n();
    const store = useStore();
    const {getIcon} = useIcons()
    const currentLanguage = ref(i18n.locale.value);
    const {leaveQuota } = getFolders();
    const leavesNotApplicable = ref(false)
    const leavesQuota = ref(null)
    const bSettings = store.getters['bSettings'];
    let isAdminAORO = false;
    const currentEmpCode = bSettings?.env?.code;
    if (currentEmpCode === 'admin') {
      isAdminAORO = true;
    }
    const reportingPath = bSettings?.env?.child_reporting_path;
    if (reportingPath !== undefined && reportingPath !== null) {
      for (let i = 0; i < reportingPath.length; i++) {
        if (reportingPath[i] !== null) {
          const parts = reportingPath[i].split('/');
          if (parts.length === 3) {
            isAdminAORO = true;
          } else if (parts.length === 2) {
            isAdminAORO = true;
          }
        }
      }
    }

    // Computed
    const currentMonthLeave = computed(() => (leavesQuota.value !== null ? leavesQuota?.value?.output?.data?.records[0]?.leaves_left : 0));
    const currentYearLeave = computed(() => (leavesQuota.value !== null ? leavesQuota?.value?.output?.data?.records[0]?.yearly_leaves_left : 0));
    const filterByTerm = computed(() => {
      if (props.searchTerm != null && props.searchTerm !== '#') {
        const retVal = props.itemsList.filter((item) => item.label.eng.toLowerCase().startsWith(props.searchTerm?.toLowerCase()))
        if (retVal.length !== 0) {
          return retVal
        }
        else {
          return props.itemsList
        }
      }
      else {
        return props.itemsList
      }
    })
    function sortedItems() {
      const filteredItems = filterByTerm.value.sort((a, b) => {
        const first = a?.label?.eng?.toLowerCase();
        const second = b?.label?.eng?.toLowerCase();
        return first.localeCompare(second);
      })
      return filteredItems
    }
    // Lifecycle Hook
    onMounted(async() => {
      try {
        const sessionId = store.getters['sessionIdGetter'];
        leavesQuota.value = await leaveQuota(bSettings, sessionId);
        if (leavesQuota.value?.output?.type === 'error') {
          leavesQuota.value = null;
        }
        else {
          if (leavesQuota.value?.output.data.records[0].status == 'error') {
            leavesNotApplicable.value = true
          }
        }
      }
      catch (err) {
        console.error(err);
      }
    });

    function convertToTitleCase(str) {
      return str.split('_').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }

    function handleFolderSearch() {

    }
    return {
      currentLanguage,
      filterByTerm,
      convertToTitleCase,
      getIcon,
      currentMonthLeave,
      currentYearLeave,
      value: '',
      isAdminAORO,
      handleFolderSearch,
      sortedItems,
      leavesNotApplicable
    };
  },
};
</script>


<style scoped>

.row {
  justify-content: center;
  align-items: center;
}

.card {
  --bs-card-bg: var(--folder-background);
  color: var(--main-color);
  transition: background-color 0.3s ease;
  /* width: calc(20% - 1rem); Adjust according to your card width and desired space between cards */
  margin: 1rem;
  box-shadow: 4px 3px var(--main-color);

}

.card:hover {
  border: 1px solid #83ACC8;
  --bs-card-bg:#82b572;
  cursor: pointer;
  color:white !important;
  transform: scale(1.09);
  font-weight: 600;
}

.card-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.card-text {
  font-size: 1rem;
}

.card-footer{
  background-color: transparent;
}
.balance-leave {
  font-size: 0.7rem;
  color:var(--co-main-color)
}

.balance-leave:hover{
color:white;
}

</style>
