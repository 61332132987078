<template>
  <div class="my-auto">
    <div>
      <img
        class="mt-5 img-logo"
        :src="logo"
      />
    </div>
    <div class="login-form">
      <LoginPage v-if="doLogin" />
      <SignupPage v-if="doSignup" />
      <RecoverPassword v-if="doRecover" />
    </div>
  </div>
  <!-- <div class="nav-links">
    <div
      v-if="doLogin"
      class="mt-3 text-decoration-underline forgot"
      @click="changeForm('forgot')"
    >
      Forgot Password?
    </div>
  </div> -->
</template>

<script>
import { ref } from 'vue';
import logo from '../../assets/images/logo.png';
import SignupPage from './SignupPage.vue';
import RecoverPassword from './RecoverPassword.vue';
import LoginPage from './LoginPage.vue';

export default {
  name: 'UserAuthentication',
  components: {
    SignupPage,
    RecoverPassword,
    LoginPage
  },
  setup() {
    // Variables
    const doSignup = ref(false);
    const doLogin = ref(true);
    const doRecover = ref(false);

    // Methods
    function changeForm(name) {
      if (name === 'signup') {
        doSignup.value = true;
        doLogin.value = false;
        doRecover.value = false;
      } else if (name === 'forgot') {
        doSignup.value = false;
        doLogin.value = false;
        doRecover.value = true;
      } else {
        doSignup.value = false;
        doLogin.value = true;
        doRecover.value = false;
      }
    }

    // Return data and methods
    return {
      logo,
      doLogin,
      doSignup,
      doRecover,
      changeForm
    };
  }
};
</script>

<style scoped>
/* Existing styles */
.nav-links {
  position: fixed;
  bottom: 5;
  right: 0;
  left: 0;
}

input {
  width: 20rem;
  height: 50px;
  margin-top: 20px;
  border-radius: 5px;
  text-align: center;
}

label {
  color: green;
}

.img-logo {
  width: 300px;
}

.forgot {
  cursor: pointer;
}

.login-form {
  background: #0000;
  width: 100%;
  max-width: 550px;
  height: 100%;
  margin: 65px auto;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 2px 25px rgba(0, 0, 0, 0.2);
}

.link {
  text-decoration: none;
  color: #747474;
  letter-spacing: 0.2px;
  display: inline-block;
  margin-top: 20px;
  font-style: italic;
  font-size: 15px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
