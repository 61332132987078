<template>
  <div
    class="editor_container"
    @keydown.capture="keyboardCommandsHandler"
  >
    <div class="editor_header">
      <span>Employee Name (date)</span>
    </div>
    <div class="editor_fields">
      <form class="editor_form">
        <div class="editor_field">
          <label
            for="docpicker"
            class="form_label"
          >
            Attendance Type:
          </label>
          <div class="form_docpicker">
            <v-select
              ref="inputText1"
              v-model="selected_att_type"
              style="text-overflow: ellipsis;"
              label="name"
              :disabled="isReadOnly"
              :placeholder="default_att_type"
              :options="docpOptions.att_type"
              clear-search-on-select
              :map-keydown="createKeydownHandler(0)"
              :selected="onClose(event)"
            >
              <template #option="option">
                <div
                  @mouseover="setHovered(option)"
                >
                  <span
                    :class="{'selected_option':selected_att_type && (selected_att_type === option.name)}"
                  >
                    {{ option.name }}
                  </span>
                </div>
              </template>
            </v-select>
          </div>
        </div>
        <div
          v-if="isReadOnly"
          class="editor_field"
        >
          <label
            for="docpicker"
            class="form_label"
          >
            Leave Type:
          </label>
          <div class="form_docpicker">
            <v-select
              v-model="default_leave_type"
              style="text-overflow: ellipsis;"
              label="name"
              :disabled="isReadOnly"
              :placeholder="default_leave_type"
              :options="[]"
            />
          </div>
        </div>
        <div class="editor_field">
          <label
            for="docpicker"
            class="form_label"
          >
            Site:
          </label>
          <div class="form_docpicker">
            <v-select
              ref="inputText2"
              v-model="selected_site"
              :disabled="isReadOnly"
              label="name"
              :placeholder="default_site"
              :options="docpOptions.sites_id"
              clear-search-on-select
              :map-keydown="createKeydownHandler(1)"
            >
              <template #option="option">
                <div
                  @mouseover="setHovered(option)"
                >
                  <span
                    :class="{'selected_option':selected_site && (selected_site === option.name)}"
                  >
                    {{ option.name }}
                  </span>
                </div>
              </template>
            </v-select>
          </div>
        </div>
        <div class="editor_field">
          <label
            for="docpicker"
            class="form_label"
          >
            Shift:
          </label>
          <div class="form_docpicker">
            <v-select
              ref="inputText3"
              v-model="selected_shift"
              :disabled="isReadOnly"
              label="name"
              :placeholder="default_shift"
              :options="docpOptions.shifts_id"
              clear-search-on-select
              :map-keydown="createKeydownHandler(2)"
            >
              <template #option="option">
                <div
                  @mouseover="setHovered(option)"
                >
                  <span
                    :class="{'selected_option':selected_shift && (selected_shift === option.name)}"
                  >
                    {{ option.name }}
                  </span>
                </div>
              </template>
            </v-select>
          </div>
        </div>
        <div class="editor_field">
          <label
            for="docpicker"
            class="form_label"
          >
            Designation:
          </label>
          <div class="form_docpicker">
            <v-select
              ref="inputText4"
              v-model="selected_designation"
              :disabled="isReadOnly"
              label="name"
              :placeholder="default_designation"
              :options="docpOptions.designation"
              clear-search-on-select
              :map-keydown="createKeydownHandler(3)"
            >
              <template #option="option">
                <div
                  @mouseover="setHovered(option)"
                >
                  <span
                    :class="{'selected_option':selected_designation && (selected_designation === option.name)}"
                  >
                    {{ option.name }}
                  </span>
                </div>
              </template>
            </v-select>
          </div>
        </div>
        <div class="editor_field">
          <label
            for="docpicker"
            class="form_label"
          >
            Working Hours:
          </label>
          <div class="form_docpicker">
            <input
              id="inputText5"
              ref="input0"
              v-model="workingHrs"
              :disabled="true"
              :style="error !== '' ? 'border: 1px solid red;': ''"
              type="text"
              placeholder="HH:MM"
              class="my-simple-editor form-control form-control-sm"
              maxlength="5"
              @input="formatTime"
              @keydown="(event) => handleKeydown(4, event)"
            >
            <p v-if="error">
              {{ error }}
            </p>
            <p v-else-if="workingHrs">
              {{ formattedTime }}
            </p>
          </div>
        </div>
        <!-- <div class="editor_field">
          <label
            for="docpicker"
            class="form_label"
          >
            Overtime Hours:
          </label>
          <div class="form_docpicker">
            <input
              id="inputText6"
              ref="input2"
              v-model="overtimeHrs"
              :disabled="isReadOnly"
              type="text"
              placeholder="0:0"
              class="my-simple-editor form-control form-control-sm"
              tabindex="1"
              @keydown="(event) => handleKeydown(5, event)"
            />
          </div>
        </div> -->
        <div class="editor_field">
          <label
            for="docpicker"
            class="form_label"
          >
            In Time:
          </label>
          <div class="form_docpicker">
            <input
              id="inputText6"
              ref="input2"
              v-model="inTime"
              :disabled="true"
              step="2"
              type="time"
              class="my-simple-editor form-control form-control-sm"
              tabindex="1"
              @keydown="(event) => handleKeydown(5, event)"
            />
          </div>
        </div>
        <div class="editor_field">
          <label
            for="docpicker"
            class="form_label"
          >
            Out Time:
          </label>
          <div class="form_docpicker">
            <input
              id="inputText7"
              ref="input3"
              v-model="outTime"
              :disabled="true"
              step="2"
              type="time"
              class="my-simple-editor form-control form-control-sm"
              tabindex="1"
              @keydown="(event) => handleKeydown(6, event)"
            />
          </div>
        </div>
        <div class="editor_field">
          <label
            for="docpicker"
            class="form_label"
          >
            Notes:
          </label>
          <div class="form_docpicker">
            <input
              id="inputText8"
              ref="input1"
              v-model="notes"
              :disabled="isReadOnly"
              type="text"
              placeholder="Note"
              class="my-simple-editor form-control form-control-sm"
              tabindex="1"
              @keydown="(event) => handleKeydown(7, event)"
            />
          </div>
        </div>
      </form>
    </div>
    <div class="editor_bottom">
      <span>Ctrl + Enter to save</span>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, computed, nextTick  } from 'vue';
import attendanceGridUtils from '../../../composables/attendanceGridUtils';
import { toast } from 'vue3-toastify';
import { useStore } from 'vuex';
import getFolders from '../../../composables/getFolders';


export default {
  name: 'CellEditor',
  setup(props) {
    const value = ref('value' in props.params ? JSON.parse(JSON.stringify(props.params.value)) : undefined);
    const { getAttendanceDate, convertMinutesToHoursFormat, timeStringToMinutes } = attendanceGridUtils();
    const default_leave_type = ref('');
    const isReadOnly = ref(false);
    if (value.value?.leave_type !== undefined && value.value?.leave_type !== null && value.value?.leave_type.length > 0) {
      isReadOnly.value = true;
      default_leave_type.value = value.value.leave_type?.[0]?.name?.eng;
    }
    if (value.value?.attendance_status !== undefined && value.value?.attendance_status !== null) {
      if (value.value?.attendance_status?.[0]?.code === 'final_approved') {
        isReadOnly.value = true;
      }
    }

    const store = useStore();
    let isHovered = null;
    const bSettings = store.getters['bSettings'];
    const { getAllFoldersList, getCurrentFolder } = getFolders()
    const folderList = getAllFoldersList(bSettings);
    const attendanceEditsFolder = getCurrentFolder('attendance_edits', folderList);
    const editsForm = attendanceEditsFolder.forms.attendance_edits_create_form;
    console.log('I am here', editsForm);

    const inputText1 = ref(null);
    const inputText2 = ref(null);
    const inputText3 = ref(null);
    const inputText4 = ref(null);
    const input1 = ref(null);
    const input2 = ref(null);
    const input3 = ref(null);
    const input4 = ref(null);
    const input5 = ref(null);

    let dataSource = {};
    dataSource = props.params?.cellEditorDataSource;
    const docpOptions = ref({});
    const workingHrs = ref(null);
    const error = ref('');
    const overtimeHrs = ref(null);
    const inTime = ref(null);
    const outTime = ref(null);
    const notes = ref(null);
    const selected_att_type = ref();
    const default_att_type = computed(() => {
      if (value.value !== undefined && value.value.hasOwnProperty('att_type')) {
        return value.value['att_type'][0]['name']['eng'];
      } else {
        return 'Select attendance';
      }
    })
    selected_att_type.value = default_att_type.value;

    const selected_designation = ref();
    const default_designation = computed(() => {
      if (value.value !== undefined && value.value.hasOwnProperty('designation')) {
        return value.value['designation'][0]['name'];
      } else {
        return 'Select designation';
      }
    })
    selected_designation.value = default_designation.value;

    const selected_site = ref();
    const default_site = computed(() => {
      if (value.value !== undefined && value.value.hasOwnProperty('sites_id')) {
        return value.value['sites_id'][0]['name'].eng;
      } else {
        return 'Select site';
      }
    })
    selected_site.value = default_site.value;

    const selected_shift = ref();
    const default_shift = computed(() => {
      if (value.value !== undefined && value.value.hasOwnProperty('shifts_id')) {
        return value.value['shifts_id'][0]['name'];
      } else {
        return 'Select shift';
      }
    })
    selected_shift.value = default_shift.value;


    const isPopup = () => {
      return true;
    };


    const getValue = () => {
      if (typeof value.value === 'undefined') {
        value.value = {};
      }
      if (isReadOnly.value) {
        return props.params.value;
      }
      const attendance_edit = {};
      if (Object.keys(value.value) === 0) {
        if (selected_att_type.value !== undefined) {
          for (let i = 0; i < dataSource['att_type'].length; i++) {
            if (selected_att_type.value.id === dataSource['att_type'][i].id) {
              value.value['att_type'] = [dataSource['att_type'][i]];
              attendance_edit['att_type'] = [dataSource['att_type'][i]];
              break;
            }
          }
        }
        if (selected_designation.value !== undefined) {
          for (let i = 0; i < dataSource['designation'].length; i++) {
            if (selected_designation.value.id === dataSource['designation'][i].id) {
              value.value['designation'] = [dataSource['designation'][i]];
              attendance_edit['designation'] = [dataSource['designation'][i]];
              break;
            }
          }
        }
        if (selected_site.value !== undefined) {
          for (let i = 0; i < dataSource['sites_id'].length; i++) {
            if (selected_site.value.id === dataSource['sites_id'][i].id) {
              value.value['sites_id'] = [dataSource['sites_id'][i]];
              attendance_edit['sites_id'] = [dataSource['sites_id'][i]];
              break;
            }
          }
        }
        if (selected_shift.value !== undefined) {
          for (let i = 0; i < dataSource['shifts_id'].length; i++) {
            if (selected_shift.value.id === dataSource['shifts_id'][i].id) {
              value.value['shifts_id'] = [dataSource['shifts_id'][i]];
              attendance_edit['shifts_id'] = [dataSource['shifts_id'][i]];
              break;
            }
          }
        }
      } else {
        if (selected_att_type.value !== undefined) {
          for (let i = 0; i < dataSource['att_type'].length; i++) {
            if (selected_att_type.value.id === dataSource['att_type'][i].id) {
              value.value['att_type'] = [dataSource['att_type'][i]];
              attendance_edit['att_type'] = [dataSource['att_type'][i]];
              break;
            }
          }
        }
        if (selected_designation.value !== undefined) {
          for (let i = 0; i < dataSource['designation'].length; i++) {
            if (selected_designation.value.id === dataSource['designation'][i].id) {
              value.value['designation'] = [dataSource['designation'][i]];
              attendance_edit['designation'] = [dataSource['designation'][i]];
              break;
            }
          }
        }
        if (selected_site.value !== undefined) {
          for (let i = 0; i < dataSource['sites_id'].length; i++) {
            if (selected_site.value.id === dataSource['sites_id'][i].id) {
              value.value['sites_id'] = [dataSource['sites_id'][i]];
              attendance_edit['sites_id'] = [dataSource['sites_id'][i]];
              break;
            }
          }
        }
        if (selected_shift.value !== undefined) {
          for (let i = 0; i < dataSource['shifts_id'].length; i++) {
            if (selected_shift.value.id === dataSource['shifts_id'][i].id) {
              value.value['shifts_id'] = [dataSource['shifts_id'][i]];
              attendance_edit['shifts_id'] = [dataSource['shifts_id'][i]];
              break;
            }
          }
        }
      }

      if (Object.keys(value.value).length === 0) {
        return props.params.value
      }

      const workingMinutes = workingHrs.value;
      const overTimeMinutes = overtimeHrs.value;
      const note = notes.value;
      const attendanceDate = props.params.column.colId;
      if (inTime.value === convertUtcToIst(value.value?.['in_time'])) {
        inTime.value = null;
      }
      if (outTime.value === convertUtcToIst(value.value?.['out_time'])) {
        outTime.value = null;
      }
      const inTimeValue = convertToUTCTimestamp(inTime.value, attendanceDate);
      const outTimeValue = convertToUTCTimestamp(outTime.value, attendanceDate);
      if (workingMinutes !== null && timeStringToMinutes(workingMinutes) !== value.value['working_minutes']) {
        value.value['working_minutes'] = timeStringToMinutes(workingMinutes !== null ? workingMinutes : '');
        attendance_edit['working_minutes'] = value.value['working_minutes'];
      }
      if (overTimeMinutes !== null && timeStringToMinutes(overTimeMinutes) !== value.value['overtime_minutes']) {
        value.value['overtime_minutes'] = timeStringToMinutes(overTimeMinutes !== null ? overTimeMinutes : '');
        attendance_edit['overtime_minutes'] = value.value['overtime_minutes'];
      }
      if (notes.value !== null || value.value?.['exception'] !== null) {
        if (value.value.notes !== note && value.value?.['exception']?.[value.value['exception'].length - 1]?.['description'] !== note) {
          value.value['notes'] = note;
          attendance_edit['notes'] = note;
        }
      }
      if (inTimeValue !== null && inTimeValue !== undefined && inTimeValue !== '' && inTimeValue !== value.value['in_time']) {
        value.value['in_time'] = inTimeValue;
        attendance_edit['in_timestamp'] = value.value['in_time'];
      }
      if (outTimeValue !== null && outTimeValue !== undefined && outTimeValue !== '' && outTimeValue !== value.value['out_time']) {
        value.value['out_time'] = outTimeValue;
        attendance_edit['out_timestamp'] = value.value['out_time'];
      }

      if (Object.keys(attendance_edit).length === 0) {
        return props.params.value;
      }

      const keysToCheck = ['att_type', 'designation', 'sites_id', 'shifts_id'];

      const missingKeys = checkKeys(value.value, keysToCheck);
      const editsMissingKeys = checkKeys(attendance_edit, keysToCheck);
      value.value['editing'] = true;
      if (missingKeys.present === false) {
        value.value['attendance_edit'] = attendance_edit;
      } else {
        if (value.value['attendance_edit'] !== undefined) {
          delete value.value['attendance_edit'];
        }
        if (editsMissingKeys.present === false) {
          for (let i = 0; i < editsMissingKeys.missingKeys.length; i++) {
            attendance_edit[editsMissingKeys.missingKeys[i]] = value.value[editsMissingKeys.missingKeys[i]];
          }
        }

        const attendanceDate = getAttendanceDate(props.params, null);
        attendance_edit['attendance_date'] = attendanceDate;
        attendance_edit['sync_timestamp'] = attendanceDate;
        value.value['editing'] = false;
        attendance_edit['employees_id'] = props.params.data['employees'];
        props.params.isEditedEnable(true);
        value.value['attendance_edit'] = attendance_edit;
      }
      return value.value;
    }

    const isCancelBeforeStart = () => {
      return false;
    };

    const isCancelAfterEnd = () => {
      return false;
    };

    onMounted(async() => {
      docpOptions.value.att_type = dataSource.att_type.map(
        (item) => ({ name: item['name']['eng'], id: item.id }
        ));
      docpOptions.value.designation = dataSource.designation.map(
        (item) => ({ name: item['name'], id: item.id }
        ));
      docpOptions.value.sites_id = dataSource.sites_id.map(
        (item) => ({ name: item['name']['eng'], id: item.id }
        ));
      docpOptions.value.shifts_id = dataSource.shifts_id.map(
        (item) => ({ name: item['name'], id: item.id }
        ));
      workingHrs.value = value.value !== undefined ? convertMinutesToHoursFormat(value.value?.['working_minutes']) : null;
      overtimeHrs.value = value.value !== undefined ? convertMinutesToHoursFormat(value.value?.['overtime_minutes']) : null;
      inTime.value = value.value !== undefined ? convertUtcToIst(value.value?.['in_time']) : null;
      outTime.value = value.value !== undefined ? convertUtcToIst(value.value?.['out_time']) : null;
      if (value.value?.['exception'] !== null) {
        console.log(value.value?.['exception']?.[value.value['exception'].length - 1]?.['description'])
        notes.value = value.value?.['exception']?.[value.value['exception'].length - 1]?.['description'];
      }
      nextTick(() => {
        if (inputText1.value) {
          inputText1.value.$el.querySelector('input').focus();
        }
      })
    });

    function keyboardCommandsHandler(event) {
      if (event && event.key === 'Enter' && event.ctrlKey === true) {
        props.params.stopEditing();
      }
      // event.stopPropagation();
    }

    const focusNext = async(e, currentIndex) => {
      const refList = [
        inputText1, inputText2, inputText3, inputText4,
        input1
      ];

      if ((e && e.key === 'Tab') || (e.key === 'Enter' && e.ctrlKey !== true)) {
        e.preventDefault(); // Prevent the default Tab behavior
        if (isHovered !== null) {
          if (isHovered?.name === selected_att_type.value?.name) {
            selected_att_type.value = null;
            isHovered = null;
          } else if (isHovered?.name === selected_designation.value?.name) {
            selected_designation.value = null; // Assuming you want to reset this too
            isHovered = null;
          } else if (isHovered?.name === selected_shift.value?.name) {
            selected_shift.value = null; // Assuming you want to reset this too
            isHovered = null;
          } else if (isHovered?.name === selected_site.value?.name) {
            selected_site.value = null; // Assuming you want to reset this too
            isHovered = null;
          }
        }
        // Determine the direction: forward or backward
        const isShiftPressed = e.shiftKey;
        const nextIndex = isShiftPressed
          ? (currentIndex - 1 + refList.length) % refList.length  // move to the previous element
          : (currentIndex + 1) % refList.length;  // move to the next element

        const nextElement = refList[nextIndex].value;

        await nextTick(); // Ensure the next DOM update is completed

        if (nextElement && nextElement.$el) {
          const inputEl = nextElement.$el.querySelector('input');
          if (inputEl) {
            inputEl.focus();
          } else {
            nextElement.focus(); // for non-input elements
          }
        } else if (nextElement) {
          nextElement.focus(); // for input elements directly
        }
      }

      // Stop editing when Enter is pressed without Shift and on the last element
      if (e && e.key === 'Enter' && e.ctrlKey === true) {
        if (error.value === '') {
          props.params.stopEditing();
        } else {
          toast.warning('Please enter valid working hours', {
            autoClose: 4000,
            closeButton: true,
            isLoading: false,
            position: toast.POSITION.TOP_CENTER,
          })
        }
      }
    };

    const createKeydownHandler = (currentIndex) => {
      return (map, vm) => ({
        ...map,
        9: async(e) => { // Tab key
          e.preventDefault();
          e.stopPropagation();
          await focusNext(e, currentIndex);
        },
        13: async(e) => { // Enter key
          e.stopPropagation();
          vm.typeAheadSelect(); // Ensure selection
          await nextTick();
          await focusNext(e, currentIndex);
        }
      });
    };

    const handleKeydown = async(currentIndex, e) => {
      if (e.key === 'Tab') {
        e.preventDefault();
        e.stopPropagation();
        await focusNext(e, currentIndex);
      } else if (e.key === 'Enter') {
        e.stopPropagation();
        // vm.typeAheadSelect(); // Ensure selection
        await nextTick();
        await focusNext(e, currentIndex);
      }
    };

    function convertUtcToIst(utcTime) {
      if (!utcTime || isNaN(new Date(utcTime).getTime())) {
        return '';
      }
      const utcDate = new Date(utcTime);
      const ISTOffset = 330;
      const localOffset = utcDate.getTimezoneOffset();
      const offsetDifference = ISTOffset - localOffset;
      const istDate = new Date(utcDate.getTime() + offsetDifference);


      // Format as HH:MM:SS
      const hours = String(istDate.getHours()).padStart(2, '0');
      const minutes = String(istDate.getMinutes()).padStart(2, '0');
      const seconds = String(istDate.getSeconds()).padStart(2, '0');

      return `${hours}:${minutes}:${seconds}`;
    }

    function checkKeys(object, keys) {
      const missingKeys = [];
      for (const key of keys) {
        if (!object.hasOwnProperty(key)) {
          missingKeys.push(key);
        }
      }
      if (missingKeys.length === 0) {
        return { present: true };
      } else {
        return { present: false, missingKeys };
      }
    }

    function convertToUTCTimestamp(timeString, dateString) {
    // Handle special cases: "0:0:0", null, or empty string
      if (timeString === '0:0:0' || timeString === null || timeString === '') {
        return null;
      }

      // Parse the time string "HH:MM:SS"
      const [hours, minutes, seconds] = timeString.split(':').map(Number);

      // Parse the date string "1Sep2024"
      const dateParts = dateString.match(/(\d{1,2})([a-zA-Z]{3})(\d{4})/);
      if (!dateParts) {
        throw new Error('Invalid date format');
      }

      const day = parseInt(dateParts[1], 10);
      const month = new Date(Date.parse(dateParts[2] + ' 1, 2024')).getMonth(); // Get the month index (0-11)
      const year = parseInt(dateParts[3], 10);

      // Create a Date object in IST using the parsed date and time
      const istDate = new Date(Date.UTC(year, month, day, hours, minutes, seconds));

      // Subtract 5 hours and 30 minutes from IST to get UTC
      const timeZoneOffset = istDate.getTimezoneOffset();
      if (timeZoneOffset > 0) {
        istDate.setUTCMinutes(istDate.getUTCMinutes() - timeZoneOffset);
      } else {
        istDate.setUTCMinutes(istDate.getUTCMinutes() - (-timeZoneOffset));
      }
      // 5 hours and 30 minutes is 330 minutes

      // Return the UTC timestamp in ISO format
      return istDate.toISOString();
    }

    function onClose(event) {
      console.log(event)
    }

    const formattedTime = computed(() => {
      if (workingHrs.value && !error.value) {
        const [hours, minutes] = workingHrs.value.split(':');
        return `${hours} hours and ${minutes} minutes`;
      }
      return '';
    });

    // Function to automatically format the input to HH:MM
    const formatTime = (event) => {
      let input = event.target.value.replace(/[^0-9]/g, ''); // Only keep numbers
      if (input.length > 2) {
        input = input.slice(0, 2) + ':' + input.slice(2); // Insert ':' after two digits
      }
      workingHrs.value = input; // Update the v-model with the formatted value

      // Validate time format (HH:MM in 24-hour format)
      const timePattern = /^([01]\d|2[0-3]):([0-5]\d)$/;
      if (!timePattern.test(workingHrs.value)) {
        error.value = 'Please enter a valid time (HH:MM).';
      } else {
        error.value = ''; // Clear error if valid
      }
    };

    function setHovered(value) {
      if (value && value.name !== '') {
        isHovered = JSON.parse(JSON.stringify(value));
      } else {
        console.warn('Hovered value is invalid:', value);
      }
    }
    return {
      setHovered,

      keyboardCommandsHandler,
      onClose,
      isReadOnly,
      default_leave_type,

      inputText1,
      inputText2,
      inputText3,
      inputText4,
      input1,
      input2,
      input3,
      input4,
      input5,
      createKeydownHandler,
      handleKeydown,

      isPopup,
      getValue,
      isCancelAfterEnd,
      isCancelBeforeStart,

      docpOptions,
      workingHrs,
      error,
      formattedTime,
      formatTime,
      overtimeHrs,
      inTime,
      outTime,
      notes,
      selected_att_type,
      default_att_type,
      selected_designation,
      default_designation,
      selected_site,
      default_site,
      selected_shift,
      default_shift,
    };
  }
}
</script>

<style>
.editor_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 450px;
  height: 510px;
  background-color: white;
}

.editor_header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 8%;
  background-color: gray;
}

.editor_fields {
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
}

.editor_form {
  padding: 10px;
  width: 100%;
}

.editor_field {
  display: flex;
  justify-content: space-between;
  padding: 0 3%;
  align-items: center;
  margin: 4%;
}

.form_label {
  width: 30% !important;
  float: left;
  text-align: left;
}

.form_docpicker {
  width: 60% !important;
}

.editor_field p {
  margin: 0;
  margin-top: 1px;
  font-size: 10px;
}


.vs__selected-options {
  flex-wrap: nowrap;
  overflow: hidden;
  white-space: nowrap;
}

.editor_bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 8%;
  background-color: gray;
}

</style>
