<template>
  <div
    v-if="employeeShow"
    class="employeeContainer"
  >
    <span class="employeeSpan">{{ employeeObject.name }} {{ employeeObject.middleName }} {{ employeeObject.lastName }}</span>
    <span class="employeeSpan">{{ employeeObject.code }}
      <v-icon
        v-if="currentView === 'attendance'"
        name="bi-table"
        fill="#292929"
        scale="0.9"
        @click="openRegisterView()"
      />
    </span>
  </div>
  <div
    v-else-if="newRowAdded"
    class="employeeContainer"
  >
    <img src="https://www.ag-grid.com/example-assets/loading.gif">
  </div>
  <div
    v-else
    class="employeeContainer"
  >
    <!-- <img src="https://www.ag-grid.com/example-assets/loading.gif"> -->
  </div>
</template>


<script>
import { computed, ref } from 'vue';

export default {
  name: 'EmployeeCellRenderer',
  setup(props) {
    const currentView = ref(props.params.gridView);
    const employeeAttData = props?.params?.data;
    const newRowAdded = ref(employeeAttData === undefined || employeeAttData === null || Object.keys(employeeAttData).length !== 0 ? true : false)
    const employeeShow = props.params.value === undefined ? false : true;
    const employeeObject = computed(() => {
      if (employeeShow) {
        const empObject = {
          name: Array.isArray(props.params.value) ? props.params.value?.[0]?.persons_id?.[0]?.first_name?.eng : props.params.value?.persons_id?.[0]?.first_name?.eng,
          code: Array.isArray(props.params.value) ? props.params.value?.[0]?.code : props.params.value?.code,
          id: Array.isArray(props.params.value) ? props.params.value?.[0]?.id : props.params.value?.id,
          lastName: Array.isArray(props.params.value) ? props.params.value?.[0]?.persons_id?.[0]?.last_name?.eng : props.params.value?.persons_id?.[0]?.last_name?.eng,
          middleName: Array.isArray(props.params.value) ? props.params.value?.[0]?.persons_id?.[0]?.middle_name?.eng : props.params.value?.persons_id?.[0]?.middle_name?.eng,
        }
        return empObject;
      } else {
        return null;
      }
    })

    function openRegisterView() {
      props.params.updateFilterValues({type:'employee', value:employeeAttData?.employees?.[0], openRegisterView: true});
    }

    return {
      currentView,
      newRowAdded,
      employeeShow,
      employeeObject,
      openRegisterView
    };
  }
}
</script>
<style>
.ag-cell .employeeContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.ag-cell .employeeContainer .employeeSpan {
  position: relative;
  bottom: 12px;
  height: 14px;
  margin: 1px;
  font-size: 12px;
}

</style>
