import { v4 as uuidv4 } from 'uuid';
import CryptoJS from 'crypto-js'; // Import CryptoJS library
import { fileUploads } from '../services/websocket';
import store from '../../store';
import { toast } from 'vue3-toastify';
const bulkUpload = () => {

  function generateMD5Hash(text) {
    return CryptoJS.MD5(text).toString();
  }

  function bulk_upload_create_data(files, txnToUse) {
    const data = [];
    const sessionId = store.getters['sessionIdGetter'];
    //replacing with props passed
    const currTxn = txnToUse
    files.forEach((f, index) => {
      const files_id = uuidv4();
      const bulk_upload = uuidv4()
      const fileObj = {
        'session_key': sessionId,
        'baseFolderPath' : 'business.folders.bulk_upload',
        'folder' : 'bulk_upload',
        'swhandle': currTxn.swhandle,
        'file_upload' : true,
        'fileObject' : f,
        'name' :f.name,
        'foldertype': 'business',
        'params' : {}
      };

      const obj = JSON.parse(JSON.stringify(currTxn));
      // {
      obj['baseFolderPath'] = 'business.folders.bulk_upload',
      obj['params'] = [
        {
          '_path': 'FATROW'
        },
        {
          'bulk_upload_id': bulk_upload
        },
        {
          '_path': 'business.folders.bulk_upload'
        },
        {
          'id': bulk_upload,
          'name': f.name,
          'processed': false,
          'files_id': [
            `${files_id}`
          ]
        },
        {
          '_path': 'business.folders.files'
        },
        {
          'id': `${files_id}`,
          'filepath': `/home/wfm/wfm-servers/part1/file_uploads/${currTxn.swhandle}/bulk_upload/${f.name}`,
          'original_filename': f.name,
          'md5sum': generateMD5Hash(f),
        },
        {
          'dataStart': 1,
          'dataEnd': 2,
          'pathIndexes': [
            2,
            4
          ],
          [`${bulk_upload}`]: '3__C',
          [`${files_id}`]: '5__C',
          [`${bulk_upload}__${files_id}`]: '3__C'
        }
      ],
      obj['session_key'] = sessionId,
      data.push(fileObj)
      data.push(obj);
    });
    return data
  }

  async function processFile(txn) {
    try {
      const serverPort = store.getters['serverPortGetter']
      const res = await fileUploads(`http://${window.location.hostname}:${serverPort}/applyFiles`, txn);
      return res;
    }
    catch (err) {
      throw err
    }
  }

  async function runTxn(files, txnToUse) {
    try {
      const fileListArray = Array.from(files);
      const txns = bulk_upload_create_data(fileListArray, txnToUse);
      const upload = await processFile(txns[0]);
      if (upload.body.output.type === 'success') {
        return txns[1];
      } else {
        return 'error';
      }
    } catch (err) {
      toast.error(err)
    }
  }

  return { runTxn }
}
export default bulkUpload;
