<template>
  <div
    v-if="checkShowCondition(arg)"
    class="events-div"
    :class="backGroundColor"
  >
    <div class="d-flex justify-space-between">
      <div>
        <input
          v-if="multiselect"
          class="multi-select-check"
          type="checkbox"
          name="selection-box"
          @click="handleInputSelect(arg)"
        >
      </div>
      <div v-if="allowDeleteTxn && !pendingTxn">
        <v-icon
          name="bi-trash"
          class="text-danger"
          data-test-id="leave-delete-btn"
          scale="1.5"
          @click.stop="deleteLeave(arg)"
        />
      </div>
    </div>

    <div class="row">
      <h4 name="leave-type-code">
        {{ leaveTypeCode }}
      </h4>
    </div>
    <div class="row">
      <div class="col">
        <div
          class="status-dot"
          :class="getLeaveStatusIndicator(arg)"
        />
        <div class="d-flex justify-space-between">
          <div>
            <span name="leave-status-code">{{ leaveStatus }}</span> {{ txnDoneBy.name?' By':'' }}
            <div> {{ txnDoneBy.name }}</div>
          </div>
          <!-- <div>
            {{ halfDayStatus }}
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div
      v-if="!deletedEvent"
      class="d-flex justify-space-between"
      :class="backgroundColorAlt"
    >
      <h6 name="leave-type-code">
        {{ leaveTypeCode }}
      </h6>
      <div>
        <span name="leave-status-code">{{ leaveStatus }}</span>{{ txnDoneBy.name?'By':'' }}
        <div> {{ txnDoneBy.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, onMounted, ref} from 'vue'
import getFolders from '../../../composables/getFolders'
import postData from '../../../composables/postData'
import { useStore } from 'vuex'
import makeFilter from '../../../composables/makeFilter'
import getFatRow from '../../../composables/fatRowGenerator'
export default {
  name:'EventsCellRenderer',
  props:{
    arg:{
      type:Object,
      default:null
    },
    multiselect:{
      type:Boolean,
      default:false
    },
    userData:{
      type:Object,
      default:null
    },
    filterParams:{
      type:Object,
      default:null
    },
    pendingTxn:{
      type:Boolean,
      default:false
    }
  },
  emits:['multi-select', 'delete-event'],
  setup(props, context) {
    const {getEmployeeData} = postData()
    const { getFolderByName} = getFolders();
    const store = useStore()
    const txnDoneBy = ref({})
    const halfDayStatus = computed(() => {
      const extendedProps = props.arg.event._def.extendedProps
      const half_day = extendedProps.halfDay
      const halfDurationCode = extendedProps?.half_day_duration?.[0]?.code
      if (half_day && halfDurationCode == 'first_half') {
        return 'First Half'
      }
      else if (half_day && halfDurationCode == 'second_half') {
        return 'Second Half'
      }
      else {
        return  'Full Day'
      }

    })
    const leaveStatus = computed(() => {
      return  props.arg.event._def.extendedProps.leave_status?.[0]?.code

    })
    const backGroundColor = computed(() => {
      let ret = {}
      const extendedProps = props.arg.event._def.extendedProps
      const halfDurationCode = extendedProps.half_day_duration?.[0]?.code
      const half_day = extendedProps.halfDay
      if (half_day && halfDurationCode == 'first_half') {
        ret = 'first-half-container'
      }
      else if (half_day && halfDurationCode == 'second_half') {
        ret = 'second-half-container'
      }
      else {
        ret = 'fullday-container'
      }
      return ret
    })
    const leaveTypeCode = computed(() => {
      const extendedProps = props.arg.event._def.extendedProps
      if (extendedProps.formData?.leave_types_id) {
        return extendedProps.formData.leave_types_id[0].code
      }
      else {
        return extendedProps.leave_types_id?.[0]?.code
      }
    })
    const backgroundColorAlt = computed(() => {
      let ret = {}
      const extendedProps = props.arg.event._def.extendedProps
      const leaveStatus = extendedProps.leave_status?.[0]?.code
      if (leaveStatus == 'rejected') {
        ret = 'bg-danger p-1 text-light'
      }
      else if (leaveStatus == 'cancelled') {
        ret = 'bg-warning p-1 text-dark'
      }
      return ret
    })

    const bSettings = computed(() => {
      const bSettingString = store.getters['bSettings']
      return bSettingString
    })
    const allowDeleteTxn = computed(() => {
      //TODO-need to refine this condition based on permission
      return true
    })
    const deletedEvent = computed(() => {
      const deletedStatus = props.arg.event._def.extendedProps?.deleted
      return deletedStatus == true ? true : false

    })
    function checkShowCondition(arg) {
      const leave_status_code = arg.event._def.extendedProps.leave_status?.[0]?.code
      const deletedStatus = arg.event._def.extendedProps?.deleted
      const leaveStatusAllowed = ['rejected', 'cancelled'].includes(leave_status_code)
      // if (props.filterParams != null) {
      //   const filterCode = props.filterParams?.filterInput?.[0]?.filterInput?.[0]?.value
      //   if (filterCode == leave_status_code && !deletedStatus) {
      //     return true
      //   }
      //   else {
      //     return false
      //   }
      // }
      // else {
      return !deletedStatus && !leaveStatusAllowed ? true : false
      //}
    }
    //indicator for leave status
    function getLeaveStatusIndicator(arg) {
      let ret = null
      const extendedProps = arg.event._def.extendedProps
      const status = extendedProps?.leave_status?.[0]?.code || extendedProps?.formData?.leave_status?.[0]?.code
      if (status == 'created') {
        ret =  'bg-light'
      }
      else if (status == 'final_approved') {
        ret = 'bg-success'
      }
      else if (status == 'rejected') {
        ret = 'bg-danger'
      }
      else if (status == 'cancelled') {
        ret = 'bg-warning'
      }
      else if (status == 'intermediate_approved') {
        ret = 'bg-primary'
      }
      return ret
    }
    function deleteLeave(arg) {
      context.emit('delete-event', arg)
    }
    function handleInputSelect(arg) {
      context.emit('multi-select', arg)
    }
    async function getTxnState(arg) {
      const txnStateToShow = arg.event._def.extendedProps.txnStateToShow
      if (txnStateToShow != null) {
        const executedById = txnStateToShow.executed_by
        const txnNameAry = txnStateToShow.txn_name
        const txnName = txnNameAry.split('_')[2]
        let txnNameToUse = ''
        if (txnName == 'reject') {
          txnNameToUse = 'Rejected'
        }
        else if (txnName == 'cancel') {
          txnNameToUse = 'Cancelled'
        }
        else if (txnName == 'approve') {
          txnNameToUse = 'Approved'
        }
        const folderObjectFromName = (fname) => getFolderByName('employees', bSettings.value);
        const empFolder = folderObjectFromName()
        const searchPath = 'employees.id'
        const searchTerm = executedById
        const { generateFilterParams } = makeFilter(
          searchTerm,
          empFolder,
          null,
          searchPath
        )
        const filterParams = generateFilterParams()
        const txnParams = {}
        txnParams.refRows = true
        txnParams.filters = filterParams
        const empListData = await getEmployeeData(empFolder, txnParams)
        const { fatRowGenerator } = getFatRow();
        const empData = fatRowGenerator(empListData);
        txnDoneBy.value.name = empData?.[0]?.persons_id?.[0]?.first_name.eng
        txnDoneBy.value.txnName = txnNameToUse

      }
    }

    onMounted(() => {
      //to be fixed and uncommented
      //getTxnState(props.arg)

    })
    return {
      checkShowCondition,
      allowDeleteTxn,
      getLeaveStatusIndicator,
      deleteLeave,
      handleInputSelect,
      getTxnState,
      txnDoneBy,
      leaveTypeCode,
      halfDayStatus,
      backGroundColor,
      backgroundColorAlt,
      leaveStatus,
      deletedEvent

    }
  }
}
</script>

<style scoped>
.status-dot{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.3s;
}
.duration-div{
  height:20px;
  width:30px;
}
.fc.fc-daygrid-day-frame {
    height: 125px;
 }
.days-div{
  width:500px;
}
.each-date{
  /* width: 30px; */
  height: 30px;
  border:1px solid green;
  border-radius: 4px;
  padding:3px;
}
.multi-select-check{
     width: 25px;
  height: 25px;
  }
.bg-secondary{
  background: #85929e !important;
}
.first_half_style{
      background: linear-gradient(90deg, rgb(144,215,245) 50%, #e3eef6 50%);

}
.full_day_style{
      background:  rgb(144,215,245) ;

}
.second_half_style{
      background: linear-gradient(90deg, #e3eef6 50%, rgb(144,215,245) 50%);

}
.deselect_date_style{
      background: rgb(69, 79, 83) ;
      color:grey;
      cursor: not-allowed;

}
.first-half-container{
      background: linear-gradient(90deg, rgb(144,215,245) 50%, #e3eef6 50%);
            padding: 10px;

}
.second-half-container{
      background: linear-gradient(90deg, #e3eef6 50%, rgb(144,215,245) 50%);
      padding: 10px;
}
.fullday-container{
      background: rgb(144,215,245);
            padding: 10px;

}
</style>
