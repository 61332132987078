const ls = window.sessionStorage;

function noop() {}

// Set a key-value pair in sessionStorage
function set(key, val) {
  ls.setItem(key, val);
}

// Get a value by key from sessionStorage
function get(key) {
  let val = ls.getItem(key);
  try {
    val = JSON.parse(val);
  }
  catch (e) {
    noop();
  }
  return val;
}

// Remove a key-value pair from sessionStorage
function remove(key) {
  ls.removeItem(key);
}

// Clear all key-value pairs from sessionStorage
function purge() {
  ls.clear();
}

// Set a session key (UUID) in sessionStorage
function setSessionKey(uuid) {
  set('sessionKey', uuid);
}

// Get the session key (UUID) from sessionStorage
function getSessionKey() {
  return get('sessionKey');
}

// Clear session data on logout
function clearSession() {
  remove('sessionKey');
  purge();  // Optionally, clear all other stored data
}

export {
  set,
  get,
  remove,
  purge,
  setSessionKey,
  getSessionKey,
  clearSession
}
