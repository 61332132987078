//provide and inject related keys
export const keys = {
  formData: Symbol('formData'), //form data
  currentTask: Symbol('currentTask'), //current task name
  folderFields: Symbol('folderFields'), //fields of a folder with or without values
  missingFields: Symbol('missingFields'), //fields which are missing on form submission
  listExpanded: Symbol('listExpanded'), //flag to decide if the fields are expanded or not
  mandatoryFilter: Symbol('mandatoryFilter'), //mandatory fields in a form flag
  leavesQuota: Symbol('leavesQuota'), //leave quota (TODO:-decide to keep or not)
  editNotAllowed: Symbol('editNotAllowed'), //read only form flags
  mandatoryFields:Symbol('mandatoryFields'),
  invalidFields:Symbol('invalidFields'),
  clearFormState:Symbol('clearFormState')
};
