<template>
  <WfmForm>
    <template #form-body>
      <div class="content">
        <span class="mt-2">Recover password</span>

        <div class="input-field mt-2">
          <input
            v-model="recoverySwhandle"
            type="text"
            autofocus
            placeholder="swhandle"
            @change="updateRecoverySwhandle"
          />
        </div>
        <div class="input-field mt-3">
          <input
            v-model="recoveryEmpcode"
            type="text"
            placeholder="UserName"
            @input="updateRecoveryEmpcode"
          />
        </div>
        <div
          v-if="recoveryTextSend"
          class="d-flex mt-4"
        >
          <div class="my-auto">
            Enter the otp
          </div>
          <v-otp-input
            v-model="recoveryOtpValue"
            :error="recoverErrorState && recoveryOtpValue.length >= 1"
            focus-all
            :length="5"
            @update:model-value="recoverHandleChange"
          />
        </div>
        <div
          class="mt-5 text-decoration-underline otp-button"
          @click="getRecoverOtp"
        >
          {{ recoverText }}
          <v-icon
            v-if="!recoveryTextSending && !recoveryTextSend"
            class="my-auto ms-3 mb-0"
            name="ri-mail-send-fill"
            scale="1.2"
            fill="#2e688dff"
            animation="ring"
            hover
          />
          <v-icon
            v-if="recoveryTextSending && !recoveryTextSend"
            class="my-auto ms-3 mb-0"
            name="fa-spinner"
            scale="1.2"
            fill="#2e688dff"
            animation="spin"
          />
          <v-icon
            v-if="recoveryTextSend"
            class="my-auto ms-3 mb-0"
            name="ri-mail-send-fill"
            scale="1.2"
            fill="#2e688dff"
          />
        </div>

        <div
          v-if="errorMsg"
          class="link text-danger"
        >
          <span>{{ errorMsg }}</span>
        </div>
      </div>
    </template>
  </WfmForm>

  <div class="action">
    <WfmButton
      class="btn-outline-success btn"
      :disabled="recoverErrorState"
    >
      Submit
    </WfmButton>
  </div>
</template>

<script>
import { ref } from 'vue'
import WfmButton from '../../common/wfm-button.vue'
import logo from '../../assets/images/logo.png'
import WfmForm from '../../common/wfm-form.vue'

export default {
  name: 'RecoverPassword',
  components: {
    WfmButton,
    WfmForm,
  },
  setup() {
    //variables
    const swHandle = ref('')

    const empCode = ref('')
    const password = ref('')
    const errorMsg = ref('')

    const recoveryOtpValue = ref('')
    const otp = ref(null)
    const recoverErrorState = ref(true)
    const recoveryTextSend = ref(false)
    const recoveryTextSending = ref(false)
    const recoverText = ref('Get Otp')
    const recoverySwhandle = ref(null)
    const recoveryEmpcode = ref(null)
    //Composable

    function recoverHandleChange() {
      if (recoveryOtpValue.value == otp.value) {
        recoverErrorState.value = false
      }
      else {
        recoverErrorState.value = true
      }
    }
    function getRecoverOtp() {
      errorMsg.value = ''
      if (recoverySwhandle.value && recoveryEmpcode.value) {
        recoveryTextSending.value = true
        recoveryTextSend.value = false
        recoverText.value = 'Sending Otp'
        setTimeout(() => {
          recoveryTextSending.value = false
          recoveryTextSend.value = true
          recoverText.value = 'Resend otp'
          otp.value = 12345
          //make the sumit active
        }, 4000);
      }
      else {
        errorMsg.value = `please enter ${recoverySwhandle.value ? '' : 'swhandle'}, ${recoveryEmpcode.value ? '' : 'username'}`
      }

    }
    return {
      swHandle,
      empCode,
      password,
      errorMsg,
      logo,
      otp,
      getRecoverOtp,
      recoveryTextSend,
      recoveryTextSending,
      recoverText,
      recoverHandleChange,
      recoveryOtpValue,
      recoverErrorState,
      recoveryEmpcode,
      recoverySwhandle,
    }
  }
}
</script>

<style scoped>
input {
  width: 20rem;
  height: 50px;
  margin-top: 20px;
  border-radius: 5px;
  text-align: center;
}

.button {
  width: 10rem;
  margin-left: 10px;
  margin-top: 30px;
}

label {
  color: green;
}

.img-logo {
  width: 300px;
}


.content {
  padding: 35px;
  height: 450px;
  width: 100%;
  text-align: center;
}

.input-field {
  padding: 12px 5px;
}


.input-field input {
  font-size: 16px;
  display: block;
  /* font-family: 'Rubik', sans-serif; */
  width: 100%;
  padding: 10px 1px;
  border: 0;
  border-bottom: 1px solid #747474;
  outline: none;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.input-field input::-webkit-input-placeholder {
  text-transform: uppercase;
}

.input-field input::-moz-placeholder {
  text-transform: uppercase;
}

.input-field input:-ms-input-placeholder {
  text-transform: uppercase;
}

.input-field input::-ms-input-placeholder {
  text-transform: uppercase;
}

.input-field input::placeholder {
  text-transform: uppercase;
}

.input-field input:focus {
  border-color: #222;
}

.link {
  text-decoration: none;
  color: #747474;
  letter-spacing: 0.2px;
  display: inline-block;
  margin-top: 20px;
  font-style: italic;
  font-size: 15px;
}

.action {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}


.action button {
  width: 100%;
  border: none;
  padding: 18px;
  /* font-family: 'Rubik', sans-serif; */
  cursor: pointer;
  text-transform: uppercase;
  color: #777;
  letter-spacing: 0.2px;
  outline: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #004084;
  color: #fff;
}

.action :nth-child(1) {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}


.action :nth-child(2) {
  background: green;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.action button:hover {
  background: #3c4d6d;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
