<template>
  <div
    v-if="!showInTable && !readOnly && showField"
    :class="{'col-sm-3':!isMultipleDocp && !showFull,
             'p-2':showFull}"
  >
    <div class="input-group my-2">
      <span>
        {{ fileOptions.label[currentLanguage] }}
        <span
          v-if="isMandatory"
          class="ms-2"
          style="color: red"
        >*</span>
        <div
          :class="{ 'd-block': isMissing }"
          class="invalid-feedback p-0 text-xs"
        >
          {{ errorText }}
        </div>
      </span>
    </div>
    <div
      class="upload-box row"
      :class="{
        'mandatory-field': isMandatory && isMissing,
      }"
      @dragover.prevent="handleDragOver"
      @dragenter.prevent="handleDragEnter"
      @dragleave.prevent="handleDragLeave"
      @drop.prevent="handleDrop"
    >
      <div
        :class="{
          'col my-auto':!isMultipleDocp,
          'col-sm-1 my-auto':isMultipleDocp
        }"
      >
        <!-- selection part -->
        <div :data-test-id="dataTestId+'/select_btn'">
          <input
            :id="dataTestId"
            type="file"
            accept="image/*, .pdf, .docs , .xlsx, .xls, .csv ,.sql"
            class="d-none"
            :multiple="isMultipleDocp?true:false"
            :disabled="isEditNotAllowed || isReadOnly"
            @change="showPreview"
          />
          <label
            :for="dataTestId"
            tabindex="0"
            class="label-upload text-sm"
            @keydown.enter="handleEnter()"
          >
            <div>
              <v-icon
                class="user"
                fill="#2f698e"
                name="md-insertdrivefile"
                scale="1.3"
              />
            </div>
            <div>Select Files</div>
            <p><small><em>(image .pdf .docs .xlsx .xls .csv .sql)</em></small></p>

          </label>
        </div>
      </div>
      <!-- preview part -->
      <div
        :class="{
          'col my-auto mx-auto':isMultipleDocp,
          'col my-auto':!isMultipleDocp
        }"
      >
        <div v-if="preview_list && preview_list?.length>0">
          Previews
        </div>
        <span
          v-else
          class="col"
        > No Previews
        </span>
        <div
          v-if="preview_list"
          class="row"
        >
          <div
            v-for="(item, index) in preview_list"
            :key="index"
            class="mt-1"
          >
            <div class="d-flex justify-space-between py-1">
              <v-icon
                v-if="!isReadOnly && !isEditNotAllowed"
                id="remove-btn"
                class="remove-btn text-danger"
                name="bi-trash"
                scale="1.2"
                @click="removeFile(index)"
              />
              <v-icon
                v-if="!item.encoding"
                :style="{cursor:'pointer'}"
                name="bi-download"
                scale="1.2"
                @click="downloadFile(item,filePathToServeFrom)"
              />
            </div>
            <div>
              <object
                v-if="item"
                :key="index"
                height="100"
                width="100"
                :data="item.encoding ? item.encoding : filePathToServeFrom"
              />
              <v-tooltip
                class="mt-2"
                activator="parent"
                open-delay="600"
                location="bottom"
              >
                <div v-if="item">
                  <object
                    :key="index"
                    height="700"
                    width="700"
                    :data="item.encoding ? item.encoding : filePathToServeFrom"
                  />
                  <div class="d-flex">
                    <div>
                      Name : {{ item?.name || item?.original_filename }}
                    </div>
                    <div class="ms-3">
                      Size : {{ Math.ceil(item?.size / 1024) }}Kb
                    </div>
                  </div>
                </div>
              </v-tooltip>
            </div>
          </div>
        </div>
      </div>
      <!-- upload part -->
      <!-- <div
        v-if="newFilesAdded.length != 0"
        class="label-upload"
        :class="{'col-sm-1 my-auto':isMultipleDocp,
                 'col my-auto':!isMultipleDocp}"
        :data-test-id="dataTestId+'/upload_btn'"
        @click="upload"
      >
        <div>
          <v-icon
            class="user"
            fill="#2f698e"
            name="fa-upload"
            scale="1.2"
          />
        </div>
        <span>
          Upload
        </span>
      </div> -->
    <!-- <WfmProgressLoader /> -->
    </div>
  </div>
  <!-- to show in grid -->
  <div v-else-if="readOnly">
    <!-- in the list show image -->
    <div
      v-if="filePathToServeFrom"
      class="profile-pic-served"
      :style="{ 'background-image': `url(${filePathToServeFrom}) !important` }"
    />
    <div
      v-else
      class="profile-pic-default mx-auto"
    >
      <v-icon
        name="bi-person-circle"
        :fill="`var(--main-color)`"
        scale="2.2"
      />
    </div>
  </div>
  <!-- to show in detail -->
  <div
    v-else-if="showInTable"
  >
    <div
      v-for="(item, index) in preview_list"
      :key="index"
    >
      <div
        class="d-flex justify-content-between"
        style="text-align: left"
      >
        <span :style="{fontSize:'1rem',textOverflow:'hidden',marginLeft:'2px'}">{{ item?.original_filename || item?.name }}</span>
        <div class="d-flex">
          <!-- <v-icon
            id="remove-btn"
            :style="{cursor:'pointer'}"
            class="remove-btn text-danger my-1 me-2"
            name="bi-x-circle"
            scale="1.2"
            @click="removeFile(index)"
          /> -->
          <div class="me-2 ms-2">
            <div class="text-primary">
              preview
            </div>
            <v-tooltip
              class="mt-2"
              activator="parent"
              open-delay="500"
              close-delay="300"
              location="bottom"
            >
              <object
                v-if="item"
                :key="index"
                height="700"
                width="700"
                :data="item.encoding ? item.encoding : filePathToServeFrom"
              />
              <div class="d-flex">
                <div>
                  Name : {{ item?.name || item?.original_filename }}
                </div>
                <div class="ms-3">
                  Size : {{ Math.ceil(item?.size / 1024) }}Kb
                </div>
              </div>
            </v-tooltip>
          </div>
          <div class="me-2 ms-2 my-auto">
            <v-icon
              v-if="!item.encoding"
              :style="{cursor:'pointer'}"
              name="bi-download"
              scale="1.2"
              @click="downloadFile(item,filePathToServeFrom)"
            />
          </div>
          <!-- <div
            v-if="item?.docpNotSet"
            class="my-auto d-flex"
            :style="{cursor:'pointer'}"
          >
            <div class="me-1 ms-4">
              <v-icon
                id="remove-btn"
                :style="{cursor:'pointer'}"
                class="upload-detail-btn text-primary my-1 me-2"
                name="bi-upload"
                scale="1"
                @click="upload()"
              />
              <v-tooltip
                class="mt-2"
                activator="parent"
                location="bottom"
              >
                upload this file
              </v-tooltip>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div
      v-if="preview_list?.length==0 || preview_list==null || preview_list==undefined"
      class="d-flex justify-space-between"
    >
      <label>
        <input
          id="my-file"
          type="file"
          accept="image/*, .pdf, .docs , .xlsx, .xls, .csv ,.sql"
          multiple
          hidden
          :disabled="isEditNotAllowed || isReadOnly"
          @change="showPreview"
        />
        <div
          class="upload-button"
          :style="{cursor:'pointer'}"
          tabindex="0"
          @keydown.enter="handleEnter()"
        >
          <v-icon
            class="user"
            fill="#2f698e"
            name="md-insertdrivefile"
            scale="1"
          />
          <span>Select files
            <small><em>(image .pdf .docs .xlsx .xls .csv .sql)</em></small>
          </span>

        </div>
      </label>
    </div>
  </div>
</template>

<script>
import { ref, reactive, watch, computed, toRef} from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
// import WfmProgressLoader from "../loader/wfm-progress-loader.vue"

import { toast } from 'vue3-toastify'
import { v4 as uuidv4 } from 'uuid'
import { fileUploads, sendMessage } from '../../services/websocket'
// import url from "url";
import processExpr from '../../composables/processExprs';
import getFolders from '../../composables/getFolders'
import CryptoJS from 'crypto-js'; // Import CryptoJS library
import makeTxn from '../../composables/makeTxn'
import controlUtility from '../../composables/controlUtility'
// import WfmModal from '../../common/wfm-modal.vue'
export default {
  name: 'WfmDocpFile',
  // components: { WfmModal },
  props: {
    params: {
      type: Object,
      default: null
    }
  },
  emits: ['input-change', 'mandatory-check'],
  setup(props, context) {
    //variable
    const store = useStore()
    const i8vue = useI18n()
    const currentLanguage = i8vue.locale.value
    const value = ref(null)
    const parsedImageWeGet = ref([])
    const base64Encoding = ref()
    const filesToUpload = ref()
    const uploadNotification = ref()
    const newFilesAdded = reactive([])
    const viewImagePreview = ref(false)
    const imageToPreview = ref()
    // const toUpload = store.getters["folderModule/filesToUpload"]
    const toUpload = reactive([])
    const mandatoryFields = props.params.mandatoryFields
    const {mandatoryCheck} = controlUtility(null, mandatoryFields)
    const showInTable = props.params.showInTable ? true : false
    const readOnly = props.params.readOnly ? true : false
    const fileOptions = props.params.fieldOptions
    const fileParams = toRef(props.params.data)
    const fieldPath = fileOptions.path
    const showFull = fileOptions?.showFull
    const folderName = fileOptions?.path?.split('.')[0]
    const idOfTheFolder = fileParams.value?.[folderName]?.[0]?.id ? fileParams.value?.[folderName]?.[0]?.id : fileParams.value?.id
    const propertyName = fileOptions.name
    const detailType = showInTable ? true : false;
    const isMultipleDocp = ref(fileOptions.is_multiple);
    const imageWeGet = fileParams.value?.[propertyName] ? fileParams.value?.[propertyName] : fileParams.value?.[folderName]?.[0]?.[propertyName] || null
    const preview_list = ref(imageWeGet)
    const sessionId = store.getters['sessionIdGetter'];
    const serverPort = store.getters['serverPortGetter']
    const baseUrl = ref(`http://${window.location.hostname}:${serverPort}/file_uploads`)
    const fileDocpInDetail = fileOptions.docpInDetail ? true : false
    const detailTableName = fileDocpInDetail ? fileOptions.detailTableName : null
    const currentFileDocp = fileParams.value?.[propertyName] || fileParams.value?.[folderName]?.[0]?.[propertyName] || fileParams.value?.[folderName]?.[0]?.[detailTableName]?.[0]?.[propertyName] || fileParams.value?.[folderName]?.[0]?.[detailTableName]?.[0][propertyName]?.[0]
    const mandatoryStatus = props.params.mandatoryStatus
    const isEditNotAllowed = props.params.isEditNotAllowed
    const missingFields = props.params.missingFields
    const valueEntered = ref(false);
    const currentFolder = ref(props.params.currentFolder)
    // const folderFields = ref(props.folderFields || props.params.fieldParams.folderFields)

    const { processReadOnly, processVisibleExpr, processMandatoryExpr} = processExpr(currentFolder.value, fileParams.value, showInTable)

    //watcher
    watch(() => preview_list.value, (newValue) => {
      try {
        //watch for change in preview_list and store the handle the changes to reflect in formData
        handleChange(newValue)
      } catch (error) {
        console.error('Error in watcher:', error);
      }
    }
    )

    const filePathToServeFrom = computed(() => {
      let url = null;
      if (imageWeGet.length > 0) {
        const filePath = imageWeGet?.[0].filepath
        if (filePath) {
          const index = filePath?.indexOf('/file_uploads');
          const subPathToUse = filePath?.substring(index + '/file_uploads'.length);
          url = baseUrl.value + subPathToUse
        }
      }
      return url
    })
    const swhandle = computed(() => {
      return store.getters['userModule/swHandle']
    })
    const isReadOnly = computed(() => {
      const retVal = processReadOnly(fileOptions.readonly_expr);

      return retVal;
    });

    const bSettings = store.getters['bSettings'];
    const { getAllFoldersList, getCurrentFolder } = getFolders()
    const folderList = getAllFoldersList(bSettings);
    const filesFolder = getCurrentFolder('files', folderList);
    const fileCreateTxn = computed(() => {
      return filesFolder.txns.txn_files_create
    })
    //hold these for now
    const isVisible = computed(() => {
      const retVal = processVisibleExpr(fileOptions.visible_expr);
      return retVal;
    });
    const isMandatory = computed(() => {
      const retVal = processMandatoryExpr(fileOptions.mandatory_expr) && isVisible.value;
      if (!showInTable) {
        mandatoryCheck({
          propertyName: dataTestId.value,
          value: retVal,
        })
      }
      return retVal;
    });
    const showField = computed(() => {
      if (!mandatoryStatus?.value) {
        return true
      }
      else {
        if (isMandatory.value) {
          return true
        }
        else {
          return false
        }
      }
    })
    const isMissing = computed(() => {
      if (missingFields?.value?.includes(dataTestId.value)) {
        if (valueEntered.value) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    });
    const errorText = computed(() => {
      if (isMissing.value) {
        return 'Mandatory field';
      } else {
        return '';
      }
    });


    const dataTestId = computed(() => {
      if (showInTable) {
        const rowIndex = props.params.node.rowIndex
        const tableName = props.params.tableName
        return `${currentFolder.value.name}/${tableName}/${propertyName}/${rowIndex}`
      } else {
        if (currentFolder.value !== null) {
          return `${currentFolder.value.name}/${propertyName}`
        }
        else {
          return `${fileOptions.type}/${propertyName}`
        }
      }
    })

    //methods
    const isDragOver = ref(false);

    function handleDragEnter(event) {
      event.preventDefault();
      event.stopPropagation();
      isDragOver.value = true;
    }

    function handleDragLeave(event) {
      event.preventDefault();
      event.stopPropagation();
      isDragOver.value = false;
    }

    function handleDragOver(event) {
      event.preventDefault();
      event.stopPropagation();
      event.dataTransfer.dropEffect = 'copy';
      isDragOver.value = true;
    }

    function handleDrop(event) {
      event.preventDefault();
      event.stopPropagation();
      event.currentTarget.classList.remove('dragover');

      showPreview(event)
      isDragOver.value = false;
    }

    function downloadFile(file, link) {
      const tempLink = document.createElement('a')
      tempLink.href = link
      tempLink.setAttribute('download', file.original_filename)
      tempLink.setAttribute('target', '_blank')
      document.body.appendChild(tempLink)
      tempLink.click()
    }
    function closeModal() {
      viewImagePreview.value = false
    }
    async function showPreview(event) {
      try {
        //process image already in the list
        var input = event.target
        if (input.files) {
          let filesToShow = []
          valueEntered.value = true;
          for (let i = 0; i < input.files.length; i++) {
            const fileObject = input.files[i]
            const fileExtension = fileObject.name.split('.').pop() // Get the file extension
            const fileId = uuidv4()
            const originalName = fileObject.name
            const newfileName = fileId + '.' + fileExtension
            //append the file in the FormData to use for upload later on
            const reader = new FileReader()
            reader.onload = (e) => {
              base64Encoding.value = e.target.result
              newFilesAdded.push({
                name: fileObject.name,
                id: fileId,
                folderName: currentFolder.value.name,
                fieldName: propertyName,
                encoding: base64Encoding.value,
                type: fileObject.type,
                size: fileObject.size,
                extension: fileExtension
              })
              toUpload.push({fileDetail:{
                'session_key': sessionId,
                'baseFolderPath' : 'business.folders.files',
                'folder' :  currentFolder.value.name,
                'foldertype': 'business',
                'name' : newfileName,
                'swhandle': swhandle.value,
                'file_upload' : true,
                'fileObject' : fileObject,
                'params' : {}
              },

              fileCreateTxnParams:{
                id:fileId,
                filepath: `/home/wfm/wfm-servers/part1/file_uploads/${swhandle.value}/${currentFolder.value.name}/${newfileName}`,
                original_filename: originalName,
                md5sum: generateMD5Hash(fileObject),
              }}
              )

              //when all files read get the list in imagetoupload
              if (newFilesAdded.length === input.files.length) {
                filesToUpload.value = newFilesAdded
              }
              //to combine the new files with existing files list if there is any
              if (Object.keys(parsedImageWeGet.value).length !== 0) {
                if (Array.isArray(parsedImageWeGet.value)) {
                  parsedImageWeGet.value = parsedImageWeGet.value.map((each) => {
                    return typeof each === 'string' ? JSON.parse(each) : each
                  })
                  filesToShow = parsedImageWeGet.value.concat(filesToUpload.value)
                } else {
                  parsedImageWeGet.value = [parsedImageWeGet.value]
                  filesToShow = parsedImageWeGet.value.concat(filesToUpload.value)
                }
              } else {
                filesToShow = filesToUpload.value
              }
              //filesToShow assign to our reactive proshowPreviewperty to reflect changes in the ui
              preview_list.value = filesToShow
            }

            // Start reading the current file as a data URL (base64)
            reader.readAsDataURL(fileObject)
          }
        }
      } catch (error) {
        throw error

      }

    }

    async function handleChange(item, taskType) {
      try {
        const valueToSend = [];
        for (var i = 0; i < item.length; i++) {
          const currentDocpId = currentFileDocp?.[0]?.id
          if (currentFileDocp?.length === 0 || currentFileDocp === undefined || currentFileDocp === null) {
            item[i].docpNotSet = true;
          } else {
            item[i].docpNotSet = false;
            item[i].oldDocpId = currentDocpId;
          }
          const newValue = item[i]?.persons_id?.[0].first_name.eng ||
          item[i]?.name?.eng ||
          item[i]?.name ||
          item[i]?.first_name?.eng ||
          item[i]?.name?.eng ||
          item[i]?.code;
          if (newValue) {
            if (!isReadOnly.value) {
              value.value = newValue;
            }
          }
          item[i].docpType = true;
          item[i].detailType = detailType;
          item[i].isMultipleDocp = false;
          item[i].toBeDeleted = taskType === 'delete' ? true : false
          valueToSend.push(item[i]);


          //if the data is changed in ag grid
          if (showInTable) {
            fileParams.value[propertyName] = isMultipleDocp.value ? item[i] : [item[i]];
            props.params.cellValueChanged(fileParams.value);
          }
          //else emit event to control
          else {
            context.emit('input-change', {
              path: fieldPath,
              value: valueToSend,
              folderId: idOfTheFolder,
              mandatory: fileOptions.mandatory_expr,
              type: 'docpicker',
            });
          }
        }
        if (taskType != 'delete') {
          await upload()

        }
      } catch (error) {
        throw error
      }

    }
    async function upload() {
      //start a notification for uploading process
      uploadNotification.value = toast.loading('uploading...', {
        position: toast.POSITION.TOP_CENTER
      })
      try {
        for (var i = 0; i < toUpload.length; i++) {
          // const address = url.format({slashes: true, protocol:"http", hostname:"localhost", port:8190, pathname: "applyTxn"});
          const address = `http://${window.location.hostname}:${serverPort}/applyFiles`
          const res = await fileUploads(address, toUpload[i].fileDetail);
          if (res.body.output.type === 'success') {

            const params = {
              normalFieldList:toUpload[i].fileCreateTxnParams,
              folderDetails:filesFolder,
              currentTaskName:'create',
              txn:fileCreateTxn.value
            }
            const { generateTxn } = makeTxn(params)
            const txnToRun = generateTxn()
            txnToRun.session_key = sessionId;
            const res = await sendMessage(fileCreateTxn.value)
            console.log(res)
            //update the notification to success if all good
            toast.update(uploadNotification.value, {
              render: 'files uploaded',
              autoClose: 1000,
              closeButton: true,
              type: 'success',
              isLoading: false
            })


          } else {
            console.error('File upload failed')
            toast.update(uploadNotification.value, {
              render: 'error',
              autoClose: 1000,
              closeButton: true,
              type: 'error',
              isLoading: false
            })
          }
        }
      }
      catch (err) {
        // Handle any errors that occur during the request and update the notification
        console.error('Error:', err)
        toast.update(uploadNotification.value, {
          render: 'files upload failed',
          autoClose: 1000,
          closeButton: true,
          type: 'error',
          isLoading: false
        })
      }
    }
    function generateMD5Hash(text) {
      return CryptoJS.MD5(text).toString();
    }
    function removeFile(index) {
      //gotta remove the index from the 'previewList' .which is reactive ,so have to do this assignment before
      const updatedPreviewList = preview_list.value
      handleChange(updatedPreviewList, 'delete')

      updatedPreviewList.splice(index, 1)
      preview_list.value = updatedPreviewList
    }
    function handleEnter() {
      event.target.click()
    }

    return {
      value,
      fileOptions,
      showInTable,
      preview_list,
      showPreview,
      removeFile,
      readOnly,
      currentLanguage,
      upload,
      newFilesAdded,
      isReadOnly,
      filePathToServeFrom,
      isMultipleDocp,
      isEditNotAllowed,
      isMandatory,
      showField,
      errorText,
      isMissing,
      dataTestId,
      handleDragLeave,
      handleDragOver,
      handleDrop,
      handleEnter,
      handleDragEnter,
      // openImageView,
      imageToPreview,
      viewImagePreview,
      closeModal,
      downloadFile,
      showFull
    }
  }
}
</script>
<style lang="css">
.upload-box {
  padding: 10px;
  border: 3px dashed rgb(90, 168, 247);
  transition: background-color 0.3s ease;
}
.modal-div{
  width: 60%;
  height: 80%;
}
.upload-box.dragover {
  background-color: rgba(90, 168, 247, 0.1);
}
.upload-box{
  border:3px dashed rgb(90, 168, 247);
}
.form-input {
  min-height: 5px;
  width: 100%;
  color:var(--controls-font-color);
  border:1px solid var(--controls-border-color)
}
.profile-pic-default{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-position: center center;
  background-size: cover;
  margin: 5px auto;
}
.profile-pic-served{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-top: 5px;
  margin-bottom: 5px;
  margin: 5px auto;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.upload-detail-btn{
  align-items: end;
}
</style>
<style scoped>
#remove-btn {
  cursor: pointer;
}

input[type='file']::file-selector-button {
  /* Add properties here */
  color: red;
}

.file-container {
  max-height: 350px;
  overflow-y: scroll;
}

.files-div {
  border-radius: 10px;
}

.file-info {
  font-size: 12px;
  text-align: left;
}

.input-detail {
  display: none;
}

.remove-btn {
  position: relative;
  bottom: 0;
  right: 0;
  left: 100;
}


.upload-btn {
  background-color: white;
  color:#2e688dff;
  border-radius: 6px;
  font-size:15px;
  padding:8px;
  font-weight: 600;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  cursor: pointer;
  width: fit-content;
  margin:0px auto;
}


.label-upload{
  color:#2e688dff;
  border-radius: 6px;
  font-size:15px;
  font-weight: 600;
  padding-right:8px;
  cursor: pointer;
}
</style>
