const commonModule = {
  namespaced: true,
  state: {
    leaveQuota: [],
    currentRowData:{}
  },
  getters: {
    leavesQuotaGetters: function(state) {
      return state.detailGridApi
    },
    currentRowDataGetter: function(state) {
      return state.currentRowData
    },
  },
  mutations: {
    leavesQuotaMutations: function(state, value) {
      state.detailGridApi = value;
    },
    currentRowDataMutations: function(state, value) {
      state.currentRowData = value;
    },
  },
  actions: {
    currentRowDataSetter(context, value) {
      context.commit('currentRowDataMutations', value)
    },

  }
}

export default commonModule;
