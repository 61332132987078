<template>
  <HeaderComponent
    class="month-switcher"
    :current-folder="currentFolder"
    :selected-cells="selectedCells"
    :is-duty-chart="isDutyChart"
    :filters-data="dataSource"
    :update-filter-values="updateFilterValues"
    :clear-selection="clearSelection"
    :on-grid-ready="onGridReady"
    @update-month-year="updateMonthYear"
  />
  <div
    class="grid-container"
    @keydown.capture="keyboardCommandsHandler"
  >
    <div
      id="my-grid"
      class="ag-theme-quartz"
    >
      <ag-grid-vue
        :style="{ width: '100%', height: gridHeight }"
        class="ag-grid"
        :row-height="60"
        :column-defs="columnDefs"
        :default-col-def="defaultColDef"
        :row-buffer="rowBuffer"
        :row-selection="rowSelection"
        :suppress-row-selection="suppressRowSelection"
        :suppress-row-click-selection="suppressRowClickSelection"
        :stop-editing-when-grid-loses-focus="stopEditingWhenGridLosesFocus"
        :row-model-type="rowModelType"
        :cache-block-size="cacheBlockSize"
        :cache-overflow-size="cacheOverflowSize"
        :max-concurrent-datasource-requests="maxConcurrentDatasourceRequests"
        :infinite-initial-row-count="infiniteInitialRowCount"
        :max-blocks-in-cache="maxBlocksInCache"
        :suppress-horizontal-scroll="suppressHorizontalScroll"
        :on-cell-key-down="onCellKeyDown"
        header-height="60"
        @grid-ready="onGridReady"
        @cell-clicked="onCellClicked"
      />
    </div>
  </div>
  <div v-if="!isDutyChart">
    <HelperList
      grid-view="allocations"
      :helper-list-data="dataSource"
      :show-designation="showDesignations"
      :show-contract="showContract"
    />
  </div>
</template>

<script>
import 'ag-grid-community/styles/ag-grid.css';
import { AgGridVue } from 'ag-grid-vue3';
import { toast } from 'vue3-toastify';
import { ref, onBeforeMount, computed, watch } from 'vue';
import { useStore } from 'vuex';
import allocationGridUtils from '../../composables/allocationGridUtils';
import attendanceGridUtils from '../../composables/attendanceGridUtils';
import dataProcessor from '../../composables/allocationGridDataProcessor';
import getFolders from '../../composables/getFolders';
import HeaderComponent from '../grid/common-components/HeaderComponent.vue';
import EmployeeCellRenderer from '../grid/common-components/EmployeeCellRenderer.vue';
import AllocationCellRenderer from './grid-components/CellRenderer.vue';
import EmployeesHeaderComponent from '../grid/common-components/EmployeesHeaderComponent.vue';
import EmployeeCellEditor from '../grid/common-components/EmployeeCellEditor.vue';
import GridHeaderComponent from '../grid/common-components/GridHeaderComponent.vue';
import TotalHoursHeaderComponent from '../grid/common-components/TotalHoursHeaderComponent.vue';
import TotalHoursCellRenderer from '../grid/common-components/TotalHoursCellRenderer.vue';
import CellEditor from './grid-components/CellEditor.vue';
import HelperList from '../grid/common-components/HelperList.vue';

export default {
  name: 'AllocationGrid',
  components: {
    /* eslint-disable vue/no-unused-components */
    AgGridVue,
    AllocationCellRenderer,
    GridHeaderComponent,
    HeaderComponent,
    CellEditor,
    HelperList
    /* eslint-enable */
  },
  setup(props) {
    const store = useStore();
    const bSettings = store.getters['bSettings'];
    const { allocationPeriodColumns, getAllDocpickerList, saveAllocation, txnDataSetter, generateTxns, checkIfDO } = allocationGridUtils();
    const { selectedCellsSetter, getAttendanceDate } = attendanceGridUtils();

    const { getFoldersFormList, getCurrentFolder } = getFolders();
    const folderList = getFoldersFormList(bSettings);
    const currentFolder = computed(() => {
      return getCurrentFolder('allocations', folderList)
    })
    const gridApi = ref();
    const selectedCells = ref([]);
    let selectAll = false;
    const cellSelected = ref([]);
    let copyValue = null;
    let newRows = null;
    let addRow = false;
    const filterValues = ref({
      contract: null,
      site:null,
      shift:null,
      designation:null,
      department: null,
      reportingManger:null
    })
    let toggleCellColor = false;
    let showContractCellColor = false;
    let showDesignationCellColor = false;
    const dataSource = ref({});
    const totalRowCount = ref(0);
    const defaultColDef = ref({
      flex: 1,
      resizable: true,
      minWidth: 100,
    });
    const isDutyChart = ref(true);
    const currentDateTime = new Date();
    const timeRange = ref({
      weekRange: {
        startOfWeek: null,
        startMonth: null,
        startYear: null,
        endOfWeek:null,
        endMonth:null,
        endYear:null,
      },
      day: 1,
      year: currentDateTime.getFullYear(),
      month: currentDateTime.getMonth() + 1
    });
    const gridHeight = ref('76vh');
    if (isDutyChart.value) {
      const current = new Date(currentDateTime);
      const dayOfWeek = current.getDay();
      const startOfWeek = new Date(current);
      startOfWeek.setDate(current.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1));
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);
      timeRange.value.weekRange.startOfWeek = startOfWeek.getDate();
      timeRange.value.weekRange.startMonth = startOfWeek.getMonth();
      timeRange.value.weekRange.startYear = startOfWeek.getFullYear();
      timeRange.value.weekRange.endOfWeek = endOfWeek.getDate();
      timeRange.value.weekRange.endMonth = endOfWeek.getMonth();
      timeRange.value.weekRange.endYear = endOfWeek.getFullYear();
      timeRange.value.day = null;
      gridHeight.value = '80vh'
    }
    const rowBuffer = ref(null);
    const rowSelection = ref(null);
    const rowModelType = ref(null);
    const cacheBlockSize = ref(null);
    const cacheOverflowSize = ref(null);
    const maxConcurrentDatasourceRequests = ref(null);
    const infiniteInitialRowCount = ref(null);
    const maxBlocksInCache = ref(null);
    const gridData = ref(null);
    const showLoader = ref();
    const suppressRowSelection = ref();
    const suppressRowClickSelection = ref();
    const stopEditingWhenGridLosesFocus = ref();
    const suppressHorizontalScroll = ref();
    const columnDefs = ref([
      {
        headerName: 'Employees',
        pinned: 'left',
        minWidth: 150,
        maxWidth: 180,
        suppressSizeToFit: false,
        field: 'employees',
        unSortIcon: true,
        sortable: true,
        suppressMovable: true,
        cellEditor: EmployeeCellEditor,
        headerComponent: EmployeesHeaderComponent,
        editable: (params) => {
          if (params.node.rowPinned) {
            return false;
          }
          return true;
        },
        filter:'agColumnsFilter',
        filterParams: {
          filterOptions: ['equals'],
          maxNumConditions: 1,
        },
        enableCellChangeFlash: true,
        cellStyle: {
          color: 'black',
          fontSize: '14px',
          border: '0.1px solid #dde2eb',
          'justify-content': 'center',
        },
        cellRendererSelector: (params) => {
          return {
            component: EmployeeCellRenderer,
          };
        },
        cellRendererParams : {
          gridView: 'allocations',
        }
      },
      ...allocationPeriodColumns(timeRange.value, showContractCellColor, showDesignationCellColor, dataSource.value, filterValues.value.contract, selectedCells, selectAll),
      {
        field: 'total',
        maxWidth: 160,
        minWidth: 150,
        sortable: false,
        suppressMovable: true,
        enableCellChangeFlash: true,
        cellRendererSelector: (params) => {
          return {
            component: TotalHoursCellRenderer,
          };
        },
        cellRendererParams: {
          'currentFolder':currentFolder.value
        },
        pinned: 'right',
        headerComponent: TotalHoursHeaderComponent,
        headerComponentParams: {
          addNewRow: addNewRow,
          clearSelection:clearSelection
        },
        cellStyle: {
          color: 'black',
          fontSize: '14px',
          border: '0.1px solid #dde2eb',
        },
      },
    ]);

    function clearSelection() {
      selectedCells.value = [];
      cellSelected.value = []
      const selected = gridApi.value.getSelectedNodes();
      for (let i = 0; i < selected.length; i++) {
        selected[i].setSelected(false);
      }
      const columnDefs = gridApi.value.getColumnDefs();
      for (let i = 0; i < columnDefs.length; i++) {
        if (columnDefs[i].field !== 'employees' && columnDefs[i].field !== 'total') {
          columnDefs[i].cellRendererParams = {
            'showContractsCellColor':showContractCellColor,
            'showDesignationsCellColor':showDesignationCellColor,
            'cellsSelected': selectedCells.value,
            'selectAllCells': selectAll
          }
        }
      }
      gridApi.value.setColumnDefs(columnDefs);
    }

    async function keyboardCommandsHandler(event) {
      if (event.altKey === true && event.keyCode === 73) {
        addNewRow(gridApi.value);
      }
      if (event.altKey === true && event.keyCode === 83) {
        await saveAllocation(store);
      }
      if (filterValues.value.contract === null && event.key === 'Enter') {
        event.stopPropagation();
        toast.warning('Please select contract')
      }
    }
    async function onCellClicked(event) {
      if (event.event.ctrlKey === true) {
        const colId = event.column.colId;
        const selectedRowNode = event.node;
        const isSelected = selectedRowNode.isSelected();
        const isDO = checkIfDO(event, 'Only duty chart officer can edit duty chart');
        if (!isDO) {
          selectedRowNode.setSelected(false);
          return isDO;
        }
        if (colId === 'employees') {
          selectedRowNode.setSelected(!isSelected);
        } else {
          selectedRowNode.setSelected(false);
        }
        const result = await selectedCellsSetter(store, event, selectedCells.value, selectAll);
        selectedCells.value = result.cellsSelected;
        selectAll = result.selectAllCells;
        cellSelected.value = result.cellsSelected;
      }
    }

    const onCellKeyDown = async(event) => {
      if (event.event.code === 'Space') {
        const colId = event.column.colId;
        const selectedRowNode = event.node;
        const isDO = checkIfDO(event, 'Only duty chart officer can edit duty chart');
        if (!isDO) {
          return isDO;
        }
        const isSelected = selectedRowNode.isSelected();
        if (colId !== 'employees') {
          selectedRowNode.setSelected(!isSelected);
        }
        const result = await selectedCellsSetter(store, event, selectedCells.value, selectAll);
        selectedCells.value = result.cellsSelected;
        selectAll = result.selectAllCells;
        cellSelected.value = result.cellsSelected;
      } else if (
        event.event.ctrlKey === true &&
        (event.event.keyCode === 65 || event.event.keyCode === 97)
      ) {
        const isDO = checkIfDO(event, 'Only duty chart officer can edit duty chart');
        if (!isDO) {
          return isDO;
        }
        const result = await selectedCellsSetter(store, event, selectedCells.value, selectAll);
        selectedCells.value = result.cellsSelected;
        selectAll = result.selectAllCells;
        cellSelected.value = result.cellsSelected;
      } else if (event.event.ctrlKey === true && event.event.keyCode === 67) {
        if (event?.value?.isLeave) {
          toast.warning('Leaves cannot be copied', {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          copyValue = event.value;
        }
      } else if (event.event.ctrlKey === true && event.event.keyCode === 86) {
        if (event?.value !== undefined && event?.value !== null && event.value.isLeave) {
          toast.warning('Please reject or delete leave to mark allocations', {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          const copiedCell = {[`${event.colDef.field}`] :  copyValue}
          const newDate = getAttendanceDate(event, null);
          const data = event.data;
          event.data = Object.assign(data, copiedCell)
          event.value = copyValue;
          if (copyValue.allocations_edit !== undefined && copyValue.allocations_edit !== null) {
            copyValue = copyValue.allocations_edit;
          }
          copyValue['employees_id'] = event?.data?.employees;
          copyValue['allocations_range'] = newDate;
          if (copyValue['departments_id'] === undefined || copyValue['departments_id'] === null) {
            copyValue['departments_id'] = event?.data?.employees?.[0]?.departments;
          }
          delete copyValue.id;
          delete copyValue.isLeave;
          delete copyValue.allocations_id;
          delete copyValue.name;
          const txnData = txnDataSetter(copyValue)
          await generateTxns(txnData);
          gridApi.value.refreshCells();
        }
      } else if (event.event.shiftKey === true && (event.event.keyCode === 37 || event.event.keyCode === 38 || event.event.keyCode === 39 || event.event.keyCode === 40)) {
        const focusedCell = gridApi.value.getFocusedCell();
        if (!focusedCell) return;
        const column = focusedCell.column;
        const nextColumn = column;
        const colId = nextColumn.colId;
        if (colId === undefined || colId === 'employees' || colId === 'total') {
          return;
        }
        const value = event.data[colId]
        event.value = value;
        event.column = nextColumn;
        const bSettings = store.getters['bSettings'];
        if (bSettings.env.code === 'admin') {
          return true;
        }
        if (event.data !== undefined && event.data !== null) {
          if (event.data.employees !== undefined && event.data.employees !== null) {
            const duty_chart_officer = event.data.employees?.[0]?.duty_chart_officer?.[0]?.id;
            if (bSettings?.env?.id === duty_chart_officer) {
              console.log('Duty chart officer')
            } else {
              toast.warning('Only duty chart officer can edit duty chart', {
                position: toast.POSITION.TOP_CENTER,
              });
              return false;
            }
          }
        }
        const result = await selectedCellsSetter(store, event, selectedCells.value, selectAll);
        selectedCells.value = result.cellsSelected;
        selectAll = result.selectAllCells;
        cellSelected.value = result.cellsSelected;
        event.event.preventDefault();
      }
    };

    function updateFilterValues(filterValue) {
      if (filterValue.type === 'contract') {
        filterValues.value.contract = filterValue.value;
        if (filterValues.value.contract !== null) {
          timeRange.value.day = filterValues.value.contract?.datePreviousMonth !== null ? filterValues.value.contract?.datePreviousMonth : 1;
          if (isDutyChart.value) {
            timeRange.value.day = null;
          }
          let siteList = [];
          let shiftList = [];
          let productList = [];
          for (let i = 0; i < dataSource?.value?.contracts_id.length; i++) {
            if (dataSource?.value?.contracts_id?.[i]?.id === filterValues.value.contract?.id) {
              const filteredList = updateDataSource(dataSource?.value?.contracts_id?.[i], siteList, shiftList, productList);
              siteList = filteredList[0];
              shiftList = filteredList[1];
              productList = filteredList[2];
              break;
            }
          }
          dataSource.value['siteList'] = siteList;
          dataSource.value['shiftList'] = shiftList;
          dataSource.value['productList'] = productList;
        } else {
          timeRange.value.day = 1;
          if (isDutyChart.value) {
            timeRange.value.day = null;
          }
          let siteList = [];
          let shiftList = [];
          let productList = [];
          for (let i = 0; i < dataSource?.value?.contracts_id.length; i++) {
            const filteredList = updateDataSource(dataSource?.value?.contracts_id?.[i], siteList, shiftList, productList);
            siteList = filteredList[0];
            shiftList = filteredList[1];
            productList = filteredList[2];
          }
          dataSource.value['siteList'] = siteList;
          dataSource.value['shiftList'] = shiftList;
          dataSource.value['productList'] = productList;
        }
        setHeaderNames(JSON.parse(JSON.stringify(timeRange.value)));
      } else if (filterValue.type === 'site') {
        filterValues.value.site = filterValue.value;
      } else if (filterValue.type === 'shift') {
        filterValues.value.shift = filterValue.value;
      } else if (filterValue.type === 'designation') {
        filterValues.value.designation = filterValue.value;
      } else if (filterValue.type === 'department') {
        filterValues.value.department = filterValue.value;
      } else if (filterValue.type === 'reportingManager') {
        filterValues.value.reportingManger = filterValue.value;
      }
    }

    function showDesignations() {
      const checkedValue = document.querySelector('.designations').checked;
      showDesignationCellColor = checkedValue;
      toggleCellColor = true;
      const columnDefs = gridApi.value.getColumnDefs();
      for (let i = 0; i < columnDefs.length; i++) {
        if (columnDefs[i].field !== 'employees' && columnDefs[i].field !== 'total') {
          columnDefs[i].cellRendererParams = {
            'cellsSelected': selectedCells.value,
            'selectAllCells': selectAll,
            'showContractsCellColor':showContractCellColor,
            'showDesignationsCellColor':showDesignationCellColor,
          }
        }
      }
      gridApi.value.setColumnDefs(columnDefs);
    }

    function showContract() {
      const checkedValue = document.querySelector('.contracts').checked;
      showContractCellColor = checkedValue;
      toggleCellColor = true;
      const columnDefs = gridApi.value.getColumnDefs();
      for (let i = 0; i < columnDefs.length; i++) {
        if (columnDefs[i].field !== 'employees' && columnDefs[i].field !== 'total') {
          columnDefs[i].cellRendererParams = {
            'showContractsCellColor':showContractCellColor,
            'showDesignationsCellColor':showDesignationCellColor,
            'cellsSelected': selectedCells.value,
            'selectAllCells': selectAll
          }
        }
      }
      gridApi.value.setColumnDefs(columnDefs);
    }

    function addObjectIfUnique(array, newObject) {
      // Check if the object with the same id already exists in the array
      if (newObject !== undefined && newObject !== null) {
        const exists = array.some((obj) => obj.id === newObject?.id);
        // If it doesn't exist, push the new object to the array
        if (!exists) {
          array.push(newObject);
        }
      }
      return array;
    }

    function updateDataSource(contract, siteList, shiftList, productList) {
      if (contract?.attendance_based_items !== null) {
        for (let j = 0; j < contract?.attendance_based_items.length; j++) {
          siteList = addObjectIfUnique(siteList, contract?.attendance_based_items?.[j]?.sites_id?.[0])
          shiftList = addObjectIfUnique(shiftList, contract?.attendance_based_items?.[j]?.shifts_id?.[0])
          productList = addObjectIfUnique(productList, contract?.attendance_based_items?.[j]?.products_id?.[0])
        }
      } else if (contract?.job_based_items !== null) {
        for (let j = 0; j < contract?.job_based_items.length; j++) {
          siteList = addObjectIfUnique(siteList, contract?.job_based_items?.[j]?.sites_id?.[0])
          productList = addObjectIfUnique(productList, contract?.job_based_items?.[j]?.sites_id?.[0])
        }
        if (siteList.length > 0) {
          for (let k = 0; k < siteList.length; k++) {
            for (let l = 0; l < dataSource?.value?.shifts_id?.length; l++) {
              if (siteList?.[k]?.id === dataSource?.value?.shifts_id?.[l]?.sites_id?.[0]?.id) {
                shiftList = addObjectIfUnique(shiftList, dataSource?.value?.shifts_id?.[l])
              }
            }
          }
        }
      }
      return [siteList, shiftList, productList];
    }

    async function filtersData() {
      //using composables to get attendance_edits folder to set as bSettings
      const fieldNames = ['contracts_id', 'shifts_id', 'departments', 'allocation_type', 'sites_id', 'products_id'];
      const employeesFolder = getCurrentFolder('employees', folderList);
      const docpickerResults = await Promise.all(fieldNames.map((fieldName) =>
        getAllDocpickerList(fieldName !== 'shifts_id' && fieldName !== 'allocation_type' ? fieldName !== 'departments' ?  currentFolder.value?.fields[fieldName] : employeesFolder?.fields[fieldName] : currentFolder.value.fields['allocations_period'].fields[fieldName])
      ));
      for (let i = 0; i < docpickerResults.length; i++) {
        const docpObj = docpickerResults[i];
        dataSource.value = Object.assign(dataSource.value, docpObj)
      }
      // let siteList = [];
      // let shiftList = [];
      // let productList = [];
      // for (let i = 0; i < dataSource?.value?.contracts_id.length; i++) {
      //   const filteredList = updateDataSource(dataSource?.value?.contracts_id?.[i], siteList, shiftList, productList);
      //   siteList = filteredList[0];
      //   shiftList = filteredList[1];
      //   productList = filteredList[2];
      // }
      // dataSource.value['siteList'] = siteList;
      // dataSource.value['shiftList'] = shiftList;
      // dataSource.value['productList'] = productList;
      filterValues.value.contract = dataSource.value['contracts_id'][0];
      const monthGroupColumnsDefs = allocationPeriodColumns(timeRange.value, showContractCellColor, showDesignationCellColor,  dataSource.value, filterValues.value.contract, selectedCells.value, selectAll)
      const columnDefs = gridApi.value.getColumnDefs()
      const columnsArray = [];
      columnsArray.push(columnDefs[0]);
      columnsArray.push(...monthGroupColumnsDefs);
      columnsArray.push(columnDefs[columnDefs.length - 1]);
      columnDefs.value = columnsArray;
      showLoader.value = false;
      if (newRows !== null) {
        if (newRows.length === 0) {
          gridApi.value.showNoRowsOverlay();
        }
      }
    }

    function addNewRow() {
      addRow = true;
      gridApi.value.refreshInfiniteCache();
    }

    function updateMonthYear(newMonthYear) {
      timeRange.value = newMonthYear;
    }

    function setHeaderNames(monthYear) {
      const monthGroupColumnsDefs = allocationPeriodColumns(monthYear, showContractCellColor, showDesignationCellColor,  dataSource.value, filterValues.value.contract, selectedCells.value, selectAll);
      const newColumnDefs = gridApi.value.getColumnDefs()
      const columnsArray = [];
      columnsArray.push(newColumnDefs[0]);
      columnsArray.push(...monthGroupColumnsDefs);
      columnsArray.push(newColumnDefs[newColumnDefs.length - 1]);
      columnDefs.value = columnsArray;
      gridApi.value.refreshInfiniteCache();
    }

    //watchers
    watch(() => timeRange.value,
      async(newTimeRange, oldTimeRange) => {
        if (isDutyChart.value) {
          if (
            newTimeRange.weekRange.endOfWeek !== oldTimeRange.weekRange.endOfWeek ||
            newTimeRange.weekRange.startOfWeek !== oldTimeRange.weekRange.startOfWeek
          ) {
            totalRowCount.value = 0;
            gridApi.value.infiniteRowModel.resetCache();
            setHeaderNames(JSON.parse(JSON.stringify(newTimeRange)));
          }
        } else {
          if (
            newTimeRange.year !== oldTimeRange.year ||
            newTimeRange.month !== new Date(`${oldTimeRange.month} 1, 2000`).getMonth() + 1
          ) {
            totalRowCount.value = 0;
            gridApi.value.infiniteRowModel.resetCache();
            setHeaderNames(JSON.parse(JSON.stringify(newTimeRange)));
          }
        }
      }
    );


    //lifecycle hooks
    onBeforeMount(async() => {
      rowBuffer.value = 0;
      rowModelType.value = 'infinite';
      rowSelection.value = 'multiple';
      cacheBlockSize.value = 100;
      cacheOverflowSize.value = 2;
      maxConcurrentDatasourceRequests.value = 1;
      infiniteInitialRowCount.value = 0;
      maxBlocksInCache.value = 50;
      showLoader.value = true;
      suppressRowSelection.value = true;
      suppressRowClickSelection.value = true;
      stopEditingWhenGridLosesFocus.value = true;
      suppressHorizontalScroll.value = false;
    });

    async function dataGenerator(params) {
      try {
        let allocationRows = store.getters['agGridModule/attendanceGridRows'];
        if (addRow) {
          allocationRows.unshift({});
          addRow = false;
          totalRowCount.value = allocationRows.length;
          setTimeout(() => {
            gridApi.value.setFocusedCell(0, gridApi.value?.columnModel?.columnDefs[0]?.field);
            gridApi.value.startEditingCell({
              rowIndex: 0,
              colKey: 'employees',
              keyPress: null,
              charPress: ''
            });
          }, 0);
        } else if (toggleCellColor) {
          toggleCellColor = false;
        } else {
          const { getAllocationRows, rowDataCreator } = dataProcessor();
          const list = await getAllocationRows(timeRange.value, currentFolder.value, filterValues.value, params.filterModel)
          totalRowCount.value = list.allocationsList.output.data.records[list.allocationsList.output.data.records.length - 1].rowCount;
          if (totalRowCount.value === undefined) {
            totalRowCount.value = 0;
          }
          allocationRows = await rowDataCreator(list, params.sortModel);
        }
        return allocationRows;
      }
      catch (e) {
        console.log(e.message);
      }
    }

    const onGridReady = (params) => {
      if (gridApi.value === undefined) {
        gridApi.value = params.api;
        store.dispatch('agGridModule/attendanceGridApiSetter', gridApi.value);
      }
      const dataSource = {
        rowCount: 200,
        getRows: async(params) => {
          try {
            console.log(
              'asking for ' + params.startRow + ' to ' + params.endRow
            );
            gridApi.value.hideOverlay();
            newRows = await dataGenerator(params);
            await store.dispatch(
              'agGridModule/attendanceGridRowsSetter',
              newRows
            );
            let lastRow = totalRowCount.value;
            if ((totalRowCount.value === 0 && newRows.length > 0) || totalRowCount.value < newRows.length || totalRowCount.value > newRows.length) {
              lastRow = newRows.length;
            }
            if (newRows.length === 0) {
              params.endRow = 0;
              lastRow = 0;
              if (showLoader.value === false) {
                gridApi.value.showNoRowsOverlay();
              }
            } else {
              params.endRow = newRows.length;
              gridApi.value.hideOverlay();
            }
            params.successCallback(newRows, lastRow);
          } catch (err) {
            console.error('Error loading data:', err);
            // toast.error(err);
            params.failCallback();
          }
        },
      };
      if (params !== undefined) {
        params.api.setDatasource(dataSource);
        filtersData()
      } else {
        gridApi.value.setDatasource(dataSource);
      }
      gridApi.value.sizeColumnsToFit();
    };
    return {
      currentFolder,
      isDutyChart,
      selectedCells,
      clearSelection,
      updateMonthYear,
      gridHeight,
      updateFilterValues,
      dataSource,
      keyboardCommandsHandler,
      rowBuffer,
      rowSelection,
      rowModelType,
      cacheBlockSize,
      cacheOverflowSize,
      maxConcurrentDatasourceRequests,
      infiniteInitialRowCount,
      maxBlocksInCache,
      gridData,
      showLoader,
      onCellKeyDown,
      onCellClicked,
      suppressRowSelection,
      suppressRowClickSelection,
      stopEditingWhenGridLosesFocus,
      suppressHorizontalScroll,
      columnDefs,
      defaultColDef,
      onGridReady,
      showDesignations,
      showContract
    };
  },
};
</script>
<style scoped>
.grid-container {
  padding: 2px;
  border: 1px solid #83ACC8;
  display: flex;
  flex-direction: column;
}

#my-grid {
  width: 100%;
}

.ag-theme-quartz .ag-row-selected {
  border: 1px solid #00b7ff !important;
  background: #f5e9c8 !important;
}

:deep .ag-root-wrapper {
  overflow: visible;
}

.ag-theme-quartz {
  --ag-foreground-color: black;
  --ag-background-color: #ffffff;
  --ag-header-foreground-color: var(--main-background-color);
  --ag-header-background-color: #83ACC8;
  /* --ag-odd-row-background-color: rgb(0, 0, 0, 0); */
  --ag-font-size: 13px;
  --ag-overlay-background-color: var(--main-background-color) !important;
  --ag-selected-row-background-color: rgba(0, 102, 255, 0.1);
  --ag-font-family: 'Poppins', sans-serif;
  --ag-popup-shadow: black;
  --ag-borders: none;
  --ag-row-border-style: solid;
  --ag-row-border-width: 0.1px;
  --ag-row-border-color: #aebbcc;
  --ag-header-column-resize-handle-display: inline;
}

.month-switcher {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  flex-shrink: 0;
  z-index: 10;
}
</style>
