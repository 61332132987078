<template>
  <div
    class="wrapper"
    :data-test-id="dataTestId"
  >
    <div
      class="search-input d-flex"
      :class="searchDivClass"
    >
      <input
        v-model="searchTerm"
        name="search-input"
        :class="searchInputClass"
        type="text"
        :placeholder="placeholder"
        @input="handleSearch"
      />
      <label
        v-if="!hideIcon"
        class="search-label my-auto"
        name="search-btn"
        @click="handleSearch"
      >
        <span
          v-if="loading"
          class="spinner-border spinner-border-sm text-light me-2 ms-2"
          role="status"
          aria-hidden="true"
        />
        <v-icon
          v-if="!loading"
          name="bi-search"
          class="me-2 ms-2"
        />
      </label>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
export default {
  name: 'WfmGlobalSearch',
  props: {
    searchInputClass: {
      type: String,
      default: '',
    },
    searchDivClass:{
      type:String,
      default:''
    },
    placeholder: {
      type: String,
      default: 'Search Box',
    },
    loading:{
      type:Boolean,
      default:false
    },
    dataTestId:{
      type:String,
      default:null
    },
    hideIcon:{
      type:Boolean,
      default:false
    }
  },
  emits: ['handle-search'],
  setup(props, { emit }) {
    //variables
    const searchTerm = ref('');

    function handleSearch() {
      emit('handle-search', searchTerm.value);
    }
    //Hooks

    return {
      searchTerm,
      handleSearch
    };
  },
};
</script>

<style scoped>
.folder {
  /* border-radius: 10px; */
  padding: 5px;
  align-items: center;
}

.folder:hover {
  color: rgb(255, 255, 255);
  background-color: rgb(158, 200, 158);
}

ul {
  list-style-type: none;
}

.search-input {
  background: transparent;
  width: 100%;
  border-radius: 5px;
  position: relative;
  box-shadow: 0px 1px 5px 3px var(--folder-shadow);
}

.search-input input {
  min-height: 15px;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 0 60px 0 20px;
  font-size: 18px;
  color:var(--main-color);
  background-color:back;
  /* box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1); */
}

.search-input.active input {
  border-radius: 5px 5px 0 0;
}


.search-label {
  cursor: pointer;
}
</style>
