import { createApp } from 'vue'
import App from './App.vue'
//import App from '../src/components/duty_charts_ag_grid.vue'
import router from '../src/router'
import store from '../store'
import { setupConnection } from '../src/services/websocket';


//bootstrap css only
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'

//npm i18n for translation
import i18n from '../src/common/translation'

import vSelect from 'vue-select';

//npm vue-datepicker for all purpose date picker
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

//bootstrap icons
import * as BootstrapIcons from 'oh-vue-icons/icons/bi'
import * as FontAwesomeIcons from 'oh-vue-icons/icons/fa'
import * as MaterialDesignIcons from 'oh-vue-icons/icons/md'
import * as RemixIcons from 'oh-vue-icons/icons/ri'
import * as GameIcons from  'oh-vue-icons/icons/gi'

const Bi = Object.values({ ...BootstrapIcons })
const Fa = Object.values({ ...FontAwesomeIcons })
const Md = Object.values({...MaterialDesignIcons})
const Gi = Object.values({...GameIcons})
const Ri = Object.values({...RemixIcons})
addIcons(...Bi, ...Fa, ...Md, ...Ri, ...Gi)
//npm oh-vue icons for customizable icons
import { OhVueIcon, addIcons } from 'oh-vue-icons'
//vuetify adding but styles mixes
//npm for advance toast notification
import Vue3Toastify from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import {VOtpInput, VStepper, VTooltip} from 'vuetify/components'
const vuetify = createVuetify({
  components: {
    VOtpInput,
    VStepper,
    VTooltip
  }})

setupConnection();

const app = createApp(App)
app.component('VSelect', vSelect);
app.component('VueDatePicker', VueDatePicker)
app.component('VIcon', OhVueIcon)
app.use(i18n)
app.use(vuetify)
app.use(store)
app.use(Vue3Toastify)
app.use(router).mount('#app')
