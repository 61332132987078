import { onClickOutside } from '@vueuse/core'
import { sendMessage } from '../services/websocket'
const utility = () => {
  // reusable function to register onClickOutside for any element.

  function registerClickOutside(elementRef, clickOutsideHandler, ignoreList) {
    onClickOutside(
      //element to register outside click
      elementRef,
      () => {
        //function to run
        clickOutsideHandler();
      },
      {
        //refs to ignore form the outside click
        ignore: ignoreList
      }
    )
  }

  // this function takes an object and makes all its values to null
  // function is moved here from nullMaker.js and the nullMaker.js composable is not deleted
  function getNullObject(obj) {
    for (var key in obj) {
      obj[key] = null
    }
    return obj
  }


  function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  async function payslipsDownload(payslips, folder, store) {
    const serverPort = store.getters['serverPortGetter']

    for (var i = 0; i < payslips.length; i++) {
      const currPayslip = payslips[i]
      const currUconst = currPayslip.uconst
      const getTxn = folder.txns.txn_payslips_get_payslip
      const params = {'uconst':currUconst}
      getTxn.params = params
      const retVal = await sendMessage(getTxn)
      if (retVal.output.type == 'success') {
        const fileName = retVal.output.fileName
        const start = fileName.indexOf('payslips');
        const result = fileName.substring(start);
        const url = `http://${window.location.hostname}:${serverPort}/${result}`
        window.open(url, '_blank')

      }

    }
  }


  return { registerClickOutside, getNullObject, debounce, payslipsDownload }
}

export default utility
