<template>
  <div class="total-container">
    <div>
      <span>Total Days</span>
    </div>
    <div
      class="add-button"
      @click="addEmptyRow"
    >
      <v-icon
        :fill="`#292929`"
        name="md-groupadd-outlined"
        scale="1.0"
      />
    </div>
  </div>
</template>

<script>

export default {
  setup(props) {
    const addEmptyRow = (event) => {
      props.params.addNewRow();
    };

    return {
      addEmptyRow,
    };
  }
}
</script>

<style>

.total-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  overflow: visible;
}

.add-button {
  cursor: pointer;
  margin-left: 10px;
}

</style>
