/* eslint-disable no-redeclare */
import store from '../../store';
import generatePatch from './generatePatch';

const makeTxnForBulk = (formData, currentFolder, txn, txnName) => {
  const bSetting = store.getters['bSettings'];
  //const swHandle = store.getters["userModule/swHandle"]
  //get available txn in the current folder
  let baseFolder = null;
  const folderType = currentFolder.foldertype;
  const folderName = currentFolder.name


  baseFolder = `business.${folderType}.${folderName}`;


  const txnToRun = txn ? txn : null;
  const dataToUpdate = []
  if (currentFolder.name === 'leaves') {
    for (var i = 0; i < formData.value.length; i++) {

      const oldDocpId = formData.value[i].oldDocpId
      const txnOp = formData.value[i].txnOp
      const mainFolderId = formData.value[i].mainFolderId
      const leaves_id = formData.value[i].params.leaves_id
      const leaveDaysId = formData.value[i].params.idToUse
      const newDocp = formData.value[i].newDocp
      const isDeleteTxn = formData.value[i].deleteTxn
      if (isDeleteTxn) {
        dataToUpdate.push({data:{id:leaves_id, ['leave_days']:[{op:'D', data:{'id':leaveDaysId}, key:leaves_id}]}, key:mainFolderId, op:'U'})

      }
      else {
        dataToUpdate.push({data:{id:leaves_id, ['leave_days']:[{op:txnOp, data:{'id':leaveDaysId, 'leave_status':[{op:txnOp, data:{...newDocp}, key:txnOp === 'U' ? oldDocpId : null}]}, key:leaves_id}]}, key:mainFolderId, op:'U'})

      }

    }
  }
  else if (currentFolder.name === 'employees') {
    for (var i = 0; i < formData.value.length; i++) {
      const oldDocpId = formData.value[i].oldDocpId
      const newDocpId = formData.value[i].newId
      const mainFolderId = formData.value[i].mainFolderId
      const propertyName = formData.value[i].propertyName
      // const personId = formData.value[i].params.persons_id[0].id
      // const empHisDataExist = formData.value[i].params.persons_id[0].employment_history !== null ? true : false
      // const empHisId = empHisDataExist ? formData.value[i].params.persons_id[0].employment_history[0].id : uuidv4()
      // const dataToAppend = {}
      let dataToAdd = null
      if (oldDocpId) {
        dataToAdd = {
          data:{id:mainFolderId,
            [propertyName]:[
              {op:'U',
                data:{id:newDocpId},
                key:oldDocpId}
            ]
          }, key:mainFolderId, op:'U'}
        // dataToAppend = {persons_id:[{op: "U",
        //   key: personId, data: {
        //     id: personId,
        //     employment_history: [
        //       {data: { id: empHisId,
        //         [propertyName]: [{op:"U", data:{id:newDocpId}, key:oldDocpId}],
        //         persons_id: personId,
        //         swhandle:swHandle,
        //         joining_date:formData.value[i].params.joining_date
        //       },
        //       op: empHisDataExist ? "U" : "C"
        //       }
        //     ]
        //   }}]}
      }
      else {
        // dataToAppend = {persons_id:[{op: "U",
        //   key: personId, data: {
        //     id: personId,
        //     employment_history: [
        //       {data: { id: empHisId,
        //         [propertyName]: [{op:"C", data:{id:newDocpId}}],
        //         persons_id: personId,
        //         swhandle:swHandle,
        //         joining_date:formData.value[i].params.joining_date


        //       },
        //       op: empHisDataExist ? "U" : "C"
        //       }
        //     ]
        //   }}]}
        dataToAdd = {
          data:{id:mainFolderId,
            [propertyName]:[
              {op:'C',
                data:{id:newDocpId}}]
          }, key:mainFolderId, op:'U'}
      }
      dataToUpdate.push(dataToAdd)
    }
  } else if (txn.name === 'txn_attendance_approve' || txn.name === 'txn_attendance_reject') {
    for (let i = 0; i < formData.length; i++) {
      const oldDocpId = formData[i].oldDocpId
      const newDocpId = formData[i].newId
      const txnOp = formData[i].txnOp
      const mainFolderId = formData[i].mainFolderId
      const propertyName = formData[i].propertyName
      dataToUpdate.push({data:{id:mainFolderId, [propertyName]:[{op:txnOp, data:{id:newDocpId}, key:oldDocpId}]}, key:mainFolderId, op:'U'})
    }
  }

  const { genPatch } = generatePatch();
  //preparing  the parameters to pass to patchGetter
  function makeParams() {
    const params = {
      baseFolderPath: baseFolder,
      params: {
        [baseFolder]:dataToUpdate
      },
    };
    console.log('here 2', params)
    return { params };

  }
  //generate txn
  function generateTxn() {
    //get the params
    const { params } = makeParams();
    //get the patch by passing the besettings and params to th genPatch
    txnToRun.params = genPatch(bSetting.output.data.records[0], params);

    //send it back
    return txnToRun;
  }
  return { generateTxn };
};

export default makeTxnForBulk;
