import store from '../../store';

const actionsListInFolder = (listOfAvailableTxn) => {
  // console.log(listOfAvailableTxn)
  const listActions = [];
  const formActions = [];
  const bSettings = store.getters['bSettings'];
  for (const key in listOfAvailableTxn) {
    const txn = listOfAvailableTxn[key];
    const permissions = txn.permissions || {};
    // if (txn.folder === "leaves") {
    //   return { actionsToShowInList: () => [], actionsToShowInForm: () => [] };
    // }

    const isEnvPermissionMet = (permissions.envPermissions === '' || evalPermission(permissions.envPermissions, {}, bSettings.env));
    const isEnvPermissionNoRestriction = (permissions.env === '');
    if (txn.form?.visible) {
      if ((txn.form?.list_view || txn.form?.bulk_operation) && isEnvPermissionMet) {
        // const newException = ['txn_employees_approve', 'txn_leaves_approve', 'txn_leaves_reject', 'txn_employees_reject']
        // if (newException.includes(txn.name)) {
        //   if (isAdminCheck(txn)) {
        //     listActions.push(txn);
        //   }
        // }
        // else {
        listActions.push(txn);

        // }

      }
      if ((txn.form?.record_view || txn.form?.single_operation) && (isEnvPermissionMet || isEnvPermissionNoRestriction)) {
        // const newException = ['txn_employees_approve', 'txn_leaves_approve', 'txn_leaves_reject', 'txn_employees_reject']
        // if (newException.includes(txn.name)) {
        //   if (isAdminCheck(txn)) {
        //     formActions.push(txn);
        //   }
        // }
        // else {
        formActions.push(txn);

        // }

      }
    }
  }

  //before returning adding the export txn in ListActions
  const folderExportTxn = bSettings?.output?.data?.records?.[0]?.business?.containers?.folders?.folders?.folders?.txns?.txn_folders_export;
  //for now adding correct label. later will be added from backend
  if (folderExportTxn?.label !== undefined && folderExportTxn?.label?.eng !== undefined) {
    folderExportTxn.label.eng = 'Export Folder'
  }
  listActions.push(folderExportTxn)
  // console.log('toshow', listActions, formActions)

  return { actionsToShowInList: () => listActions, actionsToShowInForm: () => formActions };
}
// function addShortCutKeys(txn) {
//   const iconsName = txn.label.eng.split(' ')[0];
//   if (iconsName === 'Delete') {
//     txn.shortcutKeys = 'Ctrl+Alt+d'
//   }

// }
// function isAdminCheck(txn) {
//   const bSettings = store.getters['bSettings'];

//   const code = bSettings.env.code = 'admin' ? true : false
//   const isAO = bSettings?.env?.isAO
//   const isRO = bSettings?.env?.isRO
//   if (code || isAO || isRO) {
//     return true
//   }
//   else {
//     return false
//   }

// }
function evalPermission(expr, data, env) {
  try {
    const fnBody = `try { ${expr} } catch(err) { throw new Error('error in expr evaluation ' + err.message) }`;
    const func = new Function('data', 'env', fnBody);
    return func(data, env);
  } catch (err) {
    // Handle error
    return err;
  }
}

export default actionsListInFolder;
