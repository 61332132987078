<template>
  <div
    class="header-class d-flex"
    @click="sortTheList"
  >
    <div>
      <v-icon
        :name="iconName"
        scale="1"
      />
    </div>
    <span>{{ headerName }}</span>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import makeSortingPath from '../../composables/makeSortingPath';

export default {
  name: 'HeaderComponent',

  props: {
    params: {
      type:Object,
      default:null
    }
  },

  setup(props) {
    const toggleState = computed(() => props.params.ascSortToggle);
    const iconName = computed(() => {
      return toggleState.value ? 'bi-sort-alpha-down' : 'bi-sort-alpha-up';
    });

    const headerName = ref('');

    function sortTheList() {
      const fieldInfo = props.params.fieldInfo;
      const currentFolder = props.params.currentFolder;
      const { getPathToFilter } = makeSortingPath(fieldInfo, currentFolder);
      const path = getPathToFilter();

      props.params.toggleSort(path);
    }

    onMounted(() => {
      headerName.value = props.params.headerName;
    });

    return {
      headerName,
      sortTheList,
      iconName,
    };
  },
};
</script>

<style lang="css" scoped>
.header-class {
  cursor: pointer;
}
</style>
