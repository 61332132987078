import getConverters from './getConverters';
const leavesCreateUtility = () => {

  function processLeavesList(leavesList) {
    const retVal = {}
    leavesList.forEach((each) => {
      if (!each.deleted) {
        const empCode = each.employees_id?.[0]?.code
        const leaveDays = each.leave_days || []
        const txnState = each.txn_state
        if (retVal[empCode] == null) {
          retVal[empCode] = []
          if (leaveDays) {
            leaveDays.forEach((eLdays) => {
              eLdays.employees_id = each.employees_id
              eLdays.creation_date = each.creation_date
              eLdays.txn_state = txnState
              eLdays.notes = each.notes
            })
          }
          retVal[empCode] = leaveDays
        }
        else {
          leaveDays?.forEach((eachLeave) => {
            eachLeave.employees_id = each.employees_id
            eachLeave.creation_date = each.creation_date
            eachLeave.notes = each.notes
            eachLeave.txn_state = txnState
            retVal[empCode].push(eachLeave)

          })
        }
      }
    })
    return retVal
  }
  function makeLeaveEvents(leavesList) {
    for (const key in leavesList) {
      leavesList[key].forEach((each) => {
        const {SQLTsRangeToArray } = getConverters();
        const istDateArray = SQLTsRangeToArray(each.leave_period, 'UTC', true);
        const startDate = formatDate(istDateArray[0])
        const endDate = formatDate(istDateArray[1])
        const label = each.leave_types_id?.[0]?.code
        const halfDay = each.leave_minutes == '480' ? false : true
        each.txnStateToShow = getTxnStateToShow(each)
        each.start = startDate,
        each.end = endDate,
        each.label = label,
        each.halfDay = halfDay,
        each.idToUse = each.id,
        each.backgroundColor = '#2f688d'
      })
    }
    return leavesList

  }
  function getTxnStateToShow(leaveData) {
    if (leaveData.txn_state != null) {
      const lastRecord = leaveData.txn_state[0]
      return lastRecord
    }
    else {
      return null
    }
  }
  function makeListForLeaves(eventsData) {
    let retVal = []
    for (const key in eventsData) {
      retVal = [...retVal, ...eventsData[key]]
    }
    return retVal
  }
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  function leavesListToShow(list) {
    return list
      .filter((each) => each.leave_days != null)
      .map((each) => ({
        ...each,
        leave_period: getLeavePeriod(each.leave_days),
        leave_types_id: getLeaveTypesId(each.leave_days),
        leave_status: getLeaveStatus(each.leave_days),
      }));
  }

  function getLeavePeriod(leaveDays) {
    const {SQLTsRangeToArray, arrayToSQLRange} = getConverters();
    const firstLeavePeriod = leaveDays?.[0]?.leave_period
    const lastLeavePeriod = leaveDays[leaveDays.length - 1].leave_period
    const firstLeaveArray = SQLTsRangeToArray(firstLeavePeriod, 'UTC', true);
    const lastLeaveArray = SQLTsRangeToArray(lastLeavePeriod, 'UTC', true)
    const finalArray = [firstLeaveArray[0], lastLeaveArray[1]]
    const finalPEriod = arrayToSQLRange(finalArray, true, false, true);
    return finalPEriod
  }
  function getLeaveTypesId(leaveDays) {
    const retVal = []
    leaveDays.forEach((each) => {
      if (!retVal.includes(each.leave_types_id?.[0]?.code)) {
        retVal.push(each.leave_types_id?.[0]?.code)

      }
    })
    return retVal

  }

  function getLeaveStatus(leaveDays) {
    const retVal = []
    leaveDays.forEach((each) => {
      if (!retVal.includes(each.leave_status?.[0]?.code)) {
        retVal.push(each.leave_status?.[0]?.code)

      }
    })
    return retVal

  }


  return { processLeavesList, makeLeaveEvents, makeListForLeaves, leavesListToShow};
};

export default leavesCreateUtility;
