<template>
  <div
    v-for="(item, index) in actions"
    :key="index"
    :hidden="hideActions"
    class="ms-2 mt-1 "
  >
    <div
      class="dropdown"
      :class="dropdownClass"
    >
      <div
        class="dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {{ item.name }}
      </div>
      <div
        class="dropdown-menu"
        aria-labelledby="navbarDropdown"
      >
        <div
          v-for="(actionItem, eachIndex) in item.actions"
          :key="eachIndex"
          class="dropdown-item"
          :data-test-id="makeDataTestId(actionItem)"
        >
          <div @click="handleAction(actionItem.action)">
            <v-icon
              :name="actionItem.icon"
              scale="1"
              :fill="actionItem.fill"
            />
            {{ actionItem.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'WfmActionsList',
  props:{
    actions:{
      type:Object,
      default:null
    },
    dataTestId: {
      type: String,
      default: '',
    },
    dropdownClass:{
      type:String,
      default:null
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['action-click'],
  setup(props, {emit}) {

    function makeDataTestId(action) {
      return `${props?.dataTestId}/${action?.name?.split(' ')[0]}`
    }
    function handleAction(action) {
      emit('action-click', action);
      //TODO: emit the action
    }
    return {
      makeDataTestId,
      handleAction
    }
  }
}
</script>

<style scoped>

.dropdown-item {
  width: 100%;
  font-size: 0.9rem;
  color:var(--main-font-color);
}
.dropdown{
  color:white;
}

.dropdown-menu{
  background-color:  var(--actions-list-background);
  box-shadow: 4px 3px var(--main-color);

}

.dropdown-menu :hover {
  transform: scale(1.05);
  color: var(--main-color);
}

.box {
  border-radius: 12px;
  background-color: red;
}

.folder-name {
  font-size: 18px;
  text-transform: capitalize;
  text-align: center;
  color: white;
}
</style>
