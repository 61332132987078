<template>
  <div class="d-flex justify-space-between mt-3 mx-1">
    <!-- tabs for the sidebar including folders,quick actions,reports -->
    <div
      v-for="(item, index) in tabsList"
      :key="index"
    >
      <div
        class="inactive-tab"
        :class="{ 'tab-head': tabsState[item] === true }"
      >
        <v-icon
          :name="tabIconsName[item]"
          :fill="tabsState[item]?'#292929':'grey'"
          :scale="tabsState[item]?'1.7':'1.7'"
          animation="pulse"
          :data-test-id="'sidebar/tab/'+item"
          hover
          @click="changeTheTab(item)"
        />
      </div>
    </div>
  </div>
  <div class="folder-name-container">
    <div
      v-if="tabsState.actions"
      :class="{ 'active-name p-1': tabsState.actions === true }"
    >
      <div class="p-2">
        Quick Actions
      </div>
    </div>

    <div
      v-if="tabsState.folders"
      :class="{ 'active-name p-1': tabsState.folders === true }"
    >
      <div class="p-2">
        Folders
      </div>
    </div>
    <div
      v-if="tabsState.reports"
      :class="{ 'active-name p-1': tabsState.reports === true }"
    >
      <div class="p-2">
        Reports
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import getFolders from '../../../composables/getFolders'
import {useStore} from 'vuex'
export default {
  name: 'WfmSidebarTabs',
  components: {
  },
  props: {
    tabsState:{ // used directly in template
      type:Object,
      default:null
    }
  },
  emits:['change-tab'],
  setup(props, context) {
    //variables
    const { getFoldersList} = getFolders();
    const store = useStore()
    const bSettings = store.getters['bSettings'];
    const visibleFolderList = getFoldersList(bSettings)

    const tabsList = computed(() => {
      const tabsToShow = ['folders', 'actions']
      //checking if the reports folder is visible to the user or not to show as sidebar tabs
      const hasReports = visibleFolderList.some((each) => each.name === 'reports');
      if (hasReports) {
        tabsToShow.push('reports');
      }

      return tabsToShow
    })

    const searchTerm = ref('')
    const tabIconsName = ref({
      actions: 'fa-bolt',
      folders: 'bi-folder-fill',
      reports: 'bi-clipboard2-data-fill'
    })

    async function changeTheTab(tabName) {
      //if change in the tab emit changeTab with tabName
      context.emit('change-tab', tabName)
    }
    return {
      searchTerm,
      tabIconsName,
      tabsList,
      changeTheTab
    }
  }
}
</script>

<style scoped>
.inactive-tab {
  border-radius: 8px;
  padding: 5px;
}

.tab-head {
  background-color: var(--sidebar-tab-background);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  margin-bottom: 0px;
  padding: 10px;

}

.folder-name-container {
  height: 45px;
}
.active-name {
  background-color: var(--sidebar-tab-background);
  font-size: large;
  color: #292929;
  font-weight: bold;
}
</style>
