<!-- eslint-disable vue/require-toggle-inside-transition -->

<template>
  <WfmToolbar
    custom-class="action-list p-4 ps-2 pe-1"
    :data-test-id="'generatePayslip'"
  >
    <template #left-utility>
      <WfmActionsList
        :actions="actionsToPerform.length>0?actionsToPerform:null"
        :data-test-id="'generatePayslip'"
        @action-click="performAction"
      />
    </template>
    <template #right-utility>
      <div class="d-flex">
        <div>
          <WfmButton
            button-class="update-btn"
            @button-click="generatePayslip()"
          >
            {{ currentTask+' Payslips' }}
          </WfmButton>
        </div>
      </div>
    </template>
  </WfmToolbar>
  <!-- show the form -->
  <div class="body-container">
    <WfmRadioButton
      :choices="radioOptions"
      @handle-change="selectionChanged"
    />
    <div class="row">
      <div class="col-sm-10">
        <WfmFieldGroup
          :key="formKey"
          :params="filledParams"
          :fields-to-display="fieldsToDisplay"
          :form-object-found="true"
          current-task="create"
          :base-folder="folder"
        />
      </div>
    </div>
    <WfmProgressLoader
      v-if="payslipsGenerationStarted"
      :total-count="totalCount"
      :current-count="currentCount"
      :use-count="true"
      @generated="handleGenerated"
    />
    <div
      class="containers"
    >
      <div class="text-success">
        <h5>
          Generating {{ totalCount>0?totalCount:'' }} Payslips
        </h5>
        <h6>({{ payPeiodEntered }})</h6>
      </div>
      <div class="mt-3 table-container">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">
                #
              </th>
              <th scope="col">
                Employee Name
              </th>
              <th scope="col">
                Status
              </th>
              <th scope="col">
                Message
              </th>
              <th scope="col">
                Payslip
              </th>
            </tr>
          </thead>
          <tbody class="list-body">
            <tr
              v-for="(eachSlip, index) in generatedPayslip"
              :key="index"
            >
              <th
                scope="row"
                class="text-center"
              >
                {{ index + 1 }}
              </th>
              <td class="text-center">
                <div v-if="getPayslipName(eachSlip.data)">
                  {{ getPayslipName(eachSlip.data) }}
                </div>
                <div v-else>
                  —
                </div>
              </td>
              <td class="text-center">
                <div>{{ eachSlip.status }}</div>
              </td>
              <td
                class="text-center"
                :class="{
                  'text-danger': eachSlip.status=='error',
                  'text-success': eachSlip.status=='success',
                }"
              >
                <div>{{ eachSlip.msg }}</div>
              </td>
              <td class="text-center">
                <v-icon
                  v-if="eachSlip.status === 'error'"
                  name="md-reportgmailerrorred"
                  fill="red"
                  scale="1.3"
                />
                <v-icon
                  v-else
                  name="fa-receipt"
                  fill="black"
                  scale="1.3"
                  @click="openPayslips(eachSlip.data)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import WfmToolbar from '../../common/wfm-toolbar.vue';
import WfmFieldGroup from '../wfm-folders/wfm-fieldGroup.vue';
import {computed, ref, provide} from 'vue'
import {keys} from '../../provideKeys.js'
import getFormInfo from '../../composables/getFormInfo';
import WfmButton from '../../common/wfm-button.vue';
import store from '../../../store';
import { sendMessage } from '../../services/websocket';
import { toast } from 'vue3-toastify';
import getFatRow from '../../composables/fatRowGenerator'
import getConverters from '../../composables/getConverters';
import WfmRadioButton from '../../common/wfm-radio-button.vue';
import WfmActionsList from '../../common/wfm-actions-list.vue';
import useIcons from '../../composables/getIcons'
import WfmProgressLoader from '../../common/loader/wfm-progress-loader.vue';
export default {
  name: 'PayslipGenerate',
  components: {
    WfmToolbar,
    WfmFieldGroup,
    WfmButton,
    WfmRadioButton,
    WfmActionsList,
    WfmProgressLoader
  },
  props:{
    folder:{
      type:Object,
      default:null
    },
    currentTask:{
      type:String,
      default:null
    },
    agGridApi:{
      type:String,
      default:null
    }

  },
  emits:['add-tab'],
  setup(props, context) {
    //store
    const generatedPayslip = ref([])
    const payslipsGenerationStarted = ref(false)
    const formData = ref({})
    const totalCount = ref(0)
    const currentCount = ref(0)
    const employeesSelected = ref(true)
    const departmentsSelected = ref(false)
    const {getIcon} = useIcons()
    const formKey = ref(0);
    const filledParams = ref({})
    const employeesList = computed(() => {
      return formData.value['employees_id']?.value
    })
    const allProcessComplete = computed(() => {
      return totalCount.value == currentCount.value
    })
    provide(keys.formData, formData)
    const notificationId = ref()
    const payPeiodEntered = computed(() => {
      const payPeriodSQl = formData.value['pay_period']?.value
      let startDate = ''
      let endDate = ''
      const {SQLTsRangeToArray} = getConverters();
      if (payPeriodSQl != null) {
        const payPeriod = JSON.stringify(SQLTsRangeToArray(payPeriodSQl, 'UTC', true))
        const payPeriodArrary = JSON.parse(payPeriod)
        startDate = new Date(payPeriodArrary[0]).toDateString()
        endDate = new Date(payPeriodArrary[1]).toDateString()
      }

      return `${startDate}-${endDate}`
    })
    const radioOptions = computed(() => {
      return {
        name:'Generate Payslips',
        options:[
          {
            name:'Department',
            checked:false
          },
          {
            name:'Employees',
            checked:true
          }
        ]
      }
    })
    const refBy = computed(() => {
      const retVal = []
      if (props.currentTask === 'generate') {
        const currentTxn = getCurrentTxn('create', props.folder)
        retVal.push({
          label: currentTxn.label.eng,
          name:currentTxn.label.eng,
          component: 'PayslipGenerate',
          refByName:'Employees Payslips',
          //passing searchPath to params too
          params : {folder: props.folder, readOnly:false, currentTask:'create'}
        })
      }
      return retVal
    })


    const {getCurrentTxn, getFormObject} = getFormInfo()

    const currentTxn = computed(() => {
      return getCurrentTxn(props.currentTask, props.folder)
    })
    function checkPaySlipGenerated(eachEmp) {
      const empId = eachEmp.id
      return generatedPayslip.value.some((each) => each.employees_id[0].id == empId)

    }
    function touchFormTest() {
      formKey.value = Math.floor((Math.random() * 10000) + 1);
    }

    const fieldsToDisplay = computed(() => {
      const formName = currentTxn.value?.form?.form_name ? currentTxn.value?.form?.form_name : currentTxn.value?.form;
      const fields = getFormObject(formName, props.folder);
      const finalFieldsToShow = fields.filter((each) => {
        const path = each.path;
        // Exclude fields based on selection criteria
        if (employeesSelected.value && path === 'payslips.departments_id') {
          return false;
        }
        if (departmentsSelected.value && path === 'payslips.employees_id') {
          return false;
        }
        return true;
      });
      return finalFieldsToShow;
    })
    function handleGenerated(txnResponse) {
      const retVal = txnResponse?.ctrl
      const statusType = retVal.output.type
      const msg = retVal.output.message
      currentCount.value = retVal.count_start
      totalCount.value = retVal.count_end
      const currentRecord = retVal?.output?.data?.records
      const records = []
      if (currentRecord != null) {
        for (let i = 0; i < currentRecord.length; i++) {
          records.push(JSON.parse(retVal.output.data.records[i]));
        }
        retVal.output.data.records = records;
        const { fatRowGenerator } = getFatRow()
        const listData = fatRowGenerator(retVal)
        if (listData.length > 0) {
          props.agGridApi?.applyTransaction({
            add: [listData[0]],
            addIndex: 0
          })
        }
        generatedPayslip.value.push({status:statusType, data:listData[0], msg:'Payslip Generated'})
      }
      else {
        //keeping for now as employee name is not coming in the list of failed records,
        //when employees names shows we can display
        generatedPayslip.value.push({status:statusType, data:null, msg:msg.split(':')[1]})

      }


    }
    function addRefByTab(refBy) {
      context.emit('add-tab', refBy)
    }
    function performAction(action) {
      action()
    }
    function getPayslipName(eachSlip) {
      const firstName = eachSlip?.employees_id?.[0]?.persons_id?.[0]?.first_name?.eng || ''
      const middleName = eachSlip?.employees_id?.[0]?.persons_id?.[0]?.middle_name?.eng || ''
      const lastName = eachSlip?.employees_id?.[0]?.persons_id?.[0]?.last_name?.eng || ''
      const code = eachSlip?.employees_id?.[0]?.code
      return (firstName + ' ' + middleName + ' ' + lastName) || code

    }
    function openPayslips(payslipData) {
      const employeeName = payslipData?.employees_id?.[0]?.persons_id?.[0]?.first_name.eng
      const payrollMonth = payslipData.payroll_month

      const monthOfSlip = new Date(payrollMonth).toLocaleString('default', { month: 'short' });
      const yearOfSlip = new Date(payrollMonth).getFullYear()
      const tabsSpec = {
        label: employeeName,
        component:'PayslipsTemplate',
        name:`${employeeName} ${monthOfSlip}/${yearOfSlip}`,
        params:{
          readOnly :false,
          folder:props.folder,
          payslipData:payslipData
        }
      }
      context.emit('add-tab', tabsSpec)
    }
    function selectionChanged(name) {
      if (name === 'Department') {
        departmentsSelected.value = true,
        employeesSelected.value = false
      }
      else if (name === 'Employees') {
        employeesSelected.value = true,
        departmentsSelected.value = false
      }
      touchFormTest()
      for (var key in formData.value) {
        if (key == 'pay_period') {
          filledParams.value[key] = formData.value[key].value
        }
      }
      generatedPayslip.value = []
      currentCount.value = 0
      totalCount.value = 0
    }
    function generatePayslip() {
      try {
        notificationId.value = toast.loading('generating payslips...', {
          position: toast.POSITION.TOP_CENTER,
        });
        // const formData = store.getters["commonModule/formData"]
        const params = {}
        //get the form Data
        if (departmentsSelected.value) {
          //if generating for departments
          const departmentsId = formData.value['departments_id']?.value.map((each) => each.id)
          params['departments_id'] = departmentsId
        }
        else if (employeesSelected.value) {
          //if generating for employees
          const employeeIds = formData.value['employees_id']?.value.map((each) => each.id)
          params['employees_id'] = employeeIds
        }
        params['pay_period'] = formData.value['pay_period']?.value

        //call the txn
        runTxn(currentTxn.value, params)

      } catch (error) {
        throw error
      }

    }
    async function runTxn(txn, params) {
      try {
        const sessionId = store.getters['sessionIdGetter'];
        txn.params = params
        txn.session_key = sessionId
        const retVal = await sendMessage(txn)
        if (retVal.output.type == 'error') {
          toast.update(notificationId.value, {
            render: retVal.output.message,
            autoClose: 3000,
            closeButton: true,
            type: 'error',
            isLoading: false,
          });
        }
        else if (retVal.output.type == 'success') {
          payslipsGenerationStarted.value = true
          toast.update(notificationId.value, {
            render: 'Payslips Generation Started..',
            autoClose: 2000,
            closeButton: true,
            type: 'success',
            isLoading: false,
          });
          touchFormTest()
        }

      } catch (error) {
        throw error
      }


    }
    const actionsList = computed(() => {
      const retVal = {name:'Actions', actions:[]}
      const downloadAction =
        {
          name:'Download',
          icon:getIcon('Download')?.name,
          fill:getIcon('Download')?.fill,
          action:() => {
            console.log('download function add here')
          }
        }
      const sendMailAction = {
        name:'Send Mail',
        icon:getIcon('Send')?.name,
        fill:getIcon('Send')?.fill,
        action:() => {
          console.log('send mail function here')
        }
      }
      retVal.actions.push(downloadAction)
      retVal.actions.push(sendMailAction)
      return retVal
    })

    const actionsToPerform = computed(() => {
      const retVal = []
      retVal.push(actionsList.value)
      return retVal
    })

    //:style="{ 'background-image': `url(${getImage(eachPayslip)})` }"

    return {
      actionsList,
      actionsToPerform,
      performAction,
      fieldsToDisplay,
      generatePayslip,
      refBy,
      addRefByTab,
      generatedPayslip,
      payslipsGenerationStarted,
      openPayslips,
      employeesSelected,
      departmentsSelected,
      touchFormTest,
      formKey,
      payPeiodEntered,
      radioOptions,
      selectionChanged,
      filledParams,
      getPayslipName,
      handleGenerated,
      employeesList,
      checkPaySlipGenerated,
      currentCount,
      totalCount,
      allProcessComplete
    }
  }
}
</script>

<style scoped>
.table thead{
  position: sticky;
}

.list-body{
  text-align: left;
}
.list-header{
  text-align: left;

}
.containers{
  height: 75%;
  margin-top: 20px;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
}
.table-container{
  height: fit-content;
  max-height: 72%;
  overflow-y: scroll;
}

.table-container::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.body-container{
  background-color: var(--main-background-color);
  height: 95%;
  padding:20px;
}

.update-btn {
    color: #6faa5dff;
    color: white;
    border: 1px solid white;
    height: fit-content;
    font-weight: 600;
    font-size: 12px;
    margin:10px 20px;
    border-radius: 8px;
    box-shadow: 1px 1px #2e688dff;

}

.update-btn:hover {
    color: white;
    border: 1px solid white;
    background-color: var(--co-main-color);
}

.action-list {
  color: white;
  background-color: var(--toolbar-color);
}


.glass {
  position: relative;
  display: inline-block;
  padding: 15px 25px;
  background-color: red;
  background-image: linear-gradient(green, lightgreen);
}

.glass:after {
  content: '';
  position: absolute;
  background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.2));
}

</style>
