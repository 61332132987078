<template>
  <transition
    name="modal-fade"
    appear
  >
    <div
      ref="modalRef"
      class="modal-backdrops"
    >
      <div
        ref="modalBoxRef"
        class="modals"
        :class="customModalDivClass"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header
          id="modalTitle"
          class="modal-headers"
        >
          <slot name="header" />
          <button
            type="button"
            class="btn"
            aria-label="Close modal"
            data-test-id="close_folder_form"
            @click="close"
          >
            <v-icon
              v-if="close"
              fill="#2e688dff"
              name="bi-x"
              scale="1.5"
            />
          </button>
        </header>
        <section
          id="modalDescription"
          class="modal-body"
        >
          <slot name="body" />
        </section>
        <footer class="modal-footers">
          <slot name="footer" />
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
import { ref } from 'vue';
export default {
  name: 'ModalView',
  props:{
    customModalDivClass:{
      type:String,
      default:null
    }
  },
  emits: ['close', 'submit'],
  setup(props, context) {
    //variables
    const modalBoxRef = ref(null);
    //methods
    function close() {
      context.emit('close');
    }
    function submit() {
      context.emit('submit');
    }

    return {
      modalBoxRef,
      close,
      submit,
    };
  },
};
</script>

<style>
.modal-backdrops {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  font-size: 1rem;
  /* Apply a blur effect */
  backdrop-filter: blur(2px);
}

#modalTitle {
  height: 45px;
}

.modal-headers,
.modal-footers {
  padding: 5px;
  display: flex;
}

.modal-footers {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: fit-content;
  padding:15px;
  align-items: flex-end;
}

.modals {
  margin: 20px;
  background: var(--main-background-color);
  box-shadow: 2px 2px 20px 1px;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  width: fit-content;
  height: auto;
  border-radius: 8px;
}

.modal-headers {
  padding: 2px;
  display: flex;
  height: fit-content;
  position: relative;
  width: 100%;
  color: var(--main-font-color);
  justify-content: space-between;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.modal-body {
  position: relative;
  width: auto;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: var(--main-font-color);
  background: transparent;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.btn {
  color: white;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
</style>
