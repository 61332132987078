const userModule = {
  namespaced: true,
  state: {
    isLoggedIn : localStorage.getItem('isLoggedIn'),
    username: localStorage.getItem('username'),
    swHandle: localStorage.getItem('swHandle'),
  },
  getters: {
    isLoggedIn: function(state) {
      if (state.isLoggedIn === 'true') {
        return true;
      }
      else {
        return false;
      }
    },
    username: function(state) {
      return state.username
    },
    swHandle: function(state) {
      return state.swHandle
    }
  },
  mutations: {
    isLoggedInMutation(state, value) {
      localStorage.setItem('isLoggedIn', value);
    },
    usernameMutation(state, value) {
      localStorage.setItem('username', value)
      state.username = value
    },
    swHandleMutation(state, value) {
      localStorage.setItem('swHandle', value)
      state.swHandle = value
    }
  },
  actions: {
    swHandleSetter(context, value) {
      context.commit('swHandleMutation', value)
    },
    usernameSetter(context, value) {
      context.commit('usernameMutation', value)

    },
    isLoggedInSetter(context, value) {
      context.commit('isLoggedInMutation', value);
    }
  }
}

export default userModule;
