<template>
  <div
    v-if="!reportfilterByTerm"
    class=""
  >
    <v-icon
      name="bi-clipboard2-data"
      fill="black"
      scale="3"
      animation="pulse"
    />
    Getting Reports..
  </div>
  <div v-else>
    <div
      v-for="(item, index) in reportfilterByTerm"
      :key="index"
      class="folders"
      :data-test-id="reportsListTestId(item)"
      @click="showReport(item, index)"
    >
      <div class="reports-info mt-1 row">
        <div class="col">
          <div class=" ms-1">
            <span>{{ reportName(item.name) }}</span>
          </div>
          <div class="ms-1">
            Updated on: <span class="text-success">{{ lastModified(item.last_modified) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed, onMounted, ref} from 'vue'
import { useRouter } from 'vue-router'
import reportsUtility from '../../../composables/reportsData'
import getConverters from '../../../composables/getConverters'
import getFolders from '../../../composables/getFolders'
import postData from '../../../composables/postData'
import getFatRow from '../../../composables/fatRowGenerator'
export default {
  name: 'ReportsTab',
  props:{
    searchTerm:{
      type:String,
      default:''
    }
  },
  emits: ['hide-sidebar'],
  setup(props, context) {
    //store
    const store = useStore()
    //Refs
    function lastModified(timeStamp) {
      const { timeStampStoredToUserFriendly } = getConverters();

      return timeStampStoredToUserFriendly(timeStamp)
    }
    const router = useRouter()
    //computed
    const reportsList = ref(null)
    const reportfilterByTerm = computed(() => {
      return reportsList.value?.filter((reports) => {
        return (
          reports.name
            .toLowerCase()
            .indexOf(props.searchTerm.toLowerCase()) != -1
        )
      })
    })

    //Methods
    async function showReport(data, index) {
      // router.push("/generatePayslip")

      //reportData storing in localStore
      const {setReportsData} = reportsUtility()
      setReportsData(JSON.stringify(data))
      router.push({ name: 'tabs', params: { name: `view_report/${data.name}`}});
      context.emit('hide-sidebar')
      // router.push({
      //   name: "Reportsview",
      //   params: {
      //     name: data.name
      //   }
      // });
      // context.emit("hide-sidebar")

    }
    function reportName(name) {
      const ary = name.split('_')
      ary.shift()
      const displayName = ary.join(' ')
      return displayName
    }
    //adding the reports list getter function here to be called on mounted.no need to keep in store
    async function getReportsList() {
      const { getFoldersList, getCurrentFolder } = getFolders()
      const bSettings = store.getters['bSettings'];
      const folderList = getFoldersList(bSettings);
      const folderName = 'reports'
      const currentFolder = getCurrentFolder(folderName, folderList);
      const { listData } = postData();
      const txnParams = { refRows: true };
      const retVal = await listData(currentFolder, txnParams);
      if (retVal.output.type === 'error') {
        console.log(retVal.output);
      } else {
        if (retVal.output.data.records.length > 0) {
          const { fatRowGenerator } = getFatRow();
          reportsList.value = fatRowGenerator(retVal);
        }
      }
    }
    function reportsListTestId(item) {
      return `sidebarTab/${item.name}`
    }
    onMounted(() => {
      //get the lit of reports on mounted
      getReportsList()
    })
    return {
      reportfilterByTerm,
      showReport,
      reportName,
      lastModified,
      reportsListTestId
    }
  }
}
</script>

<style scoped>
.reports-info {
  font-size: 0.8rem;
  font-weight: 900;
  text-transform: capitalize;
}


.folders {
  background-color: var(--folder-background);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 8px;
  font-size: 1rem;
  margin: 10px;
  font-weight: 700;
  border-radius: 10px;
    border-left:2px solid var(--main-color);

  box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
}

.folders:hover {
  transform: scale(1.02);
  border-radius: 0px;
  padding-left: 40px;
  color:white;
  background-color: #2e688dff;

  border-radius: 10px;


}
</style>
