const useLeaveDelete = (folder) => {

  function getLeaveDeleteTxn() {
    const txnAvailable = folder.txns
    return txnAvailable['txn_leaves_delete']
  }

  return { getLeaveDeleteTxn};
}

export default useLeaveDelete;
