import { createI18n } from 'vue-i18n';
import englishTranslation from './english';
import frenchTranslation from './french';
import hindiTranslation from './hindi';
import bengaliTranslation from './bengali';

const messages = {
  eng:englishTranslation,
  fr: frenchTranslation,
  hin: hindiTranslation,
  ben: bengaliTranslation
}

const i18n = createI18n({
  legacy: false,
  locale: JSON.parse(localStorage.getItem('languageChoice'))?.code || 'eng',
  messages
})


export default i18n;
