<template>
  <div
    v-if="attStatus.show"
    class="container"
  >
    <div class="ag-container">
      <div class="ag-status">
        <div
          v-if="attStatus.isDeficit !== null && attStatus.isDeficit"
          class="img"
        >
          *
        </div>
        <div
          v-if="attStatus.isSettled !== null && attStatus.isSettled"
          class="img"
        >
          <v-icon
            name="bi-check-circle-fill"
            scale="0.8"
            fill="rgb(69 98 69)"
          />
        </div>
        <div
          v-if="attStatus.approvedAO !== null && attStatus.approvedAO"
          class="img"
        >
          <v-icon
            name="bi-check-all"
            scale="0.8"
            fill="rgb(69 98 69)"
          />
        </div>
        <div
          v-else-if="attStatus.approvedRO !== null && attStatus.approvedRO"
          class="img"
        >
          <v-icon
            name="bi-check"
            scale="0.8"
            fill="rgb(69 98 69)"
          />
        </div>
        <div
          v-if="attStatus.rejected !== null && attStatus.rejected"
          class="img"
          alt="E"
        >
          <v-icon
            name="bi-x"
            scale="0.8"
            fill="rgb(69 98 69)"
          />
        </div>
        <div
          v-if="attStatus.systemEdit !== null && attStatus.systemEdit"
          class="flag orange"
          alt="S"
        >
        <!---->
        </div>
        <div
          v-if="attStatus.manualEdit !== null && attStatus.manualEdit"
          class="flag red"
          alt="M"
        >
        <!---->
        </div>
        <span class="shift">{{ attStatus.shift }}</span>
      </div>
      <div class="att-status">
        <span class="attendance">{{ attStatus.attendance }}</span>
      </div>
      <div class="hrs">
        <span
          v-if="attStatus.showInOut"
          class="hours"
          style="font-size:10px; padding: 0; line-height: 1.2;"
        >{{ attStatus.inTime }} <br> {{ attStatus.outTime }}</span>
        <span
          v-else
          class="hours"
        >{{ attStatus.workingHrs }} | {{ attStatus.overTimeHrs }}</span>
      </div>
    </div>
    <div
      id="designationID"
      class="designation"
    >
      designation
    </div>
  </div>
  <div
    v-else
    class="container"
  >
    <!-- comment -->
  </div>
</template>

<script>
import {
  computed,
  nextTick,
} from 'vue';
import attendanceGridUtils from '../../../composables/attendanceGridUtils';
import getConverters from '../../../composables/getConverters';
export default {
  name: 'AttendanceCellRenderer',
  setup(props) {
    const { getRandomColor, convertUtcToIst } = attendanceGridUtils();
    const { convertMinutesToHours } = getConverters();

    function attData(props, show) {
      let isDeficit = false;
      if (props.params.value?.deficit_minutes < 0) {
        isDeficit = true;
      }
      let systemEdit = false;
      let manualEdit = false;
      if (props.params.value.exception !== null && props.params.value.exception !== undefined) {
        for (let i = 0; i < props.params.value.exception.length; i++) {
          if (props.params?.value?.exception?.[i]?.type === 'system' && !systemEdit) {
            systemEdit = true;
          } else if (props.params?.value?.exception?.[i]?.type === 'manual' && !manualEdit) {
            manualEdit = true;
          }
        }
      }
      let retVal = {};
      const targetElement = props.params.eGridCell;
      const selectAll = props.params.selectAllCells;
      const selectedCells = props.params.cellsSelected;
      if (selectAll) {
        targetElement.classList.add('ag-row-selected');
      } else if (selectedCells.length !== 0) {
        for (let i = 0; i < selectedCells.length; i++) {
          const keys = Object.keys(selectedCells[i]);
          if (keys?.[0] === props.params.data?.employees?.[0]?.id) {
            const colId = props.params.column.colId;
            if (selectedCells[i][keys[0]][colId] !== undefined) {
              targetElement.classList.add('ag-row-selected');
              break;
            } else {
              targetElement.classList.remove('ag-row-selected');
              break;
            }
          }
        }
      } else {
        targetElement.classList.remove('ag-row-selected');
      }
      let attendance = 'att_type' in props.params.value ? props.params.value['att_type']?.[0]?.['code'] : null;
      if (attendance !== 'HD' && props.params.value.leave_type !== undefined && props.params.value.leave_type !== null && props.params.value.leave_type.length > 0) {
        attendance = props.params.value.leave_type?.[0]?.code;
      }
      const workingHrs = convertMinutesToHours(props.params.value['working_minutes']);
      const overTimeHrs = convertMinutesToHours(props.params.value['overtime_minutes']);
      let shift = 'shifts_id' in props.params.value ? props.params.value['shifts_id']?.[0]?.['shift_code'] : null;
      const inTime = 'in_time' in props.params.value ? convertUtcToIst(props.params.value['in_time']) : null;
      const outTime = 'out_time' in props.params.value ? convertUtcToIst(props.params.value['out_time']) : null;
      if (shift !== null && shift !== undefined && shift.length > 2) {
        shift = shift.substring(0, 2);
      }
      let approvedAO = null;
      let approvedRO = null;
      let rejected = null;
      let isSettled = null;
      if ('attendance_status' in props.params.value) {
        if (props.params.value['attendance_status']?.[0].code === 'final_approved') {
          approvedAO = true;
        } else if (props.params.value['attendance_status']?.[0].code === 'intermediate_approved') {
          approvedRO = true;
        } else if (props.params.value['attendance_status']?.[0].code === 'rejected') {
          rejected = true;
        } else if (props.params.value['attendance_status']?.[0].code === 'settled') {
          isSettled = true;
        }
      }
      retVal = {
        'show': show,
        'attendance': attendance,
        'workingHrs': workingHrs,
        'overTimeHrs': overTimeHrs,
        'shift': shift,
        'approvedAO': approvedAO,
        'approvedRO': approvedRO,
        'isSettled': isSettled,
        'rejected': rejected,
        'systemEdit': systemEdit,
        'manualEdit': manualEdit,
        'isDeficit': isDeficit,
        'inTime': inTime,
        'outTime': outTime,
        'showInOut': props.params.showInOut,
      };
      const showSite = props.params.showSitesCellColor;
      const showDesignation = props.params.showDesignationsCellColor;
      if (('sites_id' in props.params.value) && showSite) {
        const backgroundColor = getRandomColor('sites_id', props.params.value.sites_id?.[0].city, 0.5);
        targetElement.style.backgroundColor = backgroundColor;
      } else {
        targetElement.style.backgroundColor = '';
      }
      if (!showSite) {
        if (attendance === 'P') {
          targetElement.style.backgroundColor = 'rgb(182 247 182)';
        } else if (attendance === 'A') {
          targetElement.style.backgroundColor = 'rgb(245 210 209)';
        } else if (attendance === 'H') {
          targetElement.style.backgroundColor = 'rgb(199 237 249)'
        } else if (attendance === 'SP') {
          targetElement.style.backgroundColor = ' #FFFFE0'
        } else if (attendance === 'PR') {
          targetElement.style.backgroundColor = '#D3D3D3'
        } else if (attendance === 'HD') {
          targetElement.style.backgroundImage = 'repeating-linear-gradient(to bottom, transparent, transparent 5px, rgb(255, 234, 191) 5px, #ffffff 10px), repeating-linear-gradient(to right, rgb(255, 234, 191), rgb(255 255 255) 5px, rgb(251 217 146) 5px, rgb(255, 234, 191) 10px) ';
        } else {
          targetElement.style.backgroundColor = 'rgb(255 234 191)'
        }
      }
      if ('designation' in props.params.value && showDesignation) {
        const backgroundColor = getRandomColor('designation', props.params.value.designation?.[0].code, 0.5);
        nextTick(() => {
          const designationElements = props.params.eGridCell.querySelector('.designation');
          if (designationElements) {
            designationElements.style.backgroundColor = backgroundColor;
          } else {
            console.error("Element with class 'designation' not found within eGridCell");
          }
        })
      } else {
        nextTick(() => {
          const designationElements = props.params.eGridCell.querySelector('.designation');
          if (designationElements) {
            designationElements.style.backgroundColor = '';
          } else {
            console.error("Element with class 'designation' not found within eGridCell");
          }
        })
      }
      if ('editing' in props.params.value) {
        if (props.params.value.editing) {
          targetElement.style.border = 'solid red 1px';
          targetElement.style.boxShadow = '0 0 5px';
        } else {
          targetElement.style.border = 'solid lightgreen 1px';
          targetElement.style.boxShadow = '0 0 5px';
        }
      }
      return retVal;
    }

    const attStatus = computed(() => {
      let retVal = {};
      let show = true;
      if (props.params['value'] !== undefined && props.params['value'] !== null) {
        retVal = attData(props, show);
      }
      else {
        const targetElement = props.params.eGridCell;
        targetElement.style.backgroundColor = '';
        targetElement.style.border = '';
        targetElement.style.boxShadow = '';
        targetElement.classList.remove('ag-row-selected');
        show = false;
        retVal = { 'show': show }
      }
      return retVal
    })

    return {
      attStatus,
    };
  }
}
</script>

<style>
.ag-cell {
  text-align: center;
  width: 100%;
  padding: 0px;
  height: 100%;
  border: none;
}

.ag-cell:focus {
  border: 1px solid rgb(0, 0, 0) !important;
}

.ag-ltr .ag-cell {
  border: none;
}

.container {
  width: 100% !important;
  padding: 0px !important;
  height: 100% !important;
}

.ag-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  border-right: 0.01px solid #aebbcc;
  z-index: 0;
}

.ag-status {
  z-index: 2;
  display: flex;
  height: 12px;
  margin-top: 3px;
  font-size: 10px;
  align-items: normal;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.img {
  margin: 0;
  padding: 0;
  width: 5px;
  height: 5px;
}

.editing {
  margin: 0;
  padding: 0;
  width: 8px;
  height: 8px;
}

.att-status {
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15px;
  font-size: 14px;
}

.attendance {
  margin: 0px;
  padding: 2px;
}

.hrs {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 12px;
  margin-bottom: 3px;
  z-index: 2;
}

.hours {
  font-size: 10px;
  padding: 2px;
}

.designation {
  position: relative;
  z-index: 1;
  display: flex;
  height: 18px;
  font-size: 0px;
  width: 100%;
  align-items: center;
  justify-content: center;
  bottom: 39px;
}

.flag {
    height: 7px;
    width: 7px;
    background-color: transparent;
    border-radius: 50%;
    border: 0.5px solid lightgray;
    display: inline-block;
    opacity: 1 !important;
}

.orange {
    background-color: orange;
}

.red {
    background-color: red;
}
</style>
