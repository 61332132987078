<template>
  <div
    v-if="readOnly"
    :data-test-id="dataTestId"
    class="list-view-class"
  >
    <!-- <VueDatePicker v-model="value"  :is-24="true" placeholder="choose range" range readonly/> -->
    <div class="row">
      <div
        class="col-sm-8"
        name="range"
      >
        {{ leavePeriodStr }}
      </div>
      <div
        class="col-sm"
        name="days"
      >
        <div class="vr" />
        {{ daysCount }} Days
        <div class="vr" />
      </div>
    </div>
  </div>
  <div v-else-if="showField">
    <span
      v-if="!showInTable && !readOnly && !tsRangeOptions?.fieldOnlyView"
      class="input-group my-2"
    >{{
       tsRangeOptions?.label?.[currentLanguage] }}
      <span
        v-if="isMandatory"
        class="ms-2"
        style="color: red"
      >
        *</span>
    </span>
    <div
      v-if="!showInTable"
      :class="{ 'd-block': isMissing || !isValid }"
      class="invalid-feedback p-0 text-xs"
    >
      {{ errorText }}
    </div>
    <div class="d-flex range-div">
      <VueDatePicker
        v-model="value"
        :uid="dataTestId"
        :class="{ 'mandatory-field': isMandatory && isMissing, 'invalid-field':!isValid}"
        text-input
        :is-24="false"
        :placeholder="placeHolder"
        :teleport="true"
        :data-test-id="dataTestId"
        :month-change-on-scroll="false"
        show-now-button
        :range="{partialRange:false}"
        :format="range"
        multi-calendars
        locale="en-GB"
        :ui="uiClasses"
        :disabled="isReadOnly || isEditNotAllowed"
        @update:model-value="handleChange"
        @closed="handleClosed"
      />
      <div
        v-if="value"
        class="days-count-div"
      >
        {{ rangeCount(value) }} Days
      </div>
    </div>

    <!-- <v-tooltip
      v-if="!showInTable && value!=null"
      activator="parent"
      open-delay="600"
      location="top"
    >
      {{ rangeCount(value) }} Days
    </v-tooltip> -->
    <v-tooltip
      v-if="showInTable && value!=null"
      activator="parent"
      open-delay="600"
      location="top"
    >
      {{ value }}
    </v-tooltip>
  </div>
</template>

<script>
import {ref, computed, toRef} from 'vue';
import getConverters from '../../composables/getConverters';
import {
  useI18n
} from 'vue-i18n';
import processExpr from '../../composables/processExprs';
import controlUtility from '../../composables/controlUtility';
export default {
  name: 'WfmTsRange',
  props: {
    params: {
      type: Object,
      default: null,
    }
  },
  emits: ['input-change', 'invalid-check', 'mandatory-check'],
  setup(props, context) {
    //variables
    const i8vue = useI18n()
    const currentLanguage = i8vue.locale.value
    const range = computed(() => {
      return 'dd/MM/yyyy'
    })

    const showInTable = props.params?.showInTable ? true : false;
    const readOnly = props.params?.readOnly ? true : false;
    const tsRangeOptions = props.params.fieldOptions
    const tsRangeParams = toRef(props.params.data)
    const propertyName = tsRangeOptions?.name;
    const folderName = tsRangeOptions?.path?.split('.')[0]
    const tsRangeValue = tsRangeParams.value?.[propertyName] ? tsRangeParams.value?.[propertyName] : tsRangeParams.value?.[folderName]?.[0]?.[propertyName] || null;
    const idOfTheFolder = tsRangeParams.value?.[folderName]?.[0]?.id ? tsRangeParams.value?.[folderName]?.[0]?.id : tsRangeParams.value?.id
    const isValid = ref(true)
    const valueEntered = ref(false)
    const fieldPath = tsRangeOptions?.path
    const { arrayToSQLRange, SQLTsRangeToArray } = getConverters();
    const displayValueRef = ref(JSON.stringify(SQLTsRangeToArray(tsRangeValue, 'UTC', true)));
    const value = ref(SQLTsRangeToArray(tsRangeValue, 'UTC', true));

    const mandatoryStatus = props.params.mandatoryStatus
    const isEditNotAllowed = props.params.isEditNotAllowed
    const missingFields = props.params.missingFields
    const currentFolder = ref(props.params.currentFolder)
    const mandatoryFields = props.params.mandatoryFields
    const invalidFields = props.params.invalidFields
    const { invalidCheck, mandatoryCheck } = controlUtility(invalidFields, mandatoryFields)

    const { processReadOnly, processVisibleExpr, processMandatoryExpr, processDefaultValue, processValidExpr} = processExpr(currentFolder.value, tsRangeParams.value, showInTable)

    //computed
    const placeHolder = computed(() => {
      return tsRangeOptions?.placeHolder ? tsRangeOptions?.placeHolder : tsRangeOptions?.label?.eng
    })

    const leavePeriodStr = computed(() => {
      let pstr = '';
      if (displayValueRef.value?.length > 0) {
        const dtarr = JSON.parse(displayValueRef.value);
        const startDate = new Date(dtarr[0]);
        const endDate = new Date(dtarr[1]);
        pstr = `${startDate.toLocaleDateString('en-GB')} - ${endDate.toLocaleDateString('en-GB')}`

      }
      return pstr;
    })
    const tsRangeReadOnly = computed(() => {
      if (value.value.length > 0) {
        const startArray = value.value[0].split('/')
        const endArray = value.value[1].split('/')
        const startDateToShow = `${startArray[1]}/${startArray[0]}/${startArray[2]}`
        const endDateToShow = `${endArray[1]}/${endArray[0]}/${endArray[2]}`
        return `${startDateToShow} -to- ${endDateToShow}`
      }
      else {
        return null
      }
    })
    const daysCount = computed(() => {
      let diffDays = null
      const msInDay = 24 * 60 * 60 * 1000;
      if (displayValueRef.value?.length > 0) {
        const dtarr = JSON.parse(displayValueRef.value);
        const startDate = new Date(dtarr[0]);
        const endDate = new Date(dtarr[1]);

        let diffms = endDate - startDate;
        if (diffms >= 0) {
          diffms += msInDay;
        }
        diffDays = (diffms / msInDay).toFixed(2)
      }
      return diffDays
    })
    const dataTestId = computed(() => {
      if (showInTable || readOnly) {
        const rowIndex = props.params.node.rowIndex
        const tableName = props.params.tableName
        return `${currentFolder.value.name}/${tableName}/${propertyName}/${rowIndex}`
      } else {
        if (currentFolder.value !== null) {
          return `${currentFolder.value?.name}/${propertyName}`
        }
        else {
          return `${tsRangeOptions?.type}/${propertyName}`
        }
      }
    })
    const uiClasses = computed(() => {
      return {
        input:  'form-input',
      }

    })
    const defaultValue = computed(() => {
      const retVal = processDefaultValue(tsRangeOptions?.default_value)
      return retVal
    });

    if (defaultValue.value) {
      getDefaultValue()
    }
    if (tsRangeValue !== null && props.makeParams) {
      handleChange()
    }
    const showField = computed(() => {
      if (!mandatoryStatus?.value) {
        return true
      }
      else {
        if (isMandatory.value) {
          return true
        }
        else {
          return false
        }
      }
    })
    const isMissing = computed(() => {
      if (missingFields?.value?.includes(dataTestId.value)) {
        if (valueEntered.value) {
          return false
        }
        else {
          return true

        }
      }
      else {
        return false
      }
    })
    const isVisible = computed(() => {
      const retVal = processVisibleExpr(tsRangeOptions?.visible_expr)
      return retVal
    })

    const isMandatory = computed(() => {
      const retVal = processMandatoryExpr(tsRangeOptions?.mandatory_expr) && isVisible.value
      if (!showInTable) {
        mandatoryCheck({
          propertyName: dataTestId.value,
          value: retVal,
        })
      }

      return retVal
    })
    const isReadOnly = computed(() => {
      const retVal = processReadOnly(tsRangeOptions?.readonly_expr)
      return retVal
    })
    const errorText = computed(() => {
      if (!isValid.value) {
        return 'Invalid Input'
      }
      if (isMissing.value) {
        return 'Mandatory field'
      }
      else {
        return ''
      }
    })
    //methods
    function rangeCount(range) {
      let diffDays = null
      const msInDay = 24 * 60 * 60 * 1000;
      if (range?.length > 0) {
        const dtarr = range;
        const startDate = new Date(dtarr[0]);
        const endDate = new Date(dtarr[1]);

        let diffms = endDate - startDate;
        if (diffms >= 0) {
          diffms += msInDay;
        }
        diffDays = (diffms / msInDay).toFixed(2)
      }
      return diffDays
    }
    function getDefaultValue() {
      // const defaultSelectedValue = tsRangeOptions.default_value
      // value.value = tsRangeStoredToUserFriendly(defaultSelectedValue?.replace(")", "]"));
      const processedDefault = SQLTsRangeToArray(defaultValue.value, 'UTC', true)
      value.value = processedDefault
      handleChange()
    }
    function handleChange() {
      if (value.value) {
        // if (!props.makeParams) {
        // if (typeof value.value === "string") {
        //   const dateStrings = JSON.parse(value.value);
        //   value.value = dateStrings.map(function(dateString) {
        //     var dateWithoutQuotes = dateString.substring(1, dateString.length - 1);
        //     return new Date(dateWithoutQuotes);
        //   });
        // }
        const drefData  = JSON.parse(JSON.stringify(value.value)).map((dt) => new Date(dt));

        const sqlRangeStr = arrayToSQLRange(drefData, true, false, true);
        // value.value = tsRangeIstToUtc(value.value)
        // } else {
        //   const dateString = props.params.leave_period;
        //   const dateArray = JSON.parse(dateString);
        //   value.value = dateArray;
        // }
        // Create a Date object from the given UTC date string
        // const endDate = new Date(value.value[1]);
        // // Add 24 hours to the date
        // endDate.setHours(endDate.getHours() + 24);
        // // Get the updated UTC date string
        // const excludedEndDate = endDate.toISOString();
        // const newValueToStore = `["${value.value?.[0]}","${excludedEndDate}")`
        if (sqlRangeStr) {
          valueEntered.value = true
        }
        if (tsRangeOptions.valid_expr !== undefined) {
          isValid.value = processValidExpr(tsRangeOptions.valid_expr, sqlRangeStr)

        }
        if (isValid.value) {
          // context.emit("invalid-check", { propertyName: propertyName, value: false })
          invalidCheck({ propertyName: propertyName, value: false })
          //if the data is changed in ag grid
          if (showInTable) {
            tsRangeParams.value[propertyName] = sqlRangeStr
            props.params?.cellValueChanged(tsRangeParams.value)
          }
          //else emit event to control
          else {
            context.emit('input-change', { path: fieldPath, value: sqlRangeStr, folderId: idOfTheFolder, mandatory: tsRangeOptions.mandatory_expr });
          }
        }
        else {
          // context.emit("invalid-check", { propertyName: propertyName, value: true })
          invalidCheck({ propertyName: propertyName, value: true })
        }

      }
      else {
        context.emit('input-change', { path: fieldPath, value: null, folderId: idOfTheFolder, mandatory: tsRangeOptions.mandatory_expr });

      }

    }
    function handleClosed() {
      //on close giving the focus back to the input field inside the datepicker
      document.getElementById(`dp-input-${dataTestId.value}`).focus()
    }
    return {
      value,
      handleChange,
      tsRangeOptions,
      showInTable,
      readOnly,
      currentLanguage,
      range,
      isMandatory,
      isReadOnly,
      tsRangeReadOnly,
      isMissing,
      errorText,
      isValid,
      showField,
      daysCount,
      handleClosed,
      isEditNotAllowed,
      placeHolder,
      leavePeriodStr,
      dataTestId,
      uiClasses,
      rangeCount
    };
  },
};
</script>
<style>
.form-input {
  min-height: 5px;
  width: 100%;
  background-color: var(--controls-background-color) !important;
  color:var(--controls-font-color) !important;
  cursor: pointer;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

}
.range-div{
  border:1px solid var(--controls-border-color);
  border-radius: 4px;

}
.dp__disabled {
 background: var(--vs-disabled-bg) !important;
 cursor:not-allowed !important;
 opacity: 0.5;
}
</style>
<style scoped>
.input-group-text {
  background-color: antiquewhite;
}

.days-count-div{
  background: rgb(237, 190, 101);
  font-size: 10px;
  font-weight: 700;
  min-height: 5px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding-top:2px;
  color:black;
}

.mandatory-field {
  border: 1px solid rgb(214, 65, 85) !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  color: black
}
.invalid-field{
  border: 1px solid rgb(214, 65, 85) !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  color: black

}

</style>
